import React from 'react';
// Redux
import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import TableRowBodyCustom from '~/components/common/table-row-body';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import SubmitButton from '~/components/common/submit-button';
import ImgIcon from '~/components/common/img-icon';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  persianGreenColor,
  veryLightGreyColor,
} from '~/styles/themes/common-styles/color';
// Type
import { Invitation } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  invitation: Invitation;
  isSent: boolean;
  accountSeleted: Account;
  listInvitations: (
    args: NetworkActions.QueryListInvitationsArgs,
  ) => Promise<NetworkActions.LIST_INVITATIONS_RESULT_TYPE>;
  responseInvitation: (
    args: NetworkActions.MutationResponseInvitationArgs,
  ) => Promise<NetworkActions.RESPONSE_INVITATION_RESULT_TYPE>;
  cancelInvitation: (
    args: NetworkActions.MutationCancelInvitationArgs,
  ) => Promise<NetworkActions.CANCEL_INVITATION_RESULT_TYPE>;
  listNetworks: (
    args: NetworkActions.QueryListNetworksArgs,
  ) => Promise<NetworkActions.LIST_NETWORKS_RESULT_TYPE>;
}

type SubmittingStatus = 'accepting' | 'rejecting' | 'canceling';

interface IState {
  submittingStatus?: SubmittingStatus;
}

class InvitationTableRow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, invitation, isSent } = this.props;

    return (
      <TableRowBodyCustom>
        <TableCellBodyCustom className={classes.tableCellBodyCommon}>
          <div className={classNames(invitation.isDeletedNetwork && classes.deletedText)}>
            <div className={classes.netWorkName}>
              {invitation.networkName}
              {invitation.isDeletedNetwork && <span>({this.props.t('deleted')})</span>}
            </div>
            <div className={classes.networkAddress}>{invitation.networkUuid}</div>
          </div>
        </TableCellBodyCustom>
        <TableCellBodyCustom
          className={classNames(classes.tableCellBodyCommon, classes.tableBodyTd)}
        >
          {isSent ? (
            <div className={classNames(invitation.isDeletedReceiverAccount && classes.deletedText)}>
              <div className={classes.accountName}>
                {invitation.receiverAccountName}
                {invitation.isDeletedReceiverAccount && <span>({this.props.t('withdrawn')})</span>}
              </div>
              <div className={classes.accountAddress}>{invitation.receiverAccountUuid}</div>
            </div>
          ) : (
            <div className={classNames(invitation.isDeletedSenderAccount && classes.deletedText)}>
              <div className={classes.accountName}>
                {invitation.senderAccountName}
                {invitation.isDeletedSenderAccount && <span>({this.props.t('withdrawn')})</span>}
              </div>
              <div className={classes.accountAddress}>{invitation.senderAccountUuid}</div>
            </div>
          )}
        </TableCellBodyCustom>
        <TableCellBodyCustom
          className={classNames(classes.tableCellBodyCommon, classes.tableBodyTd)}
        >
          {!invitation.isDeletedNetwork && this.renderAction(invitation, isSent)}
        </TableCellBodyCustom>
        <TableCellBodyCustom className={classes.tableCellBodyCommon}>
          <div className={classes.date}>
            {this.props.t('invitation_update_at', { date: new Date(invitation.updateAt) })}
          </div>
        </TableCellBodyCustom>
      </TableRowBodyCustom>
    );
  }

  private renderAction = (invitation: Invitation, isSent?: boolean) => {
    const { classes } = this.props;
    const { submittingStatus } = this.state;

    switch (invitation.status) {
      case 'accepted':
        return (
          <div>
            <ImgIcon className={classes.listIcon} imgUrl="/images/icons/check_green_ico.png" />
            <span className={classes.actionText}>{this.props.t('accepted')}</span>
          </div>
        );
      case 'rejected':
        return (
          <div>
            <ImgIcon className={classes.listIcon} imgUrl="/images/icons/reject_ico.png" />
            <span className={classes.actionText}>{this.props.t('rejected')}</span>
          </div>
        );
      case 'canceled':
        return (
          <div>
            <ImgIcon className={classes.listIcon} imgUrl="/images/icons/cancel_ico.png" />
            <span className={classes.actionText}>{this.props.t('cancelled')}</span>
          </div>
        );
      case 'pending':
        return (
          <div>
            {isSent ? (
              <>
                <ImgIcon className={classes.listIcon} imgUrl="/images/icons/pending_ico.png" />
                <span className={classNames(classes.actionText, classes.pendingTextLabel)}>
                  {this.props.t('pending')}
                </span>
                {isSent && (
                  <SubmitButton
                    data-testid="cancel-button"
                    kind="flat"
                    isSubmitting={submittingStatus === 'canceling'}
                    isValid={!submittingStatus}
                    classes={{ root: classes.cancelBtn }}
                    onClick={(e) => this.onCancelInvitation(invitation)}
                    label={this.props.t('cancel')}
                  />
                )}
              </>
            ) : (
              <>
                <SubmitButton
                  data-testid="accept-button"
                  id="member-invitations-detail-accept"
                  kind="flat"
                  isSubmitting={submittingStatus === 'accepting'}
                  isValid={!submittingStatus}
                  classes={{ root: classes.acceptBtn }}
                  onClick={(e) => this.onResponseInvitation(invitation, true)}
                  label={this.props.t('accept')}
                />
                <SubmitButton
                  data-testid="reject-button"
                  id="member-invitations-detail-reject"
                  kind="flat"
                  isSubmitting={submittingStatus === 'rejecting'}
                  isValid={!submittingStatus}
                  classes={{ root: classes.rejectBtn }}
                  onClick={(e) => this.onResponseInvitation(invitation, false)}
                  label={this.props.t('reject')}
                />
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  private onCancelInvitation = async (invitation: Invitation) => {
    this.updateSubmittingStatus('canceling');
    try {
      await this.props.cancelInvitation({
        input: {
          invitationUuid: invitation.invitationUuid,
          accountUuid: this.props.accountSeleted.accountUuid,
        },
      });
    } catch (error) {
      // Do not need handle this error
    }
    this.updateSubmittingStatus();
  };

  private onResponseInvitation = async (invitation: Invitation, accept: boolean) => {
    this.updateSubmittingStatus(accept ? 'accepting' : 'rejecting');
    try {
      await this.props.responseInvitation({
        input: {
          invitationUuid: invitation.invitationUuid,
          accountUuid: this.props.accountSeleted.accountUuid,
          status: accept ? 'accepted' : 'rejected',
        },
      });
    } catch (error) {
      // Do not need handle this error
    }
    this.updateSubmittingStatus();
    this.props.listNetworks({
      accountUuid: this.props.accountSeleted.accountUuid,
    });
  };

  private updateSubmittingStatus = (status?: SubmittingStatus) => {
    this.setState({
      submittingStatus: status,
    });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    tableCellBodyCommon: {
      wordBreak: 'break-all',
    },
    netWorkName: {
      '& span': {
        marginLeft: 5,
      },
    },
    listIcon: {
      verticalAlign: 'middle',
    },
    networkAddress: {
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
    },
    networkNameText: {
      marginRight: 5,
    },
    accountName: {
      '& span': {
        marginLeft: 5,
      },
    },
    accountAddress: {
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
    },
    deletedText: {
      '& div': {
        color: `${veryLightGreyColor} !important`,
      },
    },
    date: {
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
      paddingTop: 29,
      paddingBottom: 29,
    },
    actionText: {
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
      marginLeft: 20,
    },
    pendingTextLabel: {
      marginRight: 12,
    },
    acceptBtn: {
      width: 85,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: persianGreenColor,
      marginLeft: 'inherit',
      margin: '5px 0',
      '&:hover': {
        backgroundColor: persianGreenColor,
      },
    },
    rejectBtn: {
      width: 85,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: lightSlateGreyColor,
      marginLeft: 'inherit',
      margin: '5px 0',
      '&:hover': {
        backgroundColor: lightSlateGreyColor,
      },
    },
    cancelBtn: {
      width: 85,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: lightSlateGreyColor,
      marginLeft: 'inherit',
      margin: '5px 0',
      '&:hover': {
        backgroundColor: lightSlateGreyColor,
      },
    },
    tableBodyTd: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableBodyTd: {
        padding: 0,
      },
    },
  });

export default withStyles(styles)(withTranslation()(InvitationTableRow));

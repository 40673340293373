import React, { useEffect, useMemo, useRef } from 'react';
import firebase from 'firebase';
import 'firebase/auth';
import FirebaseUI from 'firebaseui';
import isEqual from 'lodash/isEqual';

const ELEMENT_ID = 'firebaseui_container';

interface Props {
  auth: any;
  language?: string;
  className?: string;
  config?: FirebaseUI.auth.Config;
}

const fbConfig = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {
      const user = authResult.user;
      if (user && !user.emailVerified) {
        user.sendEmailVerification();
        return false;
      }
      return true;
    },
  },
  signInFlow: 'popup',
  credentialHelper: 'none',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID, // Email/Password Provider
  ],
};

const FirebaseAuthLocalized: React.FC<Props> = (props) => {
  const { className, language = 'en', config } = props;
  const ref = useRef<HTMLDivElement>(null);
  const firebaseUiWidget = useRef<FirebaseUI.auth.AuthUI>();
  const firebaseUiDeletion = useRef<Promise<void>>();
  const firebaseConfig = useMemo(() => ({ ...fbConfig, ...config }), [config]);

  useEffect(() => {
    require('firebaseui/dist/firebaseui.css');
  }, []);

  useEffect(() => {
    const firebaseui: typeof FirebaseUI = require(`firebaseui/dist/esm__${language}`);
    (async () => {
      if (firebaseUiDeletion) {
        // Wait to finish deleting the previous render.
        await firebaseUiDeletion.current;
      }
      const element = ref.current;
      if (element) {
        firebaseUiWidget.current =
          firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        firebaseUiWidget.current.start(element, firebaseConfig);
      }
    })();
    return () => {
      firebaseUiDeletion.current = firebaseUiWidget.current?.delete();
      /**
       * Delete the cache and we can re-require again with other language without reload the page.
       * More info here: https://webpack.js.org/api/module-methods/#requirecache
       */
      delete require.cache[require.resolve(`firebaseui/dist/esm__${language}`)];
    };
  }, [firebaseConfig, language]);

  return <div ref={ref} className={className} />;
};

export default FirebaseAuthLocalized;

import React from 'react';
import { connect } from 'react-redux';
// Redux
import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import CustomSelect from '~/components/common/custom-select';
import CustomInput from '~/components/common/custom-input';
import SubmitButton from '~/components/common/submit-button';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import { networkRoleSelections } from '~/components/common/configs';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  whiteColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
// Type
import { NetworkRoleType, Account, AccountRoleType } from '~/types/account-types';
import { INetwork } from '~/types/network-types';
// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {
  network?: INetwork;
  account?: Account;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
  getMember: (args: AppActions.QueryGetMemberArgs) => Promise<AppActions.GET_MEMBER_RESULT_TYPE>;
  addMember: (args: AppActions.MutationAddMemberArgs) => Promise<AppActions.ADD_MEMBER_RESULT_TYPE>;
  grantNetworkRole: (
    args: NetworkActions.MutationGrantNetworkRoleArgs,
  ) => Promise<NetworkActions.GRANT_NETWORK_ROLE_RESULT_TYPE>;
  listMembers: (
    args: AppActions.QueryToListMembersArgs,
  ) => Promise<AppActions.LIST_MEMBERS_RESULT_TYPE>;
  listNetworkAccesses: (
    args: NetworkActions.QueryListNetworkAccessesArgs,
  ) => Promise<NetworkActions.LIST_NETWORK_ACCESSES_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

interface IState {}

class GrantAccessDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, t } = this.props;

    return (
      <>
        <CustomDialog open={open} onClose={onClose}>
          <Formik
            initialValues={initialValues}
            validationSchema={this.getValidateSchema}
            onSubmit={this.onSubmit}
            render={({ isValid, isSubmitting }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-access-add-title">{t('grant_access')}</div>
                </CustomDialogTitle>
                {/* Render Form Field */}
                <CustomDialogContent>
                  <div title="email-field">
                    <Field name="email" render={this.emailField} />
                  </div>
                  <div className={classes.formSection} title="network-role-field">
                    <Field name="networkRole" render={this.networkRoleField} />
                  </div>
                </CustomDialogContent>
                {/* Render Submit Button */}
                <CustomDialogActions>
                  <Button
                    data-testid="cancel-button"
                    id="member-access-add-submit-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    variant="contained"
                    onClick={onClose}
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    id="member-access-add-submit-add"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('grant')}
                    submittingLabel={t('granting')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>
      </>
    );
  }

  private emailField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('email')}</div>
          {!!form.errors.email && form.touched.email && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.email}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="email-address-input"
            {...field}
            id="member-access-add-input-email"
            placeholder={this.props.t('input_email_address')}
          />
        </div>
      </>
    );
  };

  private networkRoleField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, selectedAccountRole, selectedNetworkRole } = this.props;
    const options: { value: NetworkRoleType; label: string }[] =
      selectedAccountRole === 'owner' || selectedAccountRole === 'admin'
        ? [
            { value: 'owner', label: 'Owner' },
            { value: 'admin', label: 'Admin' },
            { value: 'operator', label: 'Operator' },
            { value: 'member', label: 'Member' },
          ]
        : selectedNetworkRole === 'owner'
        ? [
            { value: 'admin', label: 'Admin' },
            { value: 'operator', label: 'Operator' },
            { value: 'member', label: 'Member' },
          ]
        : selectedNetworkRole === 'admin'
        ? [
            { value: 'operator', label: 'Operator' },
            { value: 'member', label: 'Member' },
          ]
        : [];

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('network_role')}</div>
          {!!form.errors.networkRole && form.touched.networkRole && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.networkRole}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="role-select"
            {...field}
            id="member-access-add-input-role"
            valueSelected={field.value}
            placeholder={this.props.t('select_role')}
            items={options}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const {
      onClose,
      account,
      grantNetworkRole,
      network,
      listNetworkAccesses,
      listMembers,
      selectedAccountRole,
    } = this.props;
    const callableListMembers =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedAccountRole === 'member';
    if (!account || !network) {
      return;
    }
    try {
      await grantNetworkRole({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          networkRole: values.networkRole as NetworkRoleType,
          email: values.email,
        },
      });
      onClose();
      callableListMembers && listMembers({ accountUuid: account.accountUuid });
      listNetworkAccesses({
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
      }).catch(console.log);
    } catch (error) {
      // Don't need handle this error
    }
    setSubmitting(false);
  };

  private getValidateSchema = () => {
    return Yup.object().shape<FormValues>({
      email: Yup.string()
        .required(this.props.t('required_field'))
        .email(this.props.t('invalid_email')),
      networkRole: Yup.string().required(this.props.t('required_field')),
    });
  };
}

const styles = createStyles({
  root: {},
  dialogTitle: {
    paddingBottom: 25,
  },
  // Form Field
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  network: store.appState.networkSelected,
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
  getMember: (args: AppActions.QueryGetMemberArgs) => dispatch(AppActions.getMember(args)),
  addMember: (args: AppActions.MutationAddMemberArgs) => dispatch(AppActions.addMember(args)),
  grantNetworkRole: (args: NetworkActions.MutationGrantNetworkRoleArgs) =>
    dispatch(NetworkActions.grantNetworkRole(args)),
  listMembers: (args: AppActions.QueryToListMembersArgs) => dispatch(AppActions.listMembers(args)),
  listNetworkAccesses: (args: NetworkActions.QueryListNetworkAccessesArgs) =>
    dispatch(NetworkActions.listNetworkAccesses(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GrantAccessDialog)),
);

type FormValues = {
  email: string;
  networkRole: string;
};

const initialValues: FormValues = {
  email: '',
  networkRole: '',
};

import React from 'react';
import classNames from 'classnames';
import { greyChateauColor } from '~/styles/themes/common-styles/color';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

interface IProps {
  color?: string;
  size?: string | number;
  direction: 'up' | 'down' | 'right' | 'left';
  className?: string;
}

const arrowIcon: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  color,
  size,
  direction,
  className,
}) => {
  const transform = directions[direction];

  return (
    <div
      className={classNames(classes.root, className)}
      style={{
        borderColor: color,
        padding: size,
        transform: transform,
      }}
    ></div>
  );
};

arrowIcon.defaultProps = {
  color: greyChateauColor,
  size: 2,
};

const styles: StyleRules = {
  root: {
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    borderStyle: 'solid',
  },
};

export default withStyles(styles)(arrowIcon);

const directions = {
  up: 'rotate(-135deg)',
  down: 'rotate(45deg)',
  right: 'rotate(-45deg)',
  left: 'rotate(135deg)',
};

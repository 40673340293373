import React from 'react';
// Redux
// Component
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';

import ImgIcon from '~/components/common/img-icon';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose?: () => void;
  scroll?: 'body' | 'paper' | undefined;
}

const customDialog: React.StatelessComponent<IProps> = ({
  classes,
  children,
  open,
  onClose,
  scroll,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    scroll={scroll}
    classes={{
      paper: classes.paper,
    }}
  >
    {children}
    <IconButton
      aria-label="close"
      className={classes.closeBtn}
      onClick={onClose}
      data-testid="dialog-close-button"
    >
      <ImgIcon className={classes.closeIcon} imgUrl="/images/icons/close-ico.png" />
    </IconButton>
  </Dialog>
);

customDialog.defaultProps = {
  open: false,
};

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 700,
      '& form': {
        flexDirection: 'inherit',
        overflow: 'hidden',
      },
      margin: '48px auto',
      verticalAlign: 'top',
    },
    closeIcon: {
      width: 16,
      height: 16,
      important: 'true',
    },
    closeBtn: {
      position: 'absolute !important' as any,
      top: 15,
      right: 15,
    },
  });

export default withStyles(styles)(customDialog);

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { nightRiderColor } from '~/styles/themes/common-styles/color';

interface IProps extends TableCellProps {}

const tableCellBody: React.StatelessComponent<IProps & WithStyles<typeof styles>> = (props) => {
  const { classes, className, children, ...tableCellProps } = props;
  return (
    <TableCell {...tableCellProps} className={classNames(classes.root, classes.content, className)}>
      {children}
    </TableCell>
  );
};

const styles: StyleRules = {
  root: {
    ...defaultFont,
    fontSize: 14,
    color: nightRiderColor,
    maxWidth: 0,
  },
  content: {
    paddingLeft: 10,
    paddingRight: 30,
  },
};

export default withStyles(styles)(tableCellBody);

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { denimColor } from '~/styles/themes/common-styles/color';
// Translation
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';
import ProposalList from './proposal-list';
import ProposalHistoryList from './proposal-history';
import { Divider } from '@mui/material';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

const ProposalsTab = (props: IProps) => {
  const { t } = useTranslation();
  const { classes, network, account } = props;
  document.title = TAB_TITLE_CONCAT + t('proposals');

  return (
    <>
      <ProposalList account={account} network={network} />
      <Divider className={classes.divider} />
      <ProposalHistoryList account={account} network={network} />
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    topLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    divider: {
      marginTop: 20,
      marginBottom: 20,
    },
    createClusterBtn: {
      width: 'auto',
      color: '#333',
    },
    addClusterIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tableArea: {
      marginBottom: 20,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
      transform: 'translateY(2px)',
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    typeCell: {
      width: '10%',
    },
    addressCell: {
      width: '30%',
    },
    statusCell: {
      width: '15%',
    },
    actionCell: {
      width: '10%',
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
    loading: {
      margin: 10,
    },
  });

export default withStyles(styles)(withTranslation()(ProposalsTab));

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import '../../styles/scss/not-found.scss';

interface IProps {}

const PageNotFound: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="root">
      <div className="header">
        <img className="logo" src="/images/icons/logo_guest.svg" alt="" />
      </div>
      <div className="content">
        <div className="content-container">
          <div className="description">{t('not_found_page_description')}</div>
          <div className="code">404</div>
          <div className="top-page-btn-wrapper">
            <a href="/">
              <button>{t('not_found_page_top_page_button_label')}</button>
            </a>
          </div>
          <a href="https://bccloud.zendesk.com">
            <div className="contact-link">{t('not_found_page_contact_us_text')}</div>
          </a>
        </div>
      </div>
      <div className="footer">
        <div className="copyright">© G.U.Technologies, Inc.</div>
      </div>
    </div>
  );
};

const styles = createStyles({});

export default withStyles(styles)(PageNotFound);

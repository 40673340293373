import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  dimGrayColor,
  denimColor,
  whiteSmokeColor,
  whiteColor,
  romanColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';

import { IStore } from '~/stores/configure-store';
import * as PaymentActions from '~/stores/actions/payment-action';
import { Account } from '~/types/account-types';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import { connect } from 'react-redux';
import SubmitButton from './submit-button';

import RegisterCardDialog from './register-card-dialog';

// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

// defines
import { LICENSE_TYPE_ID_STORAGE, PAYMENT_MINIMUM_CHARGE_VAL } from '~/constants/consts';
import { TAX_RATE_BASE } from '~/constants/taxes';
import { displayCurrency } from '~/utilities/payment-utils';
import classNames from 'classnames';
import { bold } from 'colors/safe';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: (status?: number) => void;
  accountSeleted?: Account;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  requireAddr?: boolean;
}

interface IDispProps {
  purchaseLicenses: (
    args: PaymentActions.MutationPurchaseLicensesArgs,
  ) => Promise<PaymentActions.PURCHASE_LICENSES_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
}

interface IState {
  isSubmitting: boolean;
  openRegisterCardDialog: boolean;
}

class ConfirmLicenseDialog extends React.Component<
  IProps & IDispProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      openRegisterCardDialog: false,
    };
  }

  componentWillUnmount() {
    this.props.onClose(0);
    this.setState({
      isSubmitting: false,
    });
  }

  public render() {
    const { classes, open, t } = this.props;
    const { isSubmitting, openRegisterCardDialog } = this.state;
    const { numDays, discount, expense, taxReal, coupon, estimateFee } = this.procLicenseFee();

    const isValid = 0 < expense && expense + taxReal < PAYMENT_MINIMUM_CHARGE_VAL ? false : true;

    if (open && !this.isAddressRegistered()) {
      return (
        <CustomDialog open={true} onClose={this.onDialogCancel}>
          <CustomDialogTitle>{t('license_statement')}</CustomDialogTitle>
          <CustomDialogContent>
            <div className={classes.nodeDesc}>{t('address_not_registered')}</div>
          </CustomDialogContent>
          <CustomDialogActions>
            <Button
              data-testid="cancel-button"
              className={classes.leftBtn}
              disabled={isSubmitting}
              variant="contained"
              onClick={this.onDialogCancel}
            >
              {t('cancel')}
            </Button>
          </CustomDialogActions>
        </CustomDialog>
      );
    }

    return (
      <>
        <CustomDialog open={open} onClose={this.onDialogCancel} scroll="body">
          <CustomDialogTitle>{t('license_statement')}</CustomDialogTitle>
          <CustomDialogContent>
            <div className={classes.nodeDesc}>{t('license_instruction')}</div>

            <div className={classes.contentArea}>
              <div className={classes.infoItem}>
                <div className={classNames(classes.nodeTitle, classes.subTitle, classes.nodeLabel)}>
                  {t('contract_detail')}
                </div>
                {(estimateFee.nextMonth.licenses || []).map((item, i) => {
                  const name =
                    item.licenseItemId !== LICENSE_TYPE_ID_STORAGE
                      ? item.licenseName
                      : `${item.licenseName} (1${item.size})`;
                  return (
                    <div
                      key={`left-${i}`}
                      className={classNames(classes.nodeItemInfo, classes.Labels)}
                    >
                      <div
                        className={classNames(
                          classes.subTitle,
                          `${
                            i === estimateFee.nextMonth.licenses.length - 1
                              ? classes.solidBottomBorder
                              : ''
                          }`,
                        )}
                      >
                        {name} <span className={classes.quanititiesCorner}>x {item.qty}</span>
                      </div>
                    </div>
                  );
                })}
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div
                    className={classNames(
                      classes.subTitle,
                      classes.contractTotal,
                      classes.hideBottomLine,
                    )}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div
                    className={classNames(
                      classes.subTitle,
                      classes.taxDetail,
                      classes.solidBottomBorder,
                    )}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div
                    className={classNames(
                      classes.subTitle,
                      classes.contractTotal,
                      classes.hideBottomLine,
                    )}
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.nodeTitle}></div>
                {(estimateFee.nextMonth.licenses || []).map((item, i) => {
                  return (
                    <div
                      key={`right-${i}`}
                      className={classNames(classes.nodeItemInfo, classes.Rates)}
                    >
                      <div
                        className={classNames(
                          classes.subTitleRight,
                          `${
                            i === estimateFee.nextMonth.licenses.length - 1
                              ? classes.solidBottomBorder
                              : ''
                          }`,
                        )}
                      >
                        {displayCurrency(item.subTotalPrice)} USD
                      </div>
                    </div>
                  );
                })}
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div
                    className={classNames(
                      classes.subTitleRight,
                      classes.contractTotal,
                      classes.hideBottomLine,
                    )}
                  >
                    {t('subtotal')} : {displayCurrency(estimateFee.nextMonth.totalPrice)} USD
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div
                    className={classNames(
                      classes.subTitleRight,
                      classes.colouredText,
                      classes.taxDetail,
                      classes.solidBottomBorder,
                    )}
                  >
                    {t('tax')} ({estimateFee.nextMonth.taxRate}%) :{' '}
                    {displayCurrency(estimateFee.nextMonth.taxFee)} USD
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div
                    className={classNames(
                      classes.subTitleRight,
                      classes.contractTotal,
                      classes.hideBottomLine,
                    )}
                  >
                    {t('per_month')} :{' '}
                    {displayCurrency(
                      estimateFee.nextMonth.totalPrice + estimateFee.nextMonth.taxFee,
                    )}{' '}
                    USD
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.contentArea}>
              <div className={classes.infoItem}>
                <div className={classNames(classes.nodeTitle, classes.subTitle, classes.nodeLabel)}>
                  {t('amount_billing')}
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div className={classes.subTitle}>{t('daily_rate_total')}</div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div className={classes.subTitle}>{t('coupon_balance')}</div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div className={classNames(classes.subTitle, classes.solidBottomBorder)}>
                    {t('coupon_amount_used_this_time')}
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div
                    className={classNames(
                      classes.subTitle,
                      classes.billingSubTotal,
                      classes.hideBottomLine,
                    )}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div
                    className={classNames(
                      classes.subTitle,
                      classes.taxDetail,
                      classes.solidBottomBorder,
                    )}
                  >
                    &nbsp;
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Labels)}>
                  <div className={classes.paymentNote}>
                    <div className={classes.warningText}>
                      {t('the_following_fees_will_be_charged_on_a_daily_basis_this_month')}
                    </div>
                    <div className={classes.warningText}>
                      <span className={classNames(classes.colouredText, classes.taxDetail)}>{`${t(
                        'for',
                      )} ${numDays} ${t('days')}`}</span>{' '}
                      ={' '}
                      <span className={classes.feesCharge}>
                        {displayCurrency(expense + taxReal)} USD
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.nodeTitle}></div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div className={classes.subTitleRight}>
                    {displayCurrency(estimateFee.totalPrice)} USD
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div className={classes.subTitleRight}>{displayCurrency(coupon.usable)} USD</div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div
                    className={classNames(
                      classes.subTitleRight,
                      classes.taxAmount,
                      classes.solidBottomBorder,
                    )}
                  >
                    {discount !== 0 ? '- ' : ''}
                    {displayCurrency(discount)} USD
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div
                    className={classNames(
                      classes.subTitleRight,
                      classes.billingSubTotal,
                      classes.hideBottomLine,
                    )}
                  >
                    {t('subtotal')} : {displayCurrency(expense)} USD
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div
                    className={classNames(
                      classes.subTitleRight,
                      classes.colouredText,
                      classes.taxDetail,
                      classes.solidBottomBorder,
                    )}
                  >
                    {t('tax')} ({estimateFee.taxRate}%) : {displayCurrency(taxReal)} USD
                  </div>
                </div>
                <div className={classNames(classes.nodeItemInfo, classes.Rates)}>
                  <div className={classNames(classes.colouredText)}></div>
                </div>
              </div>
            </div>

            <div className={classes.paymentInstruction}>
              <div
                className={classes.warningText}
                dangerouslySetInnerHTML={{
                  __html: t(
                    'network_will_remain_with_same_configuration_then_you_will_be_charged_monthly_fee_above',
                    {
                      amount: displayCurrency(
                        estimateFee.nextMonth.totalPrice + estimateFee.nextMonth.taxFee,
                      ),
                    },
                  ),
                }}
              />
            </div>
            {!isValid && (
              <div className={classes.errorText}>
                {t('the_amount_is_not_enough_for_the_minimum_settlement_amount')}
              </div>
            )}
          </CustomDialogContent>

          <CustomDialogActions>
            <Button
              data-testid="cancel-button"
              className={classes.leftBtn}
              disabled={isSubmitting}
              variant="contained"
              onClick={this.onDialogCancel}
            >
              {t('cancel')}
            </Button>
            <SubmitButton
              data-testid="purchase-button"
              isValid={isValid}
              isSubmitting={isSubmitting}
              label={t('purchase')}
              submittingLabel={t('purchasing')}
              onClick={this.onConfirmLicense}
            />
          </CustomDialogActions>
        </CustomDialog>

        <RegisterCardDialog
          open={openRegisterCardDialog}
          onClose={this.onCloseRegisterCardDialog}
        ></RegisterCardDialog>
      </>
    );
  }

  private onDialogCancel = () => {
    this.props.onClose(0);
  };

  private onConfirmLicense = async () => {
    const {
      onClose,
      purchaseLicenses,
      accountSeleted,
      estimateDate,
      requireCard,
      t,
      listActiveLicensesSummary,
    } = this.props;

    if (accountSeleted) {
      if (!this.isAddressRegistered()) {
        if (window) {
          window.alert(t('address_not_registered'));
        }
        return;
      }

      const { expense } = this.procLicenseFee();

      if (0 < expense && expense < PAYMENT_MINIMUM_CHARGE_VAL) {
        if (window) {
          window.alert(t('the_amount_is_not_enough_for_the_minimum_settlement_amount'));
        }
        return;
      }

      this.setState({ isSubmitting: true });

      // register card (if needed)
      if (requireCard) {
        this.onOpenRegisterCardDialog();
      } else {
        let status = 1;

        try {
          // purchase
          const { estimate, coupon } = this.props.estimateFee;
          const estimateFee = estimate[0] || this.initLicenseFee();

          const result = await purchaseLicenses({
            accountUuid: accountSeleted.accountUuid,
            licenses: estimateFee.licenses.map((l) => ({
              licenseItemId: l.licenseItemId,
              qty: l.qty,
              numDays: l.numDays,
            })),
            cond: {
              startDate: estimateDate,
              useCoupon: coupon.usable,
            },
          });
          if (result.purchaseLicenses.status) {
            status = 2;
            listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
              console.log,
            );
          }
        } catch (_) {}
        this.setState({ isSubmitting: false });
        onClose(status);
      }
    } else {
      if (window) {
        window.alert(t('account_not_selected'));
      }
    }
  };

  private onCloseRegisterCardDialog = (status: boolean = false) => {
    this.setState({ openRegisterCardDialog: false });

    // handling state
    if (status) {
      const { onClose, purchaseLicenses, accountSeleted, estimateDate, t } = this.props;
      let status = 1;

      // if purchase successed then status = 2
      if (accountSeleted) {
        const { estimate, coupon } = this.props.estimateFee;
        const estimateFee = estimate[0] || this.initLicenseFee();

        this.setState({ isSubmitting: true });

        purchaseLicenses({
          accountUuid: accountSeleted.accountUuid,
          licenses: estimateFee.licenses.map((l) => ({
            licenseItemId: l.licenseItemId,
            qty: l.qty,
            numDays: l.numDays,
          })),
          cond: {
            startDate: estimateDate,
            useCoupon: coupon.usable,
          },
        })
          .then((result) => {
            if (result.purchaseLicenses.status) {
              status = 2;
            }
            this.setState({ isSubmitting: false });
            onClose(status);
          })
          .catch((_) => {
            this.setState({ isSubmitting: false });
            onClose(status);
          });
      } else {
        if (window) {
          window.alert(t('account_not_selected'));
        }
        this.setState({ isSubmitting: false });
        onClose(status);
      }
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  private onOpenRegisterCardDialog = () => {
    this.setState({
      openRegisterCardDialog: true,
    });
  };

  private initLicenseFee = (): PaymentActions.LicenseFee => ({
    totalPrice: 0,
    taxFee: 0,
    taxRate: 0,
    licenses: [],
    nextMonth: {
      totalPrice: 0,
      taxFee: 0,
      taxRate: 0,
      licenses: [],
    },
  });

  private procLicenseFee = () => {
    const { estimate, coupon } = this.props.estimateFee;

    const estimateFee = estimate[0] || this.initLicenseFee();
    const numDays = estimateFee.licenses.length > 0 ? estimateFee.licenses[0].numDays : 0;

    let discount = Math.min(estimateFee.totalPrice, coupon.usable);
    let expense = estimateFee.totalPrice - discount;

    if (0 < expense && expense < PAYMENT_MINIMUM_CHARGE_VAL) {
      const diff = PAYMENT_MINIMUM_CHARGE_VAL - expense;
      const revert = Math.min(diff, discount);

      discount -= revert;
      expense += revert;
    }

    return {
      expense: expense,
      discount: discount,
      taxReal: expense > 0 ? Math.round(expense * (estimateFee.taxRate / TAX_RATE_BASE)) : 0,
      numDays: numDays,
      coupon: coupon,
      estimateFee: estimateFee,
    };
  };

  private isAddressRegistered = (): boolean => {
    const { requireAddr } = this.props;

    if (requireAddr === void 0) {
      return false;
    }
    return !requireAddr ? true : false;
  };
}

const styles = createStyles({
  root: {},
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
    width: 259,
    height: 38,
    margin: '0 0 19px',
    backgroundColor: pattensBlueColor,
  },
  nodeLabel: {
    ...defaultFontBold,
    paddingLeft: '18px !important',
    paddingTop: 8,
    color: nightRiderColor,
    fontWeight: 'bold !important',
    fontSize: '13px !important',
  },
  nodeDesc: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
    textAlign: 'center',
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
    borderBottom: `solid 1px ${pattensBlueColor}`,
  },
  subTitleRight: {
    ...defaultFontRegular,
    fontSize: 12,
    textAlign: 'right',
    borderBottom: `solid 1px ${pattensBlueColor}`,
  },
  colouredText: {
    color: `${denimColor} !important`,
  },
  contentArea: {
    marginTop: 25,
    paddingBottom: 30,
    display: 'flex',
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
  },
  infoItem: {
    flex: 1,
    width: '50%',
    maxWidth: '50%',
  },
  nodeItemInfo: {
    marginTop: 10,
    marginBottom: 10,
  },
  Labels: {
    paddingLeft: 31,
  },
  Rates: {
    paddingRight: 31,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 16,
    color: lightSlateGreyColor,
  },
  warningText: {
    ...defaultFontRegular,
    fontSize: 13,
    textAlign: 'center',
  },
  errorText: {
    ...defaultFontRegular,
    fontSize: 13,
    textAlign: 'center',
    color: romanColor,
    marginTop: 15,
    marginBottom: 15,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  taxAmount: {
    color: `${romanColor} !important`,
  },
  paymentNote: {
    width: 420,
    height: 64,
    margin: '25px 20px',
    padding: '5px 15px 0px',
    borderRadius: 4,
    border: `solid 1px ${pattensBlueColor}`,
    backgroundColor: `${whiteColor}`,
  },
  paymentInstruction: {
    margin: '39px 0px 48px',
  },
  hideBottomLine: {
    borderBottom: 'none',
  },
  solidBottomBorder: {
    borderBottom: `solid 1px ${lightSlateGreyColor}`,
  },
  feesCharge: {
    ...defaultFontMedium,
    fontSize: 20,
  },
  licenseDetail: {
    borderBottom: `solid 1px ${lightSlateGreyColor}`,
  },
  contractTotal: {
    ...defaultFontMedium,
    fontSize: 13,
  },
  billingSubTotal: {
    ...defaultFontBold,
    fontSize: 15,
  },
  taxDetail: {
    ...defaultFontRegular,
    fontSize: 13,
  },
  quanititiesCorner: {
    float: 'right',
  },
});

const mapStateToProps = (store: IStore, ownProps) => ({
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  purchaseLicenses: (args: PaymentActions.MutationPurchaseLicensesArgs) =>
    dispatch(PaymentActions.purchaseLicenses(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmLicenseDialog)),
);

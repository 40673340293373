import React from 'react';
// Styles
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { ellipsifyText } from '~/utilities/utils';
import CopyClipboardIcon from './copy-clipboard-icon';
import { Tooltip } from '@mui/material';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  address: string;
  enableCopyBtn?: boolean;
  firstAddress?: number;
  lastAddress?: number;
}

const TruncateWalletAddress = (props: IProps) => {
  const { classes, address, enableCopyBtn, firstAddress, lastAddress } = props;

  return (
    <div className={classes.endPoint}>
      <Tooltip title={address} classes={{ tooltip: classes.tooltip }}>
        <div>{ellipsifyText(address, firstAddress, lastAddress)}</div>
      </Tooltip>
      {enableCopyBtn && (
        <CopyClipboardIcon classes={{ root: classes.copyButton }} textToCopy={address || ''} />
      )}
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    tooltip: {
      backgroundColor: 'rgb(20, 26, 31)',
    },
    endPoint: {
      display: 'flex',
    },
    copyButton: {
      padding: 5,
    },
  });

export default withStyles(styles)(withTranslation()(TruncateWalletAddress));

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ms from 'ms';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';

import { denimColor, nightRiderColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import * as NetworkActions from '~/stores/actions/network-action';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';

import Grid from '@mui/material/Grid';
import CustomNumericString from '~/components/common/custom-numeric-string';
import TruncateWalletAddress from '~/components/common/truncate-wallet-address';
import { IStore } from '~/stores/configure-store';
import Pagination from '~/components/common/pagination';
import LoadingComponent from '~/components/common/loading';
import { Proposal, QueryListProposalHistoryArgs, SignerStatusChange } from '~/gapi/gtypes';

import { Badge, IconButton, Popover } from '@mui/material';
import FilterForm from './filter-form';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DEFAULT_POLLING_INTERVAL } from '~/constants/consts';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  account: Account;
}

export interface IFilterProposalHistory {
  startBlock?: number;
  endBlock?: number;
  address?: string;
}

const ProposalHistoryList = (props: IProps) => {
  const { classes, network, account } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listProposalHistory = useSelector((store: IStore) => store.appState.listProposalHistory);
  const isFetchProposalHistory = useSelector((store: IStore) =>
    NetworkActions.listProposalHistory.isPending(store),
  );
  const { totalPages, pageIndex } = listProposalHistory.pageInfo;
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [listRenewing, setListRenewing] = useState(false);

  const enableListProposalHistory = useMemo(() => {
    return (
      account.role === 'owner' ||
      account.role === 'admin' ||
      network.role === 'owner' ||
      network.role === 'admin' ||
      network.role === 'member' ||
      network.role === 'operator'
    );
  }, [account.role, network.role]);

  const fetchProposalHistory = useCallback(
    async (params: Partial<QueryListProposalHistoryArgs> = {}) => {
      if (enableListProposalHistory) {
        await dispatch(
          NetworkActions.listProposalHistory({
            accountUuid: account.accountUuid,
            networkUuid: network.networkUuid,
            ...params,
          }),
        ).catch(() => {});
      }
    },
    [account.accountUuid, dispatch, enableListProposalHistory, network.networkUuid],
  );

  const onSelectPage = useCallback(
    (pageValue: number) => {
      fetchProposalHistory({
        page: {
          pageIndex: pageValue - 1,
        },
      });
    },
    [fetchProposalHistory],
  );

  const onFilterPage = useCallback(
    (filter: IFilterProposalHistory) => {
      fetchProposalHistory({
        filter,
        page: {
          pageIndex: 0,
        },
      });
    },
    [fetchProposalHistory],
  );

  const renderSignerStatusChanges = useCallback(
    (signerStatusChanges: SignerStatusChange[]) => {
      return signerStatusChanges.map(({ address, authorize }) => {
        return (
          <div style={{ display: 'flex' }} key={address}>
            - &nbsp;
            <TruncateWalletAddress classes={{ endPoint: classes.endPoint }} address={address} />
            &nbsp;become a&nbsp;
            <div>
              {authorize ? (
                <div>
                  <b>{t('validator')}</b> {t('node')}
                </div>
              ) : (
                <div>
                  <b>{t('relay')}</b> {t('node')}
                </div>
              )}
            </div>
          </div>
        );
      });
    },
    [classes, t],
  );

  const renderProposals = useCallback(
    (proposals: Proposal[]) => {
      return proposals.map(({ from, to, authorize }, index) => {
        return (
          <div style={{ display: 'flex' }} key={index}>
            - &nbsp;
            <TruncateWalletAddress classes={{ endPoint: classes.endPoint }} address={from} />
            &nbsp;propose&nbsp;
            <TruncateWalletAddress classes={{ endPoint: classes.endPoint }} address={to} />
            &nbsp;to&nbsp;
            <div>
              {authorize ? (
                <div>
                  <b>{t('validator')}</b> {t('node')}
                </div>
              ) : (
                <div>
                  <b>{t('relay')}</b> {t('node')}
                </div>
              )}
            </div>
          </div>
        );
      });
    },
    [classes, t],
  );

  const handleOpenFilterDialog = useCallback((e) => {
    setFilterAnchorEl(e.currentTarget);
  }, []);

  const handleCloseFilterDialog = useCallback(() => {
    setFilterAnchorEl(null);
  }, []);

  useEffect(() => {
    fetchProposalHistory({ page: { pageIndex } });
    const interval = setInterval(async () => {
      setListRenewing(true);
      await fetchProposalHistory();
      setListRenewing(false);
    }, DEFAULT_POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [fetchProposalHistory, pageIndex]);

  return (
    <div>
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilterDialog}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
        classes={{ paper: classes.filterPopoverPaper }}
      >
        {enableListProposalHistory && (
          <FilterForm
            network={network}
            filter={listProposalHistory.filter}
            onClose={handleCloseFilterDialog}
            onFilterPage={onFilterPage}
          />
        )}
      </Popover>
      <div className={classes.topLine}>
        <h4 style={{ fontWeight: 400 }}>{t('Proposal History')}</h4>
        <div>
          Filter by:
          <IconButton
            disabled={!enableListProposalHistory}
            data-testid="filter-button"
            onClick={handleOpenFilterDialog}
          >
            <Badge
              badgeContent={
                Object.values(listProposalHistory.filter ?? {}).filter(
                  (val) => val != null && val != '',
                ).length
              }
              color="primary"
            >
              <FilterListIcon />
            </Badge>
          </IconButton>
        </div>
      </div>
      <div className={classes.tableArea}>
        {!listRenewing && isFetchProposalHistory ? (
          <LoadingComponent isFullScreen={false} />
        ) : (
          <>
            {listProposalHistory.proposalHistory?.map((item) => (
              <div className={classes.row} key={item.uuid}>
                <Grid container>
                  <Grid item xs={2}>
                    {t('block')}{' '}
                    <span style={{ fontWeight: 400 }}>
                      <CustomNumericString value={item.blockNumber.toString()} />
                    </span>
                    {item.isEpochBlock && <div>(Epoch block)</div>}
                  </Grid>
                  <Grid item xs={10}>
                    <div>
                      {item.isEpochBlock ? (
                        <div>- &nbsp;{t('all_proposals_reset')}</div>
                      ) : (
                        <>
                          {renderProposals(item.proposals)}
                          {renderSignerStatusChanges(item.signerStatusChanges)}
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
            {!!totalPages && totalPages > 1 && (
              <div className={classes.pagination}>
                <Pagination
                  data-testid="pagination-button"
                  currentPage={listProposalHistory.pageInfo.pageIndex + 1}
                  totalPage={totalPages}
                  selectPage={onSelectPage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    topLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filterPopoverPaper: {},
    row: {
      fontSize: '14px',
      marginTop: 5,
      padding: 10,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    endPoint: {
      display: 'flex',
      color: nightRiderColor,
      fontWeight: 400,
    },
    tableArea: {
      marginBottom: 20,
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
  });

export default withStyles(styles)(ProposalHistoryList);

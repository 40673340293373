import React from 'react';
import { connect } from 'react-redux';
import countryCodes from 'i18n-iso-countries/entry-node';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
import {
  defaultFontMedium,
  defaultFontRegular,
  defaultFontBold,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  snowColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import * as AppAction from '~/stores/actions/app-action';

// Component
import LoadingIcon from '~/components/common/loading-icon';
import EditButton from '~/components/common/edit-button';

import RegisterAddressDialog from '~/components/common/register-address-dialog';

// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { Account, IAddress, Profile, AccountRoleType } from '~/types/account-types';

// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  account?: Account;
  registerAddressLoading: boolean;
  profile?: Profile;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {
  openRegisterAddressDialog: boolean;
}

class PaymentDetailTab extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openRegisterAddressDialog: false,
    };
  }

  public render() {
    const { classes, account, profile, registerAddressLoading, selectedAccountRole, t } =
      this.props;
    const editable = selectedAccountRole === 'owner';

    if (registerAddressLoading) {
      return (
        <div className={classes.root}>
          <div className={classes.loadingArea}>
            <LoadingIcon />
          </div>
        </div>
      );
    }

    const { openRegisterAddressDialog } = this.state;
    const addr: IAddress = (account && account.paymentAddr) || {
      country: '',
      postalCode: '',
      state: '',
      city: '',
      address1: '',
      address2: '',
    };

    const langDefault = 'en';
    const langUser = (profile && profile.preference.language) || langDefault;
    const dispCountry = addr.country
      ? countryCodes.getName(addr.country, langUser || langDefault) || addr.country
      : '';

    document.title = TAB_TITLE_CONCAT + this.props.t('billing_details_tab_title');

    return (
      <>
        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        />
        <div className={classes.root}>
          <div className={classes.topLine}>
            <div className={classes.title}>{t('billing_details_tab_title')}</div>
            <EditButton
              data-testid="edit-button"
              id="member-billing-detail-button-edit"
              onClick={this.onOpenRegisterAddressDialog}
              disabled={!editable}
            />
          </div>
          <div id="node-information" className={classes.content}>
            <div>
              <div className={classes.subTitle}>{t('billing_details_tab_country')}</div>
              <div className={classes.detailValue}>{dispCountry}</div>
            </div>
            <div className={classes.nodeItemInfo}>
              <div className={classes.subTitle}>{t('billing_details_tab_postcode')}</div>
              <div className={classes.detailValue}>{addr.postalCode}</div>
            </div>
            <div className={classes.nodeItemInfo}>
              <div className={classes.subTitle}>{t('billing_details_tab_address')}</div>
              <div className={classes.detailValue}>{addr.state}</div>
              <div className={classes.detailValue}>{addr.city}</div>
              <div className={classes.detailValue}>{addr.address1 || ''}</div>
              <div className={classes.detailValue}>{addr.address2 || ''}</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    this.setState({
      openRegisterAddressDialog: false,
    });
  };

  private onOpenRegisterAddressDialog = () => {
    this.setState({
      openRegisterAddressDialog: true,
    });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    container,
    root: {
      marginTop: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      borderColor: pattensBlueColor,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      position: 'relative',
    },
    topLine: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: snowColor,
      height: 38,
      padding: '0 10px 0 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      ...defaultFontBold,
      fontSize: 18,
      color: nightRiderColor,
    },
    content: {
      padding: '20px 20px 40px',
    },
    loadingArea: {
      marginTop: 20,
      textAlign: 'center',
    },
    nodeItemInfo: {
      marginTop: 15,
    },
    subTitle: {
      ...defaultFontMedium,
      fontSize: 12,
    },
    detailValue: {
      ...defaultFontRegular,
      ...oneLineText,
      fontSize: 16,
      color: lightSlateGreyColor,
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      subTitle: {
        fontWeight: 600,
      },
      detailValue: {
        fontSize: 15,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  registerAddressLoading: AppAction.registerAddress.isPending(store),
  profile: store.appState.profile,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('member')(PaymentDetailTab)),
);

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Component
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TableRowBodyCustom from '~/components/common/table-row-body';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import OptionButton from '~/components/common/option-button';
import EditConsortiumRoleDialog from '../../edit-consortium-role-dialog';
import WithdrawConsortiumDialog from '../../withdraw-consortium-dialog';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { pattensBlueColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
// Type
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { Item } from '..';
import { MASK_ASTERISK } from '~/constants/consts';
import { isConsortiumAdmin } from '~/utilities/utils';

interface IStateProps {}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  item: Item;
  account: Account;
  network: INetwork;
}

interface IState {
  consortiumOptionPopoverAnchorEl: HTMLButtonElement | null;
  openEditConsortiumRoleDialog: boolean;
  openWithdrawConsortiumDialog: boolean;
}

class ConsortiumTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      consortiumOptionPopoverAnchorEl: null,
      openEditConsortiumRoleDialog: false,
      openWithdrawConsortiumDialog: false,
    };
  }

  public render() {
    const { classes, t, item, account, network } = this.props;

    return (
      <>
        {this.consortiumOptionsPopover}
        <TableRowBodyCustom className={classes.tableRowBodyCustom}>
          <TableCellBodyCustom className={classes.tableCellCommonBody}>
            {item.accountName}
          </TableCellBodyCustom>
          <TableCellBodyCustom className={classes.tableCellCommonBody}>
            {item.accountUuid ?? MASK_ASTERISK}
          </TableCellBodyCustom>
          <TableCellBodyCustom className={classes.tableCellCommonBody}>
            {item.consortiumRole}
          </TableCellBodyCustom>
          <TableCellBodyCustom className={classes.tableCellCommonBody}>
            {item.ownerEmail ?? MASK_ASTERISK}
          </TableCellBodyCustom>
          <TableCellBodyCustom className={classes.optionButtonCell}>
            {item.accountUuid && <OptionButton onClick={this.onOpenConsortiumOptionPopover} />}
          </TableCellBodyCustom>
        </TableRowBodyCustom>
      </>
    );
  }

  get consortiumOptionsPopover() {
    const { classes, t, item, network, account } = this.props;
    const {
      consortiumOptionPopoverAnchorEl,
      openEditConsortiumRoleDialog,
      openWithdrawConsortiumDialog,
    } = this.state;

    return (
      <>
        <EditConsortiumRoleDialog
          open={openEditConsortiumRoleDialog}
          onClose={this.onCloseEditConsortiumRoleDialog}
          consortium={item.consortium}
          network={network}
          account={account}
        />
        <WithdrawConsortiumDialog
          open={openWithdrawConsortiumDialog}
          onClose={this.onCloseWithdrawConsortiumDialog}
          consortium={item.consortium}
          network={network}
          account={account}
        />
        <Popover
          open={Boolean(consortiumOptionPopoverAnchorEl)}
          anchorEl={consortiumOptionPopoverAnchorEl}
          onClose={this.onCloseConsortiumOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
        >
          <List>
            {isConsortiumAdmin(account, network) && (
              <>
                <ListItem
                  data-testid="edit-role-button"
                  button
                  className={classes.listOptionItem}
                  onClick={this.onOpenEditConsortiumRoleDialog}
                >
                  {this.props.t('edit_role')}
                </ListItem>
                <div className={classes.horizontalSeparate}></div>
              </>
            )}
            <ListItem
              data-testid="withdraw-button"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenWithdrawConsortiumDialog}
            >
              {this.props.t('withdraw')}
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }

  onOpenConsortiumOptionPopover = (e) => {
    this.setState({ consortiumOptionPopoverAnchorEl: e.currentTarget });
  };
  onCloseConsortiumOptionPopover = () => {
    this.setState({ consortiumOptionPopoverAnchorEl: null });
  };

  onOpenEditConsortiumRoleDialog = () => {
    this.onCloseConsortiumOptionPopover();
    this.setState({ openEditConsortiumRoleDialog: true });
  };
  onCloseEditConsortiumRoleDialog = () => {
    this.setState({ openEditConsortiumRoleDialog: false });
  };

  onOpenWithdrawConsortiumDialog = () => {
    this.onCloseConsortiumOptionPopover();
    this.setState({ openWithdrawConsortiumDialog: true });
  };
  onCloseWithdrawConsortiumDialog = () => {
    this.setState({ openWithdrawConsortiumDialog: false });
  };
}

const styles = createStyles({
  root: {},
  tableCellCommonBody: {
    wordBreak: 'break-all',
  },
  optionButtonCell: {
    textAlign: 'right',
  },
  listOptionItem: {
    ...defaultFont,
    height: 46,
    width: 210,
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  tableRowBodyCustom: {
    height: '48px',
    '& .MuiTableCell-root': {
      letterSpacing: 'normal',
      padding: '4px 30px 4px 10px',
    },
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsortiumTab)),
);

import React, { createContext, useContext, useEffect } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from '~/stores/actions/app-action';
import { IStore } from '~/stores/configure-store';
import TermsofservicePage from '~/components/TermsOfService';
import LoadingComponent from '~/components/common/loading';
import { Profile } from '~/types/account-types';
import { Redirect } from 'react-router-dom';
import { AppRoutePath } from '~/AppRouter';

const ProfileContext = createContext<Profile | undefined>(undefined);

export const useProfile = () => useContext(ProfileContext);

export const withProfile = <P, S>(ChildComponent) => {
  const WithProfileHOC = (props: P) => {
    const dispatch = useDispatch();
    const profile = useSelector((store: IStore) => store.appState.profile);
    const getProfilePending = useSelector((store: IStore) =>
      AppActions.getProfile.isPending(store),
    );

    useEffect(() => {
      dispatch(AppActions.getProfile());
    }, [dispatch]);

    if (profile === undefined || getProfilePending) {
      return <LoadingComponent />;
    }

    if (profile === null) {
      return <Redirect to={AppRoutePath.TermsOfService} />;
    }

    return (
      <ProfileContext.Provider value={profile}>
        <ChildComponent {...props} />
      </ProfileContext.Provider>
    );
  };

  WithProfileHOC.displayName = 'WithProfileHOC';
  hoistNonReactStatics(WithProfileHOC, ChildComponent);

  return WithProfileHOC;
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AlertActions from '~/stores/actions/alert-action';
import { IStore } from '~/stores/configure-store';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import LoadingComponent from '~/components/common/loading';
import { DEFAULT_POLLING_INTERVAL } from '~/constants/consts';
import { useHistory } from 'react-router-dom';
import { AppRoutePath } from '~/AppRouter';

const InitAppDataContext = createContext<any>(null);

export const useInitAppData = () => useContext(InitAppDataContext);

export const withInitAppData = <P, S>(ChildComponent) => {
  const WithInitAppDataHOC = (props: P) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const profile = useSelector((store: IStore) => store.appState.profile);
    const networks = useSelector((store: IStore) => store.appState.networks);
    const listNetworksPending = useSelector((store: IStore) =>
      NetworkActions.listNetworks.isPending(store),
    );
    const accountSelected = useSelector((store: IStore) => store.appState.accountSeleted);
    const networkSelected = useSelector((store: IStore) => store.appState.networkSelected);
    const history = useHistory();

    useEffect(() => {
      dispatch(AppActions.getGeolocation());
    }, [dispatch]);

    useEffect(() => {
      dispatch(NetworkActions.listProviders());
    }, [dispatch]);

    useEffect(() => {
      profile?.preference.language && i18n.changeLanguage(profile.preference.language);
    }, [i18n, profile?.preference.language]);

    useEffect(() => {
      profile?.preference.timezone && moment.tz.setDefault(profile.preference.timezone);
      // Update i18n language to force the views rerender
      profile?.preference.language && i18n.changeLanguage(profile.preference.language);
    }, [i18n, profile?.preference.timezone, profile?.preference.language]);

    useEffect(() => {
      accountSelected?.accountUuid &&
        dispatch(NetworkActions.listNetworks({ accountUuid: accountSelected.accountUuid }));
    }, [accountSelected?.accountUuid, dispatch]);

    useEffect(() => {
      const listUnreadAlerts = () =>
        accountSelected?.accountUuid &&
        dispatch(
          AlertActions.listUnreadAlerts({
            accountUuid: accountSelected.accountUuid,
            page: { pageIndex: 0 },
          }),
        );

      listUnreadAlerts();
      const interval = setInterval(() => {
        listUnreadAlerts();
      }, DEFAULT_POLLING_INTERVAL);

      return () => clearInterval(interval);
    }, [accountSelected?.accountUuid, dispatch]);

    useEffect(() => {
      const interval = setInterval(async () => {
        if (accountSelected?.accountUuid && networkSelected?.networkUuid) {
          const getNetworkResult = await dispatch(
            NetworkActions.getNetwork({
              accountUuid: accountSelected.accountUuid,
              networkUuid: networkSelected.networkUuid,
            }),
          );

          if (getNetworkResult && !getNetworkResult.getNetwork) {
            // network not found, redirect to default page ...
            history.push(AppRoutePath.NetworkCreate);
          }
        }
      }, DEFAULT_POLLING_INTERVAL);

      return () => clearInterval(interval);
    }, [history, dispatch, accountSelected?.accountUuid, networkSelected?.networkUuid]);

    if (listNetworksPending) {
      return <LoadingComponent />;
    }

    if (!accountSelected || !networks) {
      return null;
    }

    return (
      <InitAppDataContext.Provider value={{}}>
        <ChildComponent {...props} />
      </InitAppDataContext.Provider>
    );
  };

  WithInitAppDataHOC.displayName = 'WithInitAppDataHOC';
  hoistNonReactStatics(WithInitAppDataHOC, ChildComponent);

  return WithInitAppDataHOC;
};

import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface CustomSnackBarProps {
  anchorOrigin?: SnackbarOrigin;
  snackBarState: {
    type: 'error' | 'success' | 'warning' | 'info';
    open: boolean;
    message: string;
  };
  onClose?: () => void;
  autoHideDuration?: number;
}

interface Props extends CustomSnackBarProps, WithStyles<typeof styles>, WithTranslation {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * SnackBar component
 * Render notification messages
 */
const CustomSnackBar = (props: Props) => {
  const { anchorOrigin, onClose, snackBarState, classes, t } = props;

  if (snackBarState) {
    const keyOfMessage = new Date().getTime();
    return (
      <Snackbar
        data-testid="snack-bar"
        className={classes.root}
        key={keyOfMessage}
        anchorOrigin={anchorOrigin ?? { vertical: 'bottom', horizontal: 'right' }}
        open={snackBarState.open || false}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <Alert
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" />,
            warning: <WarningIcon fontSize="inherit" />,
            error: <ErrorIcon fontSize="inherit" />,
            info: <InfoIcon fontSize="inherit" />,
          }}
          data-testid="alertSnackbar"
          elevation={6}
          variant="filled"
          onClose={onClose}
          severity={snackBarState.type}
        >
          {t(snackBarState.message)}
        </Alert>
      </Snackbar>
    );
  }
  return null;
};

const styles = createStyles({
  root: {
    width: '100%',
    maxWidth: 'unset',
    '& .MuiPaper-root': {
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      padding: '6px 24px',
    },
    '& .MuiPaper-root.MuiAlert-filledSuccess': {
      backgroundColor: '#43a047',
    },
    '& .MuiPaper-root.MuiAlert-filledError': {
      backgroundColor: '#d32f2f',
    },
  },
});

export default withStyles(styles)(withTranslation('common')(CustomSnackBar));

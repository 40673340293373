import * as Gtypes from '~/gapi/gtypes';

const invitationQuery = `{
  invitationUuid
  networkUuid
  networkName
  isDeletedNetwork
  senderAccountUuid
  senderAccountName
  isDeletedSenderAccount
  senderUid
  receiverAccountUuid
  receiverAccountName
  isDeletedReceiverAccount
  status
  createAt
  updateAt
}`;
// Send Invitation
export type MutationSendInvitationArgs = Gtypes.MutationSendInvitationArgs;
export type SEND_INVITATION_RESULT_TYPE = {
  sendInvitation: Gtypes.Invitation;
};
export const sendInvitationMutation = `mutation ($input: SendInvitationInput!) {
  sendInvitation(input: $input) ${invitationQuery}
}`;
// List Invitations
export type QueryListInvitationsArgs = Gtypes.QueryListInvitationsArgs;
export type LIST_INVITATIONS_RESULT_TYPE = {
  listInvitations: Gtypes.ListInvitations;
};
export const listInvitationsQuery = `query ($accountUuid: String!, $isSent: Boolean!, $page: InputPageInfo) {
  listInvitations(accountUuid: $accountUuid, isSent: $isSent, page: $page) {
    invitations ${invitationQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
  }
}`;
// Response Invitation
export type MutationResponseInvitationArgs = Gtypes.MutationResponseInvitationArgs;
export type RESPONSE_INVITATION_RESULT_TYPE = {
  responseInvitation: Gtypes.Invitation;
};
export const responseInvitationMutation = `mutation ($input: ResponseInvitationInput!) {
  responseInvitation(input: $input) ${invitationQuery}
}`;
// Cancel Invitation
export type MutationCancelInvitationArgs = Gtypes.MutationCancelInvitationArgs;
export type CANCEL_INVITATION_RESULT_TYPE = {
  cancelInvitation: Gtypes.Invitation;
};
export const cancelInvitationMutation = `mutation ($input: CancelInvitationInput!) {
  cancelInvitation(input: $input) ${invitationQuery}
}`;

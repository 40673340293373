import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import { lightSlateGreyColor } from '~/styles/themes/common-styles/color';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';

// Component
import ContentContainerView from '~/components/common/ContentContainer';
import OverviewTab from './tabs/overview-tab';
import SecurityTab from './tabs/security-tab';
import EndpointTab from './tabs/endpoint-tab';
import DomainTab from './tabs/domain-tab';

// defines
import { POLLING_NETWORK_INTERVAL, TAB_TITLE_CONCAT } from '~/constants/consts';

import { INetwork, ICluster, IRestrictionNetwork } from '~/types/network-types';
import { AccountRoleType, NetworkRoleType, Account } from '~/types/account-types';
import { isAccountOwnCluster, isDevMode } from '~/utilities/utils';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

interface IStateProps {
  network?: INetwork;
  rules: IRestrictionNetwork[];
  account?: Account;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  checkNetwork: (
    args: NetworkActions.QueryGetNetworkArgs,
  ) => Promise<NetworkActions.GET_NETWORK_RESULT_TYPE>;
  listEndpointRules: (
    args: NetworkActions.QueryListEndpointRestrictionsArgs,
  ) => Promise<NetworkActions.LIST_ENDPOINT_RESTRICTIONS_RESULT_TYPE>;
  listExplorerRules: (
    args: NetworkActions.QueryListExplorerRestrictionsArgs,
  ) => Promise<NetworkActions.LIST_EXPLORER_RESTRICTIONS_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IProps
  extends IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    RouteComponentProps<{ networkId: string; clusterId: string; tab?: string }>,
    WithTranslation {}

interface IState {
  cluster?: ICluster;
}

class Cluster extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.findCluster();
  }

  componentDidUpdate(prevProps: IProps, prevState) {
    if (prevProps.network !== this.props.network) {
      this.findCluster();
    }
  }

  public render() {
    const { classes, network, selectedAccountRole, selectedNetworkRole, account } = this.props;
    const { cluster } = this.state;
    const showSecurityTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedAccountRole === 'member' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin' ||
        selectedNetworkRole === 'operator');
    // Loading
    if (network === undefined || cluster === undefined || account === undefined) {
      return null;
    }

    const showDomainTab =
      account &&
      account.plan === 'enterprise' &&
      account!.accountUuid === cluster!.accountUuid &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');

    const tabs = [
      {
        title: this.props.t('overview'),
        tabName: 'overview',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/overview`,
        component: <OverviewTab cluster={cluster} network={network} />,
      },
    ];

    if (showSecurityTab) {
      tabs.push({
        title: this.props.t('endpoint'),
        tabName: 'endpoint',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/endpoint`,
        component: <EndpointTab account={account} cluster={cluster} network={network} />,
      });
    }

    if (showDomainTab) {
      tabs.push({
        title: this.props.t('domain'),
        tabName: 'domain',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/domain`,
        component: <DomainTab account={account} cluster={cluster} network={network} />,
      });
    }

    document.title = TAB_TITLE_CONCAT + this.props.t('cluster_overview_title');

    return (
      <ContentContainerView
        title={
          <div className={classes.title}>
            <span id="cluster-name" className={classes.titleText}>
              {cluster.clusterName}
            </span>
          </div>
        }
        tabs={tabs}
        routes={[
          {
            name: `Network: ${network.networkName}`,
            url: `/network/${network.networkUuid}/overview`,
          },
          {
            name: `Cluster: ${cluster.clusterName}`,
            url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/overview`,
          },
        ]}
        tabId="cluster-tab"
      />
    );
  }

  private pollingNetworkTimer: number | null = null;

  private stopPollingNetworkStatus = () => {
    if (this.pollingNetworkTimer) {
      window.clearInterval(this.pollingNetworkTimer);
    }
    this.pollingNetworkTimer = null;
  };

  private findCluster = () => {
    const { clusterId, networkId, tab } = this.props.match.params;
    const { network, history } = this.props;

    let cluster: ICluster | undefined = undefined;

    if (network) {
      if (network.networkUuid !== networkId) {
        history.push(`/network`);
      }
      cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterId.toString());

      if (cluster) {
        if (!tab) {
          history.push(`/network/${networkId}/cluster/${cluster.clusterUuid}/overview`);
        }
        const oldCluster = this.state.cluster;

        if (!oldCluster || oldCluster !== cluster) {
          this.setState({
            cluster,
          });
        }
      }
    }
    return {
      network,
      cluster,
    };
  };
}

const mapStateToProps = (store: IStore): IStateProps => ({
  network: store.appState.networkSelected,
  rules: store.appState.restrictions,
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  checkNetwork: (args: NetworkActions.QueryGetNetworkArgs) =>
    dispatch(NetworkActions.getNetwork(args)),
  listEndpointRules: (args: NetworkActions.QueryListEndpointRestrictionsArgs) =>
    dispatch(NetworkActions.listEndpointRestrictions(args)),
  listExplorerRules: (args: NetworkActions.QueryListExplorerRestrictionsArgs) =>
    dispatch(NetworkActions.listExplorerRestrictions(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

const styles: StyleRules = {
  root: {},
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  version: {
    ...defaultFont,
    fontSize: 24,
    color: lightSlateGreyColor,
  },
};

export default compose<React.ClassicComponentClass>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Cluster);

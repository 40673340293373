import React, { useCallback, useEffect, useMemo, useState } from 'react';
// Redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import Table from '@mui/material/Table';
import LoadingIcon from '~/components/common/loading-icon';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import EditEndpointListenersDialog from './edit-endpoint-listener-dialog';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  denimColor,
  matterhornColor,
  snowColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Translation
import { useTranslation } from 'react-i18next';
// Type
import { INetwork, ICluster } from '~/types/network-types';
// Defines
import { Account } from '~/types/account-types';

interface IProps extends WithStyles<typeof styles> {
  account: Account;
  network: INetwork;
  cluster: ICluster;
}

const EndpointTab = (props: IProps) => {
  const { classes, network, cluster, account } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openEditListenersDialog, setOpenEditListenersDialog] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const selectedAccountRole = useSelector(
    (store: IStore) => store.appState.accountSeleted && store.appState.accountSeleted.role,
  );

  const onOpenEditRules = useCallback(() => {
    setOpenEditListenersDialog(true);
  }, []);

  const onCloseEditRules = useCallback(
    async (isFetch) => {
      setOpenEditListenersDialog(false);
      if (isFetch) {
        setLoading(true);
        await dispatch(
          NetworkActions.getNetwork({
            accountUuid: account.accountUuid,
            networkUuid: network.networkUuid,
          }),
        ).catch(console.log);
        setLoading(false);
      }
    },
    [account.accountUuid, dispatch, network.networkUuid],
  );

  const listeners = useMemo(() => {
    return (cluster.listeners ?? [])
      .map((r) =>
        r.inbound.map((ip) => ({
          type: r.type,
          port: r.port,
          cidr: ip.cidr,
          desc: ip.desc,
        })),
      )
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, []);
  }, [cluster.listeners]);

  return isLoading ? (
    <div className={classes.root}>
      <div className={classes.loadingArea}>
        <LoadingIcon />
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <EditEndpointListenersDialog
        network={network}
        cluster={cluster}
        listeners={listeners}
        open={openEditListenersDialog}
        onClose={onCloseEditRules}
      />
      <div className={classes.tableArea}>
        <LGButton
          data-testid="edit-button"
          id="cluster-endpoint-button-edit"
          classes={{ root: classes.selectListBtn }}
          onClick={onOpenEditRules}
          disabled={selectedAccountRole != 'owner'}
        >
          <div>
            <ImgIcon className={classes.listIcon} imgUrl={`/images/icons/edit-ico.png`} />
            <span className={classes.listSelectedName}>{t('edit_listeners')}</span>
          </div>
        </LGButton>
        <Table id="cluster-endpoint-list">
          <TableHeadCustom>
            <TableRowHeadCustom>
              <TableCellHeadCustom>
                <span>{t('type')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom>
                <span>{t('port')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom>
                <span>{t('source')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom>
                <span>{t('description')}</span>
              </TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBodyCustom>
            {listeners.map((listener, i) => {
              return (
                <TableRowBodyCustom key={i} className={classes.tableRowBodyCustom}>
                  <TableCellBodyCustom>{t(listener.type)}</TableCellBodyCustom>
                  <TableCellBodyCustom>{listener.port}</TableCellBodyCustom>
                  <TableCellBodyCustom>{listener.cidr}</TableCellBodyCustom>
                  <TableCellBodyCustom className={classes.tableCellDesc}>
                    {listener.desc}
                  </TableCellBodyCustom>
                </TableRowBodyCustom>
              );
            })}
          </TableBodyCustom>
        </Table>
      </div>
    </div>
  );
};

const styles = createStyles({
  root: {
    paddingBottom: 50,
  },
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 16,
    color: lightSlateGreyColor,
    marginBottom: 21,
  },
  nodeOptionPopoverPaper: {},
  clusterTitle: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  titleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    backgroundColor: snowColor,
    paddingLeft: 20,
    paddingRight: 10,
  },
  contentArea: {
    paddingTop: 20,
    paddingBottom: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridRowGap: '20px',
  },
  clusterItemInfo: {
    paddingLeft: 20,
  },
  separateBorder: {
    borderRight: `1px solid ${pattensBlueColor}`,
  },
  borderBlock: {
    marginTop: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: pattensBlueColor,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  // Node Type
  nodeType: {
    ...defaultFontMedium,
    display: 'flex',
    alignItems: 'center',
    height: 24,
    borderRadius: 14,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 12,
    color: matterhornColor,
  },
  backgroundRomanColor: {
    // backgroundColor: romanColor,
    backgroundColor: 'rgb(227, 90, 90, 0.2)',
  },
  backgroundSummerSkyColor: {
    // backgroundColor: summerSkyColor,
    backgroundColor: 'rgb(64, 194, 230, 0.2)',
  },
  backgroundCreamCanColor: {
    // backgroundColor: creamCanColor,
    backgroundColor: 'rgb(240, 193, 80, 0.2)',
  },
  nodeItemLeft: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    ...defaultFont,
    fontSize: 13,
    color: denimColor,
    '& span': {
      marginLeft: 10,
    },
  },
  nodeItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: whiteSmokeColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  // Create Node Button
  createNodeBtn: {
    width: '100%',
    height: 40,
    // '& span': {
    //   position: 'relative',
    //   justifyContent: 'center',
    // },
  },
  addNodeIcon: {
    position: 'absolute',
    left: 0,
  },
  createNodeBtnContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  recentEvent: {
    marginTop: 30,
    '& th:first-child': {
      paddingLeft: 10,
    },
  },
  recentEventTitle: {
    ...defaultFontBold,
    fontSize: 16,
    color: lightSlateGreyColor,
    marginBottom: 21,
  },
  // Handle Button
  handleBtnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  verticalSeparate: {
    width: 1,
    height: 20,
    backgroundColor: pattensBlueColor,
    marginRight: 5,
    marginLeft: 5,
  },
  // Node option popover
  listOption: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 16,
  },
  deleteIcon: {
    marginRight: 8,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  tableArea: {
    marginTop: 5,
  },
  nodeStatusIcon: {
    marginRight: 10,
    verticalAlign: 'middle',
  },
  tableCellStatusText: {
    verticalAlign: 'middle',
    color: lightSlateGreyColor,
  },
  tableCellDesc: {
    maxWidth: 102,
    wordBreak: 'break-all',
  },
  recentEventDate: {
    ...defaultFont,
    color: lightSlateGreyColor,
    paddingLeft: 10,
  },
  selectListBtn: {
    width: 'auto',
    height: 34,
    marginTop: 20,
    marginBottom: 18,
  },
  listIcon: {
    verticalAlign: 'middle',
  },
  listSelectedName: {
    ...defaultFontMedium,
    ...oneLineText,
    fontSize: 15,
    marginRight: 10,
    marginLeft: 8,
    verticalAlign: 'middle',
  },
  loadingArea: {
    marginTop: 100,
    textAlign: 'center',
  },
  tableRowBodyCustom: {
    height: '48px',
    '& .MuiTableCell-root': {
      letterSpacing: 'normal',
      padding: '4px 30px 4px 10px',
    },
  },
});

export default withStyles(styles)(EndpointTab);

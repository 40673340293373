import React from 'react';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { Redirect } from 'react-router';

interface Props extends WithStyles<typeof styles> {}

const Home: React.FC<Props> = (props) => {
  const networkSelected = useSelector((store: IStore) => store.appState.networkSelected);

  if (!networkSelected) {
    return <Redirect to="/network/create" />;
  }
  return <Redirect to={`/network/${networkSelected.networkUuid}/overview`} />;
};

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(Home);

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import Table from '@mui/material/Table';
import LoadingIcon from '~/components/common/loading-icon';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  denimColor,
  matterhornColor,
  snowColor,
  nightRiderColor,
  whiteColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import {
  INetwork,
  ICluster,
  ICustomDomainInfo,
  ICustomDomainDetailInfo,
} from '~/types/network-types';
import { Account } from '~/types/account-types';

// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';
import Button from '@mui/material/Button';
import CustomDomainTableRow from './custom-domain/domain-table-row';
import AddCustomDomainDialog from './custom-domain/add-custom-domain-dialog';

interface IStateProps {
  customDomain?: ICustomDomainInfo;
  customDomains: ICustomDomainDetailInfo[];
  listCustomDomainLoading: boolean;
}

interface IDispProps {
  listCustomDomains: (
    args: NetworkActions.QueryListCustomDomainsArgs,
  ) => Promise<NetworkActions.LIST_CUSTOM_DOMAINS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  account: Account;
  network: INetwork;
  cluster: ICluster;
}

interface IState {
  openViewDomainDialog: boolean;
  openAddDomainDialog: boolean;
  openDeleteDomainDialog: boolean;
  menuAnchorEl: HTMLElement | null;
  addCertArn: string;
}

class CustomDomainTab extends React.Component<IProps, IState> {
  private mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      openViewDomainDialog: false,
      openAddDomainDialog: false,
      openDeleteDomainDialog: false,
      addCertArn: '',
    };
  }

  componentDidMount() {
    this.getCustomDomainList();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.customDomain && this.props.customDomain != prevProps.customDomain) {
      this.setState({
        addCertArn: this.props.customDomain.certArn,
      });
    } else if (!this.props.customDomain && prevProps.customDomain) {
      this.setState({
        addCertArn: '',
      });
    }
  }

  private getCustomDomainList = async () => {
    const { network, listCustomDomains, cluster, account } = this.props;
    listCustomDomains({
      input: {
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
        clusterUuid: cluster.clusterUuid,
      },
    });
  };

  public render() {
    const { t, classes, network, cluster, account, customDomains } = this.props;
    const { openAddDomainDialog } = this.state;
    document.title = TAB_TITLE_CONCAT + this.props.t('cluster_domain_title');
    const disabled = cluster.status === 'pending' || cluster.status === 'removing';
    return (
      <div className={classes.root}>
        <AddCustomDomainDialog
          open={openAddDomainDialog}
          onClose={this.onCloseAddDomain}
          networkUuid={network.networkUuid}
          clusterUuid={cluster.clusterUuid}
          accountUuid={account.accountUuid}
        />
        <div className={classes.borderBlock}>
          <div className={classes.titleArea}>
            <span className={classes.domainList}>{this.props.t('domain_list')}</span>
            <div className={classes.handleBtnArea}>
              <ImgIcon className={classes.listIcon} imgUrl={`/images/icons/add_ico.png`} />
              <Button
                data-testid="add-domain-button"
                id="add_domain"
                className={classes.handelBtn}
                disabled={disabled}
                onClick={() => this.onOpenAddDomain()}
              >
                {this.props.t('add_domain')}
              </Button>
            </div>
          </div>
          <div className={classes.root}>
            <div className={classes.tableArea}>{this.domainTable}</div>
          </div>
        </div>
      </div>
    );
  }

  get domainTable() {
    const { t, classes, network, cluster, account, listCustomDomainLoading, customDomains } =
      this.props;
    const { addCertArn } = this.state;
    const disabled = cluster.status === 'pending' || cluster.status === 'removing';
    if (customDomains === undefined || listCustomDomainLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }
    return (
      <Table id="domain-list">
        <colgroup>
          <col width="10%" />
          <col width="12%" />
          <col width="55%" />
          <col width="20%" />
          <col width="3%" />
        </colgroup>
        <TableHeadCustom>
          <TableRowHeadCustom>
            <TableCellHeadCustom>
              <span>{this.props.t('update')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom>
              <span>{this.props.t('status')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom>
              <span>{this.props.t('domain_name')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom>
              <span>{this.props.t('registration_information')}</span>
            </TableCellHeadCustom>
            <TableCellHeadCustom>
              <span></span>
            </TableCellHeadCustom>
          </TableRowHeadCustom>
        </TableHeadCustom>
        <TableBodyCustom>
          {customDomains.map((domain, i) => {
            return (
              <CustomDomainTableRow
                customDomainInfo={domain}
                account={account}
                network={network}
                cluster={cluster}
                isOpenView={domain.certArn === addCertArn}
                disabled={disabled}
              />
            );
          })}
        </TableBodyCustom>
      </Table>
    );
  }

  private onOpenAddDomain = () => {
    this.setState({
      openAddDomainDialog: true,
    });
  };

  private onCloseAddDomain = () => {
    this.setState({
      openAddDomainDialog: false,
    });
  };
}

const styles = createStyles({
  root: {},
  loadingArea: {
    textAlign: 'center',
  },
  domainList: {
    ...defaultFont,
    fontSize: 15,
    color: denimColor,
  },
  titleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    backgroundColor: snowColor,
    paddingLeft: 20,
    paddingRight: 10,
  },
  contentArea: {
    paddingTop: 20,
    paddingBottom: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridRowGap: '20px',
  },
  borderBlock: {
    marginTop: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: pattensBlueColor,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
  },
  // Handle Button
  handleBtnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  tableArea: {
    marginTop: 5,
  },
  listIcon: {
    verticalAlign: 'middle',
  },
  handelBtn: {
    ...defaultFontMedium,
    fontSize: 12,
    color: lightSlateGreyColor,
    textTransform: 'none',
  },
  menuIcon: {},
});

const mapStateToProps = (store: IStore): IStateProps => ({
  customDomain: store.appState.customDomain,
  customDomains: store.appState.customDomains,
  listCustomDomainLoading: NetworkActions.listCustomDomains.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listCustomDomains: (args: NetworkActions.QueryListCustomDomainsArgs) =>
    dispatch(NetworkActions.listCustomDomains(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomDomainTab)),
);

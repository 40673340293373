import React from 'react';
import moment from 'moment-timezone';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import { createStyles, Theme } from '@mui/material/styles';
import LoadingIcon from '~/components/common/loading-icon';
import CustomSelect from '~/components/common/custom-select';
import Button from '@mui/material/Button';
import LineChart from '~/components/common/line-chart';
import CircleProgress from '~/components/common/circle-progress';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles } from '@mui/styles';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  hawkesBlueColor,
  whiteColor,
  aliceBlue3Color,
} from '~/styles/themes/common-styles/color';
// Type
import { INetwork, INode } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMetricsRangeSelection, getMetricsPeriodSelection } from '~/types/network-selection';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {
  isLoading: boolean;
}

interface IDispProps {
  getMetrics: (
    args: NetworkActions.QueryGetNodeMetricsArgs,
  ) => Promise<NetworkActions.GET_NODE_METRICS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  node: INode;
  network: INetwork;
  account: Account;
}

interface IState {
  latestMetrics?: NetworkActions.NodeMetrics;
  nodeMetrics?: NetworkActions.NodeMetrics;
  selectedTimeRange: string;
  selectedPeriod: string;
}

class OverviewTab extends React.Component<IProps, IState> {
  private mounted = false;
  metricsRangeSelection: Array<{ value: string; label: string; periods: string[] }>;
  metricsPeriodSelection: Array<{ value: string; label: string }>;

  constructor(props: IProps) {
    super(props);
    this.metricsRangeSelection = getMetricsRangeSelection(this.props.t);
    this.metricsPeriodSelection = getMetricsPeriodSelection(this.props.t);
    this.state = {
      latestMetrics: void 0,
      nodeMetrics: void 0,
      selectedTimeRange: this.metricsRangeSelection[0].value, // hours
      selectedPeriod: this.metricsPeriodSelection[0].value, // minutes
    };
  }

  async componentDidMount() {
    this.mounted = true;
    await this.getLatestMetrics();
    await this.getMetrics();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const { classes, node, t, isLoading } = this.props;
    const { latestMetrics, nodeMetrics, selectedPeriod, selectedTimeRange } = this.state;

    if (latestMetrics === void 0 && nodeMetrics === void 0 && isLoading) {
      return (
        <div className={classes.root}>
          <div className={classes.loadingArea}>
            <LoadingIcon />
          </div>
        </div>
      );
    }

    const latest = latestMetrics
      ? latestMetrics.metrics
      : {
          cpu: [],
          mem: [],
          sys: [],
          disk: [],
          swap: [],
        };
    const data = nodeMetrics
      ? nodeMetrics.metrics
      : {
          cpu: [],
          mem: [],
          sys: [],
          disk: [],
          swap: [],
        };

    const cpuPercentage = latest.cpu[0] ? Math.ceil(latest.cpu[0].value) : 0;
    const memPercentage = latest.mem[0] ? Math.ceil(latest.mem[0].value) : 0;
    const sysPercentage = latest.sys[0] ? Math.ceil(latest.sys[0].value) : 0;
    const diskPercentage = latest.disk[0] ? Math.ceil(latest.disk[0].value) : 0;

    const allowedPeriodSelection = (
      this.metricsRangeSelection.find((r) => r.value === selectedTimeRange) || {
        periods: [] as string[],
      }
    ).periods;
    const dynamicPeriodSelection = getMetricsPeriodSelection(t).filter((p) =>
      allowedPeriodSelection.includes(p.value),
    );

    document.title = TAB_TITLE_CONCAT + this.props.t('node_metrics_title');

    return (
      <div className={classes.root}>
        <div className={classes.borderBlock}>
          <div id="node-metrics" className={classes.contentArea}>
            <div className={classNames(classes.infoItem, classes.nodeMetricsArea)}>
              <div className={classNames(classes.resourceHeader, classes.monitorHeader)}>
                <div className={classes.nodeTitle}>{this.props.t('resource')}:</div>
                <Button
                  data-testid="refresh-button"
                  id="node-metrics-reload"
                  className={classes.refreshButton}
                  onClick={this.reloadLatest}
                >
                  <img src="/images/icons/refresh_icon.svg" alt="" />
                </Button>
              </div>

              <div className={classes.chartResource}>
                <CircleProgress
                  value={cpuPercentage}
                  label={{
                    text: `${cpuPercentage} %`,
                    subLabel: t('cpu'),
                  }}
                />
                <CircleProgress
                  value={memPercentage}
                  label={{
                    text: `${memPercentage} %`,
                    subLabel: t('memory'),
                  }}
                />
                <CircleProgress
                  value={sysPercentage}
                  label={{
                    text: `${sysPercentage} %`,
                    subLabel: t('system_volume'),
                    subLabel2: `${node.serverInfo.systemSize} GB`,
                  }}
                />
                <CircleProgress
                  value={diskPercentage}
                  label={{
                    text: `${diskPercentage} %`,
                    subLabel: t('volume_size'),
                    subLabel2: `${node.serverInfo.volumeSize} GB`,
                  }}
                />
              </div>
            </div>

            <div
              id="node-peers"
              className={classNames(
                classes.nodePeerItem,
                classes.infoItem,
                classes.rightinfoItem,
                classes.separateBorder,
              )}
            >
              <div className={classes.nodeTitle}>{this.props.t('peer')}:</div>
              <div className={classes.nodeItemInfo}>
                <div className={classes.detailValue}>{node.nodeInfo.peerCount}</div>
                <div className={classes.subTitle}>{t('peer_count')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.monitorArea}>
          <div className={classes.monitorHeader}>
            <div className={classes.titleHeader}>{t('monitoring')}</div>
            <div className={classes.headerRight}>
              <div className={classes.labelText}>{t('time_range')}</div>
              <CustomSelect
                data-testid="node-monitoring-term-select"
                className={classes.bgNone}
                classes={{
                  selectRoot: classes.customSelect,
                  selectMenu: classes.customSelectMenu,
                  arrowDownIcon: classes.customSelectIcon,
                }}
                id="node-monitoring-term"
                items={getMetricsRangeSelection(t)}
                valueSelected={selectedTimeRange}
                onChange={this.onTimeRangeSelectorChange}
              />
              <div className={classes.labelText}>{t('period')}</div>
              <CustomSelect
                data-testid="node-monitoring-period-select"
                className={classes.bgNone}
                classes={{
                  selectRoot: classes.customSelect,
                  selectMenu: classes.customSelectMenu,
                  arrowDownIcon: classes.customSelectIcon,
                }}
                id="node-monitoring-period"
                items={dynamicPeriodSelection}
                valueSelected={selectedPeriod}
                onChange={this.onPeriodSelectorChange}
              />
              <Button
                data-testid="refresh-button"
                id="node-monitoring-reload"
                className={classes.refreshButton}
                onClick={this.reloadCharts}
              >
                <img src="/images/icons/refresh_icon.svg" alt="" />
              </Button>
            </div>
          </div>

          <div id="node-monitoring-charts" className={classes.chartArea}>
            <div className={classes.chartBox}>
              <LineChart
                title={t('cpu_usage_pc')}
                yAxesTickmin={0}
                yAxesTickmax={100}
                yAxesStepSize={20}
                data={[...data.cpu].reverse().map((cpu) => ({
                  key: moment(cpu.timestamp).format('MM/DD HH:mm'),
                  value: cpu.value,
                }))}
                classes={{ root: classes.linechart }}
              />
            </div>
            <div className={classes.chartBox}>
              <LineChart
                title={t('ram_usage_pc')}
                yAxesTickmin={0}
                yAxesTickmax={100}
                yAxesStepSize={20}
                data={[...data.mem].reverse().map((mem) => ({
                  key: moment(mem.timestamp).format('MM/DD HH:mm'),
                  value: mem.value,
                }))}
                classes={{ root: classes.linechart }}
              />
            </div>
            <div className={classes.chartBox}>
              <LineChart
                title={t('volume_usage_pc')}
                yAxesTickmin={0}
                yAxesTickmax={100}
                yAxesStepSize={20}
                data={[...data.disk].reverse().map((vol) => ({
                  key: moment(vol.timestamp).format('MM/DD HH:mm'),
                  value: vol.value,
                }))}
                classes={{ root: classes.linechart }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onTimeRangeSelectorChange = (e) => {
    if (this.mounted) {
      const newTimeRange = e.target.value as string;
      const cond = {
        selectedTimeRange: newTimeRange,
      };

      const allowed = (
        this.metricsRangeSelection.find((r) => r.value === newTimeRange) || {
          periods: [] as string[],
        }
      ).periods;

      if (!allowed.includes(this.state.selectedPeriod)) {
        const dynamicPeriodSelection = this.metricsPeriodSelection.filter((p) =>
          allowed.includes(p.value),
        );
        cond['selectedPeriod'] = dynamicPeriodSelection[0].value;
      }
      this.setState(cond, this.getMetrics);
    }
  };

  private onPeriodSelectorChange = (e) => {
    if (this.mounted) {
      this.setState(
        {
          selectedPeriod: e.target.value,
        },
        this.getMetrics,
      );
    }
  };

  private reloadLatest = async () => {
    if (this.mounted) {
      await this.getLatestMetrics();
    }
  };

  private reloadCharts = async () => {
    if (this.mounted) {
      await this.getMetrics();
    }
  };

  // for charts
  private getMetrics = async () => {
    const { network, node, getMetrics, account } = this.props;
    const { selectedTimeRange, selectedPeriod } = this.state;

    const endDate = moment().utc().startOf('minute');
    const startDate = endDate.clone().subtract(parseInt(selectedTimeRange) || 1, 'hours');

    if (node.serverInfo.status === 'alive') {
      const result = await getMetrics({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          nodeUuid: node.nodeUuid,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          periodMinute: parseInt(selectedPeriod) || 5,
        },
      });
      this.setState({ nodeMetrics: result.getNodeMetrics });
    }
  };

  // for resource (current value)
  private getLatestMetrics = async () => {
    const { network, node, getMetrics, account } = this.props;
    const endDate = moment().utc().startOf('minute');
    const startDate = endDate.clone().subtract(10, 'minutes');

    if (node.serverInfo.status === 'alive') {
      const result = await getMetrics({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          nodeUuid: node.nodeUuid,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      });
      this.setState({ latestMetrics: result.getNodeMetrics });
    }
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    nodeTitle: {
      ...defaultFontMedium,
      fontSize: 16,
      color: lightSlateGreyColor,
      marginBottom: 10,
    },
    borderBlock: {
      marginTop: 20,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      borderColor: pattensBlueColor,
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      position: 'relative',
    },
    contentArea: {
      paddingTop: 30,
      paddingBottom: 30,
      display: 'flex',
    },
    nodeMetricsArea: {
      minWidth: 'inherit !important',
      flex: 'inherit !important',
      width: '100%',
      maxWidth: '70%',
    },
    infoItem: {
      paddingLeft: 30,
      paddingRight: 30,
      flex: 1,
      minWidth: 760,
    },
    rightinfoItem: {
      minWidth: '280px !important',
    },
    nodePeerItem: {
      minWidth: 'inherit !important',
      flex: 'inherit',
    },
    nodeItemInfo: {
      marginTop: 20,
      marginLeft: 25,
      width: 125,
      height: 125,
      background: `${aliceBlue3Color}`,
      borderRadius: '50%',
    },
    separateBorder: {
      borderLeft: `1px solid ${pattensBlueColor}`,
    },
    subTitle: {
      ...defaultFont,
      color: lightSlateGreyColor,
      textAlign: 'center',
      fontSize: 14,
    },
    detailValue: {
      ...defaultFontMedium,
      ...oneLineText,
      paddingTop: 35,
      maxWidth: 400,
      textAlign: 'center',
      fontSize: 14,
    },
    states: {
      marginTop: 20,
    },
    // Handle Button
    handleBtnArea: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 8,
      top: 8,
    },
    verticalSeparate: {
      width: 1,
      height: 20,
      backgroundColor: pattensBlueColor,
      marginRight: 5,
      marginLeft: 5,
    },
    proposalArea: {
      paddingTop: 30,
      paddingBottom: 30,
      backgroundColor: whiteSmokeColor,
      border: `1px solid ${pattensBlueColor}`,
      textAlign: 'center',
    },
    proposalText: {
      ...defaultFont,
      fontSize: 12,
      color: lightSlateGreyColor,
    },
    // Node option popover
    listOption: {
      // paddingTop: 0,
      // paddingBottom: 0,
    },
    listOptionItem: {
      height: 46,
      width: 210,
      ...defaultFont,
      fontSize: 16,
    },
    horizontalSeparate: {
      height: 1,
      width: '100%',
      backgroundColor: pattensBlueColor,
    },
    nodeOptionPopoverPaper: {},
    formContent: {
      marginTop: 15,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'left',
    },
    submitBtn: {
      marginLeft: 15,
    },
    requestTypeField: {
      width: 180,
    },
    proposeNodeSelectMenu: {
      paddingTop: 8,
      paddingBottom: 7,
    },
    endPoint: {
      display: 'flex',
    },
    copyButton: {
      padding: 5,
      marginLeft: 5,
    },
    loadingArea: {
      marginTop: 200,
      textAlign: 'center',
    },
    monitorArea: {
      marginTop: 30,
    },
    resourceHeader: {
      marginBottom: '0px !important',
    },
    monitorHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 30,
    },
    titleHeader: {
      ...defaultFontMedium,
      color: lightSlateGreyColor,
      fontSize: 18,
    },
    headerRight: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
    labelText: {
      ...defaultFont,
      color: lightSlateGreyColor,
      fontSize: 13,
      marginLeft: 20,
      marginRight: 10,
    },
    bgNone: {
      background: whiteColor,
      width: 'unset',
      height: 33,
      '& .MuiInputBase-root': {
        width: 130,
        fontSize: 14,
      },
      '& .MuiInputBase-root > .MuiInputBase-input': {
        padding: '6px 15px',
      },
    },
    customSelect: {
      minWidth: 130,
      fontSize: 14,
    },
    customSelectMenu: {
      padding: '6px 15px',
    },
    customSelectIcon: {
      right: 5,
    },
    refreshButton: {
      minWidth: 'unset',
      width: 40,
      height: 32,
      borderRadius: 4,
      border: `solid 1px ${hawkesBlueColor}`,
      marginLeft: 20,
    },
    chartArea: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 1170px)': {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '20px',
      },
    },
    chartBox: {
      display: 'inline-block',
      minWidth: 245,
      marginRight: 20,
      boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.15)',
      '@media (max-width: 1170px)': {
        width: '100%',
      },
    },
    linechart: {
      padding: 12,
    },
    chartResource: {},
    textArea: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      contentArea: {
        padding: '15px 0',
      },
      infoItem: {
        minWidth: 'auto',
        padding: '0 15px',
      },
      rightinfoItem: {
        minWidth: 'auto !important',
      },
      chartBox: {
        minWidth: 225,
      },
      chartResource: {
        display: 'flex',
        marginTop: 15,
        '& div svg': {
          width: 115,
          height: 100,
          margin: '0 -5px',
        },
      },
      monitorArea: {
        marginTop: 20,
      },
      monitorHeader: {
        marginBottom: 20,
      },
      nodeItemInfo: {
        width: 80,
        height: 80,
        margin: '30px auto 0px',
      },
      detailValue: {
        fontSize: 9,
        paddingTop: 22,
      },
      subTitle: {
        fontSize: 9,
      },
      labelText: {
        marginRight: 5,
        marginLeft: 12,
      },
      customSelect: {
        minWidth: 100,
        fontSize: 12,
      },
      customSelectMenu: {
        padding: ' 6px 10px',
      },
      refreshButton: {
        marginLeft: 12,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  isLoading: NetworkActions.getNodeMetrics.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getMetrics: (args: NetworkActions.QueryGetNodeMetricsArgs) =>
    dispatch(NetworkActions.getNodeMetrics(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverviewTab)),
);

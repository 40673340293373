export const thin = '100';
export const light = '200';
export const regular = '300';
export const medium = '400';
export const bold = '500';
export const extraBold = '600';
export const black = '700';

export const defaultFont: any = {
  fontFamily: 'PT Sans',
  lineHeight: '1.5em',
  fontWeight: 400,
};

export const defaultFontRegular = {
  ...defaultFont,
  fontWeight: regular,
};

export const defaultFontMedium = {
  ...defaultFont,
  fontWeight: medium,
};

export const defaultFontBold = {
  fontFamily: 'PT Sans',
  lineHeight: '1.5em',
  fontWeight: 700,
};

export const robotoFontFamily = 'Roboto';

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import { whiteColor, sunColor } from '~/styles/themes/common-styles/color';

interface IProps {
  amountNotify?: number;
  notifyIconClassName?: any;
  className?: any;
  notifyNumberClassNumber?: any;
  selected?: boolean;
}

// Component
import ImgIcon from './img-icon';

const notifyIcon: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  amountNotify,
  className,
  notifyIconClassName,
  notifyNumberClassNumber,
  selected,
}) => {
  const showAmountNotify = !!amountNotify && amountNotify >= 0;

  return (
    <div className={classNames(classes.root, className)}>
      <ImgIcon
        imgUrl={selected ? `/images/icons/alert_current_ico.png` : `/images/icons/alert_ico.png`}
        className={classNames(classes.notifyIcon, notifyIconClassName)}
      />
      {showAmountNotify && (
        <div className={classNames(classes.notifyNumber, notifyNumberClassNumber)}>
          {amountNotify}
        </div>
      )}
    </div>
  );
};

notifyIcon.defaultProps = {
  selected: false,
};

const styles: StyleRules = {
  root: {
    position: 'relative',
  },
  notifyIcon: {
    width: 16,
    height: 16,
  },
  notifyNumber: {
    paddingLeft: 4,
    paddingRight: 4,
    height: 14,
    position: 'absolute',
    top: -3,
    right: -8,
    ...defaultFont,
    color: whiteColor,
    fontSize: 10,
    backgroundColor: sunColor,
    borderRadius: 4,
    textAlign: 'center',
  },
};

export default withStyles(styles)(notifyIcon);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import { Theme } from '@mui/material/styles';
import { denimColor } from '~/styles/themes/common-styles/color';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import CreateHardForkProposalDialog from './create-hard-fork-proposal';
import HardForkRow from './hard-fork-row';
// Actions
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
// Translation
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Defines
import TableBody from '@mui/material/TableBody';
import LoadingComponent from '~/components/common/loading';
import { QueryListHardForkProposalsArgs } from '~/gapi/gtypes';
import Pagination from '~/components/common/pagination';
import { getLatestBlockInNetwork, isConsortiumAdmin } from '~/utilities/utils';
import { HARD_FORK_CONFIGURATION } from '~/constants/consts';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  fetchHardForkProposals: (params?: Partial<QueryListHardForkProposalsArgs>) => void;
}

const HardForkList = (props: IProps) => {
  const { t } = useTranslation();
  const { classes, fetchHardForkProposals } = props;

  const account = useSelector((store: IStore) => store.appState.accountSeleted);
  const network = useSelector((store: IStore) => store.appState.networkSelected);
  const isFetchingHardforks = useSelector((store: IStore) =>
    NetworkActions.listHardForkProposals.isPending(store),
  );
  const hardForkProposals = useSelector((store: IStore) => store.appState.hardForkProposals);
  const { totalPages = 1 } = hardForkProposals?.pageInfo ?? {};

  const [openCreateProposalDialog, setOpenCreateProposalDialog] = useState(false);

  const onOpenCreateProposalDialog = useCallback(() => {
    setOpenCreateProposalDialog(true);
  }, []);

  const onCloseCreateProposalDialog = useCallback(
    (isFetchData?: boolean) => {
      setOpenCreateProposalDialog(false);
      if (isFetchData) {
        fetchHardForkProposals();
      }
    },
    [fetchHardForkProposals],
  );

  const onSelectPage = useCallback(
    (pageValue: number) => {
      fetchHardForkProposals({
        page: {
          pageIndex: pageValue - 1,
        },
      });
    },
    [fetchHardForkProposals],
  );

  const items = useMemo(() => {
    return (
      hardForkProposals?.proposals?.map((val) => ({
        uuid: val.uuid,
        accountUuid: val.accountUuid,
        name: val.name,
        periodTargetBlock: val.periodTargetBlock,
        createAt: val.createAt,
        blockNumber: val.blockNumber,
        status: val.status,
        rejectedNodeUuids: val.rejectedNodeUuids,
        approvedNodeUuids: val.approvedNodeUuids,
        signerNodeUuids: val.signerNodeUuids,
        nodeUuids: val.nodeUuids,
        explorerUuids: val.explorerUuids,
        appliedNodeUuids: val.appliedNodeUuids,
        appliedExplorerUuids: val.appliedExplorerUuids,
      })) ?? []
    );
  }, [hardForkProposals]);

  const enableProposalBtn = useMemo(() => {
    if (network?.blockchainInfo?.genesisConfig) {
      const latestBlock = getLatestBlockInNetwork(network);
      return HARD_FORK_CONFIGURATION.some((val) => {
        const hardForkConfig = network.blockchainInfo!.genesisConfig![val.nameInGenesis];
        return (
          (hardForkConfig == null || hardForkConfig > latestBlock) &&
          isConsortiumAdmin(account, network)
        );
      });
    }
    return false;
  }, [account, network]);

  return (
    <>
      <div className={classes.root}>
        <CreateHardForkProposalDialog
          open={openCreateProposalDialog}
          onClose={onCloseCreateProposalDialog}
        />
        <div className={classes.topLine}>
          <LGButton
            data-testid="create-hard-fork-proposal-button"
            classes={{ root: classes.createClusterBtn }}
            onClick={onOpenCreateProposalDialog}
            disabled={!enableProposalBtn}
          >
            <ImgIcon className={classes.addClusterIcon} imgUrl="/images/icons/add_ico.png" />
            <span>{t('create_hard_fork_proposal')}</span>
          </LGButton>
        </div>
        <div className={classes.tableArea}>
          {!hardForkProposals?.proposals && isFetchingHardforks ? (
            <LoadingComponent isFullScreen={false} />
          ) : (
            <>
              <Table id="member-notification-detail" className={classes.tblList}>
                <TableHeadCustom>
                  <TableRowHeadCustom>
                    <TableCellHeadCustom className={classes.typeCell}>
                      <span>{t('hard_fork')}</span>
                    </TableCellHeadCustom>
                    <TableCellHeadCustom className={classes.contentCell}>
                      <span>{t('target_block')}</span>
                    </TableCellHeadCustom>
                    <TableCellHeadCustom className={classes.dateCell}>
                      <span>{t('period_reach_target_block')}</span>
                    </TableCellHeadCustom>
                    <TableCellHeadCustom className={classes.dateCell}>
                      <span>{t('proposed_date_lbl')}</span>
                    </TableCellHeadCustom>
                    <TableCellHeadCustom className={classes.statusCell}>
                      <span>{t('appropval_status')}</span>
                    </TableCellHeadCustom>
                    <TableCellHeadCustom className={classes.actionCell}>
                      <span>{t('action')}</span>
                    </TableCellHeadCustom>
                  </TableRowHeadCustom>
                </TableHeadCustom>
                <TableBody>
                  {network &&
                    account &&
                    (items || []).map((item, index) => (
                      <HardForkRow
                        key={item.name}
                        item={item}
                        account={account}
                        network={network}
                        reFetchData={fetchHardForkProposals}
                      />
                    ))}
                </TableBody>
              </Table>
              {totalPages > 1 && (
                <div className={classes.pagination}>
                  <Pagination
                    data-testid="pagination-button"
                    currentPage={(hardForkProposals?.pageInfo.pageIndex || 0) + 1}
                    totalPage={totalPages}
                    selectPage={onSelectPage}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    topLine: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    createClusterBtn: {
      width: 'auto',
      color: '#333',
    },
    addClusterIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tableArea: {
      marginTop: 20,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
      transform: 'translateY(2px)',
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    typeCell: {
      width: '10%',
    },
    contentCell: {
      width: '15%',
    },
    dateCell: {
      width: '17%',
    },
    statusCell: {
      width: '20%',
    },
    actionCell: {
      width: '15%',
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
  });

export type Item = {
  name: string;
  periodTargetBlock: number;
  uuid: string;
  accountUuid: string;
  createAt: string;
  blockNumber?: number;
  appliedNodes?: number;
  status: string;
  approvedNodeUuids: string[];
  rejectedNodeUuids: string[];
  signerNodeUuids: string[];
  appliedNodeUuids: string[];
  appliedExplorerUuids: string[];
  nodeUuids: string[];
  explorerUuids: string[];
};

export default withStyles(styles)(withTranslation()(HardForkList));

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomSelect from './custom-select';
import CustomInput from './custom-input';
import CustomInputNum from './custom-input-num';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';
import SubmitButton from './submit-button';

import { INode, ICluster, INetworkProvider } from '~/types/network-types';
import { Account } from '~/types/account-types';
import { connect } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  whiteColor,
  denimColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
import {
  instancesSizeSelection,
  instancesSizeEnterpriseSelection,
} from '~/types/network-selection';

// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

import ConfirmLicenseDialog from './confirm-license-dialog';
import RegisterAddressDialog from './register-address-dialog';
// Util
import { renderClusterRegionLabel } from '~/utilities/render-utils';
// defines
import { DEFAULT_GAS_PRICE, DEFAULT_GAS_PRICE_MAX } from '~/constants/consts';
import { VALIDATE_CLUSTER_TYPE_NODES, MAX_NODE_NAME_LENGTH } from '~/constants/validation';

interface IOwnProps {
  networkUuid: string;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

interface IProps {
  providers: INetworkProvider[];
  accountSeleted?: Account;
}

interface IDispProps {
  updateNode: (
    args: NetworkActions.MutationUpdateNodeArgs,
  ) => Promise<NetworkActions.UPDATE_NODE_RESULT_TYPE>;
  insufficientLicenses: (
    args: PaymentActions.QueryListInsufficientNodeServerLicensesArgs,
  ) => Promise<PaymentActions.LIST_INSUFFICIENT_NODE_SERVER_LICENSES_RESULT_TYPE>;
  estimateLicenseFee: (
    args: PaymentActions.QueryEstimateLicenseFeeArgs,
  ) => Promise<PaymentActions.ESTIMATE_LICENSE_FEE_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentActions.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentActions.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
  listBillings: (
    args: PaymentActions.QueryListBillingsArgs,
  ) => Promise<PaymentActions.LIST_BILLINGS_RESULT_TYPE>;
  listCoupons: (
    args: PaymentActions.QueryListCouponsArgs,
  ) => Promise<PaymentActions.LIST_COUPONS_RESULT_TYPE>;
}

interface IState {
  openConfirmLicenseDialog: boolean;
  openRegisterAddressDialog: boolean;
  estimateDate: string;
  estimateFee: PaymentActions.EstimateSummary;
  requireCard: boolean;
  formik?: FormikActions<FormValues>;
}

class EditNodeDialog extends React.Component<
  IOwnProps & IProps & IDispProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      openConfirmLicenseDialog: false,
      openRegisterAddressDialog: false,
      requireCard: false,
      estimateDate: '',
      estimateFee: {
        estimate: [
          {
            totalPrice: 0,
            taxFee: 0,
            taxRate: 0,
            licenses: [],
            nextMonth: {
              totalPrice: 0,
              taxFee: 0,
              taxRate: 0,
              licenses: [],
            },
          },
        ],
        coupon: {
          usable: 0,
        },
      },
    };
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  public render() {
    const { classes, open, onClose, cluster, node, accountSeleted, t } = this.props;
    const requireAddr =
      !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
        ? true
        : false;

    const {
      openConfirmLicenseDialog,
      openRegisterAddressDialog,
      estimateDate,
      estimateFee,
      requireCard,
    } = this.state;

    const validator = VALIDATE_CLUSTER_TYPE_NODES[cluster.clusterType || 'small']
      ? VALIDATE_CLUSTER_TYPE_NODES[cluster.clusterType || 'small'].allowed
      : [];
    const validateSchema = Yup.object().shape<FormValues>({
      nameOfNode: Yup.string()
        .trim()
        .required(t('required_field'))
        .max(
          MAX_NODE_NAME_LENGTH,
          t('too_many_character_of_nodename_error_message', { value: MAX_NODE_NAME_LENGTH }),
        ),
      instanceType: Yup.string()
        .trim()
        .required(t('required_field'))
        .test(
          'check-with-cluster-type',
          t('cluster_type_not_allowed_this_instance'),
          function (instance: string) {
            return validator.includes(instance);
          },
        ),
      gasPrice: Yup.number()
        .required(t('required_field'))
        .min(0, t('validate_minimum', { val: 0 }))
        .max(DEFAULT_GAS_PRICE_MAX, t('validate_maximum', { val: DEFAULT_GAS_PRICE_MAX })),
    });

    const selectBase =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? instancesSizeEnterpriseSelection
        : instancesSizeSelection;
    const { instanceType } = node.serverInfo;
    const selectedType = instanceType && selectBase.find((s) => s.value === instanceType);
    const convGasPrice =
      node.nodeInfo.initGasPrice !== '' ? parseInt(node.nodeInfo.initGasPrice) : Number.NaN;

    const initialValues: FormValues = {
      nameOfNode: node.nodeName,
      instanceType: selectedType ? selectedType.value : validator[0] || '',
      gasPrice: !Number.isNaN(convGasPrice) ? convGasPrice : DEFAULT_GAS_PRICE,
    };

    return (
      <>
        <CustomDialog open={open} onClose={onClose}>
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={this.onSubmit}
            render={({ isValid, isSubmitting }) => (
              <Form>
                <CustomDialogTitle>
                  <div id="member-node-edit-title">{t('edit_node')}</div>
                </CustomDialogTitle>
                <CustomDialogContent>
                  <div>
                    <Field name="nameOfNode" render={this.nodeNameField} />
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="provider" render={this.providerField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="region" render={this.regionField} />
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.formSection}>
                    <Grid container>
                      <Grid item md={6} className={classes.gridLeftItem}>
                        <Field name="instanceType" render={this.instanceTypeField} />
                      </Grid>
                      <Grid item md={6} className={classes.gridRightItem}>
                        <Field name="gasPrice" render={this.gasPriceField} />
                      </Grid>
                    </Grid>
                  </div>
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    data-testid="cancel-button"
                    id="member-node-edit-cancel"
                    disabled={isSubmitting}
                    className={classes.leftBtn}
                    variant="contained"
                    onClick={onClose}
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    data-testid="update-button"
                    id="member-node-edit-submit"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('update')}
                    submittingLabel={t('updating')}
                  />
                </CustomDialogActions>
              </Form>
            )}
          />
        </CustomDialog>

        <RegisterAddressDialog
          open={openRegisterAddressDialog}
          onClose={this.onCloseRegisterAddressDialog}
        ></RegisterAddressDialog>
        <ConfirmLicenseDialog
          open={openConfirmLicenseDialog}
          onClose={this.onCloseConfirmLicenseDialog}
          estimateDate={estimateDate}
          estimateFee={estimateFee}
          requireAddr={requireAddr}
          requireCard={requireCard}
        ></ConfirmLicenseDialog>
      </>
    );
  }

  private nodeNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('node_name')}</div>
          {!!form.errors.nameOfNode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.nameOfNode}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="node-name-input"
            {...field}
            id="member-node-edit-name"
            placeholder={this.props.t('input_node_name')}
          />
        </div>
      </>
    );
  };

  private providerField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers, cluster } = this.props;
    const selectedProvider = providers.find((p) => p.value === cluster.provider);
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('provider')}</div>
        </div>
        <div>
          <CustomInput
            data-testid="provider-input"
            {...field}
            id="member-node-edit-provider"
            className={classes.disabledTextColor}
            placeholder={this.props.t('provider_name')}
            value={selectedProvider!.label}
            disabled
          />
        </div>
      </>
    );
  };

  private regionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, providers, cluster } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('region')}</div>
        </div>
        <div>
          <CustomInput
            data-testid="region-input"
            id="member-node-edit-region"
            {...field}
            placeholder={this.props.t('region_name')}
            value={renderClusterRegionLabel(providers, cluster)}
            disabled
            className={classes.disabledTextColor}
          />
        </div>
      </>
    );
  };

  private instanceTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, cluster, t, accountSeleted } = this.props;
    const filter = VALIDATE_CLUSTER_TYPE_NODES[cluster.clusterType || 'small']
      ? VALIDATE_CLUSTER_TYPE_NODES[cluster.clusterType || 'small'].allowed
      : [];
    const select =
      accountSeleted && accountSeleted.plan === 'enterprise'
        ? instancesSizeEnterpriseSelection
        : instancesSizeSelection;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('instance_type')}</div>
          {!!form.errors.instanceType && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.instanceType}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="instance-type-select"
            disabled
            {...field}
            id="member-node-edit-instance"
            classes={{ selectMenu: classes.disabledTextColor }}
            valueSelected={field.value}
            placeholder={t('instance_type')}
            items={select.filter((item) => filter.includes(item.value))}
          />
        </div>
      </>
    );
  };

  private gasPriceField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('gas_price')}</div>
          {!!form.errors.gasPrice && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.gasPrice}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            data-testid="edit-gas-price-input"
            {...field}
            id="member-node-edit-gas-price"
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return (
                floatValue === undefined || (floatValue >= 0 && floatValue <= DEFAULT_GAS_PRICE_MAX)
              );
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  private onCloseRegisterAddressDialog = (status: boolean = false) => {
    const { formik } = this.state;
    this.setState({
      openRegisterAddressDialog: false,
      formik: void 0,
    });

    if (formik) {
      if (status) {
        // if registration successed, then start to buy licenses (auto submit)
        formik.submitForm();
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenRegisterAddressDialog = () => {
    this.setState({
      openRegisterAddressDialog: true,
    });
  };

  private onCloseConfirmLicenseDialog = (status: number = 0) => {
    const { formik, requireCard } = this.state;
    this.setState({
      openConfirmLicenseDialog: false,
      formik: void 0,
    });
    if (requireCard && status > 0) {
      this.setState({ requireCard: false });
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        const { accountSeleted, listActiveLicensesSummary, listBillings, listCoupons } = this.props;
        formik.submitForm();

        if (accountSeleted) {
          listBillings({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listCoupons({ accountUuid: accountSeleted.accountUuid }).catch((_) => void 0);
          listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }).catch(
            (_) => void 0,
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  private onOpenConfirmLicenseDialog = () => {
    this.setState({
      openConfirmLicenseDialog: true,
    });
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, networkUuid, cluster, node, updateNode, accountSeleted, t } = this.props;
    const { nameOfNode, instanceType, gasPrice } = values;

    try {
      // Check selected account existence
      if (!accountSeleted) {
        return;
      }

      // check registration of address
      if (!accountSeleted.isRegisteredPaymentAddr) {
        this.setState({ formik: formikActions });
        this.onOpenRegisterAddressDialog();
        return;
      }

      const convGasPrice =
        node.nodeInfo.initGasPrice !== '' ? parseInt(node.nodeInfo.initGasPrice) : Number.NaN;
      const chkGasPrice = !Number.isNaN(convGasPrice) ? convGasPrice : DEFAULT_GAS_PRICE;

      if (nameOfNode !== node.nodeName || gasPrice !== chkGasPrice) {
        console.log(`Update node name to [${nameOfNode}], gas price to [${gasPrice}]`);
        await updateNode({
          input: {
            accountUuid: accountSeleted.accountUuid,
            networkUuid,
            clusterUuid: cluster.clusterUuid,
            nodeUuid: node.nodeUuid,
            nodeName: nameOfNode.trim(),
            gasPrice: gasPrice,
          },
        });
      }

      onClose();
    } catch (error) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  disabledTextColor: {
    color: `${pattensBlueColor} !important`,
  },
});

const mapStateToProps = (store: IStore): IProps => ({
  providers: store.appState.providers || [],
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateNode: (args: NetworkActions.MutationUpdateNodeArgs) =>
    dispatch(NetworkActions.updateNode(args)),
  insufficientLicenses: (args: PaymentActions.QueryListInsufficientNodeServerLicensesArgs) =>
    dispatch(PaymentActions.listInsufficientNodeServerLicenses(args)),
  estimateLicenseFee: (args: PaymentActions.QueryEstimateLicenseFeeArgs) =>
    dispatch(PaymentActions.estimateLicenseFee(args)),
  listActiveLicensesSummary: (args: PaymentActions.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentActions.listActiveLicensesSummary(args)),
  listBillings: (args: PaymentActions.QueryListBillingsArgs) =>
    dispatch(PaymentActions.listBillings(args)),
  listCoupons: (args: PaymentActions.QueryListCouponsArgs) =>
    dispatch(PaymentActions.listCoupons(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditNodeDialog)),
);

type FormValues = {
  nameOfNode: string;
  instanceType: string;
  gasPrice: number;
};

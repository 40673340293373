import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import creditCardType from 'credit-card-type';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
import { IStore } from '~/stores/configure-store';
import * as PaymentActions from '~/stores/actions/payment-action';
import { Account } from '~/types/account-types';
// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
import CustomDateInput from './custom-input-date';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
// defines
import { DEFAULT_MAXLEN_NAME } from '~/constants/consts';
// Util
import { isDevMode } from '~/utilities/utils';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: (status?: boolean) => void;
  accountSeleted?: Account;
}

interface IDispProps {
  registerCard: (
    args: PaymentActions.MutationRegisterCardArgs,
  ) => Promise<PaymentActions.REGISTER_CARD_RESULT_TYPE>;
}

interface IState {}

class RegisterCardDialog extends React.Component<
  IProps & IDispProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    this.props.onClose(false);
  }

  public render() {
    const { classes, open, t } = this.props;
    const initialValues: RegisterCardFormValues = {
      cardName: '',
      cardNumber: '',
      cardDate: '',
      cardCvc: '',
    };

    const validateSchema = Yup.object().shape({
      cardName: Yup.string()
        .required('required')
        .matches(/^[0-9a-zA-Z ._\-]+$/, 'name_of_card_allows_alpha_numeric_space')
        .max(DEFAULT_MAXLEN_NAME, 'name_of_card_too_long'),
      cardNumber: Yup.string()
        .required('required')
        .matches(/^[0-9]+$/, 'card_number_allows_only_numeric_characters')
        .test('is-supported-card-number', t('invalid_supported_card_number'), function (value) {
          const cardTypeInfo = creditCardType(value)[0];
          return !['JCB'].includes(cardTypeInfo?.type?.toUpperCase() || '');
        })
        .min(14, 'card_number_must_be_between_14_16_digit_number')
        .max(16, 'card_number_must_be_between_14_16_digit_number'),
      cardCvc: Yup.string()
        .required('required')
        .matches(/^[0-9]+$/, 'invalid')
        .min(3, 'invalid')
        .max(4, 'invalid'),
      cardDate: Yup.string()
        .required('required')
        .max(7, 'invalid')
        .test('check-year', 'invalid', function (d) {
          if (!d) {
            return false;
          }
          const m = d.split('/')[0] || '';
          const y = d.split('/')[1] || '';
          if (y.length === 3) {
            return false;
          }
          return !m && y ? false : true;
        })
        .test('expiration-check-date', 'expired', function (d) {
          if (!d) {
            return false;
          }
          const m = d.split('/')[0] || '';
          const y = d.split('/')[1] || '';
          const nowUtc = moment().utc();
          const nowNum = parseInt(nowUtc.format(y.length !== 2 ? 'YYYYMM' : 'YYMM')) || 0;
          const cardNum = parseInt(`${y}${m}`) || 0;
          return cardNum > nowNum ? true : false;
        }),
    });

    return (
      <CustomDialog
        open={open}
        onClose={this.onDialogCancel}
        classes={{ paper: classes.customPaper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form className={classes.form}>
              <CustomDialogTitle className={classes.customTitleDialog}>
                <div id="member-billing-register-title">{t('register_payment_method')}</div>
              </CustomDialogTitle>
              <div id="member-billing-register-note" className={classes.subTitle}>
                {t('the_following_cards_can_be_used')}
              </div>
              <div id="member-billing-register-available-cards" className={classes.cardOptions}>
                <img
                  className={classes.cardIcon}
                  src="/images/icons/american_express_card_ico.png"
                />
                <img className={classes.cardIcon} src="/images/icons/master_card_ico.png" />
                <img className={classes.cardIcon} src="/images/icons/visa_card_ico.png" />
                {/* <img className={classes.cardIcon} src="/images/icons/jcb_card_ico.png" /> */}
              </div>
              <CustomDialogContent classes={{ root: classes.customDialogContent }}>
                <div className={classes.formSection}>
                  <Field name="cardNumber" render={this.cardNumberField} />
                </div>
                <div className={classes.formSection}>
                  <Grid container className={classes.customGrid}>
                    <Grid item md={6} className={classes.gridLeftItem}>
                      <Field name="cardDate" render={this.cardExpirationDateField} />
                    </Grid>
                    <Grid item md={6} className={classes.gridRightItem}>
                      <Field name="cardCvc" render={this.cardCvcField} />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <Field name="cardName" render={this.cardNameField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions classes={{ root: classes.customSubmitArea }}>
                <Button
                  data-testid="cancel-button"
                  id="member-billing-register-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  onClick={this.onDialogCancel}
                  variant="contained"
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="register-card-button"
                  id="member-billing-register-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('register_your_card')}
                  submittingLabel={t('sending')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private onDialogCancel = () => {
    this.props.onClose(false);
  };

  private onSubmit = async (
    values: RegisterCardFormValues,
    formikActions: FormikActions<RegisterCardFormValues>,
  ) => {
    const { registerCard, onClose, accountSeleted } = this.props;
    const { setSubmitting } = formikActions;

    if (isDevMode()) {
      console.log(values);
    }
    if (accountSeleted) {
      try {
        const result = await registerCard({
          accountUuid: accountSeleted.accountUuid,
          card: {
            name: values.cardName || void 0,
            number: values.cardNumber,
            month: values.cardDate.split('/')[0],
            year: values.cardDate.split('/')[1],
            cvc: values.cardCvc || void 0,
          },
        });

        if (!result.registerCard.status) {
          throw new Error(result.registerCard.error);
        }
        onClose(true);
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      if (window) {
        window.alert('Account not selected.');
      }
    }
  };

  private cardNameField = ({ field, form }: FieldProps<RegisterCardFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('name_on_card')}</div>
        </div>
        <div>
          <CustomInput
            {...field}
            data-testid="name-card-input"
            id="member-billing-register-name"
            placeholder=""
          />
          {!!form.errors.cardName && form.touched.cardName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.cardName)}
            </div>
          )}
        </div>
      </>
    );
  };

  private cardNumberField = ({ field, form }: FieldProps<RegisterCardFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('card_number')}</div>
        </div>
        <div>
          <CustomInput
            {...field}
            data-testid="card-number-input"
            id="member-billing-register-card"
            placeholder="1234 1234 1234 1234 1234"
          />
          {!!form.errors.cardNumber && form.touched.cardNumber && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.cardNumber)}
            </div>
          )}
        </div>
      </>
    );
  };

  private cardExpirationDateField = ({ field, form }: FieldProps<RegisterCardFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabel}>{t('expiration_date')}</div>
        <div>
          <CustomDateInput
            {...field}
            data-testid="expiration-date-input"
            id="member-billing-register-expiration"
            className={classes.customYearInput}
            maxLength={7}
            onChange={(e) => {
              let { value } = e.target;
              const month = value.split('/')[0];
              const year = value.split('/')[1];
              if (month === '00') {
                e.target.value = value = `01/${year || ''}`;
              }
              if (parseInt(month[0]) > 1) {
                e.target.value = value = `0${value}`;
              }
              if (parseInt(month) > 12) {
                e.target.value = value = `0${month[1]}/${year || ''}`;
              }
              if (value.length > 1 && !value.includes('/')) {
                e.target.value = value = value.slice(0, 2) + '/' + value.slice(2);
              }
              form.setFieldValue('cardDate', value);
            }}
            onKeyDown={(e) => {
              // allow BackSpace, Tab, Left/Right arrows and digits
              if (
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                [8, 9, 37, 39].includes(e.keyCode)
              ) {
                if (e.currentTarget.value) {
                  const { value } = e.currentTarget;
                  if (value.length === 3 && e.keyCode === 8) {
                    e.currentTarget.value = value[0] + '0';
                  }
                }
              } else {
                e.preventDefault();
              }
            }}
            placeholder="MM/YY"
          />
          {!!form.errors.cardDate && form.touched.cardDate && (
            <div className={classNames(classes.formLabel, classes.formErrorYear)}>
              {t(form.errors.cardDate)}
            </div>
          )}
        </div>
      </>
    );
  };

  private cardCvcField = ({ field, form }: FieldProps<RegisterCardFormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('cvc_number')}</div>
        </div>
        <div className={classes.cvcInputArea}>
          <CustomInput
            {...field}
            data-testid="cvc-number-input"
            id="member-billing-register-cvc"
            placeholder="CVC"
          />
          <img src="/images/icons/cvc_ico.svg" className={classes.cvcIcon} />
          {!!form.errors.cardCvc && form.touched.cardCvc && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.cardCvc)}
            </div>
          )}
        </div>
      </>
    );
  };
}

const styles: StyleRules = {
  root: {},
  customPaper: {
    maxWidth: 450,
    maxHeight: 'calc(100% - 78px)',
  },
  form: {
    display: 'inherit',
  },
  customTitleDialog: {
    padding: '55px 0 26px',
  },
  customDialogContent: {
    padding: '0 81px',
    overflowY: 'unset',
  },
  customYearInput: {
    height: 40,
    paddingLeft: 16,
    paddingRight: 0,
    letterSpacing: 1,
  },
  customGrid: {
    flexWrap: 'unset',
    justifyContent: 'space-between',
  },
  subTitle: {
    color: lightSlateGreyColor,
    fontSize: 13,
    textAlign: 'center',
  },
  cardIcon: {
    width: 43,
    height: 30,
    marginRight: 7,
  },
  cardOptions: {
    textAlign: 'center',
    borderBottom: `1px solid ${pattensBlueColor}`,
    margin: '0 81px 10px',
    paddingBottom: 20,
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  formErrorYear: {
    right: 12,
    bottom: 6,
    color: romanColor,
  },
  gridLeftItem: {
    position: 'relative',
    width: 130,
    flexBasis: 'unset',
  },
  gridRightItem: {
    width: 130,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    width: 120,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginLeft: 0,
    marginRight: 10,
  },
  cvcInputArea: {
    position: 'relative',
  },
  cvcIcon: {
    position: 'absolute',
    right: 5,
    top: 8,
  },
  customSubmitArea: {
    paddingLeft: 81,
    paddingRight: 81,
  },
};

const mapStateToProps = (store: IStore, ownProps) => ({
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  registerCard: (args: PaymentActions.MutationRegisterCardArgs) =>
    dispatch(PaymentActions.registerCard(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('member')(RegisterCardDialog)),
);

type RegisterCardFormValues = {
  cardName: string;
  cardNumber: string;
  cardDate: string;
  cardCvc: string;
};

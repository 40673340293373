const bcp47 = require('bcp-47');
import Axios from 'axios';
import { HARD_FORK_CONFIGURATION } from '~/constants/consts';
import { Account, Cluster, EndpointTypeListenEnum, GenesisConfig, Network } from '~/gapi/gtypes';
import { ICluster } from '~/types/network-types';

export const getHostFromClient = () => {
  return `${location.protocol}//${location.host}`;
};

export const isDevMode = () => {
  return true;
};

export const copyTextToClipboard = (text: string) => {
  const el = document.createElement('input');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'fixed';
  el.style.left = '-99999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

interface ParsedBCP47String {
  language: string | null; // ISO 639 language code
  extendedLanguageSubtags: Array<string>; // Selected three-character ISO 639 codes
  script: string | null; // Four character ISO 15924 script code
  region: string | null; // Two alphabetical character ISO 3166-1 code, or three digit UN M.49 code
  variants: Array<string>; // 5 to 8 (inclusive) character language variants
  extensions: Array<any>;
  privateuse: Array<string>; // List of private-use subtags
  irregular: string | null; // One of the irregular tags
  regular: string | null; // One of the regular tags
}

/**
 * Parse BCP 47 string tags.
 * Visit https://github.com/wooorm/bcp-47 to get more detail
 * @param {String} language
 * @returns {NavigatorLanguage}
 */
export const parseBCP47String = (bcp47Str: string): ParsedBCP47String => {
  return bcp47.parse(bcp47Str);
};

export interface Geolocation {
  ip: string;
  version: string;
  timezone: string;
}

export interface Address {
  pref: string;
  city: string;
  town: string;
}

/**
 * Get geolocation from ip address.
 * https://ipapi.co/
 * @param {String} ipAddress
 * @returns {Geolocation}
 */
export const getGeolocationFromIpAddress = async (ipAddress: string = ''): Promise<Geolocation> => {
  const path = ipAddress !== '' ? `${ipAddress}/json/` : 'json/';
  const axios = Axios.create();
  const ipapi = await axios.get(`https://ipapi.co/${path}`).catch((_) => void 0);

  const result = {
    ip: '',
    version: '',
    timezone: '',
  };

  if (ipapi && ipapi.data) {
    result.ip = ipapi.data.ip || '';
    (result.version = ipapi.data.version
      ? ipapi.data.version.toLowerCase().replace('ipv', '')
      : ''),
      (result.timezone = ipapi.data.timezone || '');
  }

  return result;
};

export const getAddressFromZipcode = async (zipcode: string = ''): Promise<Address> => {
  const axios = Axios.create();
  const api = await axios
    .get(`https://api.zipaddress.net/?zipcode=${zipcode}`)
    .catch((_) => void 0);

  const result = {
    pref: '',
    city: '',
    town: '',
  };

  if (api && api.data && api.data.data) {
    result.pref = api.data.data.pref || '';
    result.city = api.data.data.city || '';
    result.town = api.data.data.town || '';
  }

  return result;
};

export const isConsortiumAdmin = (account?: Account, network?: Network): boolean => {
  return (
    network?.consortiums.find((value) => value.accountUuid === account?.accountUuid)?.role ===
    'admin'
  );
};

export const isAccountOwnCluster = (account?: Account, cluster?: Cluster): boolean => {
  return account?.accountUuid === cluster?.accountUuid;
};

export const getRandomInt = (min: number = 0, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getLatestHardForkByGenesis = (
  genesisConfig: GenesisConfig,
): { label: string; blockNumber: number } | undefined => {
  const hardForkOderList = HARD_FORK_CONFIGURATION.sort((a, b) => b.order - a.order);
  for (const item of hardForkOderList) {
    if (genesisConfig[item.nameInGenesis] != null) {
      return {
        label: item.label,
        blockNumber: genesisConfig[item.nameInGenesis],
      };
    }
  }
};

export const getLatestUpdateInNetwork = (
  network: Network,
): { label: string; blockNumber: number } | undefined => {
  const genesisConfig = network.blockchainInfo?.genesisConfig;
  if (genesisConfig) {
    return getLatestHardForkByGenesis(genesisConfig);
  }
};

export const getLatestBlockInNetwork = (network: Network): number => {
  const signerNodes = network.clusters
    .map((c) => c.nodes.filter((n) => n.nodeInfo.signer))
    .reduce((pre, cur) => {
      pre.push(...cur);
      return pre;
    }, []);
  return Math.max(...signerNodes.map((node) => node.nodeInfo.latestBlock)) || 0;
};

export const listNodesBelongToAccount = (
  account: Account,
  network: Network,
  onlySigner = false,
) => {
  return network.clusters
    .filter((cluster) => cluster.accountUuid === account.accountUuid)
    .map((c) => c.nodes.filter((n) => (onlySigner ? n.nodeInfo.signer : true)))
    .reduce((pre, cur) => pre.concat(cur), []);
};

export const ellipsifyText = (text: string, first = 8, last = 8) =>
  `${text.slice(0, first)}...${text.slice(-last)}`;

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import { IStore } from '~/stores/configure-store';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  romanColor,
  lightSlateGreyColor,
  matterhornColor,
} from '~/styles/themes/common-styles/color';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
import { LGPelorousDenim } from '~/styles/themes/common-styles/misc';
// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
//Redux
import * as AppActions from '~/stores/actions/app-action';
import { MAX_ORGANIZATION_NAME_LENGTH } from '~/constants/validation';

// defines

interface IStateProps {}

interface IDispProps {
  createAccount: (
    args: AppActions.MutationCreateAccountArgs,
  ) => Promise<AppActions.CREATE_ACCOUNT_RESULT_TYPE>;
  updateAccountSelected: (
    args: AppActions.UpdateAccountSelectedArgs,
  ) => Promise<AppActions.GET_ACCOUNT_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

interface IState {
  isShowAdvanced: boolean;
}

class CreateAccountDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isShowAdvanced: false,
    };
  }

  public render() {
    const { classes, open, onClose, t } = this.props;
    const validateSchema = Yup.object().shape({
      accountName: Yup.string()
        .trim()
        .required(t('required_field'))
        .max(
          MAX_ORGANIZATION_NAME_LENGTH,
          t('too_many_character_of_organization_name_error_message', {
            value: MAX_ORGANIZATION_NAME_LENGTH,
          }),
        ),
    });

    return (
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-account-add-title">{t('create_organization')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div id="member-account-add-des" className={classes.block}>
                  <div className={classes.please}>{t('please_enter_your_organization_name')}</div>
                  <div className={classes.note}>
                    {t('note')}: {t('enter_your_company_name_or_team_name_as_organization_name')}
                    <br />
                    {t('this_service_is_billed_per_organization')}
                  </div>
                </div>
                <div data-name="accountName">
                  <Field name="accountName" render={this.accountNameField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-account-add-submit-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  onClick={onClose}
                  variant="contained"
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="create-button"
                  id="member-account-add-submit-add"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('create')}
                  submittingLabel={t('creating')}
                  classes={{
                    root: classes.createBtn,
                  }}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private accountNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'organization_name'} />
          </div>
          {!!form.errors.accountName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.accountName}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="organization-name-input"
            {...field}
            id="member-account-add-input"
            placeholder={t('input_organization_name')}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, createAccount, updateAccountSelected } = this.props;
    const { accountName } = values;
    try {
      const newAccount = await createAccount({
        input: {
          displayName: accountName.trim(),
        },
      });
      updateAccountSelected({ accountUuid: newAccount.createAccount.accountUuid });
      onClose();
    } catch (error) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  createBtn: {
    ...defaultFontBold,
    fontSize: 16,
    width: 160,
    height: 36,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    backgroundImage: LGPelorousDenim,
  },
  please: {
    ...defaultFont,
    fontSize: 15,
    color: matterhornColor,
  },
  note: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  block: {
    textAlign: 'center',
    marginBottom: 42,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateAccountSelected: (args: AppActions.UpdateAccountSelectedArgs) =>
    dispatch(AppActions.updateAccountSelected(args)),
  createAccount: (args) => dispatch(AppActions.createAccount(args)),
});

export default withStyles(styles)(
  withTranslation('member')(connect(mapStateToProps, mapDispatchToProps)(CreateAccountDialog)),
);

type FormValues = {
  accountName: string;
};

const initialValues: FormValues = {
  accountName: '',
};

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { IStore } from '~/stores/configure-store';
// Component
import ContentContainerView from '../../common/ContentContainer';
import OverviewTab from './tabs/overview-tab';
import { INetwork } from '~/types/network-types';
// Hook
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';

interface IProps {
  networkSelected?: INetwork;
}

interface IDispProps {}

interface IDefaultProps extends Required<IProps> {}

interface IState {
  // network?: INetwork;
}

class Settings extends React.Component<
  IProps & IDispProps & WithStyles<typeof styles> & RouteComponentProps<{ networkId: string }>,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, match, networkSelected } = this.props;

    const {
      // network,
    } = this.state;

    // Loading
    if (networkSelected === undefined) {
      return null;
    }

    return (
      <ContentContainerView
        title="Settings"
        tabs={[
          {
            title: 'Overview',
            tabName: 'overview',
            component: <OverviewTab />,
          },
        ]}
        routes={[
          {
            name: `Network: ${networkSelected.networkName}`,
            url: `/network/${networkSelected.networkUuid}/overview`,
          },
          { name: 'Settings', url: `/network/${networkSelected.networkUuid}/settings` },
        ]}
      />
    );
  }
}

const styles: StyleRules = {
  root: {},
};

const mapStateToProps = (store: IStore, ownProps): IProps => ({
  networkSelected: store.appState.networkSelected,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default compose<React.ClassicComponentClass>(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Settings);

import React, { useCallback, useMemo, useState } from 'react';
import ms from 'ms';
import moment from 'moment';
// Styles
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  denimColor,
  lightSlateGreyColor,
  persianGreenColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
// Components
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowBodyCustom from '~/components/common/table-row-body';
// Translation
import { Trans, useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { Account } from '~/types/account-types';
import { INetwork } from '~/types/network-types';
import { Item } from '..';
import classNames from 'classnames';
import CustomNumericString from '~/components/common/custom-numeric-string';
import SubmitButton from '~/components/common/submit-button';
import { useDispatch } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
import { HARD_FORK_CONFIGURATION } from '~/constants/consts';
import { isConsortiumAdmin } from '~/utilities/utils';
import { useHistory } from 'react-router';

type SubmittingStatus = 'approved' | 'rejected' | 'canceled';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  item: Item;
  network: INetwork;
  account: Account;
  reFetchData?: () => void;
}

const HardForkRow = (props: IProps) => {
  const { classes, item, network, account, reFetchData } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [submittingStatus, setSubmittingStatus] = useState<string | null>('');

  const hasSignerNodes = network.clusters
    .filter((cluster) => account.accountUuid === cluster.accountUuid)
    .reduce(
      (hasSigner, cluster) =>
        hasSigner ||
        cluster.nodes.reduce((initial, node) => node.nodeInfo.signer || initial, false),
      false,
    );

  const voteProposal = useCallback(
    async (status: SubmittingStatus) => {
      setSubmittingStatus(status);
      // do api
      try {
        await dispatch(
          NetworkActions.voteHardForkProposal({
            input: {
              accountUuid: account.accountUuid,
              networkUuid: network.networkUuid,
              uuid: item.uuid,
              status,
            },
          }),
        );
      } catch (error) {}

      reFetchData && reFetchData();
      setSubmittingStatus(null);
    },
    [account.accountUuid, dispatch, item.uuid, network.networkUuid, reFetchData],
  );

  const handleRetryHardFork = useCallback(
    async (event) => {
      event.stopPropagation();
      setSubmittingStatus('retry');
      // do api
      try {
        await dispatch(
          NetworkActions.retryApplyHardForkToNetwork({
            input: {
              accountUuid: account.accountUuid,
              networkUuid: network.networkUuid,
            },
          }),
        );
      } catch (error) {}

      reFetchData && reFetchData();
      setSubmittingStatus(null);
    },
    [account.accountUuid, dispatch, network.networkUuid, reFetchData],
  );

  const onOpenHardForkDetail = useCallback(() => {
    if (item.status === 'approved') {
      history.push(`/network/${network.networkUuid}/hardfork/${item.uuid}`);
    }
  }, [item.status, item.uuid, history, network.networkUuid]);

  const enableReApplyHardFork = useMemo(() => {
    if (network.planningHardFork?.proposalId === item.uuid) {
      return (
        item.status === 'approved' &&
        isConsortiumAdmin(account, network) &&
        moment(network.planningHardFork.estimateEndAt).isBefore(moment())
      );
    }
    return false;
  }, [account, item, network]);

  const renderActions = useMemo(() => {
    if (hasSignerNodes) {
      let canVote = false;
      if (item.status === 'proposed') {
        const votedNodes = [...item.approvedNodeUuids, ...item.rejectedNodeUuids].filter(
          (item, i, self) => self.indexOf(item) === i,
        ); // unique list

        const myNodes = network.clusters
          .filter((c) => c.accountUuid === account.accountUuid)
          .map((c) => c.nodes.filter((n) => n.nodeInfo.signer))
          .reduce((pre, cur) => {
            pre.push(...cur);
            return pre;
          }, [])
          .filter((n) => n && !votedNodes.includes(n.nodeUuid));

        if (myNodes.length > 0) {
          canVote = true;
        }
      }

      return (
        <div className={classes.proposalAction}>
          {enableReApplyHardFork ? (
            <SubmitButton
              id="proposals-act-apply"
              kind="flat"
              isSubmitting={submittingStatus === 'retry'}
              isValid={!submittingStatus}
              classes={{ root: classes.reApplyBtn }}
              onClick={handleRetryHardFork}
              label={t('retry')}
            />
          ) : (
            <>
              {canVote && (
                <SubmitButton
                  id="proposals-act-approve"
                  kind="flat"
                  isSubmitting={submittingStatus === 'approved'}
                  isValid={!submittingStatus}
                  classes={{ root: classes.acceptBtn }}
                  onClick={(e) => voteProposal('approved')}
                  label={t('approve')}
                />
              )}
              {canVote && (
                <SubmitButton
                  id="proposals-act-reject"
                  kind="flat"
                  isSubmitting={submittingStatus === 'rejected'}
                  isValid={!submittingStatus}
                  classes={{ root: classes.rejectBtn }}
                  onClick={(e) => voteProposal('rejected')}
                  label={t('reject')}
                />
              )}
              {item.status === 'proposed' && account.accountUuid === item.accountUuid && (
                <SubmitButton
                  id="proposals-act-cancel"
                  kind="text"
                  isSubmitting={submittingStatus === 'canceled'}
                  isValid={!submittingStatus}
                  classes={{
                    root: classes.cancelBtn,
                    disabled: classes.cancelBtnDisabled,
                  }}
                  onClick={(e) => voteProposal('canceled')}
                  label={t('cancel')}
                />
              )}
              {!canVote && item.status === 'approved' && (
                <div className={classes.actionText}>{t('sent_approval')}</div>
              )}
              {!canVote && item.status === 'rejected' && (
                <div className={classes.actionText}>{t('sent_rejection')}</div>
              )}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className={classes.proposalAction}>
          {item.status === 'proposed' && account.accountUuid === item.accountUuid && (
            <SubmitButton
              id="proposals-act-cancel"
              kind="text"
              isSubmitting={submittingStatus === 'canceled'}
              isValid={!submittingStatus}
              classes={{ root: classes.cancelBtn }}
              onClick={(e) => voteProposal('canceled')}
              label={t('cancel')}
            />
          )}
          {item.status === 'approved' && (
            <div className={classes.actionText}>{t('sent_approval')}</div>
          )}
          {item.status === 'rejected' && (
            <div className={classes.actionText}>{t('sent_rejection')}</div>
          )}
        </div>
      );
    }
  }, [
    hasSignerNodes,
    item,
    classes,
    enableReApplyHardFork,
    submittingStatus,
    handleRetryHardFork,
    t,
    account.accountUuid,
    network.clusters,
    voteProposal,
  ]);

  const numberOfIncompatibleNodes = useMemo(() => {
    const hardFork = HARD_FORK_CONFIGURATION.find((val) => val.value === item.name);
    const signerNodes = network?.clusters
      .map((c) => c.nodes.filter((n) => n.nodeInfo.signer))
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, []);
    return signerNodes?.filter((node) =>
      hardFork?.incompatibleGeths.includes(node.nodeInfo.version),
    ).length;
  }, [item.name, network?.clusters]);

  return (
    <TableRowBodyCustom className={classes.tableRowBodyCustom} onClick={onOpenHardForkDetail}>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        {item.name}
      </TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        {item.blockNumber ? <CustomNumericString value={item.blockNumber.toString()} /> : 'N/A'}
      </TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        {ms(item.periodTargetBlock, { long: true })}
      </TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        <Trans i18nKey="alert_createAt" values={{ date: new Date(item.createAt) }} />
      </TableCellBodyCustom>
      <TableCellBodyCustom className={classes.approvalStatusColumn}>
        <div className={classes.appropvalStatus}>
          <div>
            {item.status === 'proposed' && (
              <div className={classes.approvalStatusIcon}>
                <img className={classes.proposalIcon} src="/images/icons/proposed_ico.svg" alt="" />
                {t('proposed')}
              </div>
            )}
            {item.status === 'approved' && (
              <div className={classes.approvalStatusIcon}>
                <img className={classes.proposalIcon} src="/images/icons/check_ico.svg" alt="" />
                {t('approved')}
              </div>
            )}
            {item.status === 'rejected' && (
              <div className={classes.approvalStatusIcon}>
                <img className={classes.proposalIcon} src="/images/icons/reject_ico.svg" alt="" />
                {t('rejected')}
              </div>
            )}
            {item.status === 'canceled' && (
              <div className={classes.approvalStatusIcon}>
                <img className={classes.proposalIcon} src="/images/icons/cancel_ico.png" alt="" />
                {t('cancelled')}
              </div>
            )}
          </div>
          <table>
            <tbody>
              <tr>
                <td className={classes.appropvalStatusTitle}>{`${t('approved_nodes')} `}</td>
                <td
                  className={classes.appropvalStatusNumber}
                >{`: ${item.approvedNodeUuids.length}`}</td>
              </tr>
              <tr>
                <td className={classes.appropvalStatusTitle}>{`${t('rejected_nodes')} `}</td>
                <td
                  className={classes.appropvalStatusNumber}
                >{`: ${item.rejectedNodeUuids.length}`}</td>
              </tr>
              <tr>
                <td className={classes.appropvalStatusTitle}>{`${t('num_of_validators')} `}</td>
                <td
                  className={classes.appropvalStatusNumber}
                >{`: ${item.signerNodeUuids.length}`}</td>
              </tr>
              {!!numberOfIncompatibleNodes && item.status === 'proposed' && (
                <tr>
                  <td className={classes.incompatibleTitle}>{`${t(
                    'number_of_incompatible_validators',
                  )} `}</td>
                  <td className={classes.incompatibleTitle}>{`: ${numberOfIncompatibleNodes}`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </TableCellBodyCustom>
      <TableCellBodyCustom className={classes.actionColumn}>{renderActions}</TableCellBodyCustom>
    </TableRowBodyCustom>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    listOptionItem: {
      ...defaultFont,
      height: 46,
      width: 210,
      fontSize: 16,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},

    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    tableRowBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
      cursor: 'pointer',
      '&:hover': {
        background: '#f5f5f5',
      },
    },
    actionColumn: {
      paddingLeft: 10,
      padding: '10px 0 10px 1.5%',
      '&:last-child': {
        paddingRight: '1%',
        width: 'inherit',
      },
    },
    proposalAction: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      maxWidth: '150px',
    },
    approvalStatusColumn: {
      ...defaultFont,
      fontSize: 13,
    },
    appropvalStatus: {
      // display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'center',
      // flexWrap: 'wrap',
    },
    approvalStatusIcon: {
      padding: '10px 0',
    },
    proposalIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    appropvalStatusTitle: {
      color: lightSlateGreyColor,
    },
    incompatibleTitle: {
      color: 'red',
    },
    appropvalStatusNumber: {
      color: denimColor,
    },
    reApplyBtn: {
      width: 'auto',
      minWidth: 80,
      paddingLeft: 15,
      paddingRight: 15,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: '#113da7',
      '&:hover': {
        backgroundColor: '#113da7',
      },
      margin: 5,
    },
    acceptBtn: {
      width: 'auto',
      minWidth: 80,
      paddingLeft: 15,
      paddingRight: 15,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: persianGreenColor,
      '&:hover': {
        backgroundColor: persianGreenColor,
      },
      margin: 5,
    },
    rejectBtn: {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: 'white',
      borderRadius: 4,
      backgroundColor: romanColor,
      '&:hover': {
        backgroundColor: romanColor,
      },
      margin: 5,
    },
    cancelBtn: {
      // width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
      height: 32,
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
      borderRadius: 4,
      margin: 5,
    },
    cancelBtnDisabled: {
      backgroundColor: 'unset',
      margin: 5,
    },
    actionText: {
      color: lightSlateGreyColor,
    },
    textExpired: {
      color: romanColor,
    },
  });

export default withStyles(styles)(withTranslation()(HardForkRow));

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IStore } from '~/stores/configure-store';
// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Component
import ContentContainerView from '~/components/common/ContentContainer';
import LicenseTab from './license-tab';
import StatementTab from './statement-tab';
import PaymentTab from './payment-tab';
import PaymentDetailTab from './payment-detail-tab';

// Style
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { lightSlateGreyColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
// Type
import { AccountRoleType, NetworkRoleType } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Hook

interface IStateProps {
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {}

interface IProps
  extends IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    RouteComponentProps<{}>,
    WithTranslation {}

interface IState {}

class Billings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { t, selectedAccountRole } = this.props;
    const showStatementTab = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';
    const showPaymentTab = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';
    const showPaymentDetailTab = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';

    const tabs = [
      {
        title: t('license'),
        tabName: 'license',
        url: '/billing/license',
        component: <LicenseTab />,
      },
    ];

    if (showStatementTab) {
      tabs.push({
        title: t('bill_state'),
        tabName: 'statement',
        url: '/billing/statement',
        component: <StatementTab />,
      });
    }

    if (showPaymentTab) {
      tabs.push({
        title: t('payment_way'),
        tabName: 'payment',
        url: '/billing/payment',
        component: <PaymentTab />,
      });
    }

    if (showPaymentDetailTab) {
      tabs.push({
        title: t('billing_details_tab_title'),
        tabName: 'detail',
        url: '/billing/detail',
        component: <PaymentDetailTab />,
      });
    }

    return (
      <ContentContainerView
        title={t('billing')}
        titleId="member-billing-name"
        tabs={tabs}
        tabId="member-billing-tab"
      />
    );
  }
}

const styles: StyleRules = {
  root: {
    paddingBottom: 50,
  },
  loadingArea: {
    marginTop: 200,
    textAlign: 'center',
  },
  tableArea: {
    marginTop: 20,
  },
  typeCell: {
    width: '15%',
    minWidth: 150,
  },
  contentCell: {
    width: '60%',
  },
  dateCell: {
    width: '25%',
  },
  description: {
    marginTop: 10,
  },
  descriptionText: {
    ...defaultFont,
    fontSize: 13,
  },
  dateText: {
    ...defaultFont,
    fontSize: 13,
    lineHeight: '18px',
    color: lightSlateGreyColor,
  },
  pagination: {
    marginTop: 30,
    textAlign: 'center',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default compose<React.ClassicComponentClass>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Billings);

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  denimColor,
} from '~/styles/themes/common-styles/color';

import SearchInput from '~/components/common/search-input';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import NodeStatusIcon from '~/components/common/node-status-icon';
import EditButton from '~/components/common/edit-button';

import { INetwork, INode } from '~/types/network-types';

interface IProps {}

interface IDefaultProps extends Required<IProps> {}

interface IState {}

class OverviewTab extends React.Component<IProps & WithStyles<typeof styles>, IState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes } = this.props;

    const {} = this.state;

    return (
      <div className={classes.root}>
        {settings.map((tool, index) => (
          <div key={index} className={classes.item}>
            <div>
              <div className={classes.toolTitle}>{tool.name}</div>
              <div className={classes.toolDescription}>{tool.description}</div>
            </div>
            <EditButton className={classes.editBtn} />
          </div>
        ))}
      </div>
    );
  }
}

const styles: StyleRules = {
  root: {
    paddingBottom: 50,
  },
  item: {
    padding: 25,
    borderBottom: `1px solid rgba(153, 170, 185, 0.38)`,
    display: 'flex',
    alignItems: 'center',
  },
  toolTitle: {
    ...defaultFont,
    color: denimColor,
    fontSize: 18,
  },
  toolDescription: {
    marginTop: 20,
    ...defaultFont,
    color: lightSlateGreyColor,
    fontSize: 14,

    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  editBtn: {
    marginLeft: 50,
    border: `1px solid ${pattensBlueColor}`,
    backgroundColor: whiteSmokeColor,
    width: 80,
    height: 36,
    marginRight: 5,
  },
};

export default withStyles(styles)(OverviewTab);

const settings: { name: string; description: string }[] = [
  {
    name: 'Deploy',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
    but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
    containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  {
    name: 'Project Name',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
    but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
    containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  {
    name: 'Project Time Zone',
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
    but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
    containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
];

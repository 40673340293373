import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Component
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import CustomDialog from '../../../custom-dialog';
import CustomDialogTitle from '../../../custom-dialog-title';
import CustomDialogContent from '../../../custom-dialog-content';
import CustomDialogActions from '../../../custom-dialog-actions';
import SubmitButton from '../../../submit-button';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';
// Type
import { IAccountMember, Account } from '~/types/account-types';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {
  account?: Account;
}

interface IDispProps {
  changePrimaryOwner: (
    args: AppActions.MutationChangePrimaryOwnerArgs,
  ) => Promise<AppActions.CHANGE_PRIMARY_OWNER_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  member: IAccountMember;
  onClose: () => void;
}

interface IState {
  isSubmitting: boolean;
}

class ChangePrimaryOwnerDialog extends React.Component<IProps, IState> {
  constructor(props: IProps & IDispProps) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  public render() {
    const { classes, open, onClose, member, t } = this.props;
    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle>
          <div id="member-account-members-transfer-title">{t('transfer_primary_owner')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div id="member-account-members-transfer-attention" className={classes.warningText}>
            {t('Are_you_sure_you_want_to_transfer_the_primary_owner')}
          </div>
        </CustomDialogContent>
        {/* Render Submit Button */}
        <CustomDialogActions>
          <Button
            data-testid="cancel-button"
            id="member-account-members-transfer-submit-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="contained"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="transfer-role-button"
            id="member-account-members-transfer-submit-del"
            isValid={true}
            isSubmitting={isSubmitting}
            label={t('transfer_role')}
            submittingLabel={t('updating')}
            onClick={this.onChangePrimaryOwner}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  private onChangePrimaryOwner = async () => {
    const { onClose, changePrimaryOwner, account, member } = this.props;
    if (!account) {
      return;
    }

    this.setSubmitting(true);
    try {
      await changePrimaryOwner({
        input: {
          accountUuid: account.accountUuid,
          uid: member.uid,
        },
      });
      onClose();
    } catch (error) {
      this.setSubmitting(false);
    }
  };

  private setSubmitting = (value: boolean) => {
    this.setState({
      isSubmitting: value,
    });
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 620,
  },
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  infoArea: {
    borderRadius: 4,
    border: `solid 1px ${pattensBlueColor}`,
    backgroundColor: snowColor,
    display: 'flex',
  },
  avatar: {
    width: 100,
    height: 100,
    marginTop: 30,
    marginLeft: 30,
    marginBottom: 30,
  },
  userInfo: {
    paddingLeft: 40,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
    paddingBottom: 2,
  },
  infoItem: {
    marginTop: 10,
    marginBottom: 6,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 14,
    color: lightSlateGreyColor,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  changePrimaryOwner: (args: AppActions.MutationChangePrimaryOwnerArgs) =>
    dispatch(AppActions.changePrimaryOwner(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePrimaryOwnerDialog)),
);

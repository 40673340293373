import React from 'react';

interface Props {}

const AboutPage: React.FC<Props> = (props) => {
  return (
    <div style={{ margin: 8, color: 'black' }}>
      <h1 style={{ fontWeight: 'bold', fontSize: '2em', fontFamily: 'none' }}>About</h1>
      <p style={{ fontSize: 16, fontFamily: 'none' }}>Version : {process.env.REACT_APP_VERSION}</p>
    </div>
  );
};

export default AboutPage;

type EnvType = {
  REACT_APP_BACKEND_API_URL: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_PUBLIC_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_POLLING_NETWORK_INTERVAL: string;
  REACT_APP_ACCOUNT_PORTAL_URL: string;
};
export const env: EnvType = process.env.NODE_ENV === 'production' ? window['env'] : process.env;

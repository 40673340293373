import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { denimColor } from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
// Translation
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Defines
import { DEFAULT_POLLING_INTERVAL, TAB_TITLE_CONCAT } from '~/constants/consts';
import { renderRoutes, RouteConfig } from 'react-router-config';
import HardForkList from './hard-fork-list';
import HardForkDetail from './hard-fork-detail';
import { PROPOSALS_PAGE_INDEX_DEFAULT, PROPOSALS_PAGE_SIZE_DEFAULT } from '~/constants/store';
import { QueryListHardForkProposalsArgs } from '~/gapi/gtypes';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

const HardForkTab = (props: IProps) => {
  const { t } = useTranslation();
  const { classes, network, account } = props;
  const dispatch = useDispatch();
  document.title = TAB_TITLE_CONCAT + t('hard_fork');

  const fetchHardForkProposals = useCallback(
    (params: Partial<QueryListHardForkProposalsArgs> = {}) => {
      dispatch(
        NetworkActions.listHardForkProposals({
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          page: {
            pageIndex: PROPOSALS_PAGE_INDEX_DEFAULT,
            pageSize: PROPOSALS_PAGE_SIZE_DEFAULT,
            ...params.page,
          },
        }),
      ).catch(() => {});
    },
    [account.accountUuid, dispatch, network.networkUuid],
  );

  const routes: RouteConfig[] = useMemo(() => {
    return [
      {
        path: `/network/${network.networkUuid}/hardfork`,
        component: () => <HardForkList fetchHardForkProposals={fetchHardForkProposals} />,
        exact: true,
      },
      {
        path: `/network/${network.networkUuid}/hardfork/:id`,
        component: () => <HardForkDetail />,
        exact: true,
      },
    ];
  }, [network.networkUuid, fetchHardForkProposals]);

  useEffect(() => {
    fetchHardForkProposals();
    const interval = setInterval(() => {
      fetchHardForkProposals();
    }, DEFAULT_POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [fetchHardForkProposals]);

  return renderRoutes(routes);
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    topLine: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    createClusterBtn: {
      width: 'auto',
      color: '#333',
    },
    addClusterIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tableArea: {
      marginTop: 20,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
      transform: 'translateY(2px)',
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    typeCell: {
      width: '10%',
    },
    contentCell: {
      width: '15%',
    },
    dateCell: {
      width: '17%',
    },
    statusCell: {
      width: '20%',
    },
    actionCell: {
      width: '15%',
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
  });

export default withStyles(styles)(withTranslation()(HardForkTab));

import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import { Theme } from '@mui/material/styles';
import { denimColor } from '~/styles/themes/common-styles/color';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import CreateNodeDialog from '~/components/common/create-node-dialog';
import SortIcon from '~/components/common/sort-icon';
import NodeRow from './node-row';
// Route
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { INetwork, INodeDisplay, INode, ICluster } from '~/types/network-types';
import { Account } from '~/types/account-types';
import { displayNodeStatus, displayNodeStatusDetail } from '~/utilities/render-utils';
import { Order, getComparator, stableSort, HeadCell } from '~/utilities/sort-utils';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

interface IState {
  openCreateNodeDialog: boolean;
  order: Order;
  orderBy: SortableHeadCellId;
}

class NodesTab extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openCreateNodeDialog: false,
      order: 'ASC',
      orderBy: 'nodeName',
    };
  }

  public render() {
    const { classes, network, t, account } = this.props;
    const { openCreateNodeDialog, order, orderBy } = this.state;
    const items = this.getItems();

    document.title = TAB_TITLE_CONCAT + this.props.t('nodes_title');

    return (
      <>
        <div className={classes.root}>
          {openCreateNodeDialog && (
            <CreateNodeDialog
              networkUuid={network.networkUuid}
              open={openCreateNodeDialog}
              onClose={this.onCloseCreateNodeDialog}
              network={network}
            />
          )}
          <div className={classes.topLine}>
            <LGButton
              data-testid="create-node-button"
              classes={{ root: classes.createClusterBtn }}
              onClick={this.onOpenCreateNodeDialog}
            >
              <ImgIcon className={classes.addClusterIcon} imgUrl="/images/icons/add_ico.png" />
              <span>{t('create_node')}</span>
            </LGButton>
          </div>
          <div className={classes.tableArea}>
            <Table id="nodes-list" className={classes.tblList}>
              <colgroup>
                <col width="20%" />
                <col width="14%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="5px" />
              </colgroup>
              <TableHeadCustom>
                <TableRowHeadCustom>
                  {headCells.map((headCell) => (
                    <TableCellHeadCustom
                      key={headCell.id}
                      data-testid="order-button"
                      onClick={this.onTableCellHeadClick(headCell.id)}
                      style={{ cursor: headCell.sortable ? 'pointer' : 'unset' }}
                      classes={{ content: classes.tableCellHeadContent }}
                    >
                      <span>{t(headCell.label)}</span>
                      {headCell.sortable && (
                        <SortIcon order={orderBy === headCell.id ? order : undefined} />
                      )}
                    </TableCellHeadCustom>
                  ))}
                  <TableCellHeadCustom></TableCellHeadCustom>
                </TableRowHeadCustom>
              </TableHeadCustom>
              <TableBodyCustom>
                {items.map((item) => (
                  <NodeRow key={item.nodeUuid} item={item} account={account} network={network} />
                ))}
              </TableBodyCustom>
            </Table>
          </div>
        </div>
      </>
    );
  }

  private onOpenCreateNodeDialog = () => {
    this.setState({
      openCreateNodeDialog: true,
    });
  };

  private onCloseCreateNodeDialog = () => {
    this.setState({
      openCreateNodeDialog: false,
    });
  };

  private getItems = (): Array<Item> => {
    const { network, t } = this.props;
    const { order, orderBy } = this.state;
    const items: Array<Item> = [];
    for (const cluster of network.clusters) {
      items.push(
        ...cluster.nodes.map((node) => ({
          nodeUuid: node.nodeUuid,
          nodeName: node.nodeName,
          nodeStatus: displayNodeStatus(node),
          nodeState: displayNodeStatusDetail(node, t, true),
          nodeType: node.nodeInfo.signer ? t('validator_node') : t('relay_node'),
          link: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/overview`,
          clusterName: cluster.clusterName,
          accountName: cluster.accountName,
          node,
          cluster,
        })),
      );
    }

    return stableSort<Item>(items, getComparator<SortableHeadCellId>(order, orderBy));
  };

  private onTableCellHeadClick = (id: SortableHeadCellId) => () => {
    const { order, orderBy } = this.state;
    this.setState({
      orderBy: id,
      order: orderBy === id && order === 'ASC' ? 'DESC' : 'ASC',
    });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    topLine: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    createClusterBtn: {
      width: 'auto',
      color: '#333',
    },
    addClusterIcon: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tableArea: {
      marginTop: 20,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
      transform: 'translateY(2px)',
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NodesTab)),
);

export type Item = {
  nodeUuid: string;
  nodeName: string;
  nodeStatus: INodeDisplay;
  nodeState: string;
  nodeType: string;
  clusterName: string;
  link: string;
  accountName: string;
  node: INode;
  cluster: ICluster;
};
type SortableHeadCellId = keyof Omit<Item, 'nodeUuid' | 'nodeStatus' | 'link' | 'node' | 'cluster'>;
const headCells: Array<HeadCell<SortableHeadCellId, never>> = [
  { id: 'nodeName', sortable: true, label: 'name' },
  { id: 'nodeState', sortable: true, label: 'states' },
  { id: 'nodeType', sortable: true, label: 'node_type' },
  { id: 'clusterName', sortable: true, label: 'cluster' },
  { id: 'accountName', sortable: true, label: 'organization_name' },
];

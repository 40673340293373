import React from 'react';
// Component
import Button from '@mui/material/Button';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { whiteColor, whisperColor, nepalColor } from '~/styles/themes/common-styles/color';

interface IProps extends WithStyles<typeof styles> {
  onClick?: any;
  disabled?: boolean;
  id?: string;
}

const lgButton: React.StatelessComponent<IProps> = ({
  classes,
  children,
  onClick,
  disabled,
  id,
  ...props
}) => (
  <Button {...props} className={classes.root} onClick={onClick} disabled={disabled} id={id}>
    {children}
  </Button>
);

const styles = createStyles({
  root: {
    backgroundImage: `linear-gradient(to bottom, ${whiteColor} , ${whisperColor})`,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: nepalColor,
    ...defaultFont,
    fontSize: 15,
    textTransform: 'none',
    width: 200,
    height: 34,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(lgButton);

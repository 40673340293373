// React, Redux
import React from 'react';

// Component
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import {
  whiteColor,
  denimColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  pattensBlueColor,
  nightRiderColor,
  sunColor,
  summerSkyColor,
  sunglow,
} from '~/styles/themes/common-styles/color';
// Type

import { Link } from 'react-router-dom';
import { AppRoutePath } from '~/AppRouter';

interface IProps extends WithStyles<typeof styles> {}

interface IState {}

interface IProps {
  brand: string;
  openHeaderBar?: boolean;
  absolute?: boolean;
  fixed?: boolean;
  rightLinks?: any;
  changeColorOnScroll: {
    height: number;
    color: string;
  };
}

interface IState {}

class Header extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  public render() {
    const { classes, rightLinks } = this.props;

    return (
      <div className={classes.header}>
        <AppBar position="fixed" className={classes.appBar}>
          <div className={classes.container}>
            <Toolbar classes={{ root: classes.toolBar }}>
              <Link to={AppRoutePath.Dashboard}>
                <img className={classes.logo} src="/images/icons/logo_guest.svg" alt="" />
              </Link>
              {rightLinks}
            </Toolbar>
          </div>
        </AppBar>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      minHeight: 60,
    },
    container,
    appBar: {
      backgroundColor: whiteColor,
      boxShadow: 'none',
      borderBottom: '1px solid rgba(112, 112, 112, 0.3)',
    },
    toolBar: {
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 60,
    },
    logo: {
      width: 240,
    },
    flex: {
      flex: 1,
    },
    circle: {
      width: 28,
      height: 28,
      borderRadius: '100%',
      opacity: 0.89,
      display: 'inline-block',
      backgroundColor: summerSkyColor,
      marginRight: 8,
      verticalAlign: 'middle',
    },
    toolBarTitle: {
      ...defaultFontBold,
      fontSize: 18,
      color: denimColor,
      verticalAlign: 'middle',
      display: 'inline-block',
      transform: 'translateY(1px)',
    },
    items: {
      display: 'flex',
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      paddingLeft: 12,
      paddingRight: 12,
    },
    itemBtn: {
      ...defaultFont,
      fontSize: 16,
      textTransform: 'none',
      height: 40,
    },
    userinfo: {
      display: 'flex',
      alignItems: 'center',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    accountName: {
      ...defaultFontMedium,
      fontSize: 13,
      color: lightSlateGreyColor,
      marginRight: 10,
      maxWidth: 400,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    accountInfo: {
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
      display: 'flex',
      alignItems: 'center',
    },
    copyButton: {
      padding: 5,
      marginLeft: 5,
      marginRight: -10,
    },
    accountSeparate: {
      width: 1,
      height: 18,
      backgroundColor: pattensBlueColor,
      marginRight: 15,
      marginLeft: 15,
    },
    closeAlertBtn: {
      position: 'absolute',
      top: '50%',
      right: 32,
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    },
    closeIcon: {
      width: 11,
      height: 11,
    },
    alertContainer: {
      height: 90,
      position: 'relative',
      transition: 'all 0.5s',
      '& .alert-background': {
        width: '100%',
        height: 30,
        position: 'fixed',
        top: 60,
        backgroundColor: sunglow,
        transition: 'all 0.5s',
      },
      '&[data-show=false]': {
        height: 60,
        '& .alert-background': {
          top: 30,
        },
      },
    },
    alertBar: {
      bottom: 0,
      height: 30,
      width: '100%',
      position: 'absolute',
    },
    alertButton: {
      ...defaultFont,
      width: 'fit-content',
      height: '100%',
      fontSize: 13,
      margin: 'auto',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      userSelect: 'none',
    },
    alertIconArea: {
      marginRight: 5,
      display: 'inline-block',
    },
    alertText: {
      ...defaultFont,
      fontSize: 13,
      paddingRight: 10,
    },
    alertNumber: {
      height: 14,
      paddingLeft: 4,
      paddingRight: 4,
      ...defaultFont,
      color: whiteColor,
      fontSize: 10,
      backgroundColor: sunColor,
      borderRadius: 4,
      textAlign: 'center',
      display: 'inline-block',
    },
    globeIcon: {
      width: 16,
      height: 16,
      marginRight: 5,
    },
    arrowIconArea: {
      marginLeft: 10,
      transform: 'translateY(-2px)',
    },
    avatarIconArea: {
      marginLeft: 12,
    },
    avatarIcon: {
      borderRadius: '50%',
      width: 30,
      height: 30,
    },
    regionText: {
      ...defaultFont,
      color: lightSlateGreyColor,
    },
    displayNameText: {
      ...defaultFont,
      color: lightSlateGreyColor,
    },
    verticalSeparate: {
      width: 1,
      height: 40,
      backgroundColor: whiteSmokeColor,
      marginRight: 12,
      marginLeft: 12,
    },
    profileMenuContainer: {
      width: 249.7,
      borderRadius: 4,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      border: `solid 1px ${pattensBlueColor}`,
      backgroundColor: whiteColor,
      marginTop: 16,
    },
    profileMenuInfo: {
      display: 'grid',
      position: 'relative',
      padding: '20px 25px',
      gridTemplateAreas: `
    'avatar name'
    'avatar mail'
    `,
      gridTemplateColumns: 'min-content auto',
      borderBottom: `1px solid ${pattensBlueColor}`,
      '& .avatar': {
        borderRadius: '50%',
        width: 36,
        height: 36,
        marginTop: 3,
        marginRight: 10,
        gridArea: 'avatar',
      },
      '& .name': {
        ...defaultFont,
        fontSize: 15,
        lineHeight: '1.2em',
        color: nightRiderColor,
        gridArea: 'name',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .mail': {
        ...defaultFont,
        fontSize: 12,
        lineHeight: '1.2em',
        color: denimColor,
        gridArea: 'mail',
        display: 'flex',
        alignItems: 'flex-end',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    profileMenuAccount: {
      paddingTop: 16.5,
      paddingBottom: 15.5,
      borderBottom: `1px solid ${pattensBlueColor}`,
    },
    checkedIcon: {
      position: 'absolute',
      left: 0,
      marginLeft: 10,
      marginRight: 6,
    },
    profilePopoverAccountName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    statusIcon: {
      marginLeft: 10,
    },
    profileMenuAccountSetting: {
      paddingTop: 5.5,
      paddingBottom: 5.5,
      borderBottom: `1px solid ${pattensBlueColor}`,
    },
    profileMenuButtonAddAccount: {
      width: '100%',
      height: 32,
      borderRadius: 4,
      border: `solid 1px ${pattensBlueColor}`,
      backgroundColor: whiteSmokeColor,
      ...defaultFont,
      fontSize: 13,
      lineHeight: 1.15,
      textAlign: 'center',
      color: denimColor,
      textTransform: 'none',
    },
    profileMenuListSettingItem: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
      ...defaultFont,
      fontSize: 13,
      color: nightRiderColor,
    },
    logout: {
      padding: 0,
      marginTop: 5.5,
      marginBottom: 5.5,
    },
    logoutButton: {
      ...defaultFont,
      fontSize: 13,
      color: nightRiderColor,
      paddingLeft: 30,
    },
    alertPopoverPaper: {
      width: 400,
      marginTop: 8,
    },
    alertItem: {
      display: 'block',
      borderBottom: `1px solid ${pattensBlueColor}`,
      paddingLeft: 21,
      paddingRight: 21,
      paddingTop: 13,
      paddingBottom: 13,
    },
    alertItemTitle: {
      ...oneLineText,
      ...defaultFontBold,
      cursor: 'pointer',
      fontSize: 15,
      lineHeight: '18px',
      color: nightRiderColor,
    },
    alertItemDescription: {
      ...defaultFont,
      ...oneLineText,
      color: lightSlateGreyColor,
      fontSize: 13,
      lineHeight: '18px',
    },
    seeAll: {
      ...defaultFont,
      fontSize: 14,
      textAlign: 'center',
      color: denimColor,
      justifyContent: 'center',
    },
    settingButton: {
      position: 'absolute',
      top: 12,
      right: 0,
      padding: 10,
      cursor: 'pointer',
      lineHeight: '13px',
    },
    settingIcon: {
      width: 13,
      height: 13,
    },
    linkItem: {},
    infoInner: {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      info: {
        alignItems: 'flex-end',
      },
      logo: {
        width: 220,
        marginRight: 20,
      },
      userinfo: {
        display: 'flex',
        flexDirection: 'column',
      },
      infoInner: {
        alignItems: 'flex-end',
      },
      accountInfo: {
        display: 'flex',
        alignItems: 'center',
      },
      accountName: {
        whiteSpace: 'normal',
      },
    },
  });

export default withStyles(styles)(Header);

import React from 'react';
// provider
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
// component
import ErrorFallback from '~/components/common/error-fallback';
import Loading from '~/components/common/loading';
import AppRouter, { AppRoutePath } from './AppRouter';
// style
import createEmotionCache from './styles/emotionCache';
import { configureStore } from './stores/configure-store';
import { theme } from './styles/themes';
// i18n
import i18n from './i18n';
// hooks
import { initFirebaseAuth } from './hooks/with-firebase-auth';
import { env } from '~/env';

initFirebaseAuth({
  firebaseConfig: {
    apiKey: env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: env.REACT_APP_FIREBASE_APP_ID,
  },
  needEmailVerified: true,
  whenAuthed: AppRoutePath.Dashboard,
  whenUnauthed: AppRoutePath.Auth,
  LoadingComponent: Loading,
});

export const store = configureStore();
const emotionCache = createEmotionCache();
const App: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <I18nextProvider i18n={i18n}>
                  <AppRouter />
                </I18nextProvider>
              </ErrorBoundary>
            </LocalizationProvider>
          </ThemeProvider>
        </CacheProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;

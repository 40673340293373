import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFontMedium } from '~/styles/themes/common-styles/font';
// Component
import ImgIcon from '~/components/common/img-icon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import TruncateWalletAddress from '~/components/common/truncate-wallet-address';

import { matterhornColor, silverColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';
import NodeTypeStatus from '~/components/common/node-type-status';

interface IProps extends WithStyles<typeof styles> {
  proposal: { address: string; authorize: boolean };
}

const PendingProposalRow = (props: IProps) => {
  const { classes, proposal } = props;
  const { t } = useTranslation();

  return (
    <Accordion disableGutters elevation={0} className={classes.accordion}>
      <AccordionSummary className={classes.accordionSummary}>
        <div className={classes.chainProposalItem} onClick={(e) => e.stopPropagation()}>
          <div className={classes.targetAddress}>
            <TruncateWalletAddress
              classes={{ endPoint: classes.endPoint }}
              address={proposal.address}
              enableCopyBtn
            />
          </div>
          <div
            className={classNames({
              [classes.nodeType]: true,
              [classes.backgroundRomanColor]: proposal.authorize,
              [classes.backgroundSummerSkyColor]: !proposal.authorize,
            })}
          >
            <NodeTypeStatus authorize={proposal.authorize} />
          </div>
          <div className={classes.pendingText}>
            {t('pending')}
            <div className={classNames(classes.loadingIconBlock)}>
              <CircularProgress size={14} className={classes.loadingIcon} />
            </div>
          </div>
        </div>
      </AccordionSummary>
    </Accordion>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    endPoint: {
      display: 'flex',
      minWidth: 200,
    },
    accordion: {
      marginBottom: 10,
      border: `1px solid ${theme.palette.divider}`,
      '&:before': {
        display: 'none',
      },
      borderRadius: '4px',
    },
    accordionSummary: {
      height: 28,
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
    },
    nodeType: {
      ...defaultFontMedium,
      display: 'flex',
      alignItems: 'center',
      height: 28,
      borderRadius: 14,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 12,
      color: matterhornColor,
    },
    chainProposalItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: 14,
      height: 34,
    },
    pendingText: {
      display: 'flex',
      width: 100,
    },
    loadingArea: {
      marginLeft: 5,
      textAlign: 'center',
    },
    backgroundRomanColor: {
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
    backgroundSummerSkyColor: {
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    targetAddress: {
      display: 'flex',
      fontSize: 14,
      width: '45%',
      whiteSpace: 'nowrap',
    },
    nodeBox: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    loadingIconBlock: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 5,
    },
    loadingIcon: {
      color: silverColor,
    },
  });

export default withStyles(styles)(PendingProposalRow);

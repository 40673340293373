import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router';
import { AppRoutePath } from '~/AppRouter';
import Loading from '~/components/common/loading';

const LogoutPage = (props) => {
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        Cookies.remove('lang');
        setLoggedOut(true);
      });
  }, []);

  if (!loggedOut) {
    return <Loading />;
  }

  return <Redirect to={AppRoutePath.Auth} exact={true} />;
};

export default LogoutPage;

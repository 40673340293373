import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';

interface IProps extends TableRowProps {}

const tableRowBody: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  children,
  ...tableRowProps
}) => (
  <TableRow className={classes.root} {...tableRowProps}>
    {children}
  </TableRow>
);

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(tableRowBody);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as semver from 'semver';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import SubmitButton from './submit-button';

import { INode, ICluster, IExplorer } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { DEFAULT_VERSION_GETH } from '~/constants/consts';
import CustomSelect from './custom-select';
import { nodeGethSelection } from '~/types/network-selection';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface IProps extends WithStyles<typeof styles> {
  networkUuid: string;
  clusterUuid: string;
  explorer: IExplorer;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  nodeVersion: string;
  enableRebuildNode?: boolean;
};

const UpdateNodeExplorerVersionDialog = (props: IProps) => {
  const { classes, open, explorer, networkUuid, clusterUuid, onClose } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.updateNodeVersion.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    nodeVersion: explorer.nodeInfo?.version || DEFAULT_VERSION_GETH,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    nodeVersion: Yup.string().required(t('required_field')),
  });

  const nodeVersionFilters = useMemo(() => {
    if (
      !nodeGethSelection.find((val) => val.value === explorer.nodeInfo?.version) &&
      explorer.nodeInfo?.version
    ) {
      return [
        {
          label: `Geth ${explorer.nodeInfo.version}`,
          value: explorer.nodeInfo.version,
        },
        ...nodeGethSelection,
      ];
    }
    return nodeGethSelection;
  }, [explorer]);

  const onCheckboxChange = useCallback((value, form: FormikProps<FormValues>) => {
    form.setFieldValue('enableRebuildNode', value);
    setChecked(value);
  }, []);

  const onNodeVersionChange = useCallback(
    (event, form: FormikProps<FormValues>) => {
      form.setFieldValue('nodeVersion', event.target.value);
      if (semver.lt(event.target.value, explorer.nodeInfo?.version || '')) {
        onCheckboxChange(true, form);
      } else {
        onCheckboxChange(false, form);
      }
    },
    [explorer, onCheckboxChange],
  );

  const nodeVersionField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('node_version')}</div>
            {!!form.errors.nodeVersion && form.touched.nodeVersion && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.nodeVersion}
              </div>
            )}
          </div>
          <div>
            <CustomSelect
              data-testid="version-select"
              {...field}
              id="member-cluster-add-ver-node"
              valueSelected={field.value}
              placeholder={t('select_node_version')}
              items={nodeVersionFilters}
              onChange={(event) => onNodeVersionChange(event, form)}
            />
          </div>
        </>
      );
    },
    [classes, nodeVersionFilters, onNodeVersionChange, t],
  );

  const enableRebuildNodeCheckbox = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div>
          <FormControlLabel
            {...field}
            disabled={semver.lte(form.values.nodeVersion, explorer.nodeInfo?.version || '')}
            checked={checked}
            className={classes.formControlLabel}
            control={
              <Checkbox
                data-testid="rebuild-node-checbox"
                color="default"
                onChange={(e, value) => onCheckboxChange(value, form)}
              />
            }
            label={<span className={classes.formLabel}>{t('enable_rebuild_node')}</span>}
          />
        </div>
      );
    },
    [
      checked,
      classes.formControlLabel,
      classes.formLabel,
      explorer.nodeInfo?.version,
      onCheckboxChange,
      t,
    ],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { nodeVersion, enableRebuildNode } = values;

      try {
        if (accountSeleted?.accountUuid) {
          await dispatch(
            NetworkActions.updateNodeExplorerVersion({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid,
                clusterUuid,
                nodeVersion,
                enableRebuildNode,
              },
            }),
          );
          onClose();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [accountSeleted, clusterUuid, dispatch, networkUuid, onClose],
  );

  const renderWarningText = useCallback(
    (values: FormValues) => {
      if (semver.lt(values.nodeVersion, explorer.nodeInfo?.version || '')) {
        return (
          <div id="member-node-rebuild-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_downgrade_this_node')}
          </div>
        );
      } else if (checked) {
        return (
          <div id="member-node-rebuild-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_rebuild_this_node')}
          </div>
        );
      }
      return <></>;
    },
    [checked, classes.warningText, explorer.nodeInfo?.version, t],
  );

  const handleCloseDialog = useCallback(() => {
    setChecked(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <CustomDialog open={open} onClose={handleCloseDialog}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-upgrade-node-ver-title">{t('change_node_version')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Field name="nodeVersion" render={nodeVersionField} />
                </div>
                <div className={classes.formSection}>
                  <Field name="enableRebuildNode" render={enableRebuildNodeCheckbox} />
                </div>
                {renderWarningText(values)}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-upgrade-node-ver-cancel"
                  disabled={isSubmitting || isNodeUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-upgrade-node-ver-submit"
                  isValid={
                    isValid && !semver.eq(values.nodeVersion, explorer.nodeInfo?.version || '')
                  }
                  isSubmitting={isSubmitting || isNodeUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(UpdateNodeExplorerVersionDialog);

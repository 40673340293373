import React from 'react';
// Redux
// Component
import DialogContent from '@mui/material/DialogContent';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type

interface IProps extends WithStyles<typeof styles> {}

const customDialogContent: React.FunctionComponent<IProps> = ({ classes, children }) => (
  <DialogContent classes={{ root: classes.root }}>{children}</DialogContent>
);

const styles = createStyles({
  root: {
    position: 'relative',
    padding: '0px 90px 0px 90px',
  },
});

export default withStyles(styles)(customDialogContent);

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Localization
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { romanColor } from '~/styles/themes/common-styles/color';
// Components
import SubmitButton from '../../submit-button';
import CustomInput from '../../custom-input';
// Types
import { Account, AccountRoleType } from '~/types/account-types';

interface IStateProps {
  account?: Account;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  updateContact: (
    args: AppActions.MutationUpdateContactArgs,
  ) => Promise<AppActions.UPDATE_CONTACT_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

class ContactTab extends React.Component<IProps> {
  private validateSchema = Yup.object().shape<ContactFormValues>({
    contactEmail: Yup.string()
      .trim()
      .required(this.props.t('required_field'))
      .email(this.props.t('invalid_email')),
    accountantEmail: Yup.string().trim().email(this.props.t('invalid_email')),
    systemAdminEmail: Yup.string().trim().email(this.props.t('invalid_email')),
  });

  constructor(props) {
    super(props);
  }

  render() {
    const { classes, t, account, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    if (!account) {
      return null;
    }
    const initialValues: ContactFormValues = {
      contactEmail: account.contactEmail || '',
      accountantEmail: account.contact?.accountantEmail || '',
      systemAdminEmail: account.contact?.systemAdminEmail || '',
    };

    return (
      <div className={classes.contactTab}>
        <div className={classes.updateContactForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={this.validateSchema}
            onSubmit={this.onUpdateContactSubmit}
            enableReinitialize
            render={({ isValid, isSubmitting }) => (
              <Form>
                <div>
                  {/* Render Form Field */}
                  <div className={classes.formSection} title="contactEmail-field">
                    <Field name="contactEmail" render={this.contactEmailField} />
                  </div>
                  <div className={classes.formSection} title="accountantEmail-field">
                    <Field name="accountantEmail" render={this.accountantEmailField} />
                  </div>
                  <div className={classes.formSection} title="systemAdminEmail-field">
                    <Field name="systemAdminEmail" render={this.systemAdminEmailField} />
                  </div>
                  {/* Render Submit Button */}
                  <div className={classes.updateContactBtnArea}>
                    <SubmitButton
                      data-testid="save-change-button"
                      isValid={isValid && updatable}
                      isSubmitting={isSubmitting}
                      label={t('save_change')}
                      submittingLabel={t('updating')}
                      id="member-account-contact-submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }

  private contactEmailField = ({ field, form }: FieldProps<ContactFormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'contact_email'} />
          </div>
          {!!form.errors.contactEmail && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.contactEmail}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="contact-email-input"
            id="member-contact-contactEmail-input"
            {...field}
            placeholder={t('input_contact_email')}
            disabled={!updatable}
          />
        </div>
      </>
    );
  };

  private accountantEmailField = ({ field, form }: FieldProps<ContactFormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'accountant_Email'} />
          </div>
          {!!form.errors.accountantEmail && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.accountantEmail}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="account-email-input"
            id="member-contact-accountantEmail-input"
            {...field}
            placeholder={t('input_accountant_email')}
            disabled={!updatable}
          />
        </div>
      </>
    );
  };

  private systemAdminEmailField = ({ field, form }: FieldProps<ContactFormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'system_admin_Email'} />
          </div>
          {!!form.errors.systemAdminEmail && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.systemAdminEmail}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="system-admin-input"
            id="member-contact-systemAdminEmail-input"
            {...field}
            placeholder={t('input_system_admin_email')}
            disabled={!updatable}
          />
        </div>
      </>
    );
  };

  private onUpdateContactSubmit = async (
    values: ContactFormValues,
    formikActions: FormikActions<ContactFormValues>,
  ) => {
    const { updateContact, account } = this.props;
    if (!account) {
      return;
    }
    const { setSubmitting } = formikActions;
    try {
      await updateContact({
        accountUuid: account.accountUuid,
        contactEmail: values.contactEmail && values.contactEmail.trim(),
        contact: {
          accountantEmail: values.accountantEmail?.trim() || undefined,
          systemAdminEmail: values.systemAdminEmail?.trim() || undefined,
        },
      });
    } catch (error) {
      // do nothing
    }
    setSubmitting(false);
  };
}

const styles = createStyles({
  contactTab: {
    overflow: 'auto',
  },
  updateContactForm: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  formSection: {
    marginTop: 15,
    marginBottom: 8,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontBold,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  updateContactBtnArea: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch) => ({
  updateContact: (args: AppActions.MutationUpdateContactArgs) =>
    dispatch(AppActions.updateContact(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactTab)),
);

type ContactFormValues = {
  contactEmail: string;
  accountantEmail: string;
  systemAdminEmail: string;
};

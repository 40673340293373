import React from 'react';
// Component
import CircularProgress from '@mui/material/CircularProgress';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';

interface IProps extends WithStyles<typeof styles> {}

const LoadingIcon: React.StatelessComponent<IProps> = ({ classes }) => (
  <CircularProgress className={classes.root} />
);

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(LoadingIcon);

import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';
import { withProfile } from '~/hooks/with-profile';
import { WithTranslation, withTranslation } from 'react-i18next';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { defaultFontRegular } from '~/styles/themes/common-styles/font';
import { useLocation } from 'react-router';
import { displayCurrency } from '~/utilities/payment-utils';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { BUSINESS_CONTACT, REGISTRATION_NO, TAX_PERCENTAGE } from '~/constants/consts';

export interface IBillParams {
  id: string;
  amount: number;
  tax: number;
  coupon: number;
  paidDate: string;
  receipt: {
    source: {
      brand: string;
      last4: string;
    };
    description: string;
  };
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const ReceiptPage = (props: IProps) => {
  const { classes, t } = props;
  const { search } = useLocation();

  const bill: IBillParams | null = useMemo(() => {
    const query = new URLSearchParams(search).get('details');
    try {
      return query ? JSON.parse(query) : null;
    } catch (error) {
      return null;
    }
  }, [search]);

  const renderCardType = useMemo(() => {
    if (!bill) return <></>;
    const { brand, last4 } = bill.receipt?.source || {};
    switch (brand) {
      case 'MasterCard':
        return (
          <>
            <img className={classes.cardIcon} src="/images/icons/master_card_ico.png" />
            <span>- {last4}</span>
          </>
        );
      case 'American Express':
        return (
          <>
            <img className={classes.cardIcon} src="/images/icons/american_express_card_ico.png" />-{' '}
            {last4}
          </>
        );
      case 'Visa':
        return (
          <>
            <img className={classes.cardIcon} src="/images/icons/visa_card_ico.png" />
            <span>- {last4}</span>
          </>
        );
      case 'JCB':
        return (
          <>
            <img className={classes.cardIcon} src="/images/icons/jcb_card_ico.png" />
            <span>- {last4}</span>
          </>
        );
      default:
        return <></>;
    }
  }, [bill, classes.cardIcon]);

  return (
    <div className={classes.root}>
      {bill ? (
        <div className={classes.receipt}>
          <div className={classes.header}>
            <div className={classes.first}>
              <img
                alt=""
                height="156"
                width="252"
                src="/images/icons/stripe_receipt_header_left.png"
              />
            </div>
            <div className={classes.middle}>
              <img
                alt=""
                height="156"
                width="252"
                src="/images/icons/stripe_receipt_header_middle.png"
              />
            </div>
            <div className={classes.last}>
              <img
                alt=""
                height="156"
                width="252"
                src="/images/icons/stripe_receipt_header_right.png"
              />
            </div>
          </div>
          <div className={classes.contentBox}>
            <div className={classes.title}>{t('receipt_from', { name: t('business_name') })}</div>
            <div className={classes.receiptNumber}>
              {t('qualified_invoice_issuer_registration_number')}&nbsp;&nbsp;{REGISTRATION_NO}
            </div>
            <div className={classes.receiptNumber}>
              {t('billing_id')} #{bill.id}
            </div>
            <Grid container padding={2} className={classes.paidInfor}>
              <Grid item xs={3}>
                <div className={classes.primaryTitle}>{t('amount_paid')}</div>
                <div className={classes.secondaryTitle}>
                  {`$${displayCurrency(bill.amount + bill.tax - bill.coupon)}`}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className={classes.primaryTitle}>{t('date_paid')}</div>
                <div className={classes.secondaryTitle}>
                  {t('date_paid_at', { date: new Date(bill.paidDate) })}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.primaryTitle}>{t('payment_method')}</div>
                <div className={classes.secondaryTitle}>{renderCardType}</div>
              </Grid>
            </Grid>
            <div className={classes.summaryTitle}>{t('summary')}</div>
            <List className={classes.summaryBox}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={bill.receipt?.description || `G.U.Blockchain Cloud #${bill.id}`}
                />
                <ListItemText
                  primary={`$${displayCurrency(bill.amount + bill.tax - bill.coupon)}`}
                  style={{ textAlign: 'end' }}
                />
              </ListItem>
              <Divider style={{ color: '#ffffff', margin: 'auto', width: '472px' }} />
              <ListItem alignItems="flex-start" style={{ paddingBottom: 0 }}>
                <ListItemText primary={t('pre_tax_amount')} />
                <ListItemText
                  primary={`$${displayCurrency(bill.amount - bill.coupon)}`}
                  style={{ textAlign: 'end' }}
                />
              </ListItem>
              <ListItem alignItems="flex-start" style={{ paddingBottom: 0, paddingTop: 0 }}>
                <ListItemText primary={`${t('tax')} (${TAX_PERCENTAGE})`} />
                <ListItemText
                  primary={`$${displayCurrency(bill.tax)}`}
                  style={{ textAlign: 'end' }}
                />
              </ListItem>
              <ListItem className={classes.total} alignItems="flex-start">
                <ListItemText primary={t('tax_inclusive_amount')} />
                <ListItemText
                  primary={`$${displayCurrency(bill.amount + bill.tax - bill.coupon)}`}
                  style={{ textAlign: 'end' }}
                />
              </ListItem>
            </List>

            <Divider className={classes.divider} />
            <p
              className={classes.contactText}
              dangerouslySetInnerHTML={{
                __html: t('receipt_contact', {
                  email: `<a
                target="_blank"
                style="border: 0; margin: 0; padding: 0; color: #625afa; font-weight: bold; text-decoration: none"
                href="${BUSINESS_CONTACT}"
              >
                ${BUSINESS_CONTACT}
              </a>`,
                }),
              }}
            ></p>
          </div>
        </div>
      ) : (
        <div>Receipt not found</div>
      )}
    </div>
  );
};

const styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgb(246, 249, 252)',
  },
  receipt: {
    margin: '84px 0 84px 0',
    minWidth: 600,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 5,
  },
  header: {
    display: 'flex',
    width: '100%',
    height: 156,
  },
  first: {
    width: 252,
    borderTopLeftRadius: 5,
    backgroundColor: '#525F7F',
  },
  middle: {
    width: 96,
    backgroundColor: '#525F7F',
    '& img': {
      display: 'block',
      width: 96,
    },
  },
  last: {
    width: 252,
    borderTopRightRadius: 5,
    backgroundColor: '#525F7F',
  },
  contentBox: {
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  title: {
    ...defaultFontRegular,
    fontSize: 24,
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: 12,
  },
  receiptNumber: {
    color: '#8898aa',
    fontSize: 15,
    lineHeight: '18px',
    textAlign: 'center',
    marginBottom: 8,
  },
  paidInfor: {
    marginTop: '32px',
    marginBottom: '32px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  primaryTitle: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#8898aa',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  secondaryTitle: {
    fontSize: 15,
    lineHeight: '24px',
    color: '#525f7f',
    whiteSpace: 'nowrap',
  },
  summaryTitle: {
    color: '#687385',
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: '32px',
  },
  summaryBox: {
    borderRadius: 5,
    backgroundColor: 'rgb(246, 249, 252)',
    marginBottom: '48px',
    width: '504px',
    '& li': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    '& span': {
      fontSize: '15px',
      fontWeight: 300,
      lineHeight: '24px',
    },
  },
  total: {
    paddingTop: 0,
    '& span': {
      fontWeight: 'bold',
    },
  },
  divider: {
    margin: '32px 0 32px 0',
  },
  contactText: {
    ...defaultFontRegular,
    color: '#414552',
    lineHeight: '24px',
    marginBottom: '84px',
  },
  cardIcon: {
    width: 30,
    marginRight: 5,
  },
});

export default compose(
  withFirebaseUser(),
  withProfile,
  withInitAppData,
  withStyles(styles),
  withTranslation(),
)(ReceiptPage);

import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import '../../styles/scss/error.scss';

/**
 * ErrorFallback component
 * Render component when app crashes or return errors
 */
export default function ErrorFallback() {
  const { t } = useTranslation();

  return (
    <div className="root">
      <div className="header">
        <img className="logo" src="/images/icons/logo_guest.svg" alt="" />
      </div>
      <div className="content">
        <div className="content-container">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: t('error_page_description') }}
          />
          <div className="code">500</div>
          <div className="top-page-btn-wrapper">
            <a href="/">
              <button>{t('error_page_top_page_button_label')}</button>
            </a>
          </div>
          <a href="https://bccloud.zendesk.com">
            <div className="contact-link">{t('error_page_contact_us_text')}</div>
          </a>
        </div>
      </div>
      <div className="footer">
        <div className="copyright">© G.U.Technologies, Inc.</div>
      </div>
    </div>
  );
}

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFontBold, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { lightSlateGreyColor } from '~/styles/themes/common-styles/color';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

import ImgIcon from './img-icon';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  className?: string;
  onClick?: any;
  id?: string;
  disabled?: boolean;
}

const editButton: React.StatelessComponent<IProps> = ({
  classes,
  className,
  onClick,
  t,
  id,
  disabled,
}) => {
  return (
    <Button
      data-testid="edit-button"
      className={classNames(classes.root, className)}
      onClick={onClick}
      id={id}
      disabled={disabled}
    >
      <ImgIcon className={classes.editIcon} imgUrl="/images/icons/edit-ico.png" />
      <span>{t('edit')}</span>
    </Button>
  );
};

const styles: StyleRules = {
  root: {
    ...defaultFontMedium,
    fontSize: 12,
    color: lightSlateGreyColor,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  editIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
};

export default withStyles(styles)(withTranslation()(editButton));

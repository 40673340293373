import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Cookies from 'js-cookie';
import firebase from 'firebase';
// React i18next
import { useTranslation } from 'react-i18next';
import { useFirebaseUser, withFirebaseUser } from '~/hooks/with-firebase-auth';
// Component
import AuthLayout from '~/components/common/AuthLayout';
import RegistrationModal from '~/components/common/registration-modal';
import { SelectChangeEvent } from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import FirebaseAuthLocalized from '~/components/common/firebase-auth-localized';

// utils
import { parseBCP47String } from '~/utilities/utils';
import { checkBrowserSupport } from '~/utilities/app-utils';
// styles
import { container } from '~/styles/themes/common-styles/grid-member';
import { LGPelorousDenim } from '~/styles/themes/common-styles/misc';
import {
  lightSlateGreyColor,
  denimColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import { defaultFontBold, defaultFont } from '~/styles/themes/common-styles/font';
import { AppRoutePath } from '~/AppRouter';

const AuthPage = (props) => {
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const firebaseUser = useFirebaseUser();

  const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false);

  const onChangeLng = useCallback(
    (event: SelectChangeEvent) => {
      i18n.changeLanguage(event.target.value as string);
      Cookies.set('lang', event.target.value);
    },
    [i18n],
  );

  const handleLogout = useCallback(() => {
    window.location.href = AppRoutePath.Logout;
  }, []);

  const onOpenRegistrationDialog = useCallback(() => {
    setOpenRegistrationDialog(true);
  }, []);

  const onCloseRegistrationDialog = useCallback(() => {
    setOpenRegistrationDialog(false);
  }, []);

  useEffect(() => {
    if (!checkBrowserSupport(navigator.userAgent)) {
      window.alert(t('not_supported_browser_alert'));
    }
  }, [t]);

  return (
    <AuthLayout>
      <div className={classes.root}>
        <div className={classes.paperArea}>
          <div style={{ width: 480 }}>
            <Paper className={classes.paper} elevation={1}>
              <div className={classes.fbUi}>
                <div>
                  <RegistrationModal
                    open={openRegistrationDialog}
                    onClose={onCloseRegistrationDialog}
                    data-testid="close-registration-modal"
                  />
                  <div className={classes.block}>
                    <div className={classes.pleaseSignIn}>{t('sign_in')}</div>
                    <div className={classes.blockchain}>G.U. Blockchain Cloud</div>
                  </div>
                  {firebaseUser && !firebaseUser.emailVerified ? (
                    <div className={classes.errorSection}>
                      <div>{t('please_complete_email_verification')}</div>
                      <br />
                      <span
                        className={classes.logoutLink}
                        onClick={handleLogout}
                        data-testid="logout-button"
                      >
                        {' '}
                        {t('logout')}{' '}
                      </span>
                    </div>
                  ) : (
                    <div key={i18n.language} className={classes.firebaseUI}>
                      <FirebaseAuthLocalized auth={firebase.auth()} language={i18n.language} />
                    </div>
                  )}
                  <div
                    id="howto-register-button"
                    className={classes.howToRegisterLabel}
                    onClick={onOpenRegistrationDialog}
                    data-testid="register-account-button"
                  >
                    <span>{t('howToRegisterAccount')}</span>
                  </div>
                </div>
              </div>
            </Paper>
            <div className={classes.bottom}>
              <div>{t('copyright_2016_openid_foundation')}</div>
              <div className={classes.flex}>
                {/* <div className={classes.aboutAccount}>
                  {t('about_account')}
                </div>
                <div>
                  {t('chooser_privacy_policy')}
                </div> */}
              </div>
            </div>
            <Select
              data-testid="language-select"
              value={parseBCP47String(i18n.language).language || 'en'}
              className={classes.selectLanguage}
              disableUnderline={true}
              onChange={onChangeLng}
              IconComponent={(props) => (
                <div {...props} className={props.className + ' ' + classes.selectIcon}>
                  <Icon>unfold_more</Icon>
                </div>
              )}
            >
              {languages.map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

const languages = [
  {
    value: 'ja',
    name: 'Japanese',
  },
  {
    value: 'en',
    name: 'English',
  },
];

const useStyles = makeStyles({
  container,
  root: {
    backgroundImage: `url('/images/backgrounds/guest_main_bg.svg')`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  fbUi: {},
  block: {
    textAlign: 'center',
    marginBottom: 20,
  },
  pleaseSignIn: {
    ...defaultFont,
    fontSize: 18,
    color: lightSlateGreyColor,
  },
  blockchain: {
    ...defaultFontBold,
    color: lightSlateGreyColor,
    fontSize: 28,
  },
  paperArea: {
    ...container,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    justifyContent: 'center',
  },
  paper: {
    maxWidth: 540,
    width: '100%',
    padding: 60,
    borderRadius: 10,
  },
  bottom: {
    ...defaultFont,
    display: 'flex',
    fontSize: 12,
    color: nightRiderColor,
    justifyContent: 'space-between',
    marginTop: 30,
    maxWidth: 540,
  },
  flex: {
    display: 'flex',
  },
  aboutAccount: {
    marginRight: 20,
  },
  selectLanguage: {
    backgroundColor: 'white',
    width: 137,
    borderRadius: 4,
    marginTop: 30,
    float: 'right',
    paddingLeft: 7,
    fontSize: 12,
    fontWeight: 300,
    height: 17,
    border: '1px solid #239BA7',
    '& .MuiSelect-select': {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      border: 0,
    },
  },
  selectIcon: {
    top: 0,
    right: 0,
    backgroundImage: LGPelorousDenim,
    borderRadius: '0px 4px 4px 0px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    width: 16,
    '& span': {
      fontSize: 16,
    },
    height: 17,
  },
  logoutLink: {
    color: denimColor,
    cursor: 'pointer',
  },
  errorSection: {
    textAlign: 'center',
  },
  howToRegisterLabel: {
    color: denimColor,
    fontWeight: 500,
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '14px !important',
    cursor: 'pointer',
    paddingTop: 10,
  },
  firebaseUI: {
    '& .firebaseui-idp-google': {
      maxWidth: 300,
      height: 40,
      borderRadius: 4,
      border: 'solid 1px #d8dfe5',
      backgroundColor: '#fafafa !important',
    },
    '& .firebaseui-idp-google > .firebaseui-idp-text': {
      fontSize: 0,
    },
    '& .firebaseui-idp-google > .firebaseui-idp-text::before': {
      ...defaultFontBold,
      content: '"Login with Google Account"',
      width: 193,
      height: 19,
      fontSize: 16,
      color: denimColor,
    },
    '& .mdl-button--raised': {
      boxShadow: 'none',
    },
    '& .firebaseui-idp-password': {
      maxWidth: 300,
      height: 40,
      borderRadius: 4,
      border: 'solid 1px #d8dfe5',
      backgroundColor: '#fafafa !important',
    },
    '& .firebaseui-idp-password > .firebaseui-idp-text': {
      fontSize: 0,
    },
    '& .firebaseui-idp-password > .firebaseui-idp-text::before': {
      ...defaultFontBold,
      content: '"Login with Email Address"',
      width: 193,
      height: 19,
      fontSize: 16,
      color: denimColor,
    },
    '& .firebaseui-idp-password > .firebaseui-idp-icon-wrapper > .firebaseui-idp-icon': {
      filter: 'invert(1)',
    },
    '& .firebaseui-idp-password:hover, .mdl-button.firebaseui-idp-password:active, .mdl-button.firebaseui-idp-password:focus':
      {
        backgroundColor: '#ffffff !important',
      },
  },
});

export default withFirebaseUser()(AuthPage);

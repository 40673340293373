import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import SubmitButton from './submit-button';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { DEFAULT_GAS_LIMIT, MAX_GAS_LIMIT, MIN_GAS_LIMIT } from '~/constants/consts';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  blockGasLimit: number;
};

const UpdateGasLimitDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, onClose } = props;
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.updateNodeVersion.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    blockGasLimit:
      node.nodeInfo.blockGasLimit || network.blockchainInfo?.blockGasLimit || DEFAULT_GAS_LIMIT,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    blockGasLimit: Yup.number()
      .required(t('required_block_gas_limit'))
      .min(MIN_GAS_LIMIT, t('validate_minimum', { val: MIN_GAS_LIMIT }))
      .max(MAX_GAS_LIMIT, t('validate_maximum', { val: MAX_GAS_LIMIT })),
  });

  const blockGasLimitField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('block_gas_limit')}</div>
          {!!form.errors.blockGasLimit && form.touched.blockGasLimit && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.blockGasLimit)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            data-testid="gas-limit-input"
            {...field}
            id="member-network-add-gas"
            placeholder={t('input_gas_limit')}
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return floatValue === undefined || floatValue >= 0;
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { blockGasLimit } = values;

      try {
        if (blockGasLimit && accountSeleted?.accountUuid) {
          await dispatch(
            NetworkActions.updateBlockGasLimit({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid: network.networkUuid,
                clusterUuid: cluster.clusterUuid,
                nodeUuid: node.nodeUuid,
                blockGasLimit: blockGasLimit.toString(),
              },
            }),
          );
          onClose();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [network, accountSeleted, cluster.clusterUuid, dispatch, node.nodeUuid, onClose],
  );

  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-update-gas-limit-title">{t('change_block_gas_limit')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Field name="blockGasLimit" render={blockGasLimitField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-update-gas-limit-cancel"
                  disabled={isSubmitting || isNodeUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-update-gas-limit-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting || isNodeUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(UpdateGasLimitDialog);

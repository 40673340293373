/* tslint:disable */
import { parseBCP47String } from '~/utilities/utils';

export function getTermsOfServiceText(lang: string) {
  switch (parseBCP47String(lang).language) {
    case 'ja':
      return termOfServiceJP;
    default:
      return termOfServiceGlobal;
  }
}

const termOfServiceGlobal = `“G.U. Blockchain Cloud“ Terms of Use

1. General rules and scope of application
 1.1. “G.U. Blockchain Cloud” Terms of Use (“this Agreement”) defines the basic terms and conditions for the use of “G.U. Blockchain Cloud” (“this Service”, details are defined in Section 3.1), which G.U. Technologies, Inc. (“the Company”, “we” or “us”) provides and operates to a subscriber (“the Subscriber"), between the Subscriber or the prospective user (“the Prospective user”, defined in clause 2.1 c) and the Company.
 1.2. In the event that the Company posts individual or additional terms and conditions regarding this Service on the website or application operated by the Company (collectively “the Company's website, etc.” or “our Websites”), those terms and conditions shall constitute one part of this Agreement. And, in the event that individual or additional terms and conditions conflict with this Agreement, those individual or additional terms and conditions shall take precedence.
 1.3. This Agreement is applied to the Subscriber, the user or the user who wants to use this Service and the Company.
 1.4. This Agreement is written in Japanese and translated into English. The Japanese text is the original and the English text is for reference only. If there is any conflict between these two texts, the Japanese text shall prevail.

2. Definitions
 2.1. In this Agreement, the definitions of the words listed in each of the following items shall be as specified in the respective items.
  a. Service use contract
   This refers to the contractual relationship between the Company and the Subscriber for the use of this Service, and includes this Agreement and the related terms and notices posted on our websites.
  b. Subscriber
   A person who has agreed to this Agreement and concluded a service use contract with us.
  c. Prospective user
   A person who wishes to conclude a service use contract with us.
  d. G.U. Account
   G.U. Account is an account necessary for the use of this Service and is granted by the Company to the Subscriber. A G.U. Account uses a Google account, so the Subscriber must register for a Google account to use this Service.
  e. Organization Account
   An Organization Account is required for the Subscriber to create a G.U. Blockchain Network(defined in next clause) for this Service. An Organization Account can include G.U. accounts of multiple Subscribers. It is also possible for a single subscriber's G.U. account to participate in multiple Organization Accounts.
  f. G.U. Blockchain Network
   The G.U. Blockchain Network is a blockchain network created on the server of this Service. An Organization Account can join the G.U. Blockchain Network on a per-account basis, and multiple Organization accounts can join a single G.U. Blockchain Network.
  g. Registration information
   This refers to the information prescribed by the Company which the Subscriber and the Prospective user provided to the Company before concluding this Service use contract, the information which the Company judged necessary and requested the Subscriber to register while using this Service, and the information when the Subscriber itself added or changed.
  h. Related Carriers
   Refers to telecommunication carriers, server operators (AWS is used in this Service), and other businesses related to this Service.
  i. Intellectual property rights
   Refers to copyrights, patent rights, utility model rights, trademark rights, design rights and other intellectual property rights (including the right to acquire those rights or to apply for registration of those rights).

3. Contents of this Service
 3.1. This Service will be one or more of the following, and details will be displayed on our Websites.
  a. G.U. Blockchain Cloud
   G.U. Blockchain Cloud is a Blockchain as a Service (BaaS) that allows users to develop and operate an Ethereum compatible blockchain infrastructure by operating the interface provided on a web browser (Google Chrome). The blockchain built in the G.U. Blockchain Cloud is stored in the cloud environment (Amazon Web Services) as a private chain, and can be accessed by external applications by sharing an endpoint (URL). Due to the characteristics of the blockchain, it may not be possible to retroactively delete information recorded in the blockchain. In addition, there are certain restrictions on the suspension or deletion of blockchain operations.
  b. Services related to or incidental to the services listed in the preceding clause.
 3.2. For the purpose of appropriate operation of this Service, we may check the usage status of this Service by the Subscriber. The Subscriber agrees to this in advance.

4. Registration of subscribers
 4.1. A Prospective user can apply for registration for use of this Service ("registration for use") to us by agreeing to comply with this Agreement and providing registration information to us in the manner prescribed by us.
 4.2. Google account is necessary for registration for use. Please comply with the various terms set forth by Google in using and managing Google account.
 4.3. We judge whether or not to register for use according to our standards and completes registration for use and grants G.U. Account only to those who wish to use and we approve the registration. For example, we may refuse registration for a user who has violated this Agreement in the past or a user who belongs to a competitor of us.
 4.4. The Service use contract based on this Agreement will be completed between the Subscriber and us when we start providing this Service to the Subscriber.
 4.5. When a minor wishes to use this Service, the consent of a legal representative is required. When a minor becomes the Subscriber, the consent of the legal guardian is deemed to have been obtained for the use of this Service and the contents of this Agreement. In addition, when a Subscriber who was a minor at the time of registration for use uses this Service after reaching the age of majority, it is considered that the use while being a minor is acknowledged.
 4.6. When the Subscriber registers the registration information (including the case where the Subscriber provides the registration information to the Company through the service of a third party), the Subscriber must send true and accurate information. The Company will provide this Service based on the registration information registered by the Subscriber. The Company shall not be held responsible for any damage incurred by the Subscriber due to falsehoods, errors, or omissions in the registered information. When the change of registration information is born according to the following article, the Company will provide this Service based on the registration information registered at the time of use of this Service by the Subscriber.

5. Change of the Subscriber's Registration Information
 5.1. If there is a change in the Subscriber's registration information, the Subscriber shall promptly carry out the procedure for changing the information using the method prescribed by the Company.
 5.2. The Subscriber agrees without objection in advance that if a notice from the Company fails to reach the Subscriber because the Subscriber fails to change the information in the preceding paragraph, the notice will be deemed to have reached the Subscriber when it should have normally reached the Subscriber.
 5.3. The Company shall not be held responsible for any damage caused by the Subscriber's negligence in making the changes in Paragraph 5.1.

6. Use of Account
 6.1. The Subscriber is responsible for maintaining the confidentiality of the information required to login to the G.U. Account and is fully responsible for all activities that occur under the G.U. Account.
 6.2. The Subscriber shall immediately notify us of any unauthorized use, suspected unauthorized use, or any other breach of security of the G.U. Account. We shall not take responsibility for any damage caused by the Subscriber's negligence of the above notification.
 6.3. We may delete the Subscriber's G.U. Account in accordance with Article 11.

7. Fees and charges
 7.1. In order to create the G.U. Blockchain Network on this Service, the Subscriber shall pay the fee for the use of this Service ("the fee" or “the fees”) in accordance with the amount and method separately set forth by the Company on the Company's website.
 7.2. We may revise the fees when we deem it necessary. However, in the case of an increase in the fee, we shall notify the Subscribers of the change in the fee, the changed fee, and the time when the changed fee will be applied by posting on our Websites or by other means deemed appropriate by a reasonable period of time before the change. In addition, we may, at its discretion, set a transfer period for the existing Subscribers to allow the application of the old fee.
 7.3. In the event that the Subscriber delays payment of the fees, the Subscriber shall pay to us the late fee at the rate of 14.6% per annum and the interest fee corresponding to the remaining period.
 7.4. Taxes on the fees shall be as follows
  a. The Subscriber shall pay the fees to the Company without any deduction of tax. In cases where the Company collects or is obliged to collect a tax, the Company shall charge it to the Subscriber.
  b. In cases where the Subscriber pay fees after deducting the tax from the fees in accordance with laws and regulations, they shall submit a certificate showing that they have paid the tax or a similar appropriate written document to the Company upon request.
 7.5. The Service use contract based on this Agreement is on a monthly basis. However, unless the Subscriber completes the cancellation procedure by the end of the effective period (the end of each month) by the method prescribed by the Company, the contract will be automatically renewed for one month with the same one content as before at the time of the end of the effective period, and the same shall apply thereafter. Even if the Subscriber cancels the service contract in the middle of a month, the fee for the remaining period of the contract (until the end of each month) will be charged and the Company will not carry out a pro-rata settlement or refund.
 7.6. Unless otherwise specified in this Agreement, the Company does not accept as a part of the refund of the fees paid by the Subscribers to the Company.

8. Use of this Service
 8.1. The Subscriber can use this Service within the effective period of the service use contract in accordance with the way specified by the Company within the range of the purpose of this Agreement and within the range not to violate this Agreement.
 8.2. The preparation and maintenance of computers, software and other equipment, communication lines and other communication environment necessary to receive this Service shall be carried out at the expense and responsibility of the Subscriber.
 8.3. The Subscriber shall take the utmost care not to lose or alter the information held by the Subscriber or to cause damage or malfunction of the equipment when installing software on the Subscriber's computer by downloading from the Company's website or other methods when starting to use this Service or during the use of this Service. The Company shall not be held responsible for any damage incurred by the Subscriber as a result of such events.
 8.4. The Subscriber shall observe the following items when using this Service.
  a. In case of unauthorized access, information leakage or threat of information leakage, etc., regarding the use of this Service (including but not limited to the case where information necessary to log in to the G.U. Account is leaked or divulged), the Subscriber must contact us immediately (including, but not limited to, the case where information necessary to log in to the G.U. Account is leaked or divulged).
  b. The subscriber shall take necessary security measures to prevent unauthorized access, information leakage and leaks, etc. regarding the use of this Service.

9. Prohibited Matters
 9.1. The Subscriber must not commit any of the following acts when using this Service.
  a. Actions that violate laws and regulations, actions that promote violations of laws and regulations, or actions that have the potential to do so.
  b. Fraud or intimidation against the Company, other subscribers to this Service, or other third parties.
  c. Actions that are offensive to public order and morals
  d. To infringe or threaten to infringe the intellectual property rights, portrait rights, privacy rights, honor, or any other rights or interests of the Company, other users of this Service, or any other third party.
  e. To license, sell, rent, lease, transfer, assign, distribute, host or otherwise use all or part of the contents of this Service for commercial purposes.
  f. Modification of the contents of this Service, creation of secondary works, disassembly, reverse engineering, etc.
  g. To use this Service for the purpose of constructing a website, product or service similar or competitive to this Service, or for the purpose of verifying its availability, performance, functionality, or for other benchmarking or competitive purposes.
  h. Copying, duplicating, distributing, reprinting, downloading, displaying, displaying or transmitting all or part of this Service except for the cases where it is explicitly permitted in this Agreement.
  i. Transmit through this Service any information that the Company deems to fall under any of the following or to be applicable
   (1) Information that contains violent or cruel expressions
   (2) Information that contains computer viruses or other harmful programs
   (3) Information that includes expressions that defame the honor or confidence of the Company, other users of this Service, or other third parties
   (4) Information that includes obscene expressions
   (5) Information that includes expressions that promote discrimination
   (6) Information that includes expressions that may encourage self-murder or self-injurious behavior
   (7) Information that includes expressions that promote the inappropriate use of drugs
   (8) Information containing antisocial expressions
   (9) Information that contains expressions that may cause discomfort to others
   (10) Information that contains false contents
   (11) Information that includes advertising, solicitation, or sales activities
  j. Any activity that may place an excessive load on this Service or the networks or systems accessed through this Service
  k. Activities aimed at collecting information on other subscribers to this Service
  l. Unauthorized access to the system connected to this Service in general, unauthorized rewriting or deletion of information stored in the Company's facilities, or other actions that may cause damage to the Company
  m. To impersonate other subscribers or third parties
  n. To use the G.U. Account of another subscriber (including, but not limited to, joint use of one G.U. Account by multiple users)
  o. Providing benefits to an anti-social force, etc. (meaning a gang, a member of a gang, a person who has been a member of a gang for less than five years, a right-wing organization, a quasi-member of a gang, “sokaiya” racketeer group, groups engaging in criminal activities under the pretext of conducting social campaigns or political activities, crime groups specialized in intellectual crimes or any other similar person. The same shall apply hereinafter)
  p. To use threatening language (including, but not limited to, to inform that the person concerned or oneself is an anti-social force)
  q. Conduct that may interfere with the operation of this Service by the Company.
  r. Actions that go against the purpose and objective of this Agreement and this Service.
  s. To violate the various terms and conditions of AWS (https://aws.amazon.com/jp/legal/) which is used as the server of this Service.
  t. Any activity that directly or indirectly causes or facilitates any of the above.
  u. Any other activities that the Company deems inappropriate.


10. Cancellation of Service Use Contract
 10.1. The Subscriber may cancel all or part of the service use contract by the method separately specified by us on our Websites. The Subscriber who has cancelled the service use contract shall not be able to use this Service (in the case that the Subscriber has cancelled only a part of the service use contract, it is limited to the range of the cancelled part) from the point of cancellation.
 10.2. If there are any debts owed by the Subscriber to the Company upon cancellation of a service use contract (including but not limited to not only the debts under this Agreement but also the damage compensation debts of the Subscriber to the Company), the Subscriber shall lose the benefit of time for all of the debts owed to the Company and immediately pay all debts to the Company. The Subscriber must immediately carry out all obligations to the Company.
 10.3. Even if the Subscriber cancels the service use contract in the middle of the effective period, the fee for the remaining period of the contract will be charged and the Company will not carry out a pro-rata settlement or refund to the Subscriber.
 10.4. Even after the cancellation of the service use contract, the Subscriber is not exempted from all obligations and liabilities to the Company which the Subscriber owes due to the use of this Service.
 10.5. The Company may retain, use, or delete the registered information of the Subscriber even after the Subscriber cancels all or part of the service use contract.
 10.6. When the subscriber wishes to register to use this Service again after the cancellation of the service use contract, it is necessary to carry out the registration procedure again. The Subscriber agrees in advance that the data before the cancellation will not be taken over by the registration procedure again.
 10.7. After the cancellation of the service use contract, the Company shall not hand over the Subscriber's data and other information provided by the Subscriber to the Company, and the Subscriber shall agree to this without objection.

11. Suspension of Service and Deletion of Account
 11.1. When the Company judges that the Subscriber corresponds to any of the following or is likely to correspond to any of the following, the Company may temporarily suspend the use of this Service or take other necessary measures, cancel the registration as the Subscriber, or cancel the service use contract with the Subscriber without prior notice or warning.
  a. In case of violation of any clause of this Agreement, or in case the Company judges that there is a possibility of violation of this Agreement
  b. When it is found that there are falsehoods in all or part of the information provided to the Company
  c. In case it is found that the information necessary to login to G.U. Account has been stolen or used by a third party
  d. In the event that the bank account or credit card designated by the Subscriber as a method of payment for the fee for this Service has been suspended or invalidated, or in the event that the improper use of such account or credit card has been found
  e. When the payment of the fee for this Service is delayed and the delay is not cleared by the date designated by the Company
  f. When the Subscriber is a minor, an adult ward, a person under curatorship, or a person under assistance, and it is found that the Subscriber has not obtained the consent, etc. of a legal representative, guardian, curator, or assistant
  g. In cases where the Subscriber has waited for payment or become unable to pay, or in cases where the Subscriber is requested to start bankruptcy proceedings, start civilian revitalization proceedings, start corporate reorganization proceedings, start special liquidation, or similar proceedings
  h. In case of a significant decline in the credibility of the Subscriber, or in case there is a significant change in the business that affects the credibility of the Subscriber
  i. In the event that the Subscriber resolves to dissolve or in the event that the Subscriber resolves to reorganize the company such as merger with another company
  j. In the event of the death of the Subscriber, or in the event that there is a reasonable cause to cancel the service use contract due to the commencement of entrustment, conservatorship, or assistance
  k. When the Subscriber does not respond to inquiries or other communications from the Company for more than 30 days
 11.2. When any of the reasons in the preceding paragraph applies, the Subscriber will naturally lose the benefit of time for all of the debts owed to the Company (including but not limited to not only the debts under this Agreement but also the debts of the Subscriber for compensation for damages to the Company) and must immediately fulfill all of the debts to the Company.
 11.3. The Subscriber shall not be exempted from all obligations and liabilities (including but not limited to compensation for damages) to the Company and other third parties for the use of this Service even after the measures based on clause 11.1 are taken.
 11.4. The Company shall not be held responsible for any damage caused to the Subscriber by the actions taken by the Company in accordance with this Article to the extent permitted by the applicable laws and regulations, and the Company may retain and use the data and other information provided by the Subscriber to the Company even after the measures based on Paragraph 11.1 are taken.


12. Changing, Suspending, Waiting, Adding, or Abandoning this Service
 12.1. The Company may change or add all or part of the contents of this Service and the software related to this Service without prior notice to the Subscriber. However, in the case of changes or changes that involve a major reduction in this Service, the Company will notify the Subscriber of the fact that this Service will be changed or changed, the contents of this Service after the change, and the timing of the change or change by posting on the Company's website or by other means that the Company deems appropriate, at least a reasonable period of time before the change or change.
 12.2. The Company may, at its discretion, stop the provision and operation of all or part of this Service. In the event that the Company decides to suspend the provision or operation of all or part of this Service, the Company will notify the Subscriber in a manner that the Company deems appropriate. However, in the case of an emergency, notification to the Subscriber may not be carried out.
 12.3. The Company may temporarily suspend all or part of this Service without prior notice to the Subscriber in the event of any of the following events.
  a. In case of regular or emergency maintenance or repair of hardware, software, communication equipment and facilities for this Service
  b. In the event that the service of a telecommunication carrier is not provided
  c. In the event that provision of this Service is difficult due to force majeure such as a natural disaster
  d. In the event that provision of this Service is difficult due to a fire, power failure, other unforeseen accident, war, conflict, disturbance, riot, or labor dispute
  e. When the system is overloaded due to excessive access or other unforeseen factors
  f. When there is a need to ensure the security of the Subscriber
  g. When the provision of all or part of the services (including but not limited to AWS) operated by related Carriers is temporarily suspended or interrupted
  h. In the event that the operation of this Service becomes impossible or extremely difficult due to laws or regulations or measures based on such laws or regulations
  i. When the Company deems it necessary in accordance with the preceding items
 12.4. The Subscriber agrees in advance that all or part of the use of this Service may be restricted when any of the following cases apply.
  a. When the eligibility for use of this Service cannot be confirmed by the authentication function of the G.U. Account
  b. In the case of using this Service in the environment that cannot be connected to the Internet
  c. In the case of using this Service in a situation where real-time communication is not possible
 12.5. The Company is not obligated to support the software and provide corrected versions (including updated versions) of this Service to the Subscriber.
 12.6. The Company shall not be responsible for any damage caused to the Subscriber by the measures taken by the Company based on this Article to the extent permitted by the applicable laws.


13. Ownership of Rights
 13.1. All intellectual property rights related to the information provided by the Company in this Service belong to the Company or the party that has granted a license to the Company.
 13.2. Trademarks, logos, service marks, etc. (collectively, "Trademarks") may be displayed on this Service, but the Company does not transfer or license the Trademarks to the Subscriber or any other third party.
 13.3. The Company is not concerned with the contents of the information recorded in the G.U. Blockchain Network created by the Subscriber. The Subscriber represents and warrants to the Company that it has the legal rights to the data it sends on the G.U. Blockchain Network and that the sending of such data does not infringe the rights of any third party.

14. Business Consignment
 14.1. The Company may, at its discretion, outsource all or part of the work related to this Service to a third party.

15. Use of Subscriber's Information, etc.
 15.1. We will not use the registration information and the information related to the usage of this Service obtained from the Subscriber through the use of this Service beyond the scope of the law and beyond the scope of the purpose of use listed in the following items.
  a. To confirm the identity of the Subscriber
  b. To provide this Service.
  c. To charge fees and late fees
  d. To confirm the validity of credit cards for payment of the fees
  e. To customize the information and advertisements on this Service for each subscriber
  f. To provide information about this Service and other services provided by the Company or the Company's group companies (G.U.Labs, Inc.)
  g. To notify of the suspension, termination, or cancellation of this Service
  h. To respond to actions that violate this Agreement
  i. To notify the Subscriber of changes to the Company's rules, policies, etc. regarding this Service
  j. To respond to disputes, lawsuits, etc.
  k. To respond to inquiries about this Service
  l. To analyze the Subscriber in terms of age, affiliation, type of business, size, etc., in order to produce processed statistical data in a form that does not allow identification of individual persons, companies, organizations, or individuals, and to improve and enhance the service, conduct service development, research, market analysis, and marketing
  m. To provide, maintain, improve, and develop this Service in accordance with the preceding items
 15.2. The Company will not disclose or share the Subscriber's information to any third party without the consent of the Subscriber. However, the Company may disclose or share the Subscriber's information to a third party in the following cases, and the subscriber shall agree to it in advance.
  a. To the provider of the tool within the necessary range in using the tool which the Company deems appropriate to grasp the status of use of this Service
  b. To the provider of the third party service within a reasonable range for the cooperation between this Service and the third party service and the improvement of the function
  c. When the Company entrusts all or part of the handling of the Subscriber's information within the scope necessary to achieve the purpose of use specified in Clause 15.1
  d. When the Company entrusts the information of the Subscriber to a settlement system company, credit card company or bank for the purpose of billing the fee to the Subscriber
  e. When the information of the Subscriber is provided as a result of succession of business due to our merger or other reasons
  f. When based on laws and regulations
  g. When the disclosure of information is requested by a court of law, the police, or other public institution in accordance with laws and regulations
 15.3. We may change the purpose of use of the information of the Subscriber within the range that is reasonably considered to have a reasonable relation to the purpose of use before the change, and we will notify and announce the change to the Subscriber.
 15.4. In this Service, in order to understand the usage of this Service by the Subscriber, to operate or improve this Service, or to optimize advertisements provided by us or third parties other than us, we use Google Analytics and other tools that we deem appropriate to collect information such as access logs (web pages which the subscriber browses, environment of use, etc.). Google Analytics and other tools that we deem appropriate are used to collect and analyze access logs and other information (including information such as web pages viewed by the Subscriber, information on the usage environment, and information such as behavioral history on advertising pages and sites linked to this Service and cookies automatically received from browsers and applications) in order to optimize advertisements provided by the Company or third parties other than the Company. These tools use cookies, Advertising ID (AAID) provided by Google, etc. (collectively, "cookies, etc.") to collect access logs, etc. without including information that identifies individuals. The collected information will be managed in accordance with the personal information protection policy of each tool provider. Please refer to each tool provider's website for their privacy policy and opt-out instructions. We are not responsible for any damage caused by using the services of each tool provider.
 15.5. We may disclose statistical data, etc. to a third party. In this case, only statistical data that does not identify specific persons, organizations or individuals will be displayed, and information that can identify the Subscriber will not be displayed.
 15.6. The Company shall manage the personal information (excluding “Tokutei-Kojinjoho”, specific personal information) of the Subscriber obtained through the use of the service in accordance with the personal information protection policy (including “Privacy Policy”) separately stipulated by the Company in addition to the provisions of this Agreement. The subscriber shall agree in advance to the Company's handling of the Subscriber's personal information contained in the Subscriber's data, etc. in accordance with the personal information protection policy.
 15.7. The Company will not handle any data sent by the Subscriber to the G.U. Blockchain Network without the Subscriber's permission.

16. Exclusion of Antisocial Forces
 16.1. The Company prohibits the use of this Service by antisocial forces. When the Company judges that the Subscriber corresponds to such a person, the Company may stop providing this Service or cancel the service use contract without notifying the Subscriber in advance. The company will not take responsibility for any damage or disadvantage caused to the Subscriber by the suspension of this Service or the cancellation of the service use contract.

17. Compensation for damages
 17.1. If the Subscriber causes damage to the Company by violating this Agreement or in connection with the use of this Service, the Subscriber must compensate the Company for all the damage (including professional fees such as lawyers' fees and the cost for human resources).
 17.2. In the event that the Company receives a claim from another subscriber, user or any other third party for infringement of rights or any other reason in relation to the use of this Service by the Subscriber, the Subscriber shall pay the Company the amount of money which the Company was forced to pay to the third party based on the claim and the amount of money which the Company incurred in order to settle the dispute in relation to the claim (including professional fees such as lawyers' fees and the cost for human resources).

18. Disclaimer of Warranties and Indemnity
 18.1. We disclaim all warranties that this Service, contents provided through this Service, and any other benefits that the Subscriber may obtain from this Service will meet the Subscriber's specific purpose, will have the expected functionality, commercial value, accuracy, and usefulness, and that the use of this Service by the Subscriber will be in compliance with the laws and regulations applicable to the Subscriber. We do not guarantee that the contents provided through this Service are legally available, that the terms of use of the services provided by other companies are observed, or that the rights of the third party are not infringed.
 18.2. Due to the characteristics of the G.U. Blockchain Network as a blockchain, it may not be possible to delete the recorded information retroactively. In addition, there are certain restrictions on the suspension and deletion of blockchain operations. The Subscriber shall use this Service with an understanding of its characteristics, and the Company shall not be liable for any damage caused by such characteristics. The Subscriber shall manage the private key information, necessary for updating the blockchain operated by the Subscriber, at the Subscriber's own responsibility, and the Company shall bear no responsibility for such management.
 18.3. If the Subscriber provides any service to a third party by sharing the G.U. Blockchain Network endpoint (URL) with the third party, the Company shall not be responsible for the provision of such service. If a dispute arises between the Subscriber and a third party regarding the provision of such services, the Subscriber shall deal with the dispute at its own expense and responsibility, and shall not cause any trouble to the Company.
 18.4. This service uses AWS as a server. The Subscriber acknowledges that the Company shall not be held responsible for any and all damages caused by system malfunctions of AWS.
 18.5. The Company shall not be liable for any damage caused by interruption, waiting, termination, unavailability, or change of the provision of this Service by the Company, deletion or loss of data sent to this Service by the Subscriber, cancellation of the registration of the Subscriber, loss of data, failure or damage of equipment caused by the use of this Service, or any other damage suffered by the Subscriber regarding this Service, except in cases where the Company is intentionally or grossly negligent.
 18.6. The Company will not be involved in communications or activities between subscribers. In the event that a dispute or problem arises between the subscribers, please resolve it at your own responsibility and expense and the Company will not be involved.
 18.7. If a dispute arises between the Subscriber and a third party, the Subscriber shall solve it by his/her own responsibility and expense, and we shall not be involved in it.
 18.8. Even if links from the Company's website to other websites, or links from other websites to the Company's website are provided, the Company accepts no responsibility for websites or the information obtained from them other than the Company's website.
 18.9. The Company shall not be held responsible for any damages incurred by the Subscriber in relation to this Service, except in cases of intentional or gross negligence on the part of the Company. In addition, even if the Company is liable for damages to the Subscriber due to the Company's willful misconduct or gross negligence, or due to the application of the Consumer Contract Act or other reasons, the scope of the Company's liability is limited to the direct and ordinary damages actually caused by the reasons attributable to the Company, and shall be limited to the total amount of the fees for this Service actually received from the Subscriber for the past one year retroactively from the time when the reason for damages was born.

19. Period of the Validity of Contract
 19.1. The service use contract shall be effective between the Company and the Subscriber from the day when the registration for this Service is completed and the Company starts providing this Service to the Subscriber to the day when the Subscriber cancels this Service or the day when the Subscriber's account is deleted, whichever comes first. The Service shall remain in effect between the Company and the Subscriber until the earlier of the date on which the Subscriber's account is deleted.

20. Revision of Agreement
 20.1. The Company may revise this Agreement without the Subscriber's consent in any of the following cases
  a. If the change is a change of the service name or expression, or a correction of typos or omissions, etc., and does not substantially affect the contents of this Agreement.
  b. When the contents of the change conform to the general interests of the Subscriber.
  c. When the contents of the change do not violate the purpose for which this Agreement was made and are reasonable in light of the necessity of the change, the reasonableness of the contents after the change and other circumstances related to the change.
 20.2. In the case of a change in accordance with Clause 20.1.b and 20.1.c, the Company shall notify the Subscriber of the change in this Agreement, the contents of the changed Agreement, and the effective date of the change by posting on the Company's website or by any other means deemed appropriate by the Company at least a reasonable period of time before the change becomes effective. In the case of a change in accordance with Clause 20.1.a, the revised Agreement shall become effective at the time that the Company notifies the Subscriber of the revised Agreement by posting the revised Agreement on the Company's website or by any other method that the Company deems appropriate.

21. Contact and Notification
 21.1. Inquiries about this Service, other communications or notifications from the Subscriber to the Company, notifications regarding changes to this Agreement, and other communications or notifications from the Company to the Subscriber shall be carried out in the manner determined by the Company. In the event that the Company sends a notice or an alternative notice to the Subscriber by electronic mail or by posting on the Company's website, such notice or alternative notice shall be deemed to have been made at the time when the electronic mail is sent by the Company or when the notice is posted on the Company's website.

22. Transfer of Status under this Agreement
 22.1. The Subscriber cannot transfer, succeed (including comprehensive succession by merger, company split, etc.), or use for security purpose all or part of the rights and obligations based on this Agreement to a third party without prior written consent of this company.
 22.2. When the Company transfers the business of this Service to another party, the Company can transfer the status on the service use contract, rights and obligations under this Agreement, registration information of the Subscriber and other customer information to the assignee of the transfer. The Subscriber is considered to have agreed to such transfer in advance.

23. Severability
 23.1. Even if any provision or part of any provision of this Agreement is determined to be invalid or unenforceable under the Consumer Contract Act or other laws or regulations, the remaining provisions of this Agreement and the remaining part of any provision that is determined to be invalid or unenforceable shall continue to be in full force and effect. The Company and the Subscriber shall endeavor to modify this Agreement to the extent necessary to make such invalid or unenforceable provisions or parts legal and enforceable, and to secure the intent and legal and economic equivalent of such invalid or unenforceable provisions or parts.

24. Survival Clause
 24.1. Article 10 (Cancellation of Service Contract), Article 11 (Service Use Standstill and Account Deletion), Article 13 (Ownership of Rights), Article 15 (Use of Information), Article 16 (Exclusion of Anti-Social Forces) through Article 18 (Disclaimer and Disclaimers of Warranties), and Article 22 (Transfer of Status under this Agreement) through Article 26 (Negotiated Solutions)

25. Governing law and agreement jurisdiction
 25.1. This Agreement shall be governed by the laws of Japan and the Tokyo District Court shall be the exclusive jurisdictional court of first instance for any and all disputes arising from or related to this Agreement.

26. Consultation and Resolution
 26.1. The Company and the Subscriber shall consult with each other in accordance with the principle of good faith to promptly resolve any disputes arising from matters not stipulated in this Agreement or from the interpretation of this Agreement.`;

const termOfServiceJP = `「G.U.ブロックチェーンクラウド」利用規約

1. 総則・適用範囲
 1.1. 「G.U.ブロックチェーンクラウド」利用規約（以下「本規約」といいます）は、G.U.テクノロジーズ株式会社（以下「当社」といいます）が契約者（2.1 項b 号に定義するものをいいます。以下同じです）に提供・運営する「G.U.ブロックチェーンクラウド」（以下「本サービス」といい、詳細は 3.1 項で定義します）について、契約者又は利用希望者（2.1 項c 号に定義するものをいいます。以下同じです）と当社との間の本サービス利用に関する基本的な契約条件を規定します。
 1.2. 当社が、当社の運営するウェブサイト又はアプリ（以下「当社ウェブサイト等」といいます）上に本サービスに関して個別規定や追加規定を掲載する場合、それらは本規約の一部を構成するものとし、個別規定又は追加規定が本規約と矛盾抵触する場合には、当該個別規定又は追加規定が優先して適用されるものとします。
 1.3. 本規約は、本サービスの利用に関し、契約者又は利用希望者と当社に対して適用されます。
 1.4. 本規約は日本語で作成され、英語に翻訳されています。日本語のテキストは原文であり、英語のテキストは参考のためのものです。これら 2 つのテキストの間に矛盾抵触がある場合は、日本語のテキストが優先して適用されるものとします。

2. 定義
 2.1. 本規約において、次の各号に掲げる用語の定義は、当該各号に定めるとおりとします。
  a. サービス利用契約
   本サービスを利用するに際し、当社と契約者との間で締結する本サービスの利用に関する契約関係をいい、本規約、当社ウェブサイト等上に掲載されるこれに関連する規約・通知等をその内容に含みます。
  b. 契約者
   本規約に同意の上、当社とサービス利用契約を締結した者をいいます。
  c. 利用希望者
   当社とサービス利用契約を締結することを希望する者をいいます。
  d. G.U.アカウント
   本サービスの利用に必要となるアカウントで、当社が、契約者に対して付与するものです。G.U. アカウントは、Google アカウントに対して付与されるため、契約者は、本サービスの利用にあたり、Google アカウントへの登録が必要となります。
  e. 組織アカウント
   契約者が、本サービスにおいて、G.U.ブロックチェーンネットワークを作成する際に必要となるアカウントです。組織アカウントには、複数の契約者の G.U.アカウントを加えることができます。また、1 人の契約者の G.U.アカウントが複数の組織アカウントに参加することも可能です。
  f. G.U.ブロックチェーンネットワーク
   本サービスのサーバー上で作成されるブロックチェーンネットワークをいいます。G.U.ブロックチェーンネットワークへは、組織アカウント単位で参加することができます。また、1 つのG.U.ブロックチェーンネットワークに複数の組織アカウントが参加することも可能です。
  g. 登録情報
   利用希望者及び契約者がサービス利用契約締結前に当社に提供した当社所定の情報、本サービス利用中に当社が必要と判断して契約者に登録を求めた情報及びこれらの情報について契約者自身が追加、変更を行った場合の当該情報をいいます。
  h. 関連事業者
   本サービスに関連する電気通信事業者、サーバー運用事業者（本サービスでは、ＡＷＳを利用しています）、その他の事業者をいいます。
  i. 知的財産権
   著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます）をいいます。

3. 本サービスの内容
 3.1. 本サービスは、次の各号の一つ又は複数とし、詳細は当社ウェブサイト等に掲示します。
  a. G.U.ブロックチェーンクラウド
  Ethereum 互換のブロックチェーン基盤を、ウェブブラウザ（Google Chrome）上で提供するインターフェースを操作することで開発・運用できる、BaaS（Blockchain as a Service）です。G.U.ブロックチェーンクラウドにて構築したブロックチェーンは、プライベートチェーンとしてクラウド環境（Amazon Web Services）に保存され、エンドポイント（URL）を共有することで、外部のアプリケーションからアクセスが可能です。ブロックチェーンの特性上、ブロックチェーンに記録された情報をさかのぼって削除できない場合がございます。また、ブロックチェーンの運用の停止や削除に際しても、一定の制約がございます。
  b. 前号に掲げるサービスに関連又は付随するサービス
 3.2. 当社は、本サービスの適切な運用のため、契約者における本サービスの利用状況等を確認することがあります。契約者は、これに予め同意するものとします。

4. 契約者の登録
 4.1. 利用希望者は、本規約を遵守することに同意し、かつ登録情報を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録（以下「利用登録」といいます）を申請することができるものとします。
 4.2. 利用登録にあたっては、Google アカウントが必要になります。Google アカウントの利用・管理にあたっては、Google の定める各種規約を遵守してください。
 4.3. 当社は、当社の基準に従って、利用登録の可否を判断し、当社が登録を認めた利用希望者に限り、利用登録を完了し、G.U.アカウントを付与するものとします。例えば、過去に本規約に違反したことのある利用希望者や、当社の競業他社に所属されている利用希望者に関しては、利用登録をお断りする場合がございます。
 4.4. 本規約に基づくサービス利用契約は、当社が契約者に対して、本サービスの提供を開始した時に、契約者と当社との間に成立し、これ以降、契約者は本サービスを当社の定める方法で利用することができるようになります。
 4.5. 未成年者が本サービスの利用を希望する場合には、法定代理人の同意が必要になります。未成年者が契約者となった場合、本サービスの利用及び本規約の内容について、法定代理人の同意があったものとみなします。また、利用登録を行った時点で未成年者であった契約者が、成年に達した後に本サービスを利用した場合、未成年者であった間の利用行為を追認したものとみなします。
 4.6. 契約者は、登録情報の登録（契約者が第三者のサービスを通じて登録情報を当社に提供する場合を含みます）にあたっては、真実かつ正確な情報を送信しなければなりません。当社は、契約者自身が登録した登録情報を前提として、本サービスを提供いたします。登録情報の内容に虚偽、誤り又は記載漏れがあったことにより契約者に生じた損害について、当社は一切責任を負いません。次条により登録情報の変更が生じた場合も同様とし、当社は契約者による本サービス利用時点において当社に登録されている登録情報を前提として、本サービスを提供いたします。

5. 契約者の登録情報の変更
 5.1. 契約者は、登録情報に変更があった場合は、速やかに、当社所定の方法により、変更手続を行うものとします。
 5.2. 契約者は、前項の変更を怠ったことにより当社からの通知が不到達となった場合、当該通知は通常到達すべき時に到達したとみなされることを予め異議無く承諾するものとします。
 5.3. 契約者が 5.1 項の変更を怠ったことにより生じた損害について、当社は一切の責任を負わないものとします。

6. アカウントの利用
 6.1. 契約者は、G.U.アカウントへのログインに必要な情報の機密性を維持する責任があり、G.U.アカウントの下で発生するすべての行動について完全に責任を負うものとします。
 6.2. 契約者は、G.U.アカウントの不正使用、不正使用の疑い、その他のセキュリティ違反があった場合、直ちに当社に通知するものとします。なお、契約者が上記の通知を怠ったことにより生じた損害について、当社は一切の責任を負わないものとします。
 6.3. 当社は、11 条に従って、契約者の G.U.アカウントを削除できるものとします。

7. 利用料金
 7.1. 契約者は、本サービス上で、G.U.ブロックチェーンネットワークを作成するにあたっては、当社に対し、当社が別途当社ウェブサイト等において定める金額及び方法に従い、本サービスの利用の対価（以下「利用料金」といいます）を支払うものとします。
 7.2. 当社は、当社が必要と判断した場合、利用料金の改定を行うことができるものとします。ただし、利用料金の増額の場合、当社は料金を変更する旨及び変更後の料金並びに変更後の料金が適用される時期を当社ウェブサイト等への掲載その他当社が適当と判断する方法により、当該変更の相当期間前までに、契約者に通知します。また、当社は、当社の裁量で、既存の契約者に対し、旧利用料金の適用を認める移行期間を設けることができるものとします。
 7.3. 契約者が利用料金の支払を遅滞した場合、契約者は当社に対して、年 14.6％の割合による遅延損害金及び残期間に対応する利用料金を支払うものとします。
 7.4. 利用料金にかかる税金の取り扱いは以下の通りとします。
  a. 契約者は、いかなる税金も差し引くことなく利用料金を当社に支払うものとし、当社が税金を徴収する又は支払う義務を負う場合には、当社は契約者に税金を請求するものとします。
  b. 契約者が、法令等にもとづき利用料金から税金を差し引いて支払う場合は、契約者は当社の求めに応じて税金を支払ったことを示す証明書又はそれに類する適切な書面を当社に提出するものとします。
 7.5. 本規約に基づくサービス利用契約の契約期間は月単位とします。ただし、契約者が有効期間終了日（各月末）までに当社所定の方法による解約手続を完了しない限り、有効期間終了日の経過時に従前と同一内容にて、1 ヶ月間自動更新するものとし、その後も同様とします。なお、契約者が、月の半ばにおいて、サービス利用契約を途中解約した場合でも、当該契約の残期間（各月末まで） に対応する利用料金は発生するものとし、当社は日割計算等による精算及び返金は行いません。
 7.6. 本規約に別途定める場合を除き、当社は、契約者から当社に対して支払われた利用料金の返金には一切応じないことにつき、契約者は予め承諾するものとします。

8. 本サービスの利用
 8.1. 契約者は、サービス利用契約の有効期間内において、本規約の目的の範囲内でかつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができるものとします。
 8.2. 本サービスの提供を受けるために必要なコンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、契約者の費用と責任において行うものとします。
 8.3. 契約者は、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイト等からのダウンロードその他の方法によりソフトウェア等を契約者のコンピューター等にインストールする場合、契約者が保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は、かかる事象に基づき契約者に生じた損害について一切責任を負わないものとします。
 8.4. 契約者は、本サービスを利用するにあっては、次に定める事項を遵守するものとします。
  a. 本サービスの利用に関して不正アクセス、情報流出・漏洩等又はそのおそれが生じたと判断した場合（G.U.アカウントへのログインに必要な情報を流出・漏洩した場合を含みますが、これに限りません。）、直ちに当社に対して連絡すること。
  b. 本サービスの利用に関して不正アクセス、情報流出・漏洩等の未然防止のために行うべきセキュリティ関連措置を行うこと。

9. 禁止事項
 9.1. 契約者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
  a. 法令に違反する行為、法令違反を助長する行為またはそれらのおそれのある行為
  b. 当社、本サービスの他の契約者又はその他第三者に対する詐欺又は脅迫行為
  c. 公序良俗に反する行為
  d. 当社、又は本サービスの他の契約者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為又はそれらのおそれのある行為
  e. 本サービスのコンテンツの全部又は一部を、ライセンス、販売、賃貸、リース、譲渡、譲渡、配布、ホスティング又はその他の方法で商業的に利用する行為
  f. 本サービスにかかるコンテンツの修正、二次的著作物の作成、逆アセンブル、リバースエンジニアリング等の行為
  g. 本サービスと類似又は競合するウェブサイト、製品、サービスを構築するため、又はその可用性、パフォーマンス、機能性を検証する目的、もしくはその他のベンチ―マーキングや競合の目的で本サービスを利用する行為
  h. 本規約において明示的に許可されている場合を除き、本サービスの全部又は一部を、コピー、複製、配布、再発行、ダウンロード、表示、掲示又は送信する行為
  i. 本サービスを通じ、以下に該当し、又は該当すると当社が判断する情報を送信する行為
   (1) 暴力的又は残虐な表現を含む情報
   (2) コンピューター・ウィルスその他の有害なプログラムを含む情報
   (3) 当社、本サービスの他の契約者又はその他の第三者の名誉又は信用を毀損する表現を含む情報
   (4) わいせつな表現を含む情報
   (5) 差別を助長する表現を含む情報
   (6) 自殺、自傷行為を助長する表現を含む情報
   (7) 薬物の不適切な利用を助長する表現を含む情報
   (8) 反社会的な表現を含む情報
   (9) 他人に不快感を与える表現を含む情報
   (10) 虚偽の内容を含む情報
   (11) 宣伝、広告、勧誘、又は営業行為を含む情報
  j. 本サービス並びに本サービスを通じてアクセスするネットワーク又はシステム等に過度な負荷をかける行為
  k. 本サービスの他の契約者の情報の収集を目的とする行為
  l. 本サービスに接続しているシステム全般について、権限なく不正にアクセスする行為、当社の設備に蓄積された情報を不正に書換え若しくは消去する行為、その他当社に損害を与える行為
  m. 他の契約者又は第三者に成りすます行為
  n. 本サービスの他の契約者の G.U.アカウントを利用する行為（複数人が１つの G.U.アカウントを共同利用する行為も含まれますが、これに限りません）
  o. 反社会的勢力等（暴力団、暴力団員、暴力団員でなくなったときから 5 年を経過しない者、右翼団体、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者を意味します。以下同じです）への利益供与行為
  p. 暴力又は脅迫的な言動（自己又は関係者が反社会的勢力等である旨を伝える行為も含まれますが、これに限りません）を用いる行為
  q. 当社による本サービスの運営を妨害するおそれのある行為
  r. 本規約及び本サービスの趣旨・目的に反する行為
  s. 本サービスのサーバーとして使用している AWS の各種規約類（https://aws.amazon.com/jp/le gal/）に反する行為
  t. 前各号の行為を直接又は間接に惹起し、又は容易にする行為
  u. その他、当社が不適切と判断する行為

10. サービス利用契約の解約
 10.1. 契約者は、当社が当社ウェブサイト等において別途指定する方法によりサービス利用契約の全部又は一部を解約することができるものとします。サービス利用契約を解約した契約者は、当該解約の時点から本サービス（当該契約者がサービス利用契約の一部のみを解約した場合には、当該解約部分の範囲に限ります）を利用することができなくなるものとします。
 10.2. サービス利用契約の解約にあたり、契約者が当社に対して負っている債務（本規約上の債務のみならず、契約者の当社に対する損害賠償債務も含みますが、これらに限りません）がある場合は、契約者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務を履行しなければなりません。
 10.3. 契約者がサービス利用契約を有効期間の途中で解約した場合でも、当該契約の残期間に対応する利用料金は発生するものとし、当社は、契約者に対し、日割計算等による精算及び返金は行いません。
 10.4. 契約者は、サービス利用契約の解約後も、本サービスの利用により契約者が負った当社に対する一切の義務及び債務を免れるものではありません。
 10.5. 当社は、契約者がサービス利用契約の全部又は一部を解約した後も、契約者の登録情報について、保有、利用又は削除することができるものとします。
 10.6. サービス利用契約の解約後、契約者が再度本サービスの利用登録を希望する際は、再度登録手続を行う必要があります。契約者は再度の登録手続によっても、解約前のデータが引き継がれないことを予め承諾するものとします。
 10.7. サービス利用契約の解約後、当社は、契約者が当社に対し提供した契約者のデータ等その他一切の情報を引渡さないものとし、契約者はこれを異議なく承諾するものとします。

11. サービス利用停止又はアカウント削除
 11.1. 当社は、契約者が次のいずれかに該当すること、又は該当するおそれがあると当社が判断した場合、事前の通知又は催告をすることなく、当該契約者の本サービスの利用を一時的に停止その他の必要な措置をし、契約者としての登録を抹消、又は契約者との間のサービス利用契約を解除することができるものとします。
  a. 本規約のいずれかの条項に違反した場合又は本規約に違反するおそれがあると当社が判断した場合
  b. 当社に提供された情報の全部又は一部に虚偽の事実があることが判明した場合
  c. G.U.アカウントへのログインに必要な情報が盗まれたり、第三者に使用されていたりすることが判明した場合
  d. 本サービスの利用料金の決済方法として契約者が指定する銀行口座又はクレジットカードが利用停止若しくは無効扱いとされた場合、又はそれらの不正使用が判明した場合
  e. 本サービスの利用料金の支払いを遅滞し、当社が指定した日までに当該遅滞を解消しない場合
  f. 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人、保佐人又は補助人の同意等を得ていないことが判明した場合
  g. 支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
  h. 信用力の著しい低下又は信用力に影響を及ぼす営業上の重要な変更がなされた場合
  i. 契約者が解散を決議し又は他の会社との合併等の会社再編を決議した場合
  j. 契約者が死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合であってサービス利用契約を解除する合理的な理由があるとき
  k. 当社からの問合せその他の回答を求める連絡に対して 30日間以上応答がない場合
 11.2. 前項各号のいずれかの事由に該当した場合、契約者は、当社に対して負っている債務の一切（本規約上の債務のみならず、契約者の当社に対する損害賠償債務も含みますが、これらに限りません） について当然に期限の利益を失い、直ちに当社に対して全ての債務を履行しなければなりません。
 11.3. 契約者は、11.1 項に基づく措置がなされた後も、当社及びその他の第三者に対する本サービス利用上の一切の義務及び債務（損害賠償を含みますが、これに限りません）を免れるものではありません。
 11.4. 当社は、本条に基づき当社が行った行為により契約者に生じた損害について、適用法令により許容される限度において一切の責任を負わず、11.1 項に基づく措置がなされた後も、当該契約者が当社に提供したデータ等その他一切の情報を保有・利用することができるものとします。

12. 本サービスの変更、中断、中止、追加及び廃止等
 12.1. 当社は、契約者に事前の通知をすることなく、本サービス及び本サービスに関するソフトウェアの内容の全部又は一部を変更又は追加することができるものとします。ただし、本サービスの大幅な縮減を伴う変更又は廃止の場合には、本サービスを変更又は廃止する旨及び変更後の本サービスの内容並びにその時期を当社ウェブサイト等への掲載その他当社が適当と判断する方法により、当該変更又は廃止の相当期間前までに、契約者に通知します。
 12.2. 当社は、当社の判断により本サービスの全部又は一部の提供・運営を中止することができるものとします。また、当社は、当社の判断により本サービスの全部又は一部の提供・運営を中止する場合、当社が適当と判断する方法で契約者にその旨通知します。ただし、緊急の場合は契約者への通知を行わない場合があります。
 12.3. 当社は、以下各号の事由が生じた場合には、契約者に事前に通知することなく、本サービスの全部又は一部を一時的に中断することができるものとします。
  a. 本サービス用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合
  b. 電気通信事業者の役務が提供されない場合
  c. 天災等の不可抗力により本サービスの提供が困難な場合
  d. 火災、停電、その他の不慮の事故、戦争、紛争、動乱、暴動又は労働争議等により本サービスの提供が困難な場合
  e. アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合
  f. 契約者のセキュリティを確保する必要が生じた場合
  g. 関連事業者が運営するサービス（ＡＷＳを含みますが、これに限りません）の全部又は一部の提供が一時的に停止又は中断された場合
  h. 法令又はこれらに基づく措置により本サービスの運営が不能又は著しく困難となった場合
  i. その他前各号に準じ当社が必要と判断した場合
 12.4. 契約者は、次の各号のいずれかに該当する場合、本サービスの利用の全部又は一部が制限されることがあることに予め承諾します。
  a. 本サービスの利用資格等の確認を目的とした G.U.アカウント等の認証機能において、利用資格等の確認ができない場合
  b. インターネットに接続できない環境において、本サービスを利用する場合
  c. リアルタイム通信ができない通信状況において本サービスを利用する場合
 12.5. 当社は、契約者に対し、本サービスに関するソフトウェアのサポート及び修正版（アップデート版を含みます）の提供を行う義務を負いません。
 12.6. 当社は、本条に基づき当社が行った措置により契約者に生じた損害について、適用法令により許容される限度において一切の責任を負いません。

13. 権利の帰属
 13.1. 本サービスにおいて、当社が提供する情報等に関する一切の知的財産権は当社又は当社にライセンスを許諾している者に帰属します。
 13.2. 本サービス上には商標、ロゴ及びサービスマーク等（以下総称して「商標等」といいます）が表示される場合がありますが、当社は、契約者その他の第三者に対し何ら当該商標等を譲渡し、又は使用を許諾するものではありません。
 13.3. 当社は、契約者が作成する G.U.ブロックチェーンネットワークに記録される情報の内容について一切関知しません。契約者は、当社に対し、G.U.ブロックチェーンネットワーク上に送信するデータについて、適法な権利を有していること、及び当該データの送信が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。

14. 業務委託
 14.1. 当社は、本サービスに関する業務の全部又は一部を、当社の裁量において、第三者に委託することができるものとします。

15. 契約者の情報の利用等
 15.1. 当社は、本サービスの利用を通じて契約者から取得した登録情報及び本サービスの利用状況に係る情報を、法令で定められている範囲を超えて、また、次の各号に掲げる利用目的の範囲を超えて利用することはありません。
  a. 契約者の同一性確認のため
  b. 本サービスを提供するため
  c. 利用料金、遅延損害金等の請求のため
  d. 代金決済等におけるクレジットカードの有効性の確認のため
  e. 契約者に合わせた本サービス上の情報、広告配信のカスタマイズのため
  f. 本サービス及び当社又は当社グループ会社（G.U.Labs 株式会社）が提供する他のサービスに関する案内のため
  g. 本サービスの停止・中止・契約解除の通知のため
  h. 本規約に違反する行為への対応のため
  i. 本サービスに関する当社の規約、ポリシー等の変更などの通知のため
  j. 紛争、訴訟などへの対応のため
  k. 本サービスに関する問い合わせ等への対応のため
  l. 契約者を年代、所属、業種、規模その他の観点から分析することにより、個別の法人、団体及び個人を識別することのできない形式に加工した統計データ等を作成し、本サービスの向上及び改善、サービス開発、研究、市場分析、マーケティングを行うため
  m. 前各号に付随する本サービスの提供・維持・改善・開発のため
 15.2. 当社は、契約者の承諾がない限り、当該契約者の情報を第三者に開示又は共有することはありません。ただし、次の各号に掲げる場合には、当社は、契約者の情報を第三者に開示又は共有する場合がございますので、契約者は、予め承諾するものとします。
  a. 当社が本サービス利用状況を把握するために適切と判断するツールを利用する上で必要な範囲内において当該ツール提供元に契約者の情報を提供する場合
  b. 本サービスと第三者サービス等との連携及びその機能向上に合理的な範囲内において当該第三者サービス等の提供元に契約者の情報を提供する場合
  c. 当社が利用目的の達成に必要な範囲内において契約者の情報の取扱いの全部又は一部を委託する場合
  d. 契約者に利用料金を請求する目的で、決済システム会社、クレジットカード会社及び銀行に本サービスを利用している契約者の情報を預託する場合
  e. 合併その他の事由による事業の承継に伴って契約者の情報が提供される場合
  f. 法令に基づく場合
  g. 法令諸規則に基づき裁判所、警察等の公的機関に開示を求められた場合
 15.3. 当社は、契約者の情報の利用目的を、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には契約者に通知又は公表します。
 15.4. 本サービスでは、契約者による本サービスの利用状況を把握し、本サービスの運用若しくは改善又は当社若しくは当社以外の第三者が提供する広告を最適化するため、Google Analytics、その他当社が適切と判断するツールを利用して、アクセスログ等（契約者が閲覧したウェブページ、利用環境等の情報、広告ページや本サービスとクッキー連携しているサイトでの行動履歴等の情報をブラウザやアプリから自動的に受け取ったものを含みます）を収集し、分析しています。これらのツールでは、cookie（クッキー）及び Google 社が提供する Advertising ID（AAID）等（以下「クッキー等」といいます。）等を使用し、個人を特定する情報を含むことなく、アクセスログ等を収集されることがあります。収集された情報は各ツール提供元の個人情報保護方針に基づき管理されています。各ツール提供元の個人情報保護方針及びオプトアウトの方法については、各ツール提供元のサイトをご覧ください。当社は、各ツール提供元のサービス利用による損害について責任を負わないものとします。
 15.5. 当社は、統計データ等を、第三者に開示することがあります。この場合、開示されるのは特定の法人、団体及び個人を識別することのできない統計データ等のみであり、契約者自身を識別できる情報を開示することはありません。
 15.6. 当社は、本サービスの利用を通じて取得した契約者の個人情報（特定個人情報を除きます。以下、本項において同じです）を、本規約に定めるほか、当社が別途規定する個人情報保護方針（「個人情報の取扱について」を含みます。以下同じです）に則って管理するものとし、契約者は、当社が契約者のデータ等に含まれる契約者の個人情報を個人情報保護方針に従って取り扱うことに予め承諾するものとします。
 15.7. 当社は、契約者が G.U.ブロックチェーンネットワーク上に送信したデータについて、契約者に無断で取り扱うことはございません。

16. 反社会的勢力の排除
 16.1. 当社は、反社会的勢力等による本サービスの利用を禁止します。当社は、契約者がこれらの者に該当すると判断した場合、事前に契約者に通知することなく、本サービスの提供を停止し、又はサービス利用契約の解除をすることができるものとします。当社は、本サービスの提供停止又はサービス利用契約の解除によって契約者に生じた損害や不利益について、一切の責任を負いません。

17. 損害賠償
 17.1. 契約者は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその全ての損害（弁護士等専門家費用及び当社人件費相当額を含みます）を賠償しなければなりません。
 17.2. 契約者による本サービスの利用に関連して、当社が、他の契約者又はユーザーその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合、当該契約者は、当該請求に基づき当社が当該第三者に支払いを余儀なくされた金額及び当該請求に係る紛争等の解決のために当社が負担した金額（弁護士等専門家費用及び当社人件費相当額を含みます）を賠償しなければなりません。

18. 保証の否認及び免責
 18.1. 当社は、本サービス、本サービスを通じて提供されるコンテンツその他本サービスにより契約者が取得し得る一切の便益が、契約者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、契約者による本サービスの利用が契約者に適用のある法令又は業界団体の内部規則等に適合すること、不具合が生じないこと、本サービスの利用に関する問題を解決すること、本サービスを通じて提供されるコンテンツが適法に利用可能であること、当社以外が提供するサービス等の利用規約等を遵守していること及び第三者の権利を侵害しないこと等について、何ら保証するものではありません。
 18.2. G.U.ブロックチェーンネットワークは、ブロックチェーンとしての特性上、記録された情報をさかのぼって削除できない場合があります。また、ブロックチェーンの運用の停止や削除に際しても一定の制約がございます。契約者は、本サービスのかかる特性を理解した上で、本サービスを利用するものとし、かかる特性に起因して発生した損害について、当社は何ら責任を負うものではありません。また、契約者が運用するブロックチェーンの更新に必要な秘密鍵情報等は、契約者自身の責任で管理するものとし、当該管理に関して、当社は何ら責任を負うものではありません。
 18.3. 契約者が、G.U.ブロックチェーンネットワークのエンドポイント（URL）を第三者に共有する方法により、第三者に対して何らかのサービス提供を行う場合、当社は、当該サービス提供に関して、何ら責任を負うものではありません。当該サービス提供に関して、第三者との間で紛争が発生した場合には、契約者が自らの費用負担と責任において対応するものとし、当社に対して、何らの迷惑もかけないものとします。
 18.4. 本サービスは、サーバーとして AWS を利用しています。契約者は、AWS のシステム上の不具合等によって発生した一切の損害に関して、当社が何らその責任を負うものではないことを確認します。
 18.5. 当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、契約者が本サービスに送信したデータ等の削除又は消失、契約者の登録の抹消、本サービスの利用によるデータ等の消失又は機器の故障若しくは損傷、その他本サービスに関して契約者が被った損害につき、当社に故意又は重過失がある場合を除き、賠償する責任を一切負わないものとします。
 18.6. 当社は、契約者間の通信や活動に関与しません。万一契約者間で紛争や問題が生じた場合には、当該契約者間の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。
 18.7. 契約者と第三者との間で紛争が生じた場合には、契約者は自身の責任と費用でこれを解決するものとし、当社はこれに一切関与しません。
 18.8. 当社ウェブサイト等から他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイト等へのリンクが提供されている場合でも、当社は、当社ウェブサイト等以外のウェブサイト及びそこから得られる情報に関して一切の責任を負わないものとします。
 18.9. 当社は、本サービスに関連して契約者が被った損害について、当社に故意又は重過失があったときを除き、一切賠償の責任を負いません。なお、当社に故意又は重過失があった場合、及び消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が契約者に対して損害賠償責任を負う場合においても、当社の賠償責任の範囲は、当社の責に帰すべき事由により現実に発生した直接かつ通常の損害に限られるものとし、かつ、損害の事由が生じた時点から遡って過去 1 年間に当該契約者から現実に受領した本サービスの利用料金の総額を上限とします。

19. サービス利用契約の有効期間
 19.1. サービス利用契約は、契約者について利用登録が完了し、当社が契約者に対して、本サービスの提供を開始した日から当該契約者が本サービスを解約した日、又は当該契約者のアカウントが削除された日のいずれか早い日までの間、当社と契約者との間で有効に存続するものとします。

20. 規約改定
 20.1. 当社は、以下の各号のいずれかに該当する場合、契約者の承諾を得ることなく、本規約を変更できるものとします。
  a. 変更内容がサービス名や表現の変更又は誤字、脱字の修正等であり、本規約の内容に実質的に影響しない場合
  b. 変更内容が契約者の一般の利益に適合する場合
  c. 変更内容が契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他変更に係る事情に照らして合理的なものである場合
 20.2. 当社は、20.1 項b 号及び c 号による変更の場合、本規約変更の効力発生の相当期間前までに、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を当社ウェブサイト等への掲載その他当社が適当と判断する方法により通知します。なお、20.1 項 a 号による変更の場合、変更後の本規約の内容を当社ウェブサイト等への掲載その他当社が適当と判断する方法により通知した時点で変更後の本規約の効力が発生するものとします。

21. 連絡・通知
 21.1. 本サービスに関する問い合わせその他契約者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から契約者に対する連絡又は通知は、当社の定める方法で行うものとします。なお、当社が、契約者に対する連絡又は通知を電子メール送信又は当社ウェブサイト等での掲載により行う場合、当該電子メールが当社から発信された時点又は当社ウェブサイト等に掲載された時点で、当該連絡又は通知が行われたものとみなします。

22. 本規約上の地位の譲渡等
 22.1. 契約者は、当社の書面による事前の承諾なく、サービス利用契約上の地位又は本規約に基づく権利義務の全部又は一部を、第三者に対する譲渡、承継（合併、会社分割等による包括承継を含みます。）し又は担保の目的に供することはできません。
 22.2. 当社が本サービスにかかる事業を他者に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約に基づく権利及び義務並びに契約者の登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡できるものとし、契約者は、かかる譲渡につき予め同意したものとみなします。

23. 分離可能性
 23.1. 本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有します。当社及び契約者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために本規約を必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。

24. 存続条項
 24.1. 10 条（サービス利用契約の解約）、11 条（サービス利用停止又はアカウント削除）、13 条（権利の帰属）、15 条（情報の利用等）、16 条（反社会的勢力の排除）乃至 18 条（保証の否認及び免責）、及び 22 条（本規約上の地位の譲渡等）乃至 26 条（協議解決）については、当社と契約者との間のサービス利用契約が終了した場合でも、その終了原因の如何を問わず、なお効力を有するものとします。

25. 準拠法及び合意管轄
 25.1. 本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。

26. 協議解決
 26.1. 当社及び契約者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。

以上`;

/**
 *
 * Locale master module
 *
 */

// default language
export const LOCALE_DEFAULT_LANG = 'en';

// locale master object
// *** see "https://www.npmjs.com/package/i18n-iso-countries" and must to use supported language ***
export const LOCALE_SUPPORTED: {
  lang: string;
  code: string;
  name: string;
}[] = [
  {
    lang: 'en',
    code: 'en-US',
    name: 'English',
  },
  {
    lang: 'ja',
    code: 'ja-JP',
    name: 'Japanese',
  },
];

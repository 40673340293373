import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import ImgIcon from './img-icon';
import classNames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IProps extends WithTranslation, WithStyles<typeof styles> {
  authorize: boolean;
  className?: string;
}

const NodeTypeStatus = (props: IProps) => {
  const { classes, authorize, className, t } = props;

  return (
    <div className={classNames(classes.root, className)}>
      {authorize ? (
        <>
          <span>{t('relay_node')}</span>
          <ImgIcon className={classes.doubleArrowIcon} imgUrl="/images/icons/double-arrow.png" />
          <span>{t('validator_node')}</span>
        </>
      ) : (
        <>
          <span>{t('validator_node')}</span>
          <ImgIcon className={classes.doubleArrowIcon} imgUrl="/images/icons/double-arrow.png" />
          <span>{t('relay_node')}</span>
        </>
      )}
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    doubleArrowIcon: {
      paddingLeft: 5,
      alignItem: 'center',
      paddingRight: 5,
      fontSize: 12,
      objectFit: 'contain',
    },
  });

export default withStyles(styles)(withTranslation()(NodeTypeStatus));

import { VALIDATE_HEX_ADDR_PATTERN } from '~/constants/validation';

/**
 * Validate hex address format
 *
 * @param address string Hex address string
 * @returns  boolean
 */
export function validateHexAddress(address: string): boolean {
  if (address.length < 3 || address.indexOf('0x') !== 0) {
    return false;
  }
  const checkValue = address.substring(2);
  return VALIDATE_HEX_ADDR_PATTERN.test(checkValue);
}

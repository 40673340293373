import * as Gtypes from '~/gapi/gtypes';

export const accountQuery = `{
  accountUuid
  networkUuids
  name
  contactEmail
  plan
  lang
  addr
  isRegisteredPaymentAddr
  paymentAddr {
    country
    postalCode
    state
    city
    address1
    address2
  }
  status
  role
  contact {
    accountantEmail
    systemAdminEmail
  }
}`;

const profileQuery = `{
  uid
  email
  displayName
  preference {
    language
    timezone
    lastSelectedAccountUuid
    lastSelectedNetworkUuid {
      accountUuid
      networkUuid
    }
    lastNotificationBarShownDatetime
  }
  status {
    acceptTermsOfService
  }
  photoURL
  provider
  emailVerified
  accounts ${accountQuery}
  createAt
  updateAt
}`;

const accountProfileQuery = `{
  uid
  email
  displayName
  preference {
    language
    timezone
    lastNotificationBarShownDatetime
  }
  status {
    acceptTermsOfService
  }
  photoURL
  provider
  emailVerified
  accounts ${accountQuery}
  createAt
  updateAt
}`;

export const getProfileQuery = `query {
  getProfile ${profileQuery}
}`;

export type GET_PROFILE_RESULT_TYPE = {
  getProfile: Gtypes.Query['getProfile'];
};

export type MutationUpdateAccountArgs = Gtypes.MutationUpdateAccountArgs;

export const updateAccountMutation = `mutation ($input: EditAccountInput!) {
  updateAccount(input: $input) ${accountQuery}
}`;

export type UPDATE_ACCOUNT_RESULT_TYPE = {
  updateAccount: Gtypes.Account;
};

export const selectNetworkMutation = `mutation ($input: UpdateProfileInput!) {
  selectNetwork(input: $input) ${profileQuery}
}`;

export type MutationSelectNetworkArgs = Gtypes.MutationSelectNetworkArgs;

export type UPDATE_SELECT_NETWORK_RESULT_TYPE = {
  selectNetwork: Gtypes.Profile;
};

export const updateProfileMutation = `mutation ($input: UpdateAccountProfileInput!) {
  updateProfile(input: $input) ${accountProfileQuery}
}`;

export type MutationUpdateProfileArgs = Gtypes.MutationUpdateProfileArgs;

export type UPDATE_PROFILE_RESULT_TYPE = {
  updateProfile: Gtypes.Profile;
};

export const memberQuery = `{
  uid
  displayName
  email
  role
  isPrimaryOwner
  hasProfile
  createAt
  updateAt
}`;

export const getMemberQuery = `query ($accountUuid: String!, $email: String!) {
  getMember(accountUuid: $accountUuid, email: $email) ${memberQuery}
}`;

export type QueryGetMemberArgs = Gtypes.QueryGetMemberArgs;

export type GET_MEMBER_RESULT_TYPE = {
  getMember: Gtypes.Member;
};

export const listMembersQuery = `query ($accountUuid: String!) {
  listMembers(accountUuid: $accountUuid) ${memberQuery}
}`;

export type QueryToListMembersArgs = Gtypes.QueryListMembersArgs;

export type LIST_MEMBERS_RESULT_TYPE = {
  listMembers: Gtypes.Member[];
};

export const addMemberMutation = `mutation ($input: AddMemberInput!) {
  addMember(input: $input) ${memberQuery}
}`;

export type MutationAddMemberArgs = Gtypes.MutationAddMemberArgs;

export type ADD_MEMBER_RESULT_TYPE = {
  addMember: Gtypes.Member;
};

export const editMemberMutation = `mutation ($input: EditMemberInput!) {
  updateMember(input: $input) ${memberQuery}
}`;

export type MutationUpdateMemberArgs = Gtypes.MutationUpdateMemberArgs;

export type EDIT_MEMBER_RESULT_TYPE = {
  updateMember: Gtypes.Member;
};

export const removeMemberMutation = `mutation ($input: RemoveMemberInput!) {
  removeMember(input: $input)
}`;

export type MutationRemoveMemberArgs = Gtypes.MutationRemoveMemberArgs;

export type REMOVE_MEMBER_RESULT_TYPE = {
  removeMember: boolean;
};

export const changePrimaryOwnerMutation = `mutation ($input: ChangePrimaryOwnerInput!) {
  changePrimaryOwner(input: $input) ${memberQuery}
}`;

export type MutationChangePrimaryOwnerArgs = Gtypes.MutationChangePrimaryOwnerArgs;

export type CHANGE_PRIMARY_OWNER_RESULT_TYPE = {
  changePrimaryOwner: Gtypes.Member[];
};

export const createAccountMutation = `mutation ($input: CreateAccountInput!) {
  createAccount(input: $input) ${accountQuery}
}`;

export type MutationCreateAccountArgs = Gtypes.MutationCreateAccountArgs;

export type CREATE_ACCOUNT_RESULT_TYPE = {
  createAccount: Gtypes.Account;
};

export const removeAccountMutation = `mutation ($accountUuid: String!, $dryRun: Boolean) {
  removeAccount(accountUuid: $accountUuid, dryRun: $dryRun) {
    status
    dryRun
    errors
  }
}`;

export type MutationRemoveAccountArgs = Gtypes.MutationRemoveAccountArgs;

export type REMOVE_ACCOUNT_RESULT_TYPE = {
  removeAccount: Gtypes.AccountResultStatus;
};

export type QueryGetAccountArgs = Gtypes.QueryGetAccountArgs;

export const getAccountQuery = `query ($accountUuid: String!) {
  getAccount(accountUuid: $accountUuid) ${accountQuery}
}`;
export type GET_ACCOUNT_RESULT_TYPE = {
  getAccount: Gtypes.Account;
};

export type QueryGetAccountNameArgs = Gtypes.QueryGetAccountNameArgs;

export const getAccountNameQuery = `query ($accountUuid: String!) {
  getAccountName(accountUuid: $accountUuid)
}`;
export type GET_ACCOUNT_NAME_RESULT_TYPE = {
  getAccountName: string;
};

export type MutationRegisterAddressArgs = Gtypes.MutationRegisterAddressArgs;

export const registerAddressMutation = `mutation ($accountUuid: String!, $address: RegisterAddressInput!) {
  registerAddress(accountUuid: $accountUuid, address: $address) ${accountQuery}
}`;

export type REGISTER_ADDRESS_RESULT_TYPE = {
  registerAddress: Gtypes.Account;
};

export type MutationUpdateContactArgs = Gtypes.MutationUpdateContactArgs;

export const updateContactMutation = `mutation ($accountUuid: String!, $contactEmail: String!, $contact: UpdateContactInput!) {
  updateContact(accountUuid: $accountUuid, contactEmail: $contactEmail, contact: $contact) ${accountQuery}
}`;

export type UPDATE_CONTACT_RESULT_TYPE = {
  updateContact: Gtypes.Account;
};

export type MutationAcceptTermsOfServiceArgs = Gtypes.MutationAcceptTermsOfServiceArgs;

export const acceptTermsOfServiceMutaion = `mutation ($input: AcceptTermsOfServiceInput!) {
  acceptTermsOfService(input: $input) ${profileQuery}
}`;

export type ACCEPT_TERMS_OF_SERVICE_RESULT_TYPE = {
  acceptTermsOfService: Gtypes.Profile;
};

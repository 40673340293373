import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import SubmitButton from './submit-button';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getLatestUpdateInNetwork } from '~/utilities/utils';
import CustomNumericString from './custom-numeric-string';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  enableRebuildNode?: boolean;
};

const UpdateExpHardForkDialog = (props: IProps) => {
  const { classes, open, network, cluster, onClose } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.updateNodeVersion.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    enableRebuildNode: false,
  };

  const onCheckboxChange = useCallback((value, form: FormikProps<FormValues>) => {
    form.setFieldValue('enableRebuildNode', value);
    setChecked(value);
  }, []);

  const enableRebuildNodeCheckbox = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div>
          <FormControlLabel
            {...field}
            checked={checked}
            className={classes.formControlLabel}
            control={
              <Checkbox
                data-testid="enable-rebuild-node-checkbox"
                color="default"
                onChange={(e, value) => onCheckboxChange(value, form)}
              />
            }
            label={<span className={classes.formLabel}>{t('enable_rebuild_node')}</span>}
          />
        </div>
      );
    },
    [checked, classes.formControlLabel, classes.formLabel, onCheckboxChange, t],
  );

  const renderWarningText = useMemo(() => {
    if (checked) {
      return (
        <div id="member-node-rebuild-attention" className={classes.warningText}>
          {t('are_you_sure_you_want_to_rebuild_this_node')}
        </div>
      );
    }
    return <></>;
  }, [checked, classes.warningText, t]);

  const handleCloseDialog = useCallback(() => {
    setChecked(false);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { enableRebuildNode } = values;

      try {
        if (accountSeleted?.accountUuid) {
          await dispatch(
            NetworkActions.applyHardForkToBlockExplorer({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid: network.networkUuid,
                clusterUuid: cluster.clusterUuid,
                enableRebuildNode,
              },
            }),
          );
          handleCloseDialog();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [accountSeleted, cluster, network, dispatch, handleCloseDialog],
  );

  const latestUpdate = useMemo(() => {
    if (network) {
      return getLatestUpdateInNetwork(network);
    }
  }, [network]);

  return (
    <>
      <CustomDialog open={open} onClose={handleCloseDialog}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-apply-hardfork-title">{t('apply_latest_genesis_config')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div id="member-node-del-detail" className={classes.content}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('cluster_name')}</div>
                      <div className={classes.itemValue}>{cluster.clusterName}</div>
                    </Grid>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('node_version')}</div>
                      <div className={classes.itemValue}>{cluster.explorer?.nodeInfo?.version}</div>
                    </Grid>
                    {latestUpdate && (
                      <Grid item md={6}>
                        <div className={classes.itemLabel}>{t('latest_hard_fork')}</div>
                        <div className={classes.itemValue}>
                          {latestUpdate.label}
                          {': '}
                          <CustomNumericString value={latestUpdate.blockNumber.toString()} />
                        </div>
                      </Grid>
                    )}
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('latest_block')}</div>
                      <div className={classes.itemValue}>
                        <CustomNumericString
                          value={(cluster.explorer?.nodeInfo?.latestBlock || 0).toString()}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <Field name="enableRebuildNode" render={enableRebuildNodeCheckbox} />
                </div>
                {renderWarningText}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-apply-hardfork-cancel"
                  disabled={isSubmitting || isNodeUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-apply-hardfork-submit"
                  isValid={true}
                  isSubmitting={isSubmitting || isNodeUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(UpdateExpHardForkDialog);

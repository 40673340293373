import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import ImgIcon from './img-icon';

interface IProps {
  className?: string;
  onClick?: any;
  id?: string;
}

const optionButton: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  onClick,
  id,
  ...rest
}) => {
  return (
    <IconButton className={classNames(classes.root, className)} onClick={onClick} id={id} {...rest}>
      <ImgIcon className={classes.optionIcon} imgUrl="/images/icons/options-ico.png" />
    </IconButton>
  );
};

const styles: StyleRules = {
  root: {},
  optionIcon: {
    width: 14,
    height: 14,
  },
};

export default withStyles(styles)(optionButton);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
import ListStaticIpAddressDialog from '../../../../../common/list-associated-ip-address-dialog';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
  denimColor,
  matterhornColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { EstimateSummary, NodeSizeType } from '~/gapi/gtypes';
import RegisterAddressDialog from '~/components/common/register-address-dialog';
import ConfirmLicenseDialog from '~/components/common/confirm-license-dialog';
import Grid from '@mui/material/Grid';
import { MAXIMUM_STATIC_IP_PER_REGION } from '~/constants/consts';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {};

const MigrateBootnodeDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, onClose } = props;
  const [openMaximumStaticIpDialog, setOpenMaximumStaticIpDialog] = useState(false);
  const [openConfirmLicenseDialog, setOpenConfirmLicenseDialog] = useState(false);
  const [openRegisterAddressDialog, setOpenRegisterAddressDialog] = useState(false);
  const [requireCard, setRequireCard] = useState(false);
  const [estimateDate, setEstimateDate] = useState('');
  const [formik, setFormik] = useState<FormikActions<FormValues>>();
  const [estimateFee, setEstimateFee] = useState<EstimateSummary>({
    estimate: [
      {
        totalPrice: 0,
        taxFee: 0,
        taxRate: 0,
        licenses: [],
        nextMonth: {
          totalPrice: 0,
          taxFee: 0,
          taxRate: 0,
          licenses: [],
        },
      },
    ],
    coupon: {
      usable: 0,
    },
  });

  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const networks = useSelector((store: IStore) => store.appState.networks || []);
  const isUpdating = useSelector((store: IStore) =>
    NetworkActions.migrateBootnode.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requireAddr = useMemo(() => {
    return !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
      ? true
      : false;
  }, [accountSeleted]);

  const onCloseRegisterAddressDialog = useCallback(
    (status: boolean = false) => {
      setOpenRegisterAddressDialog(false);
      setFormik(undefined);

      if (formik) {
        if (status) {
          // if registration successed, then start to buy licenses (auto submit)
          formik.submitForm();
        } else {
          formik.setSubmitting(false);
        }
      }
    },
    [formik],
  );

  const onOpenRegisterAddressDialog = useCallback(() => {
    setOpenRegisterAddressDialog(false);
  }, []);

  const onCloseConfirmLicenseDialog = (status: number = 0) => {
    setOpenConfirmLicenseDialog(false);
    setFormik(undefined);

    if (requireCard && status > 0) {
      setRequireCard(false);
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        formik.submitForm();

        if (accountSeleted) {
          dispatch(PaymentActions.listBillings({ accountUuid: accountSeleted.accountUuid }));
          dispatch(PaymentActions.listCoupons({ accountUuid: accountSeleted.accountUuid }));
          dispatch(
            PaymentActions.listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }),
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  const onOpenConfirmLicenseDialog = () => {
    setOpenConfirmLicenseDialog(true);
  };

  const allNodes = useMemo(() => {
    return networks
      .map((network) =>
        network.clusters
          .filter(
            (cl) => cl.region === cluster.region && cl.accountUuid === accountSeleted?.accountUuid,
          )
          .map((cluster) => ({ network, cluster })),
      )
      .reduce((pre, cur) => pre.concat(cur), [])
      .map((item) =>
        item.cluster.nodes
          .filter((node) => node.serverInfo.staticIpAddressId)
          .map((node) => ({ network: item.network, cluster: item.cluster, node })),
      )
      .reduce((pre, cur) => pre.concat(cur), []);
  }, [accountSeleted?.accountUuid, cluster.region, networks]);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;

      // Check selected account existence
      if (!accountSeleted) {
        return;
      }

      if (!node.serverInfo.staticIpAddressId && allNodes.length >= MAXIMUM_STATIC_IP_PER_REGION) {
        setOpenMaximumStaticIpDialog(true);
        setSubmitting(false);
        return;
      }

      // check registration of address
      if (!accountSeleted.isRegisteredPaymentAddr) {
        setFormik(formikActions);
        onOpenRegisterAddressDialog();
        return;
      }

      try {
        const result = await dispatch(
          NetworkActions.migrateBootnode({
            input: {
              accountUuid: accountSeleted.accountUuid,
              networkUuid: network.networkUuid,
              clusterUuid: cluster.clusterUuid,
              nodeUuid: node.nodeUuid,
            },
          }),
        );
        const { status, execAt } = result.migrateBootnode;
        if (status.includes('success')) {
          setSubmitting(false);
          onClose();
        } else if (status.includes('needCharge')) {
          // update state
          setRequireCard(status.includes('needRegister') ? true : false);
          setFormik(formikActions);

          const needs = await dispatch(
            PaymentActions.listInsufficientStaticIpAddressLicenses({
              accountUuid: accountSeleted.accountUuid,
              ipAddressNum: 1,
            }),
          );

          const params = {
            purchaseDate: execAt,
            licenses: needs.listInsufficientStaticIpAddressLicenses.map((l) => ({
              licenseItemId: l.licenseItemId,
              qty: l.qty,
            })),
          };
          const fee = await dispatch(
            PaymentActions.estimateLicenseFee({
              accountUuid: accountSeleted.accountUuid,
              input: [params],
            }),
          );
          setEstimateDate(execAt);

          if (fee.estimateLicenseFee.estimate.length > 0) {
            setEstimateFee(fee.estimateLicenseFee);
          }

          onOpenConfirmLicenseDialog();
        } else {
          setSubmitting(false);
          onClose(); // unknown status
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [
      accountSeleted,
      allNodes.length,
      onOpenRegisterAddressDialog,
      dispatch,
      network.networkUuid,
      cluster.clusterUuid,
      node,
      onClose,
    ],
  );

  const onCloseMaximumStaticIpDialog = useCallback(() => {
    setOpenMaximumStaticIpDialog(false);
  }, []);

  return (
    <>
      <ListStaticIpAddressDialog
        open={openMaximumStaticIpDialog}
        onClose={onCloseMaximumStaticIpDialog}
        allNodes={allNodes}
      />
      <RegisterAddressDialog
        open={openRegisterAddressDialog}
        onClose={onCloseRegisterAddressDialog}
      ></RegisterAddressDialog>
      <ConfirmLicenseDialog
        open={openConfirmLicenseDialog}
        onClose={onCloseConfirmLicenseDialog}
        estimateDate={estimateDate}
        estimateFee={estimateFee}
        requireAddr={requireAddr}
        requireCard={requireCard}
      ></ConfirmLicenseDialog>
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-migrate-bootnode-title">{t('migrate_to_bootnode')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div id="member-node-del-detail" className={classes.content}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('node_name')}</div>
                      <div className={classes.itemValue}>{node.nodeName}</div>
                    </Grid>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('node_id')}</div>
                      <div className={classes.itemValue}>{node.nodeUuid}</div>
                    </Grid>
                  </Grid>
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-migrate-bootnode-cancel"
                  disabled={isUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-migrate-bootnode-submit"
                  isValid={true}
                  isSubmitting={isSubmitting || isUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  leftContentItem: {
    padding: 0,
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  staticIp: {
    ...defaultFont,
    fontSize: 13,
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    whiteSpace: 'nowrap',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 15,
    // marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  nodeItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 34,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: whiteSmokeColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  nodeStatusIcon: {
    marginRight: 10,
  },
  nodeName: {
    ...defaultFont,
    fontSize: 14,
    color: denimColor,
    width: '70%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },
  nodeNameText: {
    width: '50%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  nodeLinkText: {
    color: denimColor,
  },
  tableBodyTdState: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(MigrateBootnodeDialog);

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Component
import ConsortiumSection from './consortium-section';
import NetworkInfoSection from '../overview-tab/network-info-section';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';

interface IStateProps {}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

interface IState {}

class ConsortiumTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, t, network, account } = this.props;

    document.title = TAB_TITLE_CONCAT + this.props.t('consortium');

    return (
      <div className={classes.root}>
        <NetworkInfoSection network={network} account={account} />
        <ConsortiumSection network={network} account={account} />
      </div>
    );
  }
}

const styles = createStyles({
  root: {
    marginTop: 20,
    paddingBottom: 50,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsortiumTab)),
);

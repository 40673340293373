import {
  INodeStatus,
  INodeDisplay,
  INode,
  ICluster,
  IExplorer,
  RestrictionProtocolType,
  INetworkProvider,
} from '~/types/network-types';
import { displayCurrency } from './payment-utils';
import { TFunction } from 'i18next';
import { parseBCP47String } from '~/utilities/utils';

export const displayNodeStatus = (node: INode) => {
  const { status } = node.serverInfo;
  const stateStrMap: { [key in INodeStatus]: INodeDisplay } = {
    alive: 'running',
    pending: 'pending',
    removing: 'removing',
    dead: 'stopped',
    failure: 'error',
  };
  let result: INodeDisplay = 'unknown';

  // first, chech server state
  switch (status) {
    case 'pending':
    case 'dead':
    case 'failure':
    case 'removing':
      result = stateStrMap[status];
      break;
    case 'alive':
      // next, check node state
      const { status: nodeStatus } = node.nodeInfo;

      if (nodeStatus === 'alive') {
        const disk = displayServerDiskUsage(node);
        result = disk < 95 ? stateStrMap[nodeStatus] : 'x_warn_disk';
        if (node.signerInfo?.externalClef?.status === 'failure') {
          result = 'x_warn_not_connected_to_external_clef';
        }
        if (node.signerInfo?.internalClef?.status === 'pending') {
          result = 'x_internal_clef_is_pending';
        }
        if (node.signerInfo?.internalClef?.status === 'failure') {
          result = 'x_warn_not_connected_to_internal_clef';
        }
      } else if (stateStrMap[nodeStatus]) {
        result = stateStrMap[nodeStatus];
      }
      break;
    default:
      break;
  }
  return result;
};

export const displayNodeStatusDetail = (node: INode, trans: TFunction, force: boolean = false) => {
  const target = node.serverInfo.status === 'alive' ? 'node' : 'server';
  const status = displayNodeStatus(node);

  let result = '';

  if (status.substr(0, 2) === 'x_') {
    // custom status
    result = trans(status);
  } else if (status !== 'running') {
    result = `${target} ${status}`;
  } else if (status === 'running' && force) {
    result = `${target} ${status}`;
  }
  return result;
};

export const renderClusterRegionLabel = (
  providers: INetworkProvider[],
  cluster: ICluster,
): string => {
  let label = cluster.region ?? '';
  const provider = providers.find((provider) => provider.value === cluster.provider);
  if (provider) {
    const region = provider.region.find((region) => region.value === cluster.region);
    if (region) {
      label = region.label;
    }
  }
  return label;
};

export const displayClusterStatus = (cluster: ICluster) => {
  const stateStrMap: { [key in INodeStatus]: INodeDisplay } = {
    alive: 'running',
    pending: 'pending',
    removing: 'removing',
    dead: 'stopped',
    failure: 'error',
  };
  let result: INodeDisplay = 'unknown';

  // chech cluster state
  switch (cluster.status) {
    case 'pending':
    case 'dead':
    case 'failure':
    case 'removing':
    case 'alive':
      result = stateStrMap[cluster.status];
      break;
    default:
      break;
  }
  return result;
};

export const displayBlockExplorerStatus = (cluster: ICluster) => {
  const stateStrMap: { [key in INodeStatus]: INodeDisplay } = {
    alive: 'running',
    pending: 'pending',
    removing: 'removing',
    dead: 'stopped',
    failure: 'error',
  };

  let result: INodeDisplay = 'unknown';

  if (!cluster.explorer) {
    return result;
  }
  const { status } = cluster.explorer.serverInfo;

  // chech blockscout state
  switch (status) {
    case 'pending':
    case 'dead':
    case 'failure':
    case 'removing':
      result = stateStrMap[status];
      break;
    case 'alive':
      const disk = displayServerDiskUsage(cluster.explorer);
      result = disk < 95 ? stateStrMap[status] : 'x_warn_disk';
      break;
    default:
      break;
  }
  return result;
};

export const displayBlockExplorerStatusDetail = (
  cluster: ICluster,
  trans: TFunction,
  force: boolean = false,
) => {
  const target = 'server';
  const status = displayBlockExplorerStatus(cluster);

  let result = '';

  if (status.substr(0, 2) === 'x_') {
    // custom status
    result = trans(status);
  } else if (status !== 'running') {
    result = `${target} ${status}`;
  } else if (status === 'running' && force) {
    result = `${target} ${status}`;
  }
  return result;
};

export const sortNodesByName = (pre: INode, cur: INode) => {
  let order: number = 0;
  if (pre.nodeName < cur.nodeName) {
    order = -1;
  } else if (pre.nodeName > cur.nodeName) {
    order = 1;
  }
  return order;
};

const clusterProviderNames: { [key: string]: string } = {
  amazon: 'Amazon Web Services',
};

export const displayClusterProviderName = (text: string) => {
  return clusterProviderNames[text] || 'Unknown';
};

export const displayPortRange = (
  protocol: RestrictionProtocolType,
  isEndpoint: boolean = false,
) => {
  let range = '';
  let proto = 'TCP';

  switch (protocol) {
    case 'HTTP':
      range = !isEndpoint ? '80' : '443';
      break;
    case 'RPC':
      range = '8545 - 8546';
      break;
    case 'P2P':
      range = '30303';
      proto = 'TCP / UDP';
      break;
  }
  return {
    protocol: proto,
    range: range,
  };
};

export const displayNumberSign = (target: number) => {
  if (target === 0) {
    return '';
  }
  return target > 0 ? '+' : '-';
};

export const displayServerDiskUsage = (node: INode | IExplorer) => {
  const { volumeSize, volumeUsed } = node.serverInfo;
  let diskUsedRate = 0;

  if (volumeSize && volumeSize > 0 && volumeUsed && volumeUsed > 0) {
    diskUsedRate = Math.floor((volumeUsed * 100) / volumeSize);
  }
  return diskUsedRate;
};

export const renderAccountBalance = (balance) =>
  balance === 0 ? '0' : `${balance < 0 ? '-' : ''}$${displayCurrency(Math.abs(balance))}`;

export const renderDocumentLink = (language: string) =>
  parseBCP47String(language).language === 'ja'
    ? 'https://docs.bccloud.net/ja'
    : 'https://docs.bccloud.net';

import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import * as PaymentAction from '~/stores/actions/payment-action';
import { Theme } from '@mui/material/styles';
// Component
import LoadingIcon from '~/components/common/loading-icon';
import CardSection from './card-section';
import CouponSection from './coupon-section';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

interface IStateProps {
  listCardsLoading: boolean;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {}

class PaymentTab extends React.Component<IProps & WithStyles<typeof styles>, IState> {
  public render() {
    const { classes, listCardsLoading } = this.props;

    if (listCardsLoading) {
      return (
        <div className={classes.root}>
          <div className={classes.loadingArea}>
            <LoadingIcon />
          </div>
        </div>
      );
    }

    document.title = TAB_TITLE_CONCAT + this.props.t('payment_way');

    return (
      <div className={classes.root}>
        <CardSection />
        <CouponSection />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    loadingArea: {
      marginTop: 100,
      textAlign: 'center',
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      root: {
        paddingBottom: 15,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  listCardsLoading: PaymentAction.listCards.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('member')(PaymentTab)),
);

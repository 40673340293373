import React from 'react';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import NumberFormat from 'react-number-format';

interface IProps {
  value?: string;
}

const customNumericString: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  value,
}) => {
  return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />;
};

const styles: StyleRules = {
  root: {},
};

export default withStyles(styles)(customNumericString);

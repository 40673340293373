import moment from 'moment-timezone';

// Billings
export const getBillingsStartDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).subtract(1, 'year').startOf('months').toISOString(); // start of this month last year
export const getBillingsEndDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).endOf('months').toISOString(); // end of this month

// Coupons
export const getCouponsStartDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).subtract(1, 'year').startOf('months').toISOString(); // start of this month last year
export const getCouponsEndDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).endOf('months').toISOString(); // end of this month

// Notifications
export const getNotificationsStartDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).subtract(1, 'year').startOf('day').toISOString(); // start of this day last year
export const getNotificationsEndDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).endOf('days').toISOString(); // end of today
export const NOTIFICATIONS_PAGE_INDEX_DEFAULT = 0;
export const NOTIFICATIONS_PAGE_SIZE_DEFAULT = 20;

// Proposals
export const PROPOSALS_PAGE_INDEX_DEFAULT = 0;
export const PROPOSALS_PAGE_SIZE_DEFAULT = 10;

// Invitations
export const INVITATIONS_PAGE_INDEX_DEFAULT = 0;
export const INVITATIONS_PAGE_SIZE_DEFAULT = 10;

import React from 'react';
import * as semver from 'semver';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import OptionButton from '~/components/common/option-button';
import EditExplorerVolumeDialog from '~/components/common/edit-explorer-volume-dialog';
import DeleteBlockExplorerDialog from '~/components/common/delete-block-explorer-dialog';
import ImgIcon from '~/components/common/img-icon';
import SourceIcon from '@mui/icons-material/Source';
import CircularProgress from '@mui/material/CircularProgress';
import HelpIcon from '@mui/icons-material/Help';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  snowColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
// Type
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { INetwork, ICluster, INodeControlType, INetworkProvider } from '~/types/network-types';
import { QueryGetGenesisJsonInExplorerArgs } from '~/gapi/gtypes';
// Util
import { displayClusterProviderName, renderClusterRegionLabel } from '~/utilities/render-utils';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { isAccountOwnCluster, isConsortiumAdmin } from '~/utilities/utils';
import { MASK_ASTERISK } from '~/constants/consts';
import CopyClipboardIcon from '~/components/common/copy-clipboard-icon';
import EditButton from '~/components/common/edit-button';
import UpdateBlockExpDialog from '~/components/common/update-block-exp-dialog';
import RebuildBlockExpDialog from '~/components/common/rebuild-block-exp-dialog';
import UpdateNodeExplorerVersionDialog from '~/components/common/update-node-exp-version-dialog';
import UpdateExplorerInstanceDialog from '~/components/common/update-explorer-instance-dialog';
import UpdateExpHardForkDialog from '~/components/common/update-explorer-latest-hard-fork-dialog';
import GenesisJsonViewDialog from '~/components/common//genesis-json-dialog';
import UpgradeExplorerVersionDialog from './upgrade-exp-version-dialog';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

interface IStateProps {
  providers: INetworkProvider[];
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
  isLoadingGetGenesis: boolean;
}

interface IDispProps {
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
  controleExplorer: (
    args: NetworkActions.MutationControlBlockExplorerArgs,
  ) => Promise<NetworkActions.CONTROL_BLOCK_EXPLORER_RESULT_TYPE>;
  getGenesisJsonInExplorer: (
    args: QueryGetGenesisJsonInExplorerArgs,
  ) => Promise<NetworkActions.GET_GENESIS_JSON_IN_EXPLORER_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  cluster: ICluster;
  network: INetwork;
  account: Account;
}

interface IState {
  explorerOptionAnchorEl?: any;
  openExpandExplorerVolumeDialog: boolean;
  openDeleteBlockExplorerDialog: boolean;
  openUpdateExplorerDialog: boolean;
  openRebuildExplorerDialog: boolean;
  openUpdateNodeExpVersionDialog: boolean;
  openUpdateInstanceTypeDialog: boolean;
  openUpdateExpHardForkDialog: boolean;
  openGenesisJsonDialog: boolean;
  openUpgradeBlockExplorerDialog: boolean;
  genesisJson?: string;
}

class OverviewTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openExpandExplorerVolumeDialog: false,
      openDeleteBlockExplorerDialog: false,
      openUpdateExplorerDialog: false,
      openRebuildExplorerDialog: false,
      openUpdateNodeExpVersionDialog: false,
      openUpdateInstanceTypeDialog: false,
      openUpdateExpHardForkDialog: false,
      openGenesisJsonDialog: false,
      openUpgradeBlockExplorerDialog: false,
    };
  }

  public render() {
    const {
      classes,
      cluster,
      network,
      account,
      providers,
      t,
      selectedAccountRole,
      selectedNetworkRole,
      isLoadingGetGenesis,
    } = this.props;
    const expandableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const deletableBlockExplorer =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const ableGetGenesisJson =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedAccountRole === 'member' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'member' ||
      selectedNetworkRole === 'operator';
    const ableUpgradeBlockExplorer =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const {
      explorerOptionAnchorEl,
      openExpandExplorerVolumeDialog,
      openDeleteBlockExplorerDialog,
      openUpdateExplorerDialog,
      openRebuildExplorerDialog,
      openUpdateNodeExpVersionDialog,
      openUpdateInstanceTypeDialog,
      openUpdateExpHardForkDialog,
      openGenesisJsonDialog,
      genesisJson,
      openUpgradeBlockExplorerDialog,
    } = this.state;
    const { az, ip, instanceType, volumeSize, endpointUrl } = cluster.explorer?.serverInfo ?? {};

    const formatter = new Intl.NumberFormat();

    const showSensitiveData =
      isConsortiumAdmin(account, network) || isAccountOwnCluster(account, cluster);

    return (
      <>
        <DeleteBlockExplorerDialog
          open={openDeleteBlockExplorerDialog}
          onClose={this.onCloseDeleteBlockExplorerDialog}
          network={network}
          cluster={cluster}
        />
        <EditExplorerVolumeDialog
          open={openExpandExplorerVolumeDialog}
          onClose={this.onCloseExpandExplorerVolumeDialog}
          networkUuid={network.networkUuid}
          cluster={cluster}
        />
        <UpdateBlockExpDialog
          open={openUpdateExplorerDialog}
          onClose={this.onCloseEditExplorerDialog}
          networkUuid={network.networkUuid}
          cluster={cluster}
        />
        <UpdateExpHardForkDialog
          open={openUpdateExpHardForkDialog}
          onClose={this.onCloseUpdateExplHardForkDialog}
          network={network}
          cluster={cluster}
        />
        {openGenesisJsonDialog && (
          <GenesisJsonViewDialog
            open={openGenesisJsonDialog}
            genesisJson={genesisJson}
            onClose={this.onCloseGenesisJsonDialog}
          />
        )}
        {cluster.explorer && (
          <UpdateNodeExplorerVersionDialog
            open={openUpdateNodeExpVersionDialog}
            onClose={this.onCloseUpdateNodeExpVersionDialog}
            networkUuid={network.networkUuid}
            clusterUuid={cluster.clusterUuid}
            explorer={cluster.explorer}
          />
        )}
        {cluster.explorer && (
          <RebuildBlockExpDialog
            open={openRebuildExplorerDialog}
            blockExplorer={cluster.explorer}
            onSubmit={this.controlExplorerRebuild}
            onClose={this.onCloseRebuildExplorerDialog}
          />
        )}
        {cluster.explorer && (
          <UpdateExplorerInstanceDialog
            open={openUpdateInstanceTypeDialog}
            onClose={this.onCloseUpdateInstanceTypeDialog}
            networkUuid={network.networkUuid}
            cluster={cluster}
            explorer={cluster.explorer}
          />
        )}
        {cluster.explorer && openUpgradeBlockExplorerDialog && (
          <UpgradeExplorerVersionDialog
            open={openUpgradeBlockExplorerDialog}
            onClose={this.onCloseUpgradeBlockExplorerDialog}
            networkUuid={network.networkUuid}
            clusterUuid={cluster.clusterUuid}
            explorer={cluster.explorer}
          />
        )}
        <Popover
          open={Boolean(explorerOptionAnchorEl)}
          anchorEl={explorerOptionAnchorEl}
          onClose={this.onCloseExplorerOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
          classes={{ paper: classes.nodeOptionPopoverPaper }}
        >
          <List className={classes.listOption}>
            <ListItem
              button
              data-testid="delete-button"
              className={classes.listOptionItem}
              onClick={this.onOpenDeleteBlockExplorerDialog}
              disabled={!deletableBlockExplorer}
            >
              <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
              {t('delete')}
            </ListItem>

            {cluster.explorer?.serverInfo.status === 'alive' && (
              <>
                <ListItem
                  button
                  data-testid="expand-button"
                  className={classes.listOptionItem}
                  onClick={this.onOpenExpandExplorerVolumeDialog}
                  disabled={!expandableNode}
                >
                  {t('expand')}
                </ListItem>
                <ListItem
                  button
                  data-testid="chang-node-version-button"
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpdateNodeExpVersionDialog}
                  disabled={!expandableNode}
                >
                  {t('change_node_version')}
                </ListItem>
                <ListItem
                  button
                  data-testid="change-instance-type-button"
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpdateInstanceTypeDialog}
                  disabled={!expandableNode}
                >
                  {t('change_instance_type')}
                </ListItem>
                <ListItem
                  button
                  data-testid="apply-latest-genesis-button"
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpdateExplHardForkDialog}
                  disabled={!expandableNode}
                >
                  {this.props.t('apply_latest_genesis_config')}
                </ListItem>
                <ListItem
                  button
                  data-testid="upgrade-explorer-version-button"
                  className={classes.listOptionItem}
                  onClick={this.onOpenUpgradeBlockExplorerDialog}
                  disabled={!ableUpgradeBlockExplorer}
                >
                  {this.props.t('upgrade_block_explorer_version')}
                </ListItem>
              </>
            )}
            {this.explorerControlMenu()}
            {/*<ListItem button className={classes.listOptionItem} onClick={this.controlExplorerRebootForce}>
              {t('forceReboot')}
            </ListItem> // temporary */}
          </List>
        </Popover>
        <div className={classes.root}>
          <div className={classes.borderBlock}>
            <div className={classes.topLine}>
              <div className={classes.topLineTitle}>{t('block_explorer_information')}</div>
              {account && (
                <div className={classes.handleBtnArea}>
                  {(cluster.accountUuid === account.accountUuid ||
                    isConsortiumAdmin(account, network)) &&
                    cluster.explorer?.serverInfo.status === 'alive' && (
                      <Button
                        data-testid="get-genesis-button"
                        className={classes.genesisBtn}
                        onClick={this.onOpenGenesisJsonDialog}
                        startIcon={
                          isLoadingGetGenesis ? (
                            <CircularProgress size={16} style={{ color: '#7B90A3' }} />
                          ) : (
                            <SourceIcon />
                          )
                        }
                        disabled={!ableGetGenesisJson || isLoadingGetGenesis}
                      >
                        <span>{t('genesis_json')}</span>
                      </Button>
                    )}
                  {cluster.accountUuid === account.accountUuid && (
                    <>
                      {cluster.explorer?.serverInfo.status === 'alive' && (
                        <>
                          <EditButton
                            data-testid="edit-button"
                            id="explorer-information-edit-button"
                            onClick={this.onOpenEditExplorerDialog}
                            disabled={!expandableNode}
                          />
                          <div className={classes.verticalSeparate}></div>
                        </>
                      )}
                      <OptionButton
                        id="explorer-button-menu"
                        data-testid="option-explorer-menu-button"
                        onClick={this.onOpenExplorerOptionPopover}
                      />
                    </>
                  )}
                </div>
              )}
            </div>

            <div id="cluster-information" className={classes.contentArea}>
              <div className={classNames(classes.infoItem, classes.separateBorder)}>
                <div className={classes.nodeTitle}>{t('node_information')}:</div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('node_version')}</div>
                  <div className={classes.detailValue}>{cluster.explorer?.nodeInfo?.version}</div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('block_explorer_version')}</div>
                  <div className={classes.detailValue}>
                    {semver.coerce(cluster.explorer?.blockscoutInfo.backend.version)?.version ||
                      cluster.explorer?.blockscoutInfo.backend.version}
                  </div>
                </div>
                {cluster.explorer?.blockscoutInfo.frontend &&
                  cluster.explorer?.blockscoutInfo.rpcUrl && (
                    <div className={classes.nodeItemInfo}>
                      <div className={classNames(classes.subTitle, classes.rpcEndpoint)}>
                        {t('network_RPC_endpoint')}
                        <Tooltip
                          title={t('network_RPC_endpoint_description')}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <HelpIcon style={{ marginLeft: 2, fontSize: '12px' }} />
                        </Tooltip>
                      </div>
                      <div className={classes.detailValue}>
                        {cluster.explorer?.blockscoutInfo.rpcUrl}
                      </div>
                    </div>
                  )}
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('enode')}</div>
                  <div className={classes.endPoint}>
                    <div
                      className={classNames(classes.detailValue, classes.informationItemDisplay)}
                    >
                      {cluster.explorer?.nodeInfo?.enode || 'fetching ...'}
                      <CopyClipboardIcon
                        classes={{ root: classes.copyButton }}
                        textToCopy={cluster.explorer?.nodeInfo?.enode || ''}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div id="explorer-server" className={classes.infoItem}>
                <div className={classes.nodeTitle}>{this.props.t('server')}:</div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('provider')}</div>
                  <div className={classes.detailValue}>
                    {cluster.provider && showSensitiveData
                      ? displayClusterProviderName(cluster.provider)
                      : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('region')}</div>
                  <div className={classes.detailValue}>
                    {cluster.region && showSensitiveData
                      ? renderClusterRegionLabel(providers, cluster)
                      : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('availability_zone')}</div>
                  <div className={classes.detailValue}>
                    {showSensitiveData ? az || 'fetching ...' : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('instance_type')}</div>
                  <div className={classes.detailValue}>
                    {showSensitiveData ? t(instanceType || '') : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('data_volume_size')}</div>
                  <div className={classes.detailValue}>
                    {showSensitiveData
                      ? `${volumeSize && volumeSize >= 0 ? formatter.format(volumeSize) : '-'} GB`
                      : MASK_ASTERISK}
                  </div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('ip_address')}</div>
                  <div className={classes.detailValue}>
                    {showSensitiveData ? (
                      ip ? (
                        <a href={`http://${ip}/`} target="_blank">
                          {ip}
                        </a>
                      ) : (
                        'fetching ...'
                      )
                    ) : (
                      MASK_ASTERISK
                    )}
                  </div>
                </div>
                <div className={classes.nodeItemInfo}>
                  <div className={classes.subTitle}>{t('public_DNS_name')}</div>
                  <div className={classes.detailValue}>
                    {showSensitiveData ? (
                      endpointUrl ? (
                        <a href={`http://${endpointUrl}/`} target="_blank">
                          {endpointUrl}
                        </a>
                      ) : (
                        'fetching ...'
                      )
                    ) : (
                      MASK_ASTERISK
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  private onOpenExplorerOptionPopover = (e) => {
    this.setState({
      explorerOptionAnchorEl: e.currentTarget,
    });
  };

  private onOpenEditExplorerDialog = (e) => {
    this.setState({
      openUpdateExplorerDialog: true,
    });
  };

  private onCloseEditExplorerDialog = () => {
    this.setState({
      openUpdateExplorerDialog: false,
    });
  };

  private onOpenRebuildExplorerDialog = (e) => {
    this.setState({
      openRebuildExplorerDialog: true,
    });
  };

  private onCloseRebuildExplorerDialog = () => {
    this.setState({
      openRebuildExplorerDialog: false,
    });
  };

  private onOpenUpdateExplHardForkDialog = (e) => {
    this.setState({
      openUpdateExpHardForkDialog: true,
    });
  };

  private onCloseUpdateExplHardForkDialog = () => {
    this.onCloseExplorerOptionPopover();
    this.setState({
      openUpdateExpHardForkDialog: false,
    });
  };

  private onOpenGenesisJsonDialog = async () => {
    const { account, network, cluster, getGenesisJsonInExplorer } = this.props;
    try {
      const rs = await getGenesisJsonInExplorer({
        accountUuid: account.accountUuid,
        networkUuid: network.networkUuid,
        clusterUuid: cluster.clusterUuid,
      });
      this.setState({ genesisJson: rs.getGenesisJsonInExplorer });
      this.setState({
        openGenesisJsonDialog: true,
      });
    } catch (error) {}
  };

  private onCloseGenesisJsonDialog = () => {
    this.setState({
      openGenesisJsonDialog: false,
    });
  };

  private onCloseExplorerOptionPopover = () => {
    this.setState({
      explorerOptionAnchorEl: null,
    });
  };

  private onOpenDeleteBlockExplorerDialog = () => {
    this.onCloseExplorerOptionPopover();
    this.setState({
      openDeleteBlockExplorerDialog: true,
    });
  };

  private onOpenUpdateNodeExpVersionDialog = (e) => {
    this.setState({
      openUpdateNodeExpVersionDialog: true,
    });
  };

  private onCloseUpdateNodeExpVersionDialog = () => {
    this.onCloseExplorerOptionPopover();
    this.setState({
      openUpdateNodeExpVersionDialog: false,
    });
  };

  private onCloseDeleteBlockExplorerDialog = () => {
    this.setState({
      openDeleteBlockExplorerDialog: false,
    });
  };

  private onOpenExpandExplorerVolumeDialog = () => {
    this.onCloseExplorerOptionPopover();
    this.setState({
      openExpandExplorerVolumeDialog: true,
    });
  };

  private onCloseExpandExplorerVolumeDialog = () => {
    this.setState({
      openExpandExplorerVolumeDialog: false,
    });
  };

  private onOpenUpdateInstanceTypeDialog = () => {
    this.onCloseExplorerOptionPopover();
    this.setState({
      openUpdateInstanceTypeDialog: true,
    });
  };

  private onCloseUpdateInstanceTypeDialog = () => {
    this.setState({
      openUpdateInstanceTypeDialog: false,
    });
  };

  private onOpenUpgradeBlockExplorerDialog = () => {
    this.onCloseExplorerOptionPopover();
    this.setState({
      openUpgradeBlockExplorerDialog: true,
    });
  };

  private onCloseUpgradeBlockExplorerDialog = () => {
    this.setState({
      openUpgradeBlockExplorerDialog: false,
    });
  };

  private explorerControlMenu = () => {
    const { classes, cluster, selectedAccountRole, selectedNetworkRole } = this.props;
    const controllableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    let render: any = null;

    switch (cluster.explorer?.serverInfo.status) {
      case 'alive':
        render = (
          <>
            <div className={classes.horizontalSeparate} />
            <ListItem
              button
              data-testid="stop-button"
              className={classes.listOptionItem}
              onClick={this.controlExplorerStop}
            >
              {this.props.t('stop')}
            </ListItem>
            <ListItem
              button
              data-testid="reboot-button"
              className={classes.listOptionItem}
              onClick={this.controlExplorerReboot}
              disabled={!controllableNode}
            >
              {this.props.t('reboot')}
            </ListItem>

            <ListItem
              button
              data-testid="rebuild-button"
              className={classes.listOptionItem}
              onClick={this.onOpenRebuildExplorerDialog}
              disabled={!controllableNode}
            >
              {this.props.t('rebuild')}
            </ListItem>
            <ListItem
              button
              data-testid="force-reboot-button"
              className={classes.listOptionItem}
              onClick={this.controlExplorerRebootForce}
            >
              {this.props.t('forceReboot')}
            </ListItem>
          </>
        );
        break;
      case 'dead':
        render = (
          <>
            <div className={classes.horizontalSeparate} />
            <ListItem
              button
              data-testid="start-button"
              className={classes.listOptionItem}
              onClick={this.controlExplorerStart}
            >
              {this.props.t('start')}
            </ListItem>
          </>
        );
        break;
      case 'failure':
        render = (
          <>
            <div className={classes.horizontalSeparate} />
            <ListItem
              button
              data-testid="reboot-button"
              className={classes.listOptionItem}
              onClick={this.controlExplorerReboot}
              disabled={!controllableNode}
            >
              {this.props.t('reboot')}
            </ListItem>
            <ListItem
              button
              data-testid="rebuild-button"
              className={classes.listOptionItem}
              onClick={this.onOpenRebuildExplorerDialog}
              disabled={!controllableNode}
            >
              {this.props.t('rebuild')}
            </ListItem>
            <ListItem
              button
              data-testid="force-reboot-button"
              className={classes.listOptionItem}
              onClick={this.controlExplorerRebootForce}
            >
              {this.props.t('forceReboot')}
            </ListItem>
          </>
        );
        break;
    }
    return render;
  };

  private controlExplorerStart = async () => {
    this.onSelectControlExplorerAction('start');
  };

  private controlExplorerStop = async () => {
    this.onSelectControlExplorerAction('stop');
  };

  private controlExplorerReboot = async () => {
    this.onSelectControlExplorerAction('reboot');
  };

  private controlExplorerRebootForce = async () => {
    this.onSelectControlExplorerAction('restartForce');
  };

  private controlExplorerRebuild = async (cleanBlockscoutDb = false) => {
    this.onSelectControlExplorerAction('rebuild', cleanBlockscoutDb);
  };

  private onSelectControlExplorerAction = async (
    action: INodeControlType,
    cleanBlockscoutDb?: boolean,
  ) => {
    const { controleExplorer, cluster, network, account } = this.props;
    this.onCloseExplorerOptionPopover();

    await controleExplorer({
      input: {
        accountUuid: account.accountUuid,
        action: action,
        clusterUuid: cluster.clusterUuid,
        networkUuid: network.networkUuid,
        cleanBlockscoutDb,
      },
    });
  };
}

const styles = createStyles({
  root: {
    paddingBottom: 50,
  },
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 16,
    color: lightSlateGreyColor,
  },
  borderBlock: {
    marginTop: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: pattensBlueColor,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
  },
  contentArea: {
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
  },
  infoItem: {
    paddingLeft: 30,
    paddingRight: 30,
    flex: 1,
    minWidth: 0,
  },
  nodeItemInfo: {
    marginTop: 10,
    marginBottom: 10,
  },
  separateBorder: {
    borderRight: `1px solid ${pattensBlueColor}`,
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  rpcEndpoint: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(20, 26, 31)',
  },
  detailValue: {
    ...defaultFontRegular,
    fontSize: 16,
    color: lightSlateGreyColor,
    maxWidth: 400,
    wordBreak: 'break-all',
    '& a': {
      color: denimColor,
      whiteSpace: 'inherit',
      wordBreak: 'break-all',
    },
  },
  states: {
    marginTop: 20,
  },
  // Handle Button
  handleBtnArea: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 8,
    top: 8,
  },
  verticalSeparate: {
    width: 1,
    height: 20,
    backgroundColor: pattensBlueColor,
    marginRight: 5,
    marginLeft: 5,
  },
  proposalArea: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: whiteSmokeColor,
    border: `1px solid ${pattensBlueColor}`,
    textAlign: 'center',
  },
  proposalText: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  // Node option popover
  listOption: {
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  listOptionItem: {
    height: 46,
    width: 320,
    ...defaultFont,
    fontSize: 16,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  nodeOptionPopoverPaper: {},
  formContent: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left',
  },
  submitBtn: {
    marginLeft: 15,
  },
  requestTypeField: {
    width: 180,
  },
  proposeNodeSelectMenu: {
    paddingTop: 8,
    paddingBottom: 7,
  },
  endPoint: {
    display: 'flex',
  },
  informationItemDisplay: {
    whiteSpace: 'inherit',
    wordBreak: 'break-all',
  },
  copyButton: {
    padding: 5,
    marginLeft: 5,
  },
  topLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 5,
    height: 40,
    backgroundColor: snowColor,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  topLineTitle: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  deleteIcon: {
    marginRight: 8,
  },
  genesisBtn: {
    ...defaultFontMedium,
    fontSize: 12,
    color: lightSlateGreyColor,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& .MuiButton-startIcon': {
      width: '20px',
      height: '20px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  providers: store.appState.providers || [],
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
  isLoadingGetGenesis: NetworkActions.getGenesisJsonInExplorer.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
  controleExplorer: (args: NetworkActions.MutationControlBlockExplorerArgs) =>
    dispatch(NetworkActions.controlBlockExplorer(args)),
  getGenesisJsonInExplorer: (args: QueryGetGenesisJsonInExplorerArgs) =>
    dispatch(NetworkActions.getGenesisJsonInExplorer(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverviewTab)),
);

import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';

import { momentDateConvert } from '~/utilities/moment-utils';

import common_en from './locales/en/common'; // Always shows in vipanese
import common_ja from './locales/ja/common';
import member_en from './locales/en/member';
import member_ja from './locales/ja/member';
import admin_en from './locales/en/admin';
import admin_ja from './locales/ja/admin';

const i18nOptions: InitOptions = {
  // language to use if translations in user language are not available.
  fallbackLng: 'en',
  supportedLngs: ['en', 'ja'],

  // have a common namespace used around the full app
  ns: ['guest', 'member', 'common'],
  defaultNS: 'common',

  interpolation: {
    escapeValue: false, // not needed for react!!
    format: (value: any, format: any, lng: any) => {
      if (format === 'uppercase') {
        return value.toUpperCase();
      }

      if (value instanceof Date) {
        return momentDateConvert({
          date: value,
          formatOutput: format,
          utcOffset: navigator.userAgent.includes('jsdom') ? 7 : void 0,
        });
      }

      return value;
    },
  },

  react: {
    defaultTransParent: 'div', // needed for preact
    wait: true,
  },

  resources: {
    en: {
      common: common_en,
      member: member_en,
      admin: admin_en,
    },
    ja: {
      common: common_ja,
      member: member_ja,
      admin: admin_ja,
    },
  },
};

// Client side
Object.assign(i18nOptions, {
  detection: {
    order: ['querystring', 'cookie', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'lng',
    caches: false, // ['cookie']
  },
});

i18next
  .use(BrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nOptions);

export default i18next;

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';

interface IProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  id?: string;
}

const customDateInput: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  placeholder,
  value,
  onChange,
  disabled,
  name,
  onBlur,
  onKeyDown,
  maxLength,
  id,
}) => {
  return (
    <input
      name={name}
      onBlur={onBlur}
      className={classNames(classes.input, className)}
      type="text"
      placeholder={placeholder}
      defaultValue={value}
      onChange={onChange}
      disabled={disabled}
      onKeyDown={onKeyDown}
      maxLength={maxLength}
      id={id}
    />
  );
};

customDateInput.defaultProps = {
  disabled: false,
};

const styles: StyleRules = {
  root: {},
  input: {
    ...defaultFont,
    fontSize: 13,
    '&::placeholder': {
      color: lightSlateGreyColor,
    },
    width: '100%',
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: whiteSmokeColor,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
  },
  textarea: {
    height: 60,
  },
};

export default withStyles(styles)(customDateInput);

import React from 'react';
import { connect } from 'react-redux';
// Redux
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
import { IStore } from '~/stores/configure-store';
import MemberGApiClient from '~/stores/actions/gapi/member-gapi-client';
// Component
import Button from '@mui/material/Button';
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium, defaultFont } from '~/styles/themes/common-styles/font';
import { whiteSmokeColor, dimGrayColor, romanColor } from '~/styles/themes/common-styles/color';
// Type
import { INetwork } from '~/types/network-types';
import { Account, Profile } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Lodash from 'lodash';

interface IStateProps {
  profile?: Profile;
}

interface IDispProps {
  sendInvitation: (
    args: NetworkActions.MutationSendInvitationArgs,
  ) => Promise<NetworkActions.SEND_INVITATION_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  network: INetwork;
  account: Account;
  onClose: () => void;
}

interface IState {
  isSubmitting: boolean;
  accountUuidInput: {
    value: string;
    touched: Boolean;
    error?: string;
  };
  accountNameInput: {
    value: string;
    touched: Boolean;
    error?: string;
  };
}

class NetworkInviteDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isSubmitting: false,
      accountUuidInput: {
        value: '',
        touched: false,
        error: props.t('required'),
      },
      accountNameInput: {
        value: '',
        touched: false,
        error: props.t('required'),
      },
    };
  }

  public render() {
    const { classes, open, t } = this.props;
    const { isSubmitting, accountUuidInput, accountNameInput } = this.state;

    return (
      <CustomDialog open={open} onClose={this.closeDialog}>
        <CustomDialogTitle>
          <div id="member-network-invite-title">{t('invite_network')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div>
            <div className={classes.formLabelLine}>
              <div className={classes.formLabel}>{t('organization_id')}</div>
              {accountUuidInput.error && accountUuidInput.touched && (
                <div className={classNames(classes.formLabel, classes.formError)}>
                  {accountUuidInput.error}
                </div>
              )}
            </div>
            <div>
              <CustomInput
                data-testid="origanization-id-input"
                id="member-network-invite-account"
                placeholder={t('input_organization_id')}
                value={accountUuidInput.value}
                onChange={this.onAccountUuidInputChange}
                onBlur={this.onAccountUuidInputBlur}
              />
            </div>
          </div>
          <div className={classes.formSection}>
            <div className={classes.formLabelLine}>
              <div className={classes.formLabel}>{t('organization_name')}</div>
              {accountNameInput.error && accountNameInput.touched && (
                <div className={classNames(classes.formLabel, classes.formError)}>
                  {accountNameInput.error}
                </div>
              )}
            </div>
            <div>
              <CustomInput
                data-testid="account-name-input"
                id="member-network-invite-name"
                value={accountNameInput.value}
                disabled
              />
            </div>
          </div>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            data-testid="cancel-button"
            id="member-network-invite-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="contained"
            onClick={this.closeDialog}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="send-button"
            id="member-network-invite-submit"
            isValid={!accountUuidInput.error && !accountNameInput.error}
            isSubmitting={isSubmitting}
            label={t('send')}
            submittingLabel={t('sending')}
            onClick={this.onSubmit}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  private onAccountUuidInputBlur = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const accountUuid = e.target.value;
    this.setState({
      accountUuidInput: {
        value: accountUuid,
        touched: true,
        error: accountUuid ? '' : this.props.t('required'),
      },
    });
  };

  private onAccountUuidInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const accountUuid = e.target.value;
    this.setState({
      accountUuidInput: {
        value: accountUuid,
        touched: true,
        error: accountUuid ? '' : this.props.t('required'),
      },
    });
    this.debounceDetectAccount(accountUuid);
  };

  private detectAccount = async (accountUuid) => {
    try {
      this.setState({
        accountNameInput: {
          touched: false,
          value: (await new MemberGApiClient({}).getAccountName({ accountUuid })).getAccountName,
        },
      });
    } catch (error: any) {
      this.setState({
        accountNameInput: {
          value: '',
          touched: true,
          error: error.message,
        },
      });
    }
  };

  private debounceDetectAccount = Lodash.debounce(this.detectAccount, 200);

  private onSubmit = async () => {
    const { onClose, sendInvitation, network, account, profile } = this.props;
    const { accountUuidInput } = this.state;
    if (profile) {
      this.setSubmitting(true);
      try {
        await sendInvitation({
          input: {
            senderUid: profile.uid,
            networkUuid: network.networkUuid,
            senderAccountUuid: account.accountUuid,
            receiverAccountUuid: accountUuidInput.value,
          },
        });
        this.closeDialog();
      } catch (error) {
        // Do nothing
      }
      this.setSubmitting(false);
    }
  };

  private setSubmitting = (value: boolean) => {
    this.setState({
      isSubmitting: value,
    });
  };

  private closeDialog = () => {
    this.resetForm();
    this.props.onClose();
  };

  private resetForm = () => {
    this.setState({
      accountUuidInput: {
        value: '',
        touched: false,
        error: this.props.t('required'),
      },
      accountNameInput: {
        value: '',
        touched: false,
        error: this.props.t('required'),
      },
    });
  };
}

const styles = createStyles({
  root: {},
  dialogTitle: {
    paddingBottom: 25,
  },
  // Form Field
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  sendInvitation: (args: NetworkActions.MutationSendInvitationArgs) =>
    dispatch(NetworkActions.sendInvitation(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NetworkInviteDialog)),
);

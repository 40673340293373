import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import TableHead from '@mui/material/TableHead';

import { whiteSmokeColor } from '~/styles/themes/common-styles/color';

interface IProps {
  className?: string;
}

const tableHead: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  children,
}) => {
  return <TableHead className={classNames(classes.root, className)}>{children}</TableHead>;
};

const styles: StyleRules = {
  root: {
    backgroundColor: whiteSmokeColor,
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
};

export default withStyles(styles)(tableHead);

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions, FormikTouched } from 'formik';
import Button from '@mui/material/Button';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';

// Mock
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// React i18next
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import {
  MAX_NETWORK_NAME_LENGTH,
  MAX_NETWORK_DESCRIPTION_LENGTH,
  MAX_CURRENCY_NAME_LENGTH,
  MAX_CURRENCY_SYMBOL_LENGTH,
} from '~/constants/validation';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';
import { DEFAULT_GAS_PRICE_MAX } from '~/constants/consts';
import Grid from '@mui/material/Grid';

interface IStateProps {
  accountSelected?: Account;
}

interface IDispProps {
  updateNetwork: (
    args: NetworkActions.MutationUpdateNetworkArgs,
  ) => Promise<NetworkActions.UPDATE_NETWORK_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: INetwork;
}

interface IState {
  isAttentionChangeCurrency: boolean;
}

class EditNetworkDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isAttentionChangeCurrency: false,
    };
  }

  public render() {
    const { classes, open, onClose, network, t } = this.props;

    const validateSchema = Yup.object().shape<FormValues>({
      networkName: Yup.string()
        .trim()
        .required(this.props.t('required'))
        .max(
          MAX_NETWORK_NAME_LENGTH,
          t('too_many_character_error_message', { value: MAX_NETWORK_NAME_LENGTH }),
        ),
      networkDescription: Yup.string()
        .trim()
        .max(
          MAX_NETWORK_DESCRIPTION_LENGTH,
          t('too_many_character_of_network_details_error_message', {
            value: MAX_NETWORK_DESCRIPTION_LENGTH,
          }),
        ),
      currencyName: Yup.string()
        .min(1)
        .max(
          MAX_CURRENCY_NAME_LENGTH,
          t('too_many_currency_character_error_message', { value: MAX_CURRENCY_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return name && name.trim() === '' ? false : true;
          },
        ),
      currencySymbol: Yup.string()
        .min(1)
        .max(
          MAX_CURRENCY_SYMBOL_LENGTH,
          t('too_many_currency_character_error_message', { value: MAX_CURRENCY_SYMBOL_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return name && name.trim() === '' ? false : true;
          },
        ),
    });

    const initialValues: FormValues = {
      networkName: network.networkName,
      networkDescription: network.networkDescription,
      defaultGasPrice: network.blockchainInfo?.defaultGasPrice,
      currencyName: network.blockchainInfo?.currencyName,
      currencySymbol: network.blockchainInfo?.currencySymbol,
    };

    return (
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          enableReinitialize
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting, values, touched }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-network-edit-title">{t('edit_network')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div>
                  <div id="member-network-edit-ident" className={classes.formLabel}>
                    {t('blockchain_network_id')}
                  </div>
                  <div>
                    <CustomInput
                      data-testid="datatestid-input"
                      disabled={true}
                      value={network.networkUuid}
                    />
                  </div>
                </div>
                <div className={classes.formSection}>
                  <Field name="networkName" render={this.networkNameField} />
                </div>
                <div className={classes.formSection}>
                  <Field name="defaultGasPrice" render={this.defaultGasPriceField} />
                </div>
                <div className={classes.formSection}>
                  <Grid container>
                    <Grid item md={6} className={classes.gridLeftItem}>
                      <Field name="currencyName" render={this.currencyNameField} />
                    </Grid>
                    <Grid item md={6} className={classes.gridRightItem}>
                      <Field name="currencySymbol" render={this.currencySymbolField} />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <Field name="networkDescription" render={this.networkDescriptionField} />
                </div>
                {this.renderAttentionCurrencyUpdation(values, touched)}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-network-edit-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  onClick={onClose}
                  variant="contained"
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-network-edit-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private networkNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('network_name')}</div>
          {!!form.errors.networkName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.networkName}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="network-name-input"
            {...field}
            id="member-network-edit-name"
            placeholder={this.props.t('input_network_name')}
          />
        </div>
      </>
    );
  };

  private currencyNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('currency_name')}</div>
          {!!form.errors.currencyName && form.touched.currencyName && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.currencyName)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="currency-name-input"
            {...field}
            id="member-network-add-currency-name"
          />
        </div>
      </>
    );
  };

  private currencySymbolField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('currency_symbol')}</div>
          {!!form.errors.currencySymbol && form.touched.currencySymbol && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {this.props.t(form.errors.currencySymbol)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="currency-symbol-input"
            {...field}
            id="member-network-add-currency-symbol"
          />
        </div>
      </>
    );
  };

  private networkDescriptionField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('description')}</div>
          {!!form.errors.networkDescription && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.networkDescription}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="description-input"
            {...field}
            classes={{
              textarea: classes.networkDescriptionInputTextarea,
            }}
            id="member-network-edit-description"
            placeholder={this.props.t('edit_network_dialog_description_placeholder', {
              value: MAX_NETWORK_DESCRIPTION_LENGTH,
            })}
            multiline
          />
        </div>
      </>
    );
  };

  private defaultGasPriceField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('default_gas_price')}</div>
          {!!form.errors.defaultGasPrice && form.touched.defaultGasPrice && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.defaultGasPrice)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            data-testid="default-gas-price-input"
            {...field}
            id="member-network-add-default-gas-price"
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return (
                floatValue === undefined || (floatValue >= 0 && floatValue <= DEFAULT_GAS_PRICE_MAX)
              );
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { updateNetwork, network, onClose, accountSelected } = this.props;

    if (!accountSelected) {
      return;
    }

    try {
      await updateNetwork({
        input: {
          accountUuid: accountSelected.accountUuid,
          networkUuid: network.networkUuid,
          networkName: values.networkName.trim(),
          defaultGasPrice: values.defaultGasPrice ?? undefined,
          networkDescription: values.networkDescription && values.networkDescription.trim(),
          currencySymbol: values.currencySymbol || undefined,
          currencyName: values.currencyName || undefined,
        },
      });
      onClose();
    } catch (error) {
      setSubmitting(false);
    }
  };

  private renderAttentionCurrencyUpdation = (
    values: FormValues,
    touched: FormikTouched<FormValues>,
  ) => {
    const { classes, network } = this.props;
    const { currencySymbol, currencyName } = network.blockchainInfo ?? {};
    if (
      (values.currencySymbol !== '' && values.currencySymbol !== currencySymbol) ||
      (values.currencyName !== '' && values.currencyName !== currencyName)
    ) {
      return (
        <div className={classes.attentionCurrencyUpdation}>
          {this.props.t('attention_currency_updation')}
        </div>
      );
    } else {
      return <></>;
    }
  };
}

const styles: StyleRules = {
  root: {},
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  networkDescriptionInputTextarea: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  attentionCurrencyUpdation: {
    marginTop: 30,
    ...defaultFontRegular,
    fontSize: 18,
    lineHeight: '24px',
    color: 'rgb(255, 167, 38)',
    textAlign: 'center',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateNetwork: (args: NetworkActions.MutationUpdateNetworkArgs) =>
    dispatch(NetworkActions.updateNetwork(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditNetworkDialog)),
);

type FormValues = {
  networkName: string;
  networkDescription?: string;
  defaultGasPrice?: number;
  currencySymbol?: string;
  currencyName?: string;
};

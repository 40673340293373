import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Localization
import { withTranslation, WithTranslation } from 'react-i18next';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  nightRiderColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
// Components
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import OptionButton from '~/components/common/option-button';
import TableRowBodyCustom from '../../../table-row-body';
import TableCellBodyCustom from '../../../table-cell-body';
import EditButton from '~/components/common/edit-button';
import ImgIcon from '~/components/common/img-icon';
import EditAccountMemberDialog from '../edit-account-member-dialog';
import DeleteAccountMemberDialog from '../delete-account-member-dialog';
import ChangePrimaryOwnerDialog from '../change-primary-owner-dialog';
// Types
import { Account, IAccountMember, AccountRoleType, Profile } from '~/types/account-types';
import { Tooltip } from '@mui/material';
// Mixs

interface IStateProps {
  account?: Account;
  profile?: Profile;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  member: IAccountMember;
}

interface IState {
  openEditAccountMemberDialog: boolean;
  openDeleteAccountMemberDialog: boolean;
  openChangePrimaryOwnerDialog: boolean;
  editAccountMemberOptionAnchorEl: HTMLButtonElement | null;
}

class MemberTableRow extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openEditAccountMemberDialog: false,
      openDeleteAccountMemberDialog: false,
      openChangePrimaryOwnerDialog: false,
      editAccountMemberOptionAnchorEl: null,
    };
  }

  render() {
    const { classes, account, member, selectedAccountRole, profile } = this.props;
    const {
      openEditAccountMemberDialog,
      openDeleteAccountMemberDialog,
      openChangePrimaryOwnerDialog,
    } = this.state;

    if (!account || !profile || !member) {
      return null;
    }

    const updatable =
      selectedAccountRole === 'owner' ||
      (selectedAccountRole === 'admin' && member.role !== 'owner' && member.role !== 'admin');
    return (
      <>
        <EditAccountMemberDialog
          open={openEditAccountMemberDialog}
          member={member}
          onClose={this.onCloseEditAccountMemberDialog}
        />
        <DeleteAccountMemberDialog
          open={openDeleteAccountMemberDialog}
          member={member}
          onClose={this.onCloseDeleteAccountMemberDialog}
        />
        <ChangePrimaryOwnerDialog
          open={openChangePrimaryOwnerDialog}
          member={member}
          onClose={this.onCloseChangePrimaryOwnerDialog}
        />
        {this.editMemberOptionPopoverRender}
        <TableRowBodyCustom>
          <TableCellBodyCustom className={classNames(classes.nameCell, classes.cellBody)}>
            {member.hasProfile ? (
              <>
                <div className={classes.memberNameText}>{member.displayName}</div>
                <div className={classes.memberEmailText}>{member.email}</div>
              </>
            ) : (
              <Tooltip title={this.props.t('inactive_user_warning')}>
                <div className={classes.memberInactive}>{this.props.t('unknown_member')}</div>
              </Tooltip>
            )}
          </TableCellBodyCustom>
          <TableCellBodyCustom className={classNames(classes.roleCell, classes.cellBody)}>
            <div className={classes.memberRoleText}>{member.role}</div>
            {member.role === 'owner' && Boolean(member.isPrimaryOwner) && (
              <div className={classes.memberRoleText}>{'(primary)'}</div>
            )}
          </TableCellBodyCustom>
          <TableCellBodyCustom className={classNames(classes.actionCell, classes.cellBody)}>
            {updatable && (
              <EditButton
                id="member-account-general-detail-edit"
                className={classes.editBtn}
                disabled={!member.hasProfile}
                onClick={this.onOpenEditAccountMemberDialog}
              />
            )}
            {updatable && (
              <OptionButton
                id="edit-member-pulldown-menu"
                className={classes.optionBtn}
                onClick={this.onOpenEditAccountMemberOptionPopover}
              />
            )}
          </TableCellBodyCustom>
        </TableRowBodyCustom>
      </>
    );
  }

  get editMemberOptionPopoverRender() {
    const { classes, account, member, selectedAccountRole, profile, t } = this.props;
    const { editAccountMemberOptionAnchorEl } = this.state;
    if (!account || !profile || !member) {
      return null;
    }
    const transferable =
      member.hasProfile &&
      member.role === 'owner' &&
      !Boolean(member.isPrimaryOwner) &&
      selectedAccountRole === 'owner';
    const removable =
      profile.uid !== member.uid &&
      (selectedAccountRole === 'owner' ||
        (selectedAccountRole === 'admin' && member.role !== 'owner' && member.role !== 'admin'));
    return (
      <>
        <Popover
          open={Boolean(editAccountMemberOptionAnchorEl)}
          anchorEl={editAccountMemberOptionAnchorEl}
          onClose={this.onCloseEditAccountMemberOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
        >
          <List>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.onOpenChangePrimaryOwnerDialog}
              disabled={!transferable}
            >
              <img className={classes.transferIcon} src="/images/icons/access_ico.png" alt="" />
              {t('transfer_primary_owner')}
            </ListItem>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.onOpenDeleteAccountMemberDialog}
              disabled={!removable}
            >
              <img className={classes.deleteIcon} src="/images/icons/delete_ico.png" alt="" />
              {t('delete_member')}
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }

  private onOpenEditAccountMemberDialog = () => {
    this.setState({
      openEditAccountMemberDialog: true,
    });
  };

  private onCloseEditAccountMemberDialog = () => {
    this.setState({
      openEditAccountMemberDialog: false,
    });
  };

  private onOpenEditAccountMemberOptionPopover = (e) => {
    this.setState({ editAccountMemberOptionAnchorEl: e.currentTarget });
  };
  private onCloseEditAccountMemberOptionPopover = () => {
    this.setState({ editAccountMemberOptionAnchorEl: null });
  };

  private onOpenDeleteAccountMemberDialog = () => {
    this.setState({
      openDeleteAccountMemberDialog: true,
    });
    this.onCloseEditAccountMemberOptionPopover();
  };

  private onCloseDeleteAccountMemberDialog = () => {
    this.setState({
      openDeleteAccountMemberDialog: false,
    });
  };

  private onOpenChangePrimaryOwnerDialog = () => {
    this.setState({
      openChangePrimaryOwnerDialog: true,
    });
    this.onCloseEditAccountMemberOptionPopover();
  };

  private onCloseChangePrimaryOwnerDialog = () => {
    this.setState({
      openChangePrimaryOwnerDialog: false,
    });
  };
}

const styles = createStyles({
  nameCell: {
    width: '50%',
  },
  roleCell: {
    width: '20%',
  },
  actionCell: {
    width: '30%',
  },
  cellBody: {
    paddingTop: 25,
    paddingBottom: 25,
    '&:last-child': {
      paddingRight: '24px',
    },
  },
  memberNameText: {
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  memberEmailText: {
    ...defaultFont,
    fontSize: 13,
    color: lightSlateGreyColor,
  },
  memberRoleText: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
    textAlign: 'center',
  },
  editBtn: {
    border: `1px solid ${pattensBlueColor}`,
    backgroundColor: whiteSmokeColor,
    width: 80,
    height: 36,
    marginRight: 5,
  },
  optionBtn: {
    width: 36,
    height: 36,
    minWidth: 36,
  },
  deleteIcon: {
    marginRight: 8,
  },
  transferIcon: {
    marginRight: 8,
  },
  listOptionItem: {
    height: 46,
    width: 'auto',
    ...defaultFont,
    fontSize: 16,
  },
  memberInactive: {
    color: romanColor,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  profile: store.appState.profile,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MemberTableRow)),
);

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import TableRow from '@mui/material/TableRow';

interface IProps {
  className?: string;
}

const tableRowHead: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  children,
}) => {
  return <TableRow className={classNames(classes.root, className)}>{children}</TableRow>;
};

const styles: StyleRules = {
  root: {
    height: 50,
  },
};

export default withStyles(styles)(tableRowHead);

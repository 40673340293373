import React from 'react';
import classNames from 'classnames';

import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import TableBody from '@mui/material/TableBody';

interface IProps {
  className?: string;
}

const tableBody: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  children,
}) => {
  return <TableBody className={classNames(classes.root, className)}>{children}</TableBody>;
};

const styles: StyleRules = {
  root: {},
};

export default withStyles(styles)(tableBody);

import { createTheme } from '@mui/material/styles';
import { lightBlue, red } from '@mui/material/colors';
import {
  minWidthXS,
  minWidthSM,
  minWidthMD,
  minWidthLG,
  minWidthXL,
} from '~/styles/themes/common-styles/grid-member';

const theme = createTheme({
  palette: {
    primary: lightBlue,
    grey: red,
    mode: 'light',
  },
  breakpoints: {
    values: {
      xs: minWidthXS,
      sm: minWidthSM,
      md: minWidthMD,
      lg: minWidthLG,
      xl: minWidthXL,
    },
  },
});

theme.components = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        margin: theme.spacing(2),
      },
    },
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 3, 3, 3),
        '& > :not(:first-of-type)': {
          marginLeft: 0,
        },
      },
      spacing: {
        '& > :not(:first-of-type)': {
          marginLeft: 0,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        color: '#333333',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
};
export { theme };

import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Component
import Table from '@mui/material/Table';
import TableHeadCustom from '~/components/common/table-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableBodyCustom from '~/components/common/table-body';
import ConsortiumRow from './consortium-row';
import SortIcon from '~/components/common/sort-icon';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type
import { INetwork, IConsortium, IConsortiumRoleType } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { Order, getComparator, stableSort, HeadCell } from '~/utilities/sort-utils';

interface IStateProps {}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  account: Account;
}

interface IState {
  order: Order;
  orderBy: SortableHeadCellId;
}

class ConsortiumTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      order: 'ASC',
      orderBy: 'accountName',
    };
  }

  public render() {
    const { classes, t, network, account } = this.props;
    const { order, orderBy } = this.state;
    const items = this.getItems();

    return (
      <div className={classes.root}>
        <Table id="nodes-list">
          <colgroup>
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="80px" />
          </colgroup>
          <TableHeadCustom>
            <TableRowHeadCustom>
              {headCells.map((headCell) => (
                <TableCellHeadCustom
                  key={headCell.id}
                  data-testid={`sort-${headCell.id}-button`}
                  onClick={this.onTableCellHeadClick(headCell.id)}
                  style={{ cursor: headCell.sortable ? 'pointer' : 'unset' }}
                  classes={{ content: classes.tableCellHeadContent }}
                >
                  <span>{t(headCell.label)}</span>
                  {headCell.sortable && (
                    <SortIcon order={orderBy === headCell.id ? order : undefined} />
                  )}
                </TableCellHeadCustom>
              ))}
              <TableCellHeadCustom></TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBodyCustom>
            {items.map((item, index) => (
              <ConsortiumRow
                key={item.accountUuid ?? index}
                item={item}
                network={network}
                account={account}
              />
            ))}
          </TableBodyCustom>
        </Table>
      </div>
    );
  }

  private getItems = (): Array<Item> => {
    const { network } = this.props;
    const { order, orderBy } = this.state;
    const items: Array<Item> = network.consortiums.map((consortium) => ({
      accountName: consortium.accountName,
      accountUuid: consortium.accountUuid,
      consortiumRole: consortium.role,
      ownerEmail: consortium.ownerEmail,
      consortium,
    }));

    return stableSort<Item>(items, getComparator<SortableHeadCellId>(order, orderBy));
  };

  private onTableCellHeadClick = (id: SortableHeadCellId) => () => {
    const { order, orderBy } = this.state;
    this.setState({
      orderBy: id,
      order: orderBy === id && order === 'ASC' ? 'DESC' : 'ASC',
    });
  };
}

const styles = createStyles({
  root: {
    marginTop: 20,
  },
  tableCellHeadContent: {
    paddingRight: 20,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsortiumTab)),
);

export type Item = {
  accountName: string;
  accountUuid?: string;
  consortiumRole: IConsortiumRoleType;
  ownerEmail?: string;
  consortium: IConsortium;
};
type SortableHeadCellId = keyof Omit<Item, 'consortium'>;
const headCells: Array<HeadCell<SortableHeadCellId, never>> = [
  { id: 'accountName', sortable: true, label: 'organization_name' },
  { id: 'accountUuid', sortable: true, label: 'organization_id' },
  { id: 'consortiumRole', sortable: true, label: 'role' },
  { id: 'ownerEmail', sortable: true, label: 'owner_email' },
];

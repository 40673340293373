import React, { useMemo } from 'react';
import moment from 'moment';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
// Translation
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { INetwork } from '~/types/network-types';
// Components
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowBodyCustom from '~/components/common/table-row-body';

import { Cluster, HardForkProposal, Node, QueryListHardForkProposalsArgs } from '~/gapi/gtypes';
import classNames from 'classnames';
import { denimColor, persianGreenColor, romanColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
import NodeStatusIcon from '~/components/common/node-status-icon';
import { displayBlockExplorerStatus, displayNodeStatus } from '~/utilities/render-utils';
import { Link } from 'react-router-dom';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  cluster: Cluster;
  proposal?: HardForkProposal;
  network: INetwork;
}

const HardForkDetailExplorerRow = (props: IProps) => {
  const { t } = useTranslation();
  const { classes, cluster, proposal, network } = props;

  const renderHardForkStatus = useMemo(() => {
    if (network.planningHardFork && network.planningHardFork?.proposalId === proposal?.uuid) {
      return proposal?.appliedExplorerUuids.includes(cluster.explorer?.explorerUuid ?? '') ? (
        <div className={classes.doneStatus}>{t('done')}</div>
      ) : (
        t('pending')
      );
    }
    return proposal?.appliedExplorerUuids.includes(cluster.explorer?.explorerUuid ?? '') ? (
      <div className={classes.doneStatus}>{t('done')}</div>
    ) : (
      <div className={classes.failedStatus}>{t('failed')}</div>
    );
  }, [
    network.planningHardFork,
    proposal?.uuid,
    proposal?.appliedExplorerUuids,
    cluster.explorer?.explorerUuid,
    classes.doneStatus,
    classes.failedStatus,
    t,
  ]);

  return (
    <TableRowBodyCustom className={classes.tableRowBodyCustom}>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.tableBodyTd) }}
      >
        <Link
          to={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/overview`}
        >
          <div className={classNames(classes.nodeLinkText, classes.tableBodyTdState)}>
            <NodeStatusIcon
              className={classes.nodeStatusIcon}
              status={displayBlockExplorerStatus(cluster)}
            />
            {cluster.clusterName} block explorer
          </div>
        </Link>
      </TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      ></TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        {cluster.clusterName}
      </TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        {proposal?.name}
      </TableCellBodyCustom>
      <TableCellBodyCustom
        classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
      >
        {renderHardForkStatus}
      </TableCellBodyCustom>
    </TableRowBodyCustom>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    listOptionItem: {
      ...defaultFont,
      height: 46,
      width: 210,
      fontSize: 16,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},

    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    tableRowBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
    doneStatus: {
      color: persianGreenColor,
    },
    failedStatus: {
      color: romanColor,
    },
  });

export default withStyles(styles)(withTranslation()(HardForkDetailExplorerRow));

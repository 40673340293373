import React from 'react';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium } from '~/styles/themes/common-styles/font';
import { lightSlateGreyColor } from '~/styles/themes/common-styles/color';
import classNames from 'classnames';

interface IProps extends WithStyles<typeof styles> {
  className?: string;
}

const customDialogTitle: React.StatelessComponent<IProps> = ({ classes, children, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
);

const styles = createStyles({
  root: {
    position: 'relative',
    paddingTop: 55,
    paddingBottom: 30,
    paddingLeft: 90,
    paddingRight: 90,
    textAlign: 'center',
    ...defaultFontMedium,
    fontSize: 24,
    lineHeight: '28px',
    color: lightSlateGreyColor,
  },
});

export default withStyles(styles)(customDialogTitle);

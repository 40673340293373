import React from 'react';
import { connect } from 'react-redux';
// Redux
import { IStore } from '~/stores/configure-store';
// Component
import CustomDialog from '../custom-dialog';
import CustomTabs, { ITabs } from '../custom-tabs';
import CustomDialogTitle from '../custom-dialog-title';
import CustomDialogContent from '../custom-dialog-content';
import GeneralTab from './general-tab';
import MemberTab from './member-tab';
import ContactTab from './contact-tab';
import WithdrawalTab from './withdrawal-tab';
// Form
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// Type
import { AccountRoleType } from '~/types/account-types';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

interface IState {}

class AccountSettingDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { classes, open, onClose, t, selectedAccountRole } = this.props;
    const showGeneralTab = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';
    const showMemberTab =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedAccountRole === 'member';
    const showWithdrawalTab = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';
    const showContactTab = selectedAccountRole === 'owner' || selectedAccountRole === 'admin';
    const tabs: ITabs[] = [];
    if (showGeneralTab) {
      tabs.push({
        title: t('general'),
        component: <GeneralTab />,
      });
    }
    if (showMemberTab) {
      tabs.push({
        title: t('members'),
        component: <MemberTab />,
      });
    }
    if (showContactTab) {
      tabs.push({
        title: t('contact'),
        component: <ContactTab />,
      });
    }
    if (showWithdrawalTab) {
      tabs.push({
        title: t('withdraw'),
        component: <WithdrawalTab onClose={onClose} />,
      });
    }

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle classes={{ root: classes.dialogTitle }}>
          <img src="/images/icons/setting_ico.svg" className={classes.settingIcon} />
          <div id="member-account-general-title">{t('organization_setting')}</div>
        </CustomDialogTitle>
        <CustomDialogContent classes={{ root: classes.dialogContent }}>
          <CustomTabs
            tabs={tabs}
            classes={{
              tabLabelContainer: classes.tabLabelContainer,
              content: classes.tabContent,
            }}
            id="member-account-general-tabs"
          />
        </CustomDialogContent>
      </CustomDialog>
    );
  }
}

const styles = createStyles({
  root: {},
  // Dialog
  dialogPaper: {
    maxWidth: 700,
    minHeight: 415,
  },
  dialogContent: {
    marginBottom: 55,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  settingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  tabLabelContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabContent: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccountSettingDialog)),
);

type WithdrawFormValues = {
  confirmName: string;
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import * as semver from 'semver';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DEFAULT_VERSION_INTERNAL_CLEF } from '~/constants/consts';
import classNames from 'classnames';
import CustomSelect from '~/components/common/custom-select';
import { nodeClefSelection } from '~/types/network-selection';
import { MIN_GETH_SUPPORT_EXTERNAL_CLEF_VERSION } from '~/constants/validation';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  clefVersion: string;
};

const MigrateClefNodeDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, onClose } = props;
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.applyHardForkToNode.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    clefVersion: DEFAULT_VERSION_INTERNAL_CLEF,
  };

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const nodeVersionField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('internal_clef_version')}</div>
            {!!form.errors.clefVersion && form.touched.clefVersion && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.clefVersion}
              </div>
            )}
          </div>
          <div>
            <CustomSelect
              data-testid="node-version-select"
              {...field}
              id="member-cluster-add-ver-node"
              valueSelected={field.value}
              placeholder={t('select_node_version')}
              items={nodeClefSelection}
            />
          </div>
        </>
      );
    },
    [classes, t],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;

      try {
        if (accountSeleted?.accountUuid) {
          await dispatch(
            NetworkActions.migrateToInternalClefNode({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid: network.networkUuid,
                clusterUuid: cluster.clusterUuid,
                nodeUuid: node.nodeUuid,
                version: values.clefVersion || DEFAULT_VERSION_INTERNAL_CLEF,
              },
            }),
          );
          handleCloseDialog();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [accountSeleted, cluster, network, node, dispatch, handleCloseDialog],
  );

  return (
    <>
      <CustomDialog open={open} onClose={handleCloseDialog}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ isSubmitting, values }) => {
            const disabled = semver.lt(
              node.nodeInfo.version,
              MIN_GETH_SUPPORT_EXTERNAL_CLEF_VERSION,
            );
            return (
              <Form>
                <CustomDialogTitle>
                  <div id="member-apply-hardfork-title">{t('migrate_to_internal_clef')}</div>
                </CustomDialogTitle>
                <CustomDialogContent>
                  <div id="member-node-del-detail" className={classes.content}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <div className={classes.itemLabel}>{t('node_version')}</div>
                        <div className={classes.itemValue}>Geth {node.nodeInfo?.version}</div>
                      </Grid>
                      <Grid item md={6}>
                        <div className={classes.itemLabel}>{t('Clef version')}</div>
                        <div className={classes.itemValue}>
                          {disabled ? '...' : <>Clef {values.clefVersion}</>}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {!disabled && (
                    <div className={classes.formSection}>
                      <Field name="clefVersion" render={nodeVersionField} />
                    </div>
                  )}
                  {disabled && (
                    <div id="member-node-migrate-attention" className={classes.warningText}>
                      {t('migrate_internal_clef_warning', { version: node.nodeInfo?.version })}
                    </div>
                  )}
                </CustomDialogContent>
                <CustomDialogActions>
                  <Button
                    data-testid="cancel-button"
                    id="member-apply-hardfork-cancel"
                    disabled={isSubmitting || isNodeUpdating}
                    className={classes.leftBtn}
                    variant="contained"
                    onClick={handleCloseDialog}
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    data-testid="update-button"
                    id="member-apply-hardfork-submit"
                    isValid={!disabled}
                    isSubmitting={isSubmitting || isNodeUpdating}
                    label={t('update')}
                    submittingLabel={t('updating')}
                  />
                </CustomDialogActions>
              </Form>
            );
          }}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(MigrateClefNodeDialog);

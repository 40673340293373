import React from 'react';
import { connect } from 'react-redux';
// Redux
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
import MemberGApiClient from '~/stores/actions/gapi/member-gapi-client';
// Component
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium, defaultFont } from '~/styles/themes/common-styles/font';
import { whiteSmokeColor, dimGrayColor, romanColor } from '~/styles/themes/common-styles/color';
import { blue } from '@mui/material/colors';
// Type
import { INetwork, IConsortium } from '~/types/network-types';
import { Account, Profile } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

interface IStateProps {
  profile?: Profile;
}

interface IDispProps {
  updateNetworkOption: (
    args: NetworkActions.MutationUpdateNetworkOptionArgs,
  ) => Promise<NetworkActions.UPDATE_NETWORK_OPTION_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  network: INetwork;
  account: Account;
  onClose: () => void;
  consortium: IConsortium;
}

interface IState {
  isSubmitting: boolean;
  checked: boolean;
  toggleChecked: boolean;
}

class NetworkOptionDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let enableDeadlockResolver = false;
    if (props.network.options && props.network.options.enableDeadlockResolver) {
      enableDeadlockResolver = props.network.options.enableDeadlockResolver;
    }

    this.state = {
      isSubmitting: false,
      checked: enableDeadlockResolver,
      toggleChecked: enableDeadlockResolver,
    };
  }

  public render() {
    const { classes, open, t } = this.props;
    const { isSubmitting, checked, toggleChecked } = this.state;

    return (
      <CustomDialog open={open} onClose={this.onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle>
          <div id="member-network-invite-title">{t('option')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div className={classes.formSection}>
            <FormControlLabel
              className={classes.switch}
              control={
                <OptionSwitch
                  data-testid="switch-button"
                  checked={checked}
                  onChange={(e, bool) => {
                    this.onDeadlockResolverChange(bool);
                  }}
                  color="primary"
                />
              }
              label={t('deadlock_resolver')}
            />
          </div>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            data-testid="cancel-button"
            id="member-network-invite-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="contained"
            onClick={this.onCancel}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="update-button"
            id="member-network-invite-submit"
            isValid={checked != toggleChecked}
            isSubmitting={isSubmitting}
            label={t('update')}
            submittingLabel={t('updating')}
            onClick={this.onSubmit}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  onDeadlockResolverChange = (value: boolean) => {
    this.setState({ checked: value });
  };

  private onSubmit = async () => {
    const { onClose, updateNetworkOption, network, account, profile, consortium } = this.props;
    if (profile) {
      this.setSubmitting(true);
      try {
        const { checked } = this.state;
        await updateNetworkOption({
          input: {
            accountUuid: account.accountUuid,
            networkUuid: network.networkUuid,
            enableDeadlockResolver: checked,
          },
        });
        this.setState({ checked: checked });
        this.setState({ toggleChecked: checked });
        this.closeDialog();
      } catch (error) {
        // Do nothing
      }
      this.setSubmitting(false);
    }
  };

  private onCancel = async () => {
    const { toggleChecked } = this.state;
    this.setState({ checked: toggleChecked });
    this.setState({ toggleChecked: toggleChecked });
    this.closeDialog();
  };

  private onClose = async () => {
    const { toggleChecked, isSubmitting } = this.state;
    if (!isSubmitting) {
      this.setState({ checked: toggleChecked });
      this.setState({ toggleChecked: toggleChecked });
    }
    this.closeDialog();
  };

  private setSubmitting = (value: boolean) => {
    this.setState({
      isSubmitting: value,
    });
  };

  private closeDialog = () => {
    this.resetForm();
    this.props.onClose();
  };

  private resetForm = () => {
    this.setState({});
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 470,
  },
  dialogTitle: {
    paddingBottom: 25,
  },
  // Form Field
  formSection: {
    maxWidth: 350,
    marginTop: 15,
    marginLeft: 5,
    backgroundColor: whiteSmokeColor,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  switch: {
    marginLeft: 40,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateNetworkOption: (args: NetworkActions.MutationUpdateNetworkOptionArgs) =>
    dispatch(NetworkActions.updateNetworkOption(args)),
});

const OptionSwitch = withStyles({
  switchBase: {
    color: whiteSmokeColor,
    '&$checked': {
      color: blue[700],
    },
    '&$checked + $track': {
      backgroundColor: blue[300],
    },
  },
  track: {},
  checked: {},
})(Switch);

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NetworkOptionDialog)),
);

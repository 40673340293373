import React from 'react';
// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// Component
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium } from '~/styles/themes/common-styles/font';
import { nepalColor, nightRiderColor, dodgerBlueColor } from '~/styles/themes/common-styles/color';
// Type

export interface ITabs {
  title: string;
  tabName: string;
  component: JSX.Element;
  url?: string;
}

interface IStateProps {
  tabs: ITabs[];
}

interface IProps
  extends IStateProps,
    RouteComponentProps<{ tab: string }>,
    WithStyles<typeof styles> {
  id?: string;
  variant?: TabsProps['variant'];
}

const LinkTab = (props) => <Tab {...props} component={Link} />;

class CustomTabs extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { classes, tabs, id, variant } = this.props;
    let tabValue = 0;
    tabValue = tabs
      .map((tab, index) => tab.tabName)
      .findIndex((tabName) => tabName === this.props.match.params.tab);

    return (
      <>
        <Tabs
          value={tabValue > 0 ? tabValue : 0}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
            // scrollable: classes.tabsScrollable,
          }}
          id={id}
          variant={variant}
          scrollButtons={false}
        >
          {tabs.map((tab, index) => (
            <LinkTab
              to={tab.url || '#'}
              key={index}
              label={tab.title}
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              data-testid={tab['data-testid']}
            />
          ))}
        </Tabs>
        <div className={classes.tabBarBottom} />
        {tabs.map((tab, index) => {
          if (this.props.match.params.tab !== tab.tabName) {
            return null;
          }
          return (
            <div key={index} className={classes.content}>
              {tab.component}
            </div>
          );
        })}
      </>
    );
  }
}

const styles = (theme) =>
  createStyles({
    root: {},
    tabRoot: {
      ...defaultFontMedium,
      textTransform: 'initial',
      fontSize: 16,
      color: nepalColor,
      '&$tabSelected': {
        color: nightRiderColor,
        margin: 0,
      },
      minWidth: 100,
      width: 'calc(100%/5)',
      maxWidth: 200,
    },
    tabsRoot: {
      // borderBottom: `1px solid ${nepalColor}`,
    },
    tabsIndicator: {
      backgroundColor: dodgerBlueColor,
      height: 3,
      zIndex: 3,
    },
    tabsScrollable: {
      overflow: 'hidden',
    },
    tabLabelContainer: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    tabSelected: {},
    tabBarBottom: {
      width: '100%',
      height: 3,
      minHeight: 3,
      backgroundColor: 'rgba(153, 170, 185, 0.38)',
      transform: 'translateY(-3px)',
    },
    content: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tabLabelContainer: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  });

export default withStyles(styles)(withRouter(CustomTabs));

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { container } from '~/styles/themes/common-styles/grid-member';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { whiteColor, orientColor } from '~/styles/themes/common-styles/color';

interface IProps {}

class Footer extends React.Component<IProps & WithStyles<typeof styles>, {}> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.footerContent}>
          <div className={classes.label} id="footer-left-rights">
            © G.U.Technologies, Inc.
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

const styles: StyleRules = {
  container,
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: orientColor,
  },
  footerContent: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 25,
  },
  label: {
    ...defaultFont,
    flex: 1,
    color: whiteColor,
    fontSize: 14,
  },
};

export default withStyles(styles)(Footer);

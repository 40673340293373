import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import {
  whiteColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
  lightSlateGreyColor,
  nightRiderColor,
  matisseColor,
  astronautBlueColor,
} from '~/styles/themes/common-styles/color';

// Component
import Footer from '~/components/common/AuthLayout/footer';
import Header from '~/components/common/AuthLayout/header';
import HeaderLinks from './header-links';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
//Redux

// types

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

interface IState {}

class TermsOfServiceLayout extends React.Component<IProps, IState> {
  public render() {
    const { classes, t, children } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.headerBar} />
        <Header
          brand={t('brand')}
          openHeaderBar={false}
          color="whiteTransparent01"
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 100,
            color: 'white',
          }}
          fixed={true}
        />
        {children}
        <Footer />
      </div>
    );
  }
}

const styles = createStyles({
  root: {
    height: '100vh',
    minHeight: 900,
  },
  headerBar: {
    position: 'fixed',
    top: 0,
    height: 3,
    width: '100%',
    background: astronautBlueColor,
    zIndex: 1000,
  },
});

export default withStyles(styles)(withTranslation('member')(TermsOfServiceLayout));

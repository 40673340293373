import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';

interface IProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  disabled?: boolean;
  name?: string;
  onBlur?: any;
  id?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  prefix?: string;
  isAllowed?: (value: number | undefined) => void;
}

const customInputNumCommaFormat: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  placeholder,
  value,
  onChange,
  disabled,
  name,
  onBlur,
  id,
  decimalScale,
  fixedDecimalScale,
  prefix,
  isAllowed,
  ...props
}) => {
  return (
    <NumberFormat
      {...props}
      name={name}
      onBlur={onBlur}
      type="text"
      thousandsGroupStyle="thousand"
      className={classNames(classes.input, className)}
      value={value}
      prefix={prefix}
      placeholder={placeholder}
      onValueChange={onChange}
      disabled={disabled}
      id={id}
      decimalSeparator="."
      displayType="input"
      thousandSeparator={true}
      allowNegative={false}
      decimalScale={decimalScale}
      defaultValue={value}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};

customInputNumCommaFormat.defaultProps = {
  disabled: false,
};

const styles: StyleRules = {
  root: {},
  input: {
    ...defaultFont,
    fontSize: 13,
    '&::placeholder': {
      color: lightSlateGreyColor,
    },
    width: '100%',
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: whiteSmokeColor,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
  },
  textarea: {
    height: 60,
  },
};

export default withStyles(styles)(customInputNumCommaFormat);

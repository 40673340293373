import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
// Component
import Header from './auth-header';
import HeaderLinks from './auth-header-links';
import Footer from './footer';

interface IProps {}

interface IState {
  openHeaderBar: boolean;
}

class AuthLayout extends React.Component<
  IProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      openHeaderBar: false,
    };
  }

  public componentDidMount() {}

  public render() {
    const { classes, t, children } = this.props;
    const { openHeaderBar } = this.state;
    return (
      <div className={classes.root}>
        <Header
          brand={t('brand')}
          openHeaderBar={openHeaderBar}
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 100,
            color: 'white',
          }}
          fixed={true}
        />
        {children}
        <Footer />
      </div>
    );
  }
}

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(withTranslation()(AuthLayout));

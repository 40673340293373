import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  whiteColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
import { IStore } from '~/stores/configure-store';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import { ICluster, INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
import SubmitButton from './submit-button';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

interface IStateProps {
  accountSeleted?: Account;
}

interface IDispProps {
  deleteCluster: (
    args: NetworkActions.MutationDeleteClusterArgs,
  ) => Promise<NetworkActions.DELETE_CLUSTER_RESULT_TYPE>;
  checkCluster: (
    args: NetworkActions.QueryCheckClusterRemovalStatusArgs,
  ) => Promise<NetworkActions.CLUSTER_REMOVAL_STATUS_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps,
    WithStyles<typeof styles>,
    WithTranslation {
  network: INetwork;
  cluster: ICluster;
  open: boolean;
  onClose: () => void;
}

interface IState {
  isSubmitting: boolean;
}

class DeleteClusterDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
  }

  public render() {
    const { classes, open, onClose, cluster, t } = this.props;

    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose} scroll="body">
        <CustomDialogTitle>
          <div id="member-cluster-del-title">{t('delete_cluster')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div id="member-cluster-del-detail" className={classes.contentArea}>
            <div className={classNames(classes.clusterItemInfo)}>
              <div className={classes.subTitle}>{t('cluster_name')}</div>
              <div className={classes.detailValue}>{cluster.clusterName}</div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{t('cluster_id')}</div>
              <div className={classNames(classes.detailValue)}>{cluster.clusterUuid}</div>
            </div>
            <div className={classNames(classes.clusterItemInfo)}>
              <div className={classes.subTitle}>{t('endpoint')}</div>
              <div className={classes.detailValue}>{cluster.endpointUrl}</div>
            </div>
          </div>

          <div id="member-cluster-del-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_delete_this_cluster')}
          </div>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            data-testid="cancel-button"
            id="member-cluster-del-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="contained"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="delete-button"
            id="member-cluster-del-submit"
            isValid={true}
            isSubmitting={isSubmitting}
            label={t('delete')}
            submittingLabel={t('deleting')}
            onClick={this.onSubmit}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  private onSubmit = async () => {
    const {
      deleteCluster,
      checkCluster,
      openSnackBar,
      network,
      cluster,
      onClose,
      t,
      accountSeleted,
    } = this.props;

    if (!accountSeleted) {
      return;
    }

    this.setState({ isSubmitting: true });

    try {
      if (network.clusters.length > 1) {
        // check status before start
        const { checkClusterRemovalStatus: status } = await checkCluster({
          accountUuid: accountSeleted.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
        });

        if (status.detectBcSigner) {
          openSnackBar({
            type: 'error',
            message: t('the_cluster_still_has_more_than_1_validator_node'),
          });
          throw new Error('the_cluster_still_has_more_than_1_validator_node');
        }

        if (!status.syncedBlock || !status.syncedBcSigner) {
          if (!window.confirm(t('warn_maybe_not_synced_network'))) {
            throw new Error('canceled');
          }
        }
      }

      // do action
      await deleteCluster({
        input: {
          accountUuid: accountSeleted.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
        },
      });

      onClose();
      this.setState({ isSubmitting: false });
      this.props.history.push(`/network/${network.networkUuid}/overview`);
    } catch (err) {
      this.setState({ isSubmitting: false });
    }
  };
}

const styles: StyleRules = {
  root: {},
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  contentArea: {
    display: 'grid',
    padding: 30,
    gridRowGap: '15px',
    gridColumnGap: '15px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
  },
  infoItem: {
    flex: 1,
    width: '50%',
    maxWidth: '50%',
  },
  leftInfoItemContent: {
    margin: '30px 0 30px 30px',
  },
  rightInfoItemContent: {
    margin: '30px 30px 30px 0',
  },
  clusterItemInfo: {
    margin: 0,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
    wordBreak: 'break-all',
  },
  warningText: {
    ...defaultFont,
    fontSize: 18,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
};

const mapStateToProps = (store: IStore) => ({
  accountSeleted: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  deleteCluster: (args: NetworkActions.MutationDeleteClusterArgs) =>
    dispatch(NetworkActions.deleteCluster(args)),
  checkCluster: (args: NetworkActions.QueryCheckClusterRemovalStatusArgs) =>
    dispatch(NetworkActions.checkClusterRemovalStatus(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteClusterDialog))),
);

export const minWidthXS = 0;
export const minWidthSM = 750;
export const minWidthMD = 970;
export const minWidthLG = 1170;
export const minWidthXL = 1400;

export const conatinerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  // width: '100%',
};
export const container = {
  ...conatinerFluid,
  '@media (min-width: 576px)': {
    width: 'calc(100% - 0px)',
    paddingLeft: 10,
    paddingRight: 10,
  },
  '@media (min-width: 768px)': {
    width: 'calc(100% - 30px)',
  },
  '@media (min-width: 992px)': {
    width: 'calc(100% - 30px)',
  },
  '@media (min-width: 1200px)': {
    width: 'calc(100% - 30px)',
    maxWidth: 1400,
  },
};

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { Order } from '~/utilities/sort-utils';

interface IProps {
  order?: Order;
}

const SortIcon = ({ classes, order }: IProps & WithStyles<typeof styles>) =>
  order === 'ASC' ? (
    <img src="/images/icons/up_ico_on.svg" alt="" className={classes.icon} />
  ) : order === 'DESC' ? (
    <img src="/images/icons/down_ico_on.svg" alt="" className={classes.icon} />
  ) : (
    <img src="/images/icons/down_ico_off.svg" alt="" className={classes.icon} />
  );

const styles = createStyles({
  icon: {
    width: 11,
    height: 7,
    userSelect: 'none',
  },
});

export default withStyles(styles)(SortIcon);

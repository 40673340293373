/**
 *
 * Tax master module
 *
 */

import moment from 'moment';

/*
 * TODO : Migrate to MongoDB in the future
 */
export const TAX_RATE_BASE = 100;

// VAT master object
export const TAX_VAT_RATE: {
  [key: string]: {
    rate: number;
    start: Date;
    end?: Date;
  }[];
} = {
  JP: [
    {
      rate: 10,
      start: moment('Tue, 01 Oct 2019 00:00:00 +0900').utc().toDate(),
    },
  ],
};

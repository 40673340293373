import React from 'react';

interface IProps {
  imgUrl: string;
  className?: string;
}

const imgIcon: React.FunctionComponent<IProps> = ({ imgUrl, className }) => {
  const src = imgUrl;
  const ext = imgUrl.split('.').pop() || '';
  const pathWithName = imgUrl.slice(0, imgUrl.length - ext.length - 1);
  const srcSet = `${imgUrl} 1x, ${pathWithName}@2x.${ext} 2x, ${pathWithName}@3x.${ext} 3x`;

  return <img src={src} srcSet={srcSet} className={className} />;
};

export default imgIcon;

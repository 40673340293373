import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import SubmitButton from './submit-button';

import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import CustomTextField from './custom-text-field';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Explorer } from '~/gapi/gtypes';
import Grid from '@mui/material/Grid';

interface IProps extends WithStyles<typeof styles> {
  blockExplorer: Explorer;
  open: boolean;
  onSubmit: (cleanBlockscoutDb?: boolean) => void;
  onClose: () => void;
}

type FormValues = {
  cleanBlockscoutDb?: boolean;
};

const RebuildBlockExplorerDialog = (props: IProps) => {
  const { classes, open, blockExplorer, onClose, onSubmit } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.updateNodeVersion.isPending(store),
  );
  const { t } = useTranslation();

  const initialValues: FormValues = {};

  const onCheckboxChange = useCallback((value, form: FormikProps<FormValues>) => {
    form.setFieldValue('cleanBlockscoutDb', value);
    setChecked(value);
  }, []);

  const cleanBlockscoutDbCheckbox = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div>
          <FormControlLabel
            {...field}
            checked={checked}
            className={classes.formControlLabel}
            control={
              <Checkbox
                data-testid="clean-db-checkbox"
                color="default"
                onChange={(e, value) => onCheckboxChange(value, form)}
              />
            }
            label={<span className={classes.formLabel}>{t('clean_blockscout_db')}</span>}
          />
        </div>
      );
    },
    [checked, classes.formControlLabel, classes.formLabel, onCheckboxChange, t],
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { cleanBlockscoutDb } = values;

      try {
        if (accountSeleted?.accountUuid) {
          onSubmit(cleanBlockscoutDb);
          onClose();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [accountSeleted?.accountUuid, onClose, onSubmit],
  );

  const renderWarningText = useCallback(
    (values: FormValues) => {
      if (checked) {
        return (
          <div id="member-rebuild-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_clean_db')}
          </div>
        );
      }
      return <></>;
    },
    [checked, classes.warningText, t],
  );

  const handleCloseDialog = useCallback(() => {
    setChecked(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <CustomDialog open={open} onClose={handleCloseDialog}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-upgrade-node-ver-title">{t('rebuild_block_exp')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div id="member-node-del-detail" className={classes.content}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('block_exp_type')}</div>
                      <div className={classes.itemValue}>
                        {blockExplorer.serverInfo?.explorerType}
                      </div>
                    </Grid>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('node_version')}</div>
                      <div className={classes.itemValue}>{blockExplorer.nodeInfo?.version}</div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <Field name="cleanBlockscoutDb" render={cleanBlockscoutDbCheckbox} />
                </div>
                {renderWarningText(values)}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-upgrade-node-ver-cancel"
                  disabled={isSubmitting || isNodeUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="submit-rebuild-explorer-button"
                  id="member-upgrade-node-ver-submit"
                  isValid={true}
                  isSubmitting={isSubmitting || isNodeUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
});

export default withStyles(styles)(RebuildBlockExplorerDialog);

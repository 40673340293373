import React from 'react';
import { connect } from 'react-redux';
// Redux
import { IStore } from '~/stores/configure-store';
// Component
import Button from '@mui/material/Button';
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomSelect from './custom-select';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// Form
import countryCodes from 'i18n-iso-countries/entry-node';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontBold,
} from '~/styles/themes/common-styles/font';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// Type
import * as AppActions from '~/stores/actions/app-action';
import * as PaymentAction from '~/stores/actions/payment-action';
import { Account, IAddress, Profile } from '~/types/account-types';
import { IGeolocation } from '~/types/geolocation-types';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
// Util
import { isDevMode } from '~/utilities/utils';
import { VALIDATE_POSTAL_CODE_PATTERN } from '~/constants/validation';
import { getAddressFromZipcode } from '~/utilities/utils';
import { DEFAULT_COUNTRY_CODE } from '~/constants/consts';

interface IStateProps {
  account?: Account;
  profile?: Profile;
  geolocation?: IGeolocation;
}

interface IDispProps {
  registerAddress: (
    args: AppActions.MutationRegisterAddressArgs,
  ) => Promise<AppActions.REGISTER_ADDRESS_RESULT_TYPE>;
  listActiveLicensesSummary: (
    args: PaymentAction.QueryListActiveLicensesSummaryArgs,
  ) => Promise<PaymentAction.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: (status?: boolean) => void;
}

interface IState {}

class RegisterAddressDialog extends React.Component<IProps, IState> {
  private validateSchema = Yup.lazy<IAddress>((values) => {
    if (values.country === DEFAULT_COUNTRY_CODE) {
      return Yup.object().shape({
        country: Yup.string()
          .trim()
          .required(this.props.t('required_field'))
          .oneOf(Object.keys(countryCodes.getAlpha2Codes()), this.props.t('invalid_country_code')),
        postalCode: Yup.string()
          .trim()
          .required(this.props.t('required_field'))
          .matches(VALIDATE_POSTAL_CODE_PATTERN, this.props.t('invalid_postal_code')),
        state: Yup.string()
          .trim()
          .required(this.props.t('billing_address_dialog_state_required_error_text')),
        city: Yup.string()
          .trim()
          .required(this.props.t('billing_address_dialog_city_required_error_text')),
        address1: Yup.string()
          .trim()
          .required(this.props.t('billing_address_dialog_address_1_required_error_text')),
        address2: Yup.string(),
      });
    } else {
      return Yup.object().shape({
        country: Yup.string()
          .trim()
          .required(this.props.t('required_field'))
          .oneOf(Object.keys(countryCodes.getAlpha2Codes()), this.props.t('invalid_country_code')),
        postalCode: Yup.string(),
        state: Yup.string()
          .trim()
          .required(this.props.t('billing_address_dialog_state_required_error_text')),
        city: Yup.string()
          .trim()
          .required(this.props.t('billing_address_dialog_city_required_error_text')),
        address1: Yup.string()
          .trim()
          .required(this.props.t('billing_address_dialog_address_1_required_error_text')),
        address2: Yup.string(),
      });
    }
  });

  constructor(props: IProps & IDispProps) {
    super(props);
  }

  public render() {
    const { classes, open, t, account, geolocation } = this.props;
    const addr: IAddress = (account && account.paymentAddr) || {
      country: geolocation ? geolocation.countryCode : DEFAULT_COUNTRY_CODE,
      postalCode: '',
      state: '',
      city: '',
      address1: '',
      address2: '',
    };

    return (
      <CustomDialog
        open={open}
        onClose={this.onDialogCancel}
        classes={{ paper: classes.dialogPaper }}
      >
        <Formik
          initialValues={addr}
          validationSchema={this.validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting, errors, touched }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-billing-detail-update-title">
                  {t('billing_address_dialog_title')}
                </div>
              </CustomDialogTitle>
              {/* Render Form Field */}
              <CustomDialogContent classes={{ root: classes.dialogContentRoot }}>
                <div className={classes.description}>{t('billing_address_dialog_description')}</div>
                <div className={classes.formSection}>
                  <Field name="country" render={this.countryField} />
                </div>
                <div className={classes.formSection}>
                  <Field name="postalCode" render={this.postalCodeField} />
                </div>
                <div className={classes.formSection}>
                  <div className={classes.formLabelLine}>
                    <div className={classes.formLabel}>
                      {t('billing_address_dialog_address_label')}
                    </div>
                    {!!errors.state && touched.state ? (
                      <div className={classNames(classes.formLabel, classes.formError)}>
                        {t(errors.state)}
                      </div>
                    ) : !!errors.city && touched.city ? (
                      <div className={classNames(classes.formLabel, classes.formError)}>
                        {t(errors.city)}
                      </div>
                    ) : (
                      !!errors.address1 &&
                      touched.address1 && (
                        <div className={classNames(classes.formLabel, classes.formError)}>
                          {t(errors.address1)}
                        </div>
                      )
                    )}
                  </div>
                  <Field name="state" render={this.stateField} />
                </div>
                <div className={classes.formSection}>
                  <Field name="city" render={this.cityField} />
                </div>
                <div className={classes.formSection}>
                  <Field name="address1" render={this.address1Field} />
                </div>
                <div className={classes.formSection}>
                  <Field name="address2" render={this.address2Field} />
                </div>
              </CustomDialogContent>
              {/* Render Submit Button */}
              <CustomDialogActions classes={{ root: classes.dialogActionRoot }}>
                <Button
                  data-testid="cancel-button"
                  id="member-billing-detail-udpate-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={this.onDialogCancel}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="register-button"
                  id="member-billing-detail-udpate-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('register')}
                  submittingLabel={t('register')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private onDialogCancel = () => {
    this.props.onClose(false);
  };

  private onSubmit = async (values: IAddress, formikActions: FormikActions<IAddress>) => {
    const { onClose, registerAddress, listActiveLicensesSummary, account, t } = this.props;
    const { setSubmitting } = formikActions;

    if (isDevMode()) {
      console.log(values);
    }
    if (account) {
      try {
        await registerAddress({
          accountUuid: account.accountUuid,
          address: {
            country: values.country.trim(),
            postalCode: values.postalCode.trim(),
            state: values.state.trim(),
            city: values.city.trim(),
            address1: values.address1.trim(),
            address2: values.address2.trim(),
          },
        });
        onClose(true);

        // reload licenses list (related with country/tax detection)
        await listActiveLicensesSummary({ accountUuid: account.accountUuid }).catch((_) => void 0);
      } catch (err) {
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      if (window) {
        window.alert(t('account_not_selected'));
      }
    }
  };

  private countryField = ({ field, form }: FieldProps<IAddress>) => {
    const { classes, t, profile } = this.props;

    const langDefault = 'en';
    const langUser = (profile && profile.preference.language) || langDefault;
    const langOkay = countryCodes.langs().includes(langUser) ? langUser : langDefault;

    const countryList = Object.entries(countryCodes.getNames(langOkay)).map(([k, v]) => ({
      value: k as string,
      label: v as string,
    }));

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('billing_address_dialog_country_label')}</div>
          {!!form.errors.country && form.touched.country && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.country}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            data-testid="country-select"
            onChange={(event) => this.onCountryFieldChange(event, form)}
            id="member-billing-detail-update-country"
            valueSelected={field.value}
            placeholder={t('select')}
            items={countryList}
          />
        </div>
      </>
    );
  };

  private onCountryFieldChange = (event, form: FormikProps<IAddress>) => {
    form.setFieldValue('country', event.target.value);
    form.setFieldValue('city', '');
    form.setFieldTouched('city', false);
    form.setFieldValue('state', '');
    form.setFieldTouched('state', false);
    form.setFieldValue('postalCode', '');
    form.setFieldTouched('postalCode', false);
    form.setFieldValue('address1', '');
    form.setFieldValue('address2', '');
  };

  private postalCodeField = ({ field, form }: FieldProps<IAddress>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('billing_address_dialog_postcode_label')}</div>
          {!!form.errors.postalCode && form.touched.postalCode && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.postalCode)}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-billing-detail-update-postalcode"
            placeholder={t('billing_address_dialog_postcode_placeholder')}
            onBlur={(event) => this.onPostalCodeFieldBlur(event, form)}
          />
        </div>
      </>
    );
  };
  private onPostalCodeFieldBlur = async (event, form: FormikProps<IAddress>) => {
    if (form.values.country === DEFAULT_COUNTRY_CODE) {
      form.setFieldTouched('postalCode', true);
      const postalCode = event.target.value;
      const data = await getAddressFromZipcode(postalCode);
      if (data.pref) {
        form.setFieldValue('state', data.pref);
        form.setFieldValue('city', data.city);
        form.setFieldValue('address1', data.town);
        if (postalCode.length === 7) {
          form.setFieldValue('postalCode', postalCode.slice(0, 3) + '-' + postalCode.slice(3));
        }
      }
    }
  };

  private stateField = ({ field, form }: FieldProps<IAddress>) => {
    const { classes, t } = this.props;
    return (
      <div>
        <CustomInput
          {...field}
          data-testid="state-input"
          id="member-billing-detail-update-state"
          placeholder={t('billing_address_dialog_state_placeholder')}
        />
      </div>
    );
  };

  private cityField = ({ field, form }: FieldProps<IAddress>) => {
    const { classes, t } = this.props;
    return (
      <div>
        <CustomInput
          {...field}
          data-testid="city-input"
          id="member-billing-detail-update-city"
          placeholder={t('billing_address_dialog_city_placeholder')}
        />
      </div>
    );
  };

  private address1Field = ({ field, form }: FieldProps<IAddress>) => {
    const { classes, t } = this.props;
    return (
      <div>
        <CustomInput
          {...field}
          data-testid="address1-input"
          id="member-billing-detail-update-address1"
          placeholder={t('billing_address_dialog_address_1_placeholder')}
        />
      </div>
    );
  };

  private address2Field = ({ field, form }: FieldProps<IAddress>) => {
    const { classes, t } = this.props;
    return (
      <div>
        <CustomInput
          {...field}
          data-testid="address2-input"
          id="member-billing-detail-update-address2"
          placeholder={t('billing_address_dialog_address_2_placeholder')}
        />
      </div>
    );
  };
}

const styles = createStyles({
  dialogPaper: {
    maxWidth: 440,
  },
  dialogContentRoot: {
    paddingLeft: 80,
    paddingRight: 80,
  },
  dialogActionRoot: {
    paddingLeft: 80,
    paddingRight: 80,
  },
  description: {
    ...defaultFontMedium,
    fontSize: 12,
    color: lightSlateGreyColor,
    textAlign: 'center',
  },
  // Form Field
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontBold,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 10,
    paddingRight: 10,
    textTransform: 'none',
    marginRight: 10,
    marginLeft: 0,
    width: 110,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  profile: store.appState.profile,
  geolocation: store.appState.geolocation,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  registerAddress: (args: AppActions.MutationRegisterAddressArgs) =>
    dispatch(AppActions.registerAddress(args)),
  listActiveLicensesSummary: (args: PaymentAction.QueryListActiveLicensesSummaryArgs) =>
    dispatch(PaymentAction.listActiveLicensesSummary(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RegisterAddressDialog)),
);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';

import { INode, ICluster, INetwork } from '~/types/network-types';
import NodeStatusIcon from '~/components/common/node-status-icon';
import {
  romanColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
  denimColor,
  whiteColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { displayNodeStatus } from '~/utilities/render-utils';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStore } from '~/stores/configure-store';

interface IProps extends WithStyles<typeof styles> {
  allNodes: Array<{
    network: INetwork;
    cluster: ICluster;
    node: INode;
  }>;
  open: boolean;
  onClose: () => void;
}

const ListStaticIpAddressDialog = (props: IProps) => {
  const { classes, open, allNodes, onClose } = props;
  const { t } = useTranslation();
  const account = useSelector((store: IStore) => store.appState.accountSeleted);

  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <CustomDialogTitle>
          <div id="member-maximum-static-ip-title">{t('maximum_static_ip_address')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div id="member-node-del-detail" className={classes.content}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {allNodes.length > 0 && (
                  <>
                    <div className={classes.formLabel}>{t('list_associated_ip_address')}</div>
                    <div className={classNames(classes.leftContentItem)}>
                      {allNodes.map(({ network, cluster, node }) => {
                        return (
                          <div id="information" className={classes.nodeItem}>
                            <div className={classes.nodeName}>
                              <NodeStatusIcon
                                className={classes.nodeStatusIcon}
                                status={displayNodeStatus(node)}
                              />
                              <Link
                                className={classNames(
                                  classes.nodeLinkText,
                                  classes.tableBodyTdState,
                                )}
                                target="_blank"
                                to={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/overview`}
                              >
                                {node.nodeName}
                              </Link>
                            </div>
                            <div className={classNames(classes.staticIp)}>
                              {node.serverInfo.ip} ({t('static')})
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div className={classes.warningText}>{t('maximum_static_ip_address_desc')}</div>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            id="member-maximum-static-ip-address-cancel"
            data-testid="maximum-static-ip-address-cancel"
            disabled={false}
            className={classes.leftBtn}
            variant="contained"
            onClick={onClose}
          >
            {t('Close')}
          </Button>
        </CustomDialogActions>
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  leftContentItem: {
    padding: 0,
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  staticIp: {
    ...defaultFont,
    fontSize: 13,
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    whiteSpace: 'nowrap',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 15,
    // marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    // color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    color: whiteColor,
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    // marginRight: 10,
  },
  nodeItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 34,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: whiteSmokeColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  nodeStatusIcon: {
    marginRight: 10,
  },
  nodeName: {
    ...defaultFont,
    fontSize: 14,
    color: denimColor,
    width: '70%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },
  nodeNameText: {
    width: '50%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  nodeLinkText: {
    color: denimColor,
  },
  tableBodyTdState: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(ListStaticIpAddressDialog);

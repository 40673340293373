import React, { useCallback, useMemo, useState } from 'react';
import ms from 'ms';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import { useDispatch, useSelector } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
import CustomSelect from '~/components/common/custom-select';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  denimColor,
  persianGreenColor,
  matterhornColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { hardForkSelection, periodTargetBlockSelection } from '~/types/network-selection';
import { HARD_FORK_CONFIGURATION } from '~/constants/consts';
import CompatibleNodeTable from './compatible-node-table';

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (isFetchData?: boolean) => void;
}

type FormValues = {
  name: string;
  periodTargetBlock: string;
};

const CreateHardForkProposalDialog = (props: IProps) => {
  const { classes, open, onClose } = props;
  const [hardForkName, setHardForkName] = useState<string | undefined>();
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const networkSelected = useSelector((store: IStore) => store.appState.networkSelected);
  const isPending = useSelector((store: IStore) =>
    NetworkActions.createHardForkProposal.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    name: '',
    periodTargetBlock: '1d',
  };

  const validateSchema = Yup.object().shape<FormValues>({
    name: Yup.string().trim(),
    periodTargetBlock: Yup.string().trim() as any,
  });

  const onHardForkNameChange = useCallback((event, form: FormikProps<FormValues>) => {
    form.setFieldValue('name', event.target.value);
    setHardForkName(event.target.value);
  }, []);

  const hardForkNameField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('name')}</div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.name}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="datatestid-select"
            {...field}
            id="hard-fork-name"
            valueSelected={field.value}
            placeholder={t('name')}
            items={hardForkSelection}
            onChange={(event) => onHardForkNameChange(event, form)}
          />
        </div>
      </>
    );
  };

  const periodTargetBlockField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('period_reach_target_block')}</div>
          {!!form.errors.periodTargetBlock && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.periodTargetBlock}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="datatestid-select"
            {...field}
            id="hard-fork-period"
            valueSelected={field.value}
            items={periodTargetBlockSelection}
          />
        </div>
      </>
    );
  };

  const handleCloseDialog = useCallback(
    (isFetchData?: boolean) => {
      setHardForkName(undefined);
      onClose(isFetchData);
    },
    [onClose],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      try {
        if (accountSeleted?.accountUuid && networkSelected) {
          await dispatch(
            NetworkActions.createHardForkProposal({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid: networkSelected.networkUuid,
                name: values.name,
                periodTargetBlock: ms(values.periodTargetBlock),
              },
            }),
          );
          handleCloseDialog(true);
          formikActions.setSubmitting(false);
        }
      } catch (error) {
        formikActions.setSubmitting(false);
      }
    },
    [accountSeleted?.accountUuid, dispatch, handleCloseDialog, networkSelected],
  );

  const signerNodes = useMemo(
    () =>
      networkSelected?.clusters
        .map((c) => c.nodes.filter((n) => n.nodeInfo.signer))
        .reduce((pre, cur) => {
          pre.push(...cur);
          return pre;
        }, []) || [],
    [networkSelected?.clusters],
  );

  const isCompatibleNode = useMemo(() => {
    const hardFork = HARD_FORK_CONFIGURATION.find((val) => val.value === hardForkName);

    const compatibaleSignerNodes = signerNodes.filter(
      (node) => !hardFork?.incompatibleGeths.includes(node.nodeInfo.version),
    );
    if (compatibaleSignerNodes.length > Math.floor(signerNodes.length / 2)) {
      return true;
    }
    return false;
  }, [hardForkName, signerNodes]);

  const existSyncingOrIncompatibleNode = useMemo(() => {
    const hardFork = HARD_FORK_CONFIGURATION.find((val) => val.value === hardForkName);
    return signerNodes.some(
      (node) =>
        node.nodeInfo.syncing || hardFork?.incompatibleGeths.includes(node.nodeInfo.version),
    );
  }, [hardForkName, signerNodes]);

  return (
    <>
      <CustomDialog
        open={open}
        onClose={handleCloseDialog}
        scroll={hardForkName ? 'body' : undefined}
        classes={{ paper: classes.paper }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-create-hard-fork-schedule-title">
                  {t('create_hard_fork_proposal')}
                </div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Grid container>
                    <Grid item md={6} className={classes.gridLeftItem}>
                      <Field name="name" render={hardForkNameField} />
                    </Grid>
                    <Grid item md={6} className={classes.gridRightItem}>
                      <Field name="periodTargetBlock" render={periodTargetBlockField} />
                    </Grid>
                  </Grid>
                </div>
                {hardForkName && (
                  <>
                    <CompatibleNodeTable
                      hardForkName={hardForkName}
                      network={networkSelected}
                      account={accountSeleted}
                    />
                    {!isCompatibleNode && (
                      <div id="member-node-attention" className={classes.warningText}>
                        {t('many_node_incompatible_geth_version')}
                      </div>
                    )}
                    {isCompatibleNode && existSyncingOrIncompatibleNode && (
                      <div id="member-node-attention" className={classes.warningText}>
                        {t('create_hard_fork_node_not_ready_warning')}
                      </div>
                    )}
                  </>
                )}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-create-hard-fork-schedule-cancel"
                  disabled={isSubmitting || isPending}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={() => handleCloseDialog()}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="create-button"
                  id="member-create-hard-fork-schedule-submit"
                  isValid={isValid && isCompatibleNode}
                  isSubmitting={isSubmitting || isPending}
                  label={t('create')}
                  submittingLabel={t('creating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    paper: {
      maxWidth: 800,
    },
    content: {
      marginTop: 10,
      padding: 10,
      paddingBottom: 0,
      backgroundColor: snowColor,
      borderRadius: 4,
      border: `1px solid ${pattensBlueColor}`,
      boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
      wordBreak: 'break-word',
    },
    formLabel: {
      ...defaultFontMedium,
      fontSize: 12,
      marginBottom: 5,
    },
    formControlLabel: {
      marginLeft: '-14px',
      '& .MuiCheckbox-root': {
        padding: '12px',
      },
    },
    formSection: {},
    formLabelLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    formError: {
      color: romanColor,
    },
    warningText: {
      ...defaultFont,
      fontSize: 16,
      color: romanColor,
      textAlign: 'center',
      marginTop: 10,
    },
    leftBtn: {
      ...defaultFont,
      color: dimGrayColor,
      fontSize: 14,
      height: 36,
      backgroundColor: whiteSmokeColor,
      '&:hover': {
        backgroundColor: whiteSmokeColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      textTransform: 'none',
      marginRight: 10,
    },
    gridLeftItem: {
      paddingRight: 6,
      maxWidth: '50%',
    },
    gridRightItem: {
      paddingLeft: 6,
      maxWidth: '50%',
    },
  });

export default withStyles(styles)(CreateHardForkProposalDialog);

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Formik, Field, Form, FieldProps, FormikActions, FormikErrors, FieldArray } from 'formik';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import * as Yup from 'yup';
import IPCIDR from 'ip-cidr';
import publicIp from 'public-ip';
import * as semver from 'semver';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
import { IStore } from '~/stores/configure-store';
import { ICluster, INetwork } from '~/types/network-types';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import CustomInputControlled from '~/components/common/custom-input-controlled';
import CustomSelect from '~/components/common/custom-select';
import SubmitButton from '~/components/common/submit-button';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
// React i18next
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import {
  endpointServicesPortSelection,
  endpointServicesTypeSelection,
  switchCidrInputType,
} from '~/types/network-selection';
import {
  VALIDATE_SECURITY_DESC_PATTERN,
  VALIDATE_SECURITY_DESC_LENGTH,
} from '~/constants/validation';
import { EndpointTypeListenEnum } from '~/gapi/gtypes';
import { DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2 } from '~/constants/consts';
import CustomConfirmDialog from '~/components/common/custom-confirm-dialog';

interface ILoadlbalancerListener {
  type: EndpointTypeListenEnum;
  port: number;
  cidr: string;
  desc: string;
}

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (isFetch?: boolean) => void;
  network: INetwork;
  cluster: ICluster;
  listeners: ILoadlbalancerListener[];
}

type FormValuesRule = {
  type: string;
  cidrType: string;
  cidr: string;
  desc: string;
  port?: string;
};

type FormValues = {
  listeners: FormValuesRule[];
};

const EditEndpointListenersDialog = (props: IProps) => {
  const { open, classes, network, cluster, listeners, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accountSeleted = useSelector((state: IStore) => state.appState.accountSeleted);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [formik, setFormikActions] = useState<FormikActions<FormValues> | undefined>();
  const [formikValues, setFormikValues] = useState<FormValues>();

  const initialValues: FormValues = {
    listeners:
      listeners.length > 0
        ? listeners.map((r) => ({
            type: r.type,
            cidrType: 'custom',
            cidr: r.cidr,
            desc: r.desc,
            port: r.port.toString(),
          }))
        : [],
  };

  const validateSchema = Yup.object().shape({
    listeners: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .required(t('required_field'))
          .oneOf(
            endpointServicesTypeSelection.map((sel) => sel.value),
            t('invalid_protocol_type'),
          ),
        port: Yup.string()
          .required(t('required_field'))
          .oneOf(
            endpointServicesPortSelection.map((sel) => sel.value),
            t('invalid_port'),
          ),
        cidr: Yup.string()
          .required(t('required_field'))
          .test('cidr-v4-check', t('invalid_ipv4_cidr_format'), function (cidrV4) {
            const addr = new IPCIDR(cidrV4);
            return addr.isValid();
          }),
        desc: Yup.string()
          .max(
            VALIDATE_SECURITY_DESC_LENGTH,
            t('validate_maximum_length', { val: VALIDATE_SECURITY_DESC_LENGTH }),
          )
          .matches(
            VALIDATE_SECURITY_DESC_PATTERN,
            t('desc_of_secvurity_allows_alpha_numeric_symbol'),
          ),
      }),
    ),
  });

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>, confirm = false) => {
      const { setSubmitting } = formikActions;
      const errors = [];
      try {
        setSubmitting(true);
        if (!accountSeleted) {
          return;
        }
        const listenerMap: {
          [key: string]: {
            port: number;
            type: EndpointTypeListenEnum;
            inbound: Array<{ cidr: string; desc: string }>;
          };
        } = {};

        for (const listener of values.listeners) {
          if (listenerMap[`${listener.type}`]) {
            if (listenerMap[`${listener.type}`].port !== Number(listener.port)) {
              errors.push(t('exist_many_port_each_service', { service: t(listener.type) }));
            }
            const cidrs = listenerMap[`${listener.type}`].inbound.map(
              (inbound: any) => inbound.cidr,
            );
            if (cidrs.includes(listener.cidr)) {
              errors.push(t('duplicate_listener_rule', { service: t(listener.type) }));
            }
            listenerMap[`${listener.type}`].inbound.push({
              cidr: listener.cidr,
              desc: listener.desc || '',
            });
          } else {
            listenerMap[`${listener.type}`] = {
              type: listener.type as EndpointTypeListenEnum,
              port: Number(listener.port),
              inbound: [
                {
                  cidr: listener.cidr,
                  desc: listener.desc || '',
                },
              ],
            };
          }
        }

        // Check duplicate listener port
        const _listeners = Object.entries(listenerMap).map(([_, v]) => v);
        const listenerPorts = _listeners.map((val) => val.port);
        if (listenerPorts.length !== new Set(listenerPorts).size) {
          errors.push(t('duplicate_listener_port'));
        }
        if (errors.length > 0) {
          dispatch(AppActions.openSnackBar({ message: errors[0], type: 'error' }));
          return;
        }
        // Block explorer change or not
        const curExplorer = listeners.find((val) => val.type === 'BLOCK_EXPLORER');
        const explorer = _listeners.find((val) => val.type === 'BLOCK_EXPLORER');
        const existNewBlockExplorerUI =
          cluster.explorer &&
          semver.gte(
            cluster.explorer.blockscoutInfo.backend.version,
            DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
          );

        if (
          !(curExplorer?.port === explorer?.port) &&
          explorer &&
          existNewBlockExplorerUI &&
          !confirm
        ) {
          setFormikActions(formikActions);
          setFormikValues(values);
          setOpenConfirmDialog(true);
          return;
        }

        await dispatch(
          NetworkActions.setEndpointListener({
            input: {
              accountUuid: accountSeleted.accountUuid,
              networkUuid: network.networkUuid,
              clusterUuid: cluster.clusterUuid,
              listeners: _listeners,
            },
          }),
        );

        onClose(true);
      } catch (err: any) {
      } finally {
        setSubmitting(false);
      }
    },
    [
      accountSeleted,
      listeners,
      cluster.explorer,
      cluster.clusterUuid,
      dispatch,
      network.networkUuid,
      onClose,
      t,
    ],
  );

  const onCancel = useCallback((handleReset: () => void, onClose: () => void) => {
    handleReset();
    onClose();
  }, []);

  const onAddItem = useCallback((setValues: (values: FormValues) => void, values: FormValues) => {
    values.listeners.push({
      type: 'BLOCK_EXPLORER',
      cidrType: 'anywhere',
      cidr: '0.0.0.0/0',
      desc: '',
    });
    setValues(values);
  }, []);

  const ruleServiceTypeField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div>
          <CustomSelect
            data-testid="service-select"
            {...field}
            id="member-cluster-endpoint-listener-type"
            placeholder={t('select_service')}
            valueSelected={field.value}
            items={endpointServicesTypeSelection}
          />
        </div>
      );
    },
    [t],
  );

  const listenPortField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div>
          <CustomSelect
            data-testid="port-select"
            {...field}
            id="member-cluster-endpoint-port"
            placeholder={t('select_port')}
            valueSelected={field.value}
            items={endpointServicesPortSelection}
          />
        </div>
      );
    },
    [t],
  );

  const ruleCidrField = useCallback(({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomInputControlled
          {...field}
          data-testid="address-input"
          id="member-cluster-endpoint-listener-address"
          placeholder=""
        />
      </div>
    );
  }, []);

  const ruleDescriptionField = useCallback(({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomInputControlled
          {...field}
          data-testid="description-input"
          id="member-cluster-endpoint-listener-description"
          placeholder=""
        />
      </div>
    );
  }, []);

  const renderErrorArea = useCallback(
    (err: FormikErrors<FormValuesRule> | undefined, column: string) => {
      return (
        <div className={classes.formLabelLine}>
          {err && err[column] && (
            <div className={classNames(classes.formLabel, classes.formError)}>{err[column]}</div>
          )}
        </div>
      );
    },
    [classes],
  );

  const onSwitchCidrValue = useCallback(
    async (e: any) => {
      const { target } = e;
      let result = '';

      switch (target.value) {
        case 'myip':
          try {
            result = `${await publicIp.v4()}/32`;
          } catch (error) {
            dispatch(
              AppActions.openSnackBar({
                type: 'error',
                message: t('error_my_ip_address'),
              }),
            );
          }
          break;
        case 'anywhere':
          result = '0.0.0.0/0';
          break;
        case 'custom':
        default:
          break;
      }
      return result;
    },
    [dispatch, t],
  );

  const renderSwitchCidrInput = useCallback(
    (helper: any, values: FormValues, index: number) => {
      return (
        <div>
          <CustomSelect
            data-testid="cidr-type-select"
            id="member-cluster-endpoint-listener-addr-type"
            placeholder="Select CIDR input type"
            items={switchCidrInputType}
            valueSelected={values.listeners[index].cidrType || switchCidrInputType[0].value}
            onChange={async (e: any) => {
              if (values.listeners[index]) {
                const newValue = await onSwitchCidrValue(e);
                const result = {
                  ...values.listeners[index],
                  cidr: newValue || values.listeners[index].cidr,
                  cidrType: e.target.value,
                };
                helper.replace(index, result);
              }
            }}
          />
        </div>
      );
    },
    [onSwitchCidrValue],
  );

  const onCloseConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  const onConfirmDialog = useCallback(() => {
    if (formik && formikValues) {
      onSubmit(formikValues, formik, true);
    }
    setFormikActions(undefined);
    setOpenConfirmDialog(false);
  }, [formik, formikValues, onSubmit]);

  return (
    <>
      <CustomConfirmDialog
        open={openConfirmDialog}
        title="Confirm update listeners"
        content={t('block_explorer_port_change_warning')}
        onClose={onCloseConfirmDialog}
        onSubmit={onConfirmDialog}
      />
      <CustomDialog
        open={open}
        onClose={() => onClose(false)}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ values, errors, isValid, isSubmitting, handleReset, setValues }) => {
            return (
              <Form className={classes.editInboundRulesForm}>
                <CustomDialogTitle className={classes.customDialogTitle}>
                  <div id="member-cluster-endpoint-title">{t('edit_listeners')}</div>
                </CustomDialogTitle>
                <CustomDialogContent classes={{ root: classes.customDialogContent }}>
                  <Table className={classes.securityTable}>
                    <colgroup>
                      <col width="180px" />
                      <col width="110px" />
                      <col width="300px" />
                      <col width="auto" />
                    </colgroup>
                    <TableHeadCustom>
                      <TableRowHeadCustom>
                        <TableCellHeadCustom>
                          <span>{t('type')}</span>
                        </TableCellHeadCustom>
                        <TableCellHeadCustom>
                          <span>{t('port')}</span>
                        </TableCellHeadCustom>
                        <TableCellHeadCustom>
                          <span>{t('source')}</span>
                        </TableCellHeadCustom>
                        <TableCellHeadCustom className={classes.tableHeadCellDescription}>
                          <span>{t('description')}</span>
                        </TableCellHeadCustom>
                      </TableRowHeadCustom>
                    </TableHeadCustom>
                    <FieldArray
                      name="listeners"
                      render={(arrayHelpers) => {
                        return (
                          <TableBodyCustom>
                            {values.listeners.length > 0 &&
                              values.listeners.map((listener, i) => {
                                const error = errors.listeners && errors.listeners[i];
                                return (
                                  <TableRowBodyCustom key={i}>
                                    <TableCellBodyCustom
                                      className={classNames(
                                        classes.tableProtocolColumn,
                                        classes.tableCellColumn,
                                      )}
                                    >
                                      <Field
                                        name={`listeners.${i}.type`}
                                        render={ruleServiceTypeField}
                                      />
                                      {renderErrorArea(error, 'type')}
                                    </TableCellBodyCustom>
                                    <TableCellBodyCustom className={classes.tableCellColumn}>
                                      <Field
                                        name={`listeners.${i}.port`}
                                        render={listenPortField}
                                      />
                                      {renderErrorArea(error, 'port')}
                                    </TableCellBodyCustom>
                                    <TableCellBodyCustom
                                      className={classNames(
                                        classes.tableCidrColumn,
                                        classes.tableCellColumn,
                                      )}
                                    >
                                      <div>
                                        <Grid container>
                                          <Grid
                                            item
                                            md={6}
                                            className={classNames(
                                              classes.gridLeftItem,
                                              classes.customSourceField,
                                            )}
                                          >
                                            {renderSwitchCidrInput(arrayHelpers, values, i)}
                                          </Grid>
                                          <Grid
                                            item
                                            md={6}
                                            className={classNames(
                                              classes.gridRightItem,
                                              classes.customSourceField,
                                            )}
                                          >
                                            <Field
                                              name={`listeners.${i}.cidr`}
                                              render={ruleCidrField}
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                      {renderErrorArea(error, 'cidr')}
                                    </TableCellBodyCustom>
                                    <TableCellBodyCustom className={classes.tableCellColumn}>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={11}
                                          className={classNames(
                                            classes.gridLeftItem,
                                            classes.descriptionField,
                                          )}
                                        >
                                          <Field
                                            name={`listeners.${i}.desc`}
                                            render={ruleDescriptionField}
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <div>
                                            <IconButton
                                              id="member-cluster-endpoint-listener-remove"
                                              className={classes.closeBtn}
                                              onClick={() => arrayHelpers.remove(i)}
                                            >
                                              <ImgIcon
                                                className={classes.closeIcon}
                                                imgUrl="/images/icons/alert_error_ico.png"
                                              />
                                            </IconButton>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {renderErrorArea(error, 'desc')}
                                    </TableCellBodyCustom>
                                  </TableRowBodyCustom>
                                );
                              })}
                          </TableBodyCustom>
                        );
                      }}
                    />
                  </Table>
                </CustomDialogContent>
                <CustomDialogActions classes={{ root: classes.customDialogAction }}>
                  <LGButton
                    data-testid="add-listener-button"
                    classes={{ root: classes.addRuleBtn }}
                    onClick={() => onAddItem(setValues, values)}
                  >
                    <div>
                      <ImgIcon
                        className={classes.addRuleIcon}
                        imgUrl={`/images/icons/add_ico.png`}
                      />
                      <span className={classes.addRuleItem}>{t('add_listener')}</span>
                    </div>
                  </LGButton>
                  <Button
                    data-testid="cancel-button"
                    className={classes.leftBtn}
                    onClick={() => onCancel(handleReset, onClose)}
                    variant="contained"
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    data-testid="update-button"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('update')}
                    submittingLabel={t('updating')}
                  />
                </CustomDialogActions>
              </Form>
            );
          }}
        />
      </CustomDialog>
    </>
  );
};

const styles: StyleRules = {
  root: {},
  dialogPaper: {
    maxWidth: 1240,
  },
  editInboundRulesForm: {
    width: 'calc(100% - 10%)',
    margin: 'auto',
  },
  customDialogTitle: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  customDialogContent: {
    padding: 0,
    overflowX: 'auto',
  },
  customDialogAction: {
    display: 'flex',
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'flex-end',
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    position: 'absolute',
    top: 2,
  },
  formError: {
    color: romanColor,
  },
  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  addRuleBtn: {
    width: 175,
    height: 34,
    position: 'absolute',
    left: 0,
  },
  addRuleIcon: {
    verticalAlign: 'middle',
  },
  addRuleItem: {
    ...defaultFontMedium,
    ...oneLineText,
    fontSize: 15,
    marginRight: 10,
    marginLeft: 8,
    verticalAlign: 'middle',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  closeBtn: {},
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  descriptionField: {
    width: '80%',
  },
  securityTable: {
    tableLayout: 'fixed',
  },
  tableHeadCellDescription: {
    width: 300,
  },
  tableCellColumn: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 25,
  },
  tableProtocolColumn: {
    minWidth: 70,
  },
  tableCidrColumn: {
    minWidth: 160,
  },
  customSourceField: {
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: '50%',
  },
};

export default withStyles(styles)(EditEndpointListenersDialog);

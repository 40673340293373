export const thin = '100';
export const extraLight = '200';
export const light = '300';
export const regular = '400';
export const medium = '500';
export const semiBold = '600';
export const bold = '700';
export const extraBold = '800';
export const black = '900';

export const defaultFont: any = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: regular,
  lineHeight: '1.5em',
};

export const defaultFontThin = {
  ...defaultFont,
  fontWeight: thin,
};

export const defaultFontExtraLight = {
  ...defaultFont,
  fontWeight: extraLight,
};

export const defaultFontLight = {
  ...defaultFont,
  fontWeight: light,
};

export const defaultFontRegular = {
  ...defaultFont,
  fontWeight: regular,
};

export const defaultFontMedium = {
  ...defaultFont,
  fontWeight: medium,
};

export const defaultFontSemiBold = {
  ...defaultFont,
  fontWeight: semiBold,
};

export const defaultFontBold = {
  ...defaultFont,
  fontWeight: bold,
};

export const defaultFontExtraBold = {
  ...defaultFont,
  fontWeight: extraBold,
};

export const defaultFontBlack = {
  ...defaultFont,
  fontWeight: black,
};

import * as Gtypes from '~/gapi/gtypes';

export const proposalHistoryQuery = `{
  uuid
  networkUuid
  blockNumber
  isEpochBlock
  proposals {
    from
    to
    authorize
  }
  signerStatusChanges {
    address
    authorize
  }
  createdAt
  updatedAt
}`;

export type QueryGetSignersArgs = Gtypes.QueryGetSignersArgs;
export type GET_SIGNERS_RESULT_TYPE = {
  getSigners: string;
};
export const getSignersQuery = `query ($networkUuid: String!, $nodeUuid: String!) {
  getSigners(networkUuid: $networkUuid, nodeUuid: $nodeUuid)
}`;
export type QueryGetProposalStatusArgs = Gtypes.QueryGetProposalStatusArgs;
export type GET_PROPOSAL_STATUS_RESULT_TYPE = {
  getProposalStatus: string;
};
export const getProposalStatusQuery = `query ($networkUuid: String!, $nodeUuid: String!) {
  getProposalStatus(networkUuid: $networkUuid, nodeUuid: $nodeUuid)
}`;

export const sendProposal = `mutation ($input: SendProposalInput!) {
  sendProposal(input: $input)
}`;
export type SEND_PROPOSAL_RESULT_TYPE = {
  sendProposal: Boolean;
};

export const discardProposal = `mutation ($input: DiscardPorposalInput!) {
  discardProposal(input: $input)
}`;
export type DISCARD_PROPOSAL_RESULT_TYPE = {
  discardProposal: Boolean;
};

// List Proposal History
export type LIST_PROPOSAL_HISTORY_RESULT_TYPE = {
  listProposalHistory: Gtypes.ListProposalHistory;
};
export const listProposalHistoryQuery = `query ($accountUuid: String!, $networkUuid: String!, $page: InputProposalHistoryPageInfo, $filter: InputProposalHistoryFilter) {
  listProposalHistory(accountUuid: $accountUuid, networkUuid: $networkUuid, page: $page, filter: $filter) {
    proposalHistory ${proposalHistoryQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      startBlock
      endBlock
      address
    }
  }
}`;

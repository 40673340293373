import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';

interface IProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  disabled?: boolean;
  multiline?: boolean;
  name?: string;
  onBlur?: any;
  id?: string;
}

const customInput: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  placeholder,
  value,
  onChange,
  disabled,
  multiline,
  name,
  onBlur,
  id,
  ...props
}) => {
  if (multiline) {
    return (
      <textarea
        // {...props}
        name={name}
        onBlur={onBlur}
        className={classNames(classes.input, classes.textarea, className)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        id={id}
      />
    );
  }

  return (
    <input
      {...props}
      name={name}
      onBlur={onBlur}
      className={classNames(classes.input, className)}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      id={id}
    />
  );
};

customInput.defaultProps = {
  disabled: false,
  multiline: false,
};

const styles: StyleRules = {
  root: {},
  input: {
    ...defaultFont,
    fontSize: 13,
    '&::placeholder': {
      color: lightSlateGreyColor,
    },
    width: '100%',
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: whiteSmokeColor,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
  },
  textarea: {
    height: 60,
  },
};

export default withStyles(styles)(customInput);

import { pelorousColor2, denimColor } from './color';

export const oneLineText: any = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
export const limitLineText: any = {
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'normal',
};
export const LGPelorousDenim: any = `linear-gradient(to bottom, ${pelorousColor2}, ${denimColor})`;

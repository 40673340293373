import React from 'react';
import { renderRoutes, RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import AuthPage from './components/Auth';
import NetworkCreate from '~/components/Dashboard/NetworkCreate';
import NetworkDetail from '~/components/Dashboard/Network';
import ClusterExplorer from '~/components/Dashboard/Cluster/explorer';
import NodeDetail from '~/components/Dashboard/Node';
import ClusterDetail from '~/components/Dashboard/Cluster/cluster';
import Notifications from '~/components/Dashboard/Notifications';
import Access from '~/components/Dashboard/Access';
import Settings from '~/components/Dashboard/Settings';
import Invitations from '~/components/Dashboard/Invitation';
import Billings from '~/components/Dashboard/Billing';
import PageNotFound from '~/components/common/not-found';
import AboutPage from '~/components/About';
import Home from '~/components/Dashboard/Home';
import TermsOfService from '~/components/TermsOfService';
import Dashboard from '~/components/Dashboard';
import { Redirect } from 'react-router';
import LogoutPage from './components/Logout';
import ReceiptPage from '~/components/Receipt';
import InvoicePage from '~/components/Invoice';

export enum AppRoutePath {
  About = '/about',
  Receipt = '/receipt',
  Invoice = '/invoice',
  Auth = '/auth',
  Logout = '/logout',
  TermsOfService = '/termsofservice',
  Dashboard = '/',
  Network = '/network',
  NetworkCreate = '/network/create',
  NetworkDetail = '/network/:networkId',
  NetworkDetailExtra = '/network/:networkId/:tab?/:extra?',
  ClusterExplorer = '/network/:networkId/cluster/:clusterId/explorer',
  ClusterExplorerExtra = '/network/:networkId/cluster/:clusterId/explorer/:tab',
  NodeDetail = '/network/:networkId/cluster/:clusterId/node/:nodeId',
  NodeDetailExtra = '/network/:networkId/cluster/:clusterId/node/:nodeId/:tab',
  ClusterDetail = '/network/:networkId/cluster/:clusterId',
  ClusterDetailExtra = '/network/:networkId/cluster/:clusterId/:tab',
  Notifications = '/notifications',
  Access = '/access',
  Settings = '/settings/:tab',
  Invitations = '/invitations',
  Billings = '/billing/:tab/:month?',
  MemberBackwardCompatible = '/member/*',
}

export const routes: RouteConfig[] = [
  {
    path: AppRoutePath.About,
    component: AboutPage,
    exact: true,
  },
  {
    path: AppRoutePath.Auth,
    component: AuthPage,
    exact: true,
  },
  {
    path: AppRoutePath.Logout,
    component: LogoutPage,
    exact: true,
  },
  {
    path: AppRoutePath.TermsOfService,
    component: TermsOfService,
    exact: true,
  },
  {
    path: AppRoutePath.Receipt,
    component: ReceiptPage,
    exact: true,
  },
  {
    path: AppRoutePath.Invoice,
    component: InvoicePage,
    exact: true,
  },
  {
    path: AppRoutePath.MemberBackwardCompatible,
    render: (props: RouteConfigComponentProps<any>) => {
      return <Redirect to={props.location.pathname.replace('/member', '')} />;
    },
    exact: true,
  },
  {
    path: AppRoutePath.Dashboard,
    component: Dashboard,
    routes: [
      {
        path: AppRoutePath.Dashboard,
        component: Home,
        exact: true,
      },
      {
        path: AppRoutePath.Network,
        component: Home,
        exact: true,
      },
      {
        path: AppRoutePath.NetworkCreate,
        component: NetworkCreate,
        exact: true,
      },
      {
        path: AppRoutePath.NetworkDetail,
        component: NetworkDetail,
        exact: true,
      },
      {
        path: AppRoutePath.NetworkDetailExtra,
        component: NetworkDetail,
        exact: true,
      },
      {
        path: AppRoutePath.ClusterExplorer,
        component: ClusterExplorer,
        exact: true,
      },
      {
        path: AppRoutePath.ClusterExplorerExtra,
        component: ClusterExplorer,
        exact: true,
      },
      {
        path: AppRoutePath.NodeDetail,
        component: NodeDetail,
        exact: true,
      },
      {
        path: AppRoutePath.NodeDetailExtra,
        component: NodeDetail,
        exact: true,
      },
      {
        path: AppRoutePath.ClusterDetail,
        component: ClusterDetail,
        exact: true,
      },
      {
        path: AppRoutePath.ClusterDetailExtra,
        component: ClusterDetail,
        exact: true,
      },
      {
        path: AppRoutePath.Notifications,
        component: Notifications,
        exact: true,
      },
      {
        path: AppRoutePath.Access,
        component: Access,
        exact: true,
      },
      {
        path: AppRoutePath.Settings,
        component: Settings,
        exact: true,
      },
      {
        path: AppRoutePath.Invitations,
        component: Invitations,
        exact: true,
      },
      {
        path: AppRoutePath.Billings,
        component: Billings,
        exact: true,
      },
    ],
  },
  {
    path: '*',
    component: PageNotFound,
    exact: true,
  },
];

const AppRouter = () => {
  return renderRoutes(routes);
};

export default AppRouter;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Component
import StatementMonths from './statement-months';
import StatementMonth from './statement-month';

interface IProps {}

const StatementTab = (props: IProps) => (
  <Switch>
    <Route path="/billing/statement" exact={true} component={StatementMonths} />
    <Route path="/billing/statement/:month" exact={true} component={StatementMonth} />
  </Switch>
);

export default StatementTab;

import * as Gtypes from '~/gapi/gtypes';

export type NodeSizeType = Gtypes.NodeSizeType;
export type LicenseFee = Gtypes.LicenseFee;
export type LicenseQty = Gtypes.LicenseQty;
export type CardSummary = Gtypes.CardSummary;
export type EstimateSummary = Gtypes.EstimateSummary;

const qtyQuery = `{
  licenseItemId
  licenseName
  size
  qty
}`;

const feeQuery = `{
  licenseItemId
  licenseName
  size
  qty
  unitPrice
  subTotalPrice
  startDate
  endDate
  numDays
}`;

const licenseQuery = `{
  licenseItemId
  licenseName
  size
  qty
  unitPrice
  startDate
  endDate
  numDays
}`;

const statusQuery = `{
  status
  error
}`;

export const billingQuery = `{
  startPeriod
  endPeriod
  details {
    id
    amount
    coupon
    tax
    receipt
    result
    invoicedDate
    canceledDate
    paidDate
    licenses {
      licenseItemId
      licenseName
      size
      qty
      unitPrice
      startDate
      endDate
      numDays
      amount
    }
  }
}`;

export const couponQuery = `{
  startPeriod
  endPeriod
  details {
    id
    name
    code
    amount
    balance
    createAt
    deleteAt
    expiredAt
  }
}`;

export const listActiveLicensesQuery = `query ($accountUuid: String!) {
  listActiveLicenses(accountUuid: $accountUuid) ${licenseQuery}
}`;

export type QueryListActiveLicensesArgs = Gtypes.QueryListActiveLicensesArgs;

export type LIST_ACTIVE_LICENSES_RESULT_TYPE = {
  listActiveLicenses: Gtypes.LicenseSummary[];
};

export const summaryMonthQuery = `{
  billingId
  coupon
  invoicedDate
  canceledDate
  paidDate
  startDate
  endDate
  numDays
  amount
  taxFee
  taxRate
  licenses {
    licenseItemId
    licenseName
    size
    qty
    unitPrice
    subTotalPrice
  }
}`;

export const listActiveLicensesSummaryQuery = `query ($accountUuid: String!) {
  listActiveLicensesSummary(accountUuid: $accountUuid) {
    thisMonth ${summaryMonthQuery}
    nextMonth ${summaryMonthQuery}
  }
}`;

export type QueryListActiveLicensesSummaryArgs = Gtypes.QueryListActiveLicensesSummaryArgs;

export type LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE = {
  listActiveLicensesSummary: Gtypes.LicenseSummaryByBilling;
};

export const listAvailableLicensesQuery = `query ($accountUuid: String!) {
  listAvailableLicenses(accountUuid: $accountUuid) ${licenseQuery}
}`;

export type QueryListAvailableLicensesArgs = Gtypes.QueryListAvailableLicensesArgs;

export type LIST_AVAILABLE_LICENSES_RESULT_TYPE = {
  listAvailableLicenses: Gtypes.LicenseSummary[];
};

export const listInsufficientLicensesQuery = `query ($accountUuid: String!, $licenses: [InputLicenseQty!]!) {
  listInsufficientLicenses(accountUuid: $accountUuid, licenses: $licenses) ${qtyQuery}
}`;

export type QueryListInsufficientLicensesArgs = Gtypes.QueryListInsufficientLicensesArgs;

export type LIST_INSUFFICIENT_LICENSES_RESULT_TYPE = {
  listInsufficientLicenses: Gtypes.LicenseQty[];
};

export const listInsufficientClusterLicensesQuery = `query ($accountUuid: String!, $nodeNum: Int!, $nodeType: NodeSizeType!, $clusterType: ClusterSizeType!, $blockExplorerType: BlockExplorerSizeType!, $includedBlockExplorer: Boolean) {
  listInsufficientClusterLicenses(accountUuid: $accountUuid, nodeNum: $nodeNum, nodeType: $nodeType, clusterType: $clusterType, blockExplorerType:$blockExplorerType, includedBlockExplorer: $includedBlockExplorer) ${qtyQuery}
}`;

export type QueryListInsufficientClusterLicensesArgs =
  Gtypes.QueryListInsufficientClusterLicensesArgs;

export type LIST_INSUFFICIENT_CLUSTER_LICENSES_RESULT_TYPE = {
  listInsufficientClusterLicenses: Gtypes.LicenseQty[];
};

export const listInsufficientNodeLicensesQuery = `query ($accountUuid: String!, $nodeNum: Int!, $nodeType: NodeSizeType!, $isBootnode: Boolean) {
  listInsufficientNodeLicenses(accountUuid: $accountUuid, nodeNum: $nodeNum, nodeType: $nodeType, isBootnode: $isBootnode) ${qtyQuery}
}`;

export type QueryListInsufficientNodeLicensesArgs = Gtypes.QueryListInsufficientNodeLicensesArgs;

export type LIST_INSUFFICIENT_NODE_LICENSES_RESULT_TYPE = {
  listInsufficientNodeLicenses: Gtypes.LicenseQty[];
};

export const listInsufficientNodeServerLicensesQuery = `query ($accountUuid: String!, $nodeNum: Int!, $nodeType: NodeSizeType!) {
  listInsufficientNodeServerLicenses(accountUuid: $accountUuid, nodeNum: $nodeNum, nodeType: $nodeType) ${qtyQuery}
}`;

export type QueryListInsufficientNodeServerLicensesArgs =
  Gtypes.QueryListInsufficientNodeServerLicensesArgs;

export type LIST_INSUFFICIENT_NODE_SERVER_LICENSES_RESULT_TYPE = {
  listInsufficientNodeServerLicenses: Gtypes.LicenseQty[];
};

export const listInsufficientVolumeLicensesQuery = `query ($accountUuid: String!, $volumeSize: Int!) {
  listInsufficientVolumeLicenses(accountUuid: $accountUuid, volumeSize: $volumeSize) ${qtyQuery}
}`;

export type QueryListInsufficientVolumeLicensesArgs =
  Gtypes.QueryListInsufficientVolumeLicensesArgs;

export type LIST_INSUFFICIENT_VOLUME_LICENSES_RESULT_TYPE = {
  listInsufficientVolumeLicenses: Gtypes.LicenseQty[];
};

export const estimateLicenseFeeQuery = `query ($accountUuid: String!, $input: [InputLicenseFee!]!) {
  estimateLicenseFee(accountUuid: $accountUuid, input: $input) {
    estimate {
      totalPrice
      taxFee
      taxRate
      licenses ${feeQuery}
      nextMonth {
        totalPrice
        taxFee
        taxRate
        licenses ${feeQuery}
      }
    }
    coupon {
      usable
    }
  }
}`;

export type QueryEstimateLicenseFeeArgs = Gtypes.QueryEstimateLicenseFeeArgs;

export type ESTIMATE_LICENSE_FEE_RESULT_TYPE = {
  estimateLicenseFee: Gtypes.EstimateSummary;
};

export const listBillingsQuery = `query ($accountUuid: String!, $startDate: String, $endDate: String) {
  listBillings(accountUuid: $accountUuid, startDate: $startDate, endDate: $endDate) ${billingQuery}
}`;

export type QueryListBillingsArgs = Gtypes.QueryListBillingsArgs;

export type LIST_BILLINGS_RESULT_TYPE = {
  listBillings: Gtypes.Billing;
};

export const listCouponsQuery = `query ($accountUuid: String!, $startDate: String, $endDate: String) {
  listCoupons(accountUuid: $accountUuid, startDate: $startDate, endDate: $endDate) ${couponQuery}
}`;

export type QueryListCouponsArgs = Gtypes.QueryListCouponsArgs;

export type LIST_COUPONS_RESULT_TYPE = {
  listCoupons: Gtypes.Coupon;
};

export const listCardsQuery = `query ($accountUuid: String!) {
  listCards(accountUuid: $accountUuid) {
    brand
    country
    name
    last4
    year
    month
  }
}`;

export type QueryListCardsArgs = Gtypes.QueryListCardsArgs;

export type LIST_CARDS_RESULT_TYPE = {
  listCards: Gtypes.CardSummary[];
};

export const registerCardMutation = `mutation ($accountUuid: String!, $card: InputRegisterCard!) {
  registerCard(accountUuid: $accountUuid, card: $card) ${statusQuery}
}`;

export type MutationRegisterCardArgs = Gtypes.MutationRegisterCardArgs;

export type REGISTER_CARD_RESULT_TYPE = {
  registerCard: Gtypes.RegisterCard;
};

export const cancelPurchaseLicenseMutation = `mutation ($accountUuid: String!, $billingId: String!) {
  cancelPurchaseLicense(accountUuid: $accountUuid, billingId: $billingId) ${statusQuery}
}`;

export type MutationCancelPurchaseLicenseArgs = Gtypes.MutationCancelPurchaseLicenseArgs;

export type CANCEL_PURCHASE_LICENSE_RESULT_TYPE = {
  cancelPurchaseLicense: Gtypes.LicenseCancel;
};

export const purchaseLicensesMutation = `mutation ($accountUuid: String!, $licenses: [InputLicensePurchase!]!, $cond: InputCondPurchase!) {
  purchaseLicenses(accountUuid: $accountUuid, licenses: $licenses, cond: $cond) {
    status
    receipt
    error {
      type
      message
    }
  }
}`;

export type MutationPurchaseLicensesArgs = Gtypes.MutationPurchaseLicensesArgs;

export type PURCHASE_LICENSES_RESULT_TYPE = {
  purchaseLicenses: Gtypes.LicensePurchase;
};

export const listInsufficientBlockExplorerLicenses = `query ($accountUuid: String!, $sizeType: BlockExplorerSizeType) {
  listInsufficientBlockExplorerLicenses(accountUuid: $accountUuid, sizeType: $sizeType) ${qtyQuery}
}`;

export type QueryListInsufficientBlockExplorerLicensesArgs =
  Gtypes.QueryListInsufficientBlockExplorerLicensesArgs;

export type LIST_INSUFFICIENT_BLOCK_EXPLORER_LICENSES_RESULT_TYPE = {
  listInsufficientBlockExplorerLicenses: Gtypes.LicenseQty[];
};

export const listInsufficientBlockExpInstanceLicenses = `query ($accountUuid: String!, $blockExplorerType: BlockExplorerSizeType!) {
  listInsufficientBlockExpInstanceLicenses(accountUuid: $accountUuid, blockExplorerType: $blockExplorerType) ${qtyQuery}
}`;

export type LIST_INSUFFICIENT_BLOCK_EXP_INSTANCE_LICENSES_RESULT_TYPE = {
  listInsufficientBlockExpInstanceLicenses: Gtypes.LicenseQty[];
};

export const listInsufficientStaticIpAddressLicenses = `query ($accountUuid: String!, $ipAddressNum: Int!) {
  listInsufficientStaticIpAddressLicenses(accountUuid: $accountUuid, ipAddressNum: $ipAddressNum) ${qtyQuery}
}`;

export type LIST_INSUFFICIENT_ELASTIC_IP_LICENSES_RESULT_TYPE = {
  listInsufficientStaticIpAddressLicenses: Gtypes.LicenseQty[];
};

import React, { useCallback, useMemo } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FormikActions } from 'formik';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {};

const DisassociateStaticIpAddressDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, onClose } = props;

  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isDisassociating = useSelector((store: IStore) =>
    NetworkActions.disassociateStaticIpAddress.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;

      // Check selected account existence
      if (!accountSeleted) {
        return;
      }

      try {
        await dispatch(
          NetworkActions.disassociateStaticIpAddress({
            input: {
              accountUuid: accountSeleted.accountUuid,
              networkUuid: network.networkUuid,
              clusterUuid: cluster.clusterUuid,
              nodeUuid: node.nodeUuid,
            },
          }),
        );
      } catch (error) {}
      setSubmitting(false);
      onClose();
    },
    [accountSeleted, cluster.clusterUuid, network.networkUuid, node.nodeUuid, onClose, dispatch],
  );

  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-disassociate-static-ip-title">
                  {t('disassociate_static_ip_address')}
                </div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div id="member-node-del-detail" className={classes.content}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('node_name')}</div>
                      <div className={classes.itemValue}>{node.nodeName}</div>
                    </Grid>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('ip_address')} (Static)</div>
                      <div className={classes.itemValue}>{node.serverInfo.ip}</div>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ textAlign: 'center' }} className={classes.warningText}>
                  {t('disassociate_static_ip_address_confirm')}
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  id="member-disassociate-static-ip-cancel"
                  disabled={isDisassociating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  id="member-disassociate-static-ip-submit"
                  isValid={true}
                  isSubmitting={isSubmitting || isDisassociating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  leftContentItem: {
    padding: 0,
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  content: {
    padding: 30,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  staticIp: {
    ...defaultFont,
    fontSize: 13,
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    whiteSpace: 'nowrap',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 15,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(DisassociateStaticIpAddressDialog);

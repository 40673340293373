import { EndpointTypeListenEnum } from '~/gapi/gtypes';
import {
  INodeType,
  IConsensus,
  RestrictionEndpointProtocolType,
  RestrictionExplorerProtocolType,
  RestrictionProtocolType,
  NodeLogType,
  NodeLogLine,
} from './network-types';
import { TFunction } from 'react-i18next';

export const clusterTypeSelection = [
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
  { value: 'mega', label: 'Mega' },
];

export const clusterTypeEnterpriseSelection = [
  ...clusterTypeSelection,
  { value: 'enterprise', label: 'Enterprise' },
];

export const instancesSizeSelection = [
  { value: 'small_v3', label: 'Small (v3)' },
  { value: 'medium_v3', label: 'Medium (v3)' },
  { value: 'large_v3', label: 'Large (v3)' },
  { value: 'business_v3', label: 'Business (v3)' },
  { value: 'businesslarge_v3', label: 'Business Large (v3)' },
  { value: 'mega_v3', label: 'Mega (v3)' },
  { value: 'metal_v3', label: 'Metal (Max TPS) (v3)' },
];

export const blockExplorerSizeOptions = [
  { value: 'large_v3', label: 'Large (v3)' },
  { value: 'business_v3', label: 'Business (v3)' },
  { value: 'businesslarge_v3', label: 'Business Large (v3)' },
];

export const instancesSizeEnterpriseSelection = [...instancesSizeSelection];

export const nodeTypesSelection: { value: INodeType; label: string }[] = [
  { value: 'bootnode', label: 'Boot Node' },
  { value: 'geth', label: 'Geth Node' },
  { value: 'parity', label: 'Parity Node' },
  { value: 'block-explorer', label: 'Block Explorer Node' },
];

export const nodeGethSelection: { value: string; label: string }[] = [
  { value: '1.11.6', label: 'Geth 1.11.6' },
  { value: '1.12.2', label: 'Geth 1.12.2' },
];

export const nodeClefSelection: { value: string; label: string }[] = [
  { value: '1.11.6', label: 'Clef 1.11.6' },
  { value: '1.12.2', label: 'Clef 1.12.2' },
];

export const nodeGcModeOptions: { value: string; label: string }[] = [
  { value: 'full', label: 'Full' },
  { value: 'archive', label: 'Archive' },
];

export const consensusTypesSelection: { value: IConsensus; label: string }[] = [
  { value: 'clique', label: 'PoA (Clique)' },
];

export const getProposeNodeSelection = (t: TFunction) => [
  { value: 'signer', label: t('signer_node') },
  { value: 'transaction', label: t('transaction_node') },
];

export const endpointProtocolTypeSelection: {
  value: RestrictionEndpointProtocolType;
  label: string;
}[] = [
  { value: 'HTTP', label: 'HTTPS' },
  { value: 'RPC', label: 'RPC' },
];

export const explorerProtocolTypeSelection: {
  value: RestrictionExplorerProtocolType;
  label: string;
}[] = [{ value: 'HTTP', label: 'HTTP' }];

export const protocolTypeSelection: { value: RestrictionProtocolType; label: string }[] = [
  { value: 'HTTP', label: 'HTTP' },
  { value: 'RPC', label: 'RPC' },
];

export const protocolTypeSelectionEnterprise: { value: RestrictionProtocolType; label: string }[] =
  [
    { value: 'HTTP', label: 'HTTP' },
    { value: 'RPC', label: 'RPC' },
    { value: 'P2P', label: 'P2P' },
  ];

export const switchCidrInputType: { value: string; label: string }[] = [
  { value: 'custom', label: 'Custom' },
  { value: 'anywhere', label: 'Anywhere' },
  { value: 'myip', label: 'My IP address' },
];

export const getMetricsRangeSelection = (
  t: TFunction,
): Array<{ value: string; label: string; periods: string[] }> => [
  {
    value: '1',
    label: t('metrics_range_option_label_last_hour', { value: 1 }),
    periods: ['10', '20'],
  },
  {
    value: '3',
    label: t('metrics_range_option_label_last_hour_plural', { value: 3 }),
    periods: ['30', '60'],
  },
  {
    value: '6',
    label: t('metrics_range_option_label_last_hour_plural', { value: 6 }),
    periods: ['60', '120'],
  },
  {
    value: '12',
    label: t('metrics_range_option_label_last_hour_plural', { value: 12 }),
    periods: ['120', '240'],
  },
  {
    value: '24',
    label: t('metrics_range_option_label_last_hour_plural', { value: 24 }),
    periods: ['240', '480'],
  },
  {
    value: '72',
    label: t('metrics_range_option_label_last_day_plural', { value: 3 }),
    periods: ['720', '1440'],
  },
  {
    value: '168',
    label: t('metrics_range_option_label_last_week', { value: 1 }),
    periods: ['1440'],
  },
  {
    value: '336',
    label: t('metrics_range_option_label_last_week_plural', { value: 2 }),
    periods: ['4320'],
  },
];

export const getMetricsPeriodSelection = (
  t: TFunction,
): Array<{ value: string; label: string }> => [
  { value: '10', label: t('metrics_period_option_label_minute_plural', { value: 10 }) },
  { value: '20', label: t('metrics_period_option_label_minute_plural', { value: 20 }) },
  { value: '30', label: t('metrics_period_option_label_minute_plural', { value: 30 }) },
  { value: '60', label: t('metrics_period_option_label_hour', { value: 1 }) },
  { value: '120', label: t('metrics_period_option_label_hour', { value: 2 }) },
  { value: '240', label: t('metrics_period_option_label_hour', { value: 4 }) },
  { value: '360', label: t('metrics_period_option_label_hour_plural', { value: 6 }) },
  { value: '480', label: t('metrics_period_option_label_hour_plural', { value: 8 }) },
  { value: '720', label: t('metrics_period_option_label_hour_plural', { value: 12 }) },
  { value: '1440', label: t('metrics_period_option_label_day', { value: 1 }) },
  { value: '4320', label: t('metrics_period_option_label_day', { value: 3 }) },
];

export const logsRangeSelectionMemberNode: { label: string; value: NodeLogType }[] = [
  { label: 'Node Log', value: 'node_geth' },
];

export const logsRangeSelectionMemberBlockscout: { label: string; value: NodeLogType }[] = [
  { label: 'Blockscout', value: 'blockscout' },
  { label: 'Blockscout Postgres', value: 'blockscout_postgres' },
  { label: 'System Log', value: 'messages' },
  { label: 'Docker Log', value: 'docker' },
];

export const logsRangeSelectionAdmin: { label: string; value: NodeLogType }[] = [
  { label: 'Node Geth Log', value: 'node_geth' },
];

export const logsRangeSelectionAdminBlockscout: { label: string; value: NodeLogType }[] = [
  { label: 'Blockscout Log', value: 'blockscout' },
  { label: 'Blockscout Postgres', value: 'blockscout_postgres' },
  { label: '/var/log/messages', value: 'messages' },
  { label: '/var/log/dmesg', value: 'dmesg' },
  { label: '/var/log/docker', value: 'docker' },
];

export const linesRangeSelectionAll: NodeLogLine = '_10000';

export const linesRangeSelection: { label: string; value: NodeLogLine }[] = [
  { label: '100', value: '_100' },
  { label: '1000', value: '_1000' },
  { label: 'All', value: linesRangeSelectionAll },
];

export const commandSelection = [
  { label: 'Get Signer', value: 'get_validator' },
  { label: 'Get Proposal', value: 'get_proposal' },
];

export const hardForkSelection = [
  { label: 'Berlin', value: 'berlin' },
  { label: 'London', value: 'london' },
];

export const periodTargetBlockSelection = [
  { label: '15 Minutes', value: '15m' },
  { label: '30 Minutes', value: '30m' },
  { label: '1 Hour', value: '1h' },
  { label: '1 Day', value: '1d' },
  { label: '7 Days', value: '7d' },
];

export const changeInstancesSizeSelection: Array<{
  provider: string;
  fromSizes: Array<string>;
  selectOptions: Array<{ value: string; label: string }>;
}> = [
  {
    provider: 'amazon',
    fromSizes: [
      'small_v1',
      'medium_v1',
      'large_v1',
      'xlarge_v1',
      '2xlarge_v1',
      'small_v2',
      'medium_v2',
      'large_v2',
      'business_v2',
      'businesslarge_v2',
      'mega_v2',
      'metal_v2',
    ],
    selectOptions: [
      { value: 'small_v2', label: 'Small (v2)' },
      { value: 'medium_v2', label: 'Medium (v2)' },
      { value: 'large_v2', label: 'Large (v2)' },
      { value: 'business_v2', label: 'Business (v2)' },
      { value: 'businesslarge_v2', label: 'Business Large (v2)' },
      { value: 'mega_v2', label: 'Mega (v2)' },
      { value: 'metal_v2', label: 'Metal (Max TPS) (v2)' },
    ],
  },
  {
    provider: 'amazon',
    fromSizes: [
      'small_v3',
      'medium_v3',
      'large_v3',
      'business_v3',
      'businesslarge_v3',
      'mega_v3',
      'metal_v3',
    ],
    selectOptions: [
      { value: 'small_v3', label: 'Small (v3)' },
      { value: 'medium_v3', label: 'Medium (v3)' },
      { value: 'large_v3', label: 'Large (v3)' },
      { value: 'business_v3', label: 'Business (v3)' },
      { value: 'businesslarge_v3', label: 'Business Large (v3)' },
      { value: 'mega_v3', label: 'Mega (v3)' },
      { value: 'metal_v3', label: 'Metal (Max TPS) (v3)' },
    ],
  },
];

export const changeExplorerInstancesSizeSelection: Array<{
  provider: string;
  fromSizes: Array<string>;
  selectOptions: Array<{ value: string; label: string }>;
}> = [
  {
    provider: 'amazon',
    fromSizes: [
      'small_v1',
      'medium_v1',
      'large_v1',
      'xlarge_v1',
      '2xlarge_v1',
      'large_v2',
      'business_v2',
      'businesslarge_v2',
    ],
    selectOptions: [
      { value: 'large', label: 'Large (v2)' },
      { value: 'business', label: 'Business (v2)' },
      { value: 'businesslarge', label: 'Business Large (v2)' },
    ],
  },
  {
    provider: 'amazon',
    fromSizes: ['large_v3', 'business_v3', 'businesslarge_v3'],
    selectOptions: [
      { value: 'large_v3', label: 'Large (v3)' },
      { value: 'business_v3', label: 'Business (v3)' },
      { value: 'businesslarge_v3', label: 'Business Large (v3)' },
    ],
  },
];

export const blockscoutBackendSelection: { value: string; label: string }[] = [
  { value: '4.1.8-gubuild.0', label: 'Blockscout 4.1.8' },
  { value: '5.4.0-gubuild.0', label: 'Blockscout 5.4.0' },
  { value: '6.5.0-gubuild.0', label: 'Blockscout 6.5.0' },
];

export const endpointServicesTypeSelection: {
  value: EndpointTypeListenEnum;
  label: string;
}[] = [
  { value: 'BLOCK_EXPLORER', label: 'Block Explorer' },
  { value: 'HTTP_RPC', label: 'HTTP RPC' },
  { value: 'WS_RPC', label: 'WS RPC' },
];

export const endpointServicesPortSelection: {
  value: string;
  label: string;
}[] = [
  { value: '443', label: '443' },
  { value: '8545', label: '8545' },
  { value: '8546', label: '8546' },
];

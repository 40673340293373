import firebase from 'firebase';
// React, Redux
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
import * as AlertActions from '~/stores/actions/alert-action';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Theme } from '@mui/material/styles';

import ImgIcon from '~/components/common/img-icon';
import ArrowIcon from '~/components/common/arrow-icon';
import EditProfileDialog from '~/components/common/edit-profile-dialog';
import AccountSettingDialog from '~/components/common/AccountSettingDialog';
import CreateAccountDialog from '~/components/common/create-account-dialog';
import CopyClipboardIcon from '~/components/common/copy-clipboard-icon';
// Route
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
// Translation
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import {
  whiteColor,
  denimColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  pattensBlueColor,
  nightRiderColor,
  sunColor,
  summerSkyColor,
  sunglow,
} from '~/styles/themes/common-styles/color';
// Type
import { Profile, Account, AccountRoleType } from '~/types/account-types';
import { IAlert, IAlertList } from '~/types/alert-types';
import { INetwork } from '~/types/network-types';
import { AppRoutePath } from '~/AppRouter';

interface IStateProps {
  profile?: Profile;
  networks?: INetwork[];
  accountList: Account[];
  accountSelected?: Account;
  unreadAlertList: IAlertList;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  updateProfile: (
    args: AppActions.MutationUpdateProfileArgs,
  ) => Promise<AppActions.UPDATE_PROFILE_RESULT_TYPE>;
  selectNetwork: (
    args: AppActions.MutationSelectNetworkArgs,
  ) => Promise<AppActions.UPDATE_SELECT_NETWORK_RESULT_TYPE>;
  updateAccountSelected: (
    args: AppActions.UpdateAccountSelectedArgs,
  ) => Promise<AppActions.GET_ACCOUNT_RESULT_TYPE>;
  setAlertRead: (
    args: AlertActions.MutationSetAlertReadArgs,
  ) => Promise<AlertActions.SET_ALERT_READ_RESULT_TYPE>;
  listUnreadAlerts: (
    args: AlertActions.QueryListAlertsArgs,
  ) => Promise<AlertActions.LIST_ALERTS_RESULT_TYPE>;
  createAccount: (
    args: AppActions.MutationCreateAccountArgs,
  ) => Promise<AppActions.CREATE_ACCOUNT_RESULT_TYPE>;
  listNetworks: (
    args: NetworkActions.QueryListNetworksArgs,
  ) => Promise<NetworkActions.LIST_NETWORKS_RESULT_TYPE>;
  resetStore: () => void;
}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps,
    WithStyles<typeof styles>,
    WithTranslation {}

interface IState {
  profileAnchorEl: HTMLButtonElement | null;
  alertAnchorEl: HTMLDivElement | null;
  isShowAlertBar: boolean;
  openProfileSettingDialog: boolean;
  openAccountSettingDialog: boolean;
  openCreateAccountDialog: boolean;
}

class Header extends React.Component<IProps, IState> {
  private _isMounted: boolean;

  constructor(props: IProps) {
    super(props);
    this.state = {
      profileAnchorEl: null,
      alertAnchorEl: null,
      isShowAlertBar: false,
      openProfileSettingDialog: false,
      openAccountSettingDialog: false,
      openCreateAccountDialog: false,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const { profile, unreadAlertList } = this.props;
    if (profile && unreadAlertList.alerts) {
      this.handleShowAlertBar(profile, unreadAlertList.alerts);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: IProps, prevState: IState, snapshot: any) {
    const { profile, unreadAlertList } = this.props;
    if (
      profile &&
      (this.props.unreadAlertList !== prevProps.unreadAlertList ||
        this.props.profile !== prevProps.profile)
    ) {
      this.handleShowAlertBar(profile, unreadAlertList.alerts);
    }
  }

  public render() {
    const { classes, profile, networks, updateProfile, accountSelected, accountList } = this.props;
    const { openProfileSettingDialog, openAccountSettingDialog } = this.state;

    if (!profile || !accountSelected) {
      return null;
    }

    return (
      <>
        {this.renderProfilePopover}
        {this.renderAlertPopover}
        <AccountSettingDialog
          open={openAccountSettingDialog}
          onClose={this.onCloseAccountSettingDialog}
        />
        {networks && (
          <EditProfileDialog
            open={openProfileSettingDialog}
            profile={profile}
            accountList={accountList}
            networks={networks}
            onClose={this.onCloseProfileSettingDialog}
            updateProfile={updateProfile}
          />
        )}
        <div className={classes.header}>
          <AppBar position="fixed" className={classes.appBar}>
            <div className={classes.container}>
              <Toolbar classes={{ root: classes.toolBar }}>
                <Link className={classes.linkItem} to="/" id="header-left-top-logo">
                  <img className={classes.logo} src="/images/icons/logo_guest.svg" alt="" />
                </Link>
                <div className={classes.items}>
                  <div className={classes.info}>
                    <div className={classes.infoInner}>
                      <div className={classes.userinfo}>
                        <span className={classes.accountName} id="header-account-name">
                          {accountSelected.name}
                        </span>
                        <span className={classes.accountInfo} id="header-account-id">
                          ID: {accountSelected.accountUuid}
                          <CopyClipboardIcon
                            classes={{ root: classes.copyButton }}
                            textToCopy={accountSelected.accountUuid}
                          />
                          <div className={classes.accountSeparate} />
                        </span>
                      </div>
                    </div>
                    <span className={classes.accountName} id="header-user-name">
                      {profile.displayName}
                    </span>
                    <span className={classes.accountInfo} id="header-user-email">
                      {profile.email}
                    </span>
                  </div>
                  <Button
                    data-testid="open-profile-button"
                    className={classes.itemBtn}
                    id="header-right-menu"
                    onClick={this.handleOpenProfileMenu}
                  >
                    <div className={classes.avatarIconArea}>
                      <img
                        src={profile.photoURL || '/images/icons/avatar_ico.png'}
                        alt=""
                        className={classes.avatarIcon}
                      />
                    </div>
                    <div className={classes.arrowIconArea}>
                      <ArrowIcon direction="down" />
                    </div>
                  </Button>
                </div>
              </Toolbar>
            </div>
          </AppBar>
          {this.renderAlertBar}
        </div>
      </>
    );
  }

  private get renderAlertBar() {
    const { classes, unreadAlertList } = this.props;
    const { isShowAlertBar } = this.state;

    return (
      <div className={classes.alertContainer} data-show={isShowAlertBar}>
        <div className="alert-background" />
        <div className={classes.alertBar}>
          <div
            className={classes.alertButton}
            onClick={this.handleOpenAlertPopover}
            data-testid="open-alert-option"
          >
            <div className={classes.alertIconArea}>
              <ImgIcon className={classes.globeIcon} imgUrl="/images/icons/alert_white_ico.png" />
              {!!unreadAlertList.pageInfo.totalItems && (
                <div id="member-notification-bar-detail-count" className={classes.alertNumber}>
                  {unreadAlertList.pageInfo.totalItems}
                </div>
              )}
            </div>
            <span id="member-notification-bar-detail-label" className={classes.alertText}>
              {this.props.t('there_is_a_notification')}
            </span>
            <ImgIcon imgUrl="/images/icons/arrowdown_ico.png" />
          </div>
          <div
            className={classes.closeAlertBtn}
            onClick={this.handleCloseAlertBar}
            data-testid="close-alert-bar-button"
          >
            <ImgIcon className={classes.closeIcon} imgUrl="/images/icons/close_white_ico.png" />
          </div>
        </div>
      </div>
    );
  }

  private get renderAlertPopover() {
    const { classes, unreadAlertList, i18n } = this.props;
    const { alertAnchorEl } = this.state;
    return (
      <Popover
        open={Boolean(alertAnchorEl)}
        anchorEl={alertAnchorEl}
        onClose={this.handleCloseAlertPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.alertPopoverPaper }}
      >
        <List id="member-notification-bar-popover-detail">
          {(unreadAlertList.alerts || []).slice(0, 4).map((alert, index) => (
            <a href={alert.linkUrl} key={index}>
              <ListItem
                data-testid="alert-row"
                button
                className={classes.alertItem}
                onClick={() => this.onAlertClick(alert)}
              >
                <div className={classes.alertItemTitle}>{alert.title[i18n.language]}</div>
                {alert.description && (
                  <div className={classes.alertItemDescription}>
                    {alert.description[i18n.language]}
                  </div>
                )}
              </ListItem>
            </a>
          ))}
          <Link id="member-notification-bar-popover-button" to="/notifications">
            <ListItem
              data-testid="close-alert-items"
              className={classes.seeAll}
              onClick={this.handleCloseAlertItems}
            >
              {this.props.t('see_all')}
            </ListItem>
          </Link>
        </List>
      </Popover>
    );
  }

  private get renderProfilePopover() {
    const { classes, accountSelected, profile, accountList, createAccount, selectedAccountRole } =
      this.props;
    const { profileAnchorEl, openCreateAccountDialog } = this.state;
    const showAccountSettingListItem =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedAccountRole === 'member';

    if (!profile || !accountSelected) {
      return null;
    }

    return (
      <>
        <Popover
          open={Boolean(profileAnchorEl)}
          anchorEl={profileAnchorEl}
          onClose={this.handleCloseProfileMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ paper: classes.profileMenuContainer }}
        >
          <div className={classes.profileMenuInfo}>
            <img
              src={profile.photoURL || '/images/icons/avatar_ico.png'}
              alt=""
              className="avatar"
            />
            <div className="name">{profile.displayName}</div>
            <div className="mail">{profile.email}</div>
            <div
              data-testid="open-profile-setting"
              className={classes.settingButton}
              id="top-menu-button-setting"
              onClick={this.onOpenProfileSettingDialog}
            >
              <ImgIcon imgUrl="/images/icons/setting_ico.png" className={classes.settingIcon} />
            </div>
          </div>
          <List className={classes.profileMenuAccount} id="top-menu-accounts">
            {accountList.map((account, j) => {
              const seleted = accountSelected.accountUuid === account.accountUuid;
              return (
                <ListItem
                  data-testid={`account-${account.accountUuid}-item`}
                  button={true}
                  onClick={() => this.updateAccountSelected(account)}
                  className={classes.profileMenuListSettingItem}
                  key={j}
                >
                  {seleted && (
                    <ImgIcon imgUrl="/images/icons/check_ico.png" className={classes.checkedIcon} />
                  )}
                  <span className={classes.profilePopoverAccountName}>{account.name}</span>
                  {seleted && (
                    <ImgIcon imgUrl="/images/icons/states_ico.png" className={classes.statusIcon} />
                  )}
                </ListItem>
              );
            })}
            <ListItem className={classes.profileMenuListSettingItem}>
              <Button
                data-testid="add-new-organization-button"
                id="top-menu-button-add-account"
                className={classes.profileMenuButtonAddAccount}
                onClick={this.handleOpenCreateAccountDialog}
              >
                {this.props.t('add_new_organization')}
              </Button>
            </ListItem>
          </List>
          {showAccountSettingListItem && (
            <List className={classes.profileMenuAccountSetting}>
              <ListItem
                data-testid="organization-settings-button"
                id="top-menu-button-setting-account"
                button={true}
                className={classes.profileMenuListSettingItem}
                onClick={this.onOpenAccountSettingDialog}
              >
                <Trans i18nKey={'organization_settings'}></Trans>
              </ListItem>
            </List>
          )}
          <List className={classes.logout}>
            <ListItem
              data-testid="logout-button"
              button={true}
              className={classes.logoutButton}
              id="top-menu-signout"
              onClick={() => {
                window.location.href = AppRoutePath.Logout;
              }}
            >
              <Trans i18nKey={'logout'}></Trans>
            </ListItem>
          </List>
        </Popover>
        <CreateAccountDialog
          open={openCreateAccountDialog}
          onClose={this.handleCloseCreateAccountDialog}
        />
      </>
    );
  }

  private handleShowAlertBar(profile: Profile, alerts?: IAlert[]) {
    let isShowAlertBar = false;
    if (profile && alerts) {
      const unreadAlertLatestCreatedTime = Math.max(
        ...alerts.map((alert) => new Date(alert.createAt).getTime()),
        0,
      );
      isShowAlertBar =
        new Date(profile.preference.lastNotificationBarShownDatetime).getTime() <
        unreadAlertLatestCreatedTime;
    }
    this.setState((prevState) => ({
      isShowAlertBar,
      alertAnchorEl: isShowAlertBar ? prevState.alertAnchorEl : null,
    }));
  }

  private onAlertClick = async (alert: IAlert) => {
    const { alertUuid, read } = alert;
    const { setAlertRead } = this.props;

    if (!read) {
      await setAlertRead({ input: { alertUuid } });
      await this.listUnreadAlerts();
    }
  };

  private listUnreadAlerts = async () => {
    const { accountSelected } = this.props;
    return (
      accountSelected &&
      this.props.listUnreadAlerts({
        accountUuid: accountSelected.accountUuid,
        page: { pageIndex: 0 },
      })
    );
  };

  public updateAccountSelected = async (account: Account) => {
    const { updateAccountSelected, selectNetwork, accountSelected } = this.props;

    if (accountSelected && accountSelected.accountUuid === account.accountUuid) {
      return;
    }
    const { accountUuid } = account;

    try {
      await updateAccountSelected({ accountUuid });
      this.props.listNetworks({ accountUuid });
      selectNetwork({
        input: {
          preference: {
            lastSelectedAccountUuid: accountUuid,
          },
        },
      });
      this.props.history.replace(AppRoutePath.Dashboard);
    } catch (error) {
      console.log(error);
    }

    if (this._isMounted) {
      this.handleCloseProfileMenu();
    }
  };

  private onCloseProfileSettingDialog = () => {
    this.setState({
      openProfileSettingDialog: false,
    });
  };

  private onOpenProfileSettingDialog = () => {
    this.setState({
      openProfileSettingDialog: true,
    });
    this.handleCloseProfileMenu();
  };

  public handleOpenProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ profileAnchorEl: event.currentTarget });
  };

  public handleCloseProfileMenu = () => {
    this.setState({ profileAnchorEl: null });
  };

  public handleOpenAlertPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ alertAnchorEl: event.currentTarget });
  };

  public handleCloseAlertPopover = () => {
    this.setState({ alertAnchorEl: null });
    this.updateNotificationBarLastShownDatetime();
  };

  public handleCloseAlertBar = () => {
    this.setState({ isShowAlertBar: false });
    this.updateNotificationBarLastShownDatetime();
  };

  public handleCloseAlertItems = () => {
    this.handleCloseAlertPopover();
    this.handleCloseAlertBar();
  };

  private onOpenAccountSettingDialog = () => {
    this.setState({ openAccountSettingDialog: true });
    this.handleCloseProfileMenu();
  };

  private onCloseAccountSettingDialog = () => {
    this.setState({ openAccountSettingDialog: false });
  };

  private handleOpenCreateAccountDialog = () => {
    this.setState({ openCreateAccountDialog: true });
    this.handleCloseProfileMenu();
  };

  private handleCloseCreateAccountDialog = () => {
    this.setState({ openCreateAccountDialog: false });
  };

  private updateNotificationBarLastShownDatetime = () => {
    this.props.selectNetwork({
      input: {
        preference: {
          lastNotificationBarShownDatetime: new Date().toISOString(),
        },
      },
    });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      minHeight: 60,
    },
    container,
    appBar: {
      backgroundColor: whiteColor,
      boxShadow: 'none',
      borderBottom: '1px solid rgba(112, 112, 112, 0.3)',
    },
    toolBar: {
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 60,
    },
    logo: {
      width: 240,
    },
    flex: {
      flex: 1,
    },
    circle: {
      width: 28,
      height: 28,
      borderRadius: '100%',
      opacity: 0.89,
      display: 'inline-block',
      backgroundColor: summerSkyColor,
      marginRight: 8,
      verticalAlign: 'middle',
    },
    toolBarTitle: {
      ...defaultFontBold,
      fontSize: 18,
      color: denimColor,
      verticalAlign: 'middle',
      display: 'inline-block',
      transform: 'translateY(1px)',
    },
    items: {
      display: 'flex',
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      paddingLeft: 12,
      paddingRight: 12,
    },
    itemBtn: {
      ...defaultFont,
      fontSize: 16,
      textTransform: 'none',
      height: 40,
    },
    userinfo: {
      display: 'flex',
      alignItems: 'center',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    accountName: {
      ...defaultFontMedium,
      fontSize: 13,
      color: lightSlateGreyColor,
      marginRight: 10,
      maxWidth: 400,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    accountInfo: {
      ...defaultFont,
      fontSize: 13,
      color: lightSlateGreyColor,
      display: 'flex',
      alignItems: 'center',
    },
    copyButton: {
      padding: 5,
      marginLeft: 5,
      marginRight: -10,
    },
    accountSeparate: {
      width: 1,
      height: 18,
      backgroundColor: pattensBlueColor,
      marginRight: 15,
      marginLeft: 15,
    },
    closeAlertBtn: {
      position: 'absolute',
      top: '50%',
      right: 32,
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    },
    closeIcon: {
      width: 11,
      height: 11,
    },
    alertContainer: {
      height: 90,
      position: 'relative',
      transition: 'all 0.5s',
      '& .alert-background': {
        width: '100%',
        height: 30,
        position: 'fixed',
        top: 60,
        backgroundColor: sunglow,
        transition: 'all 0.5s',
      },
      '&[data-show=false]': {
        height: 60,
        '& .alert-background': {
          top: 30,
        },
      },
    },
    alertBar: {
      bottom: 0,
      height: 30,
      width: '100%',
      position: 'absolute',
    },
    alertButton: {
      ...defaultFont,
      width: 'fit-content',
      height: '100%',
      fontSize: 13,
      margin: 'auto',
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      userSelect: 'none',
    },
    alertIconArea: {
      marginRight: 5,
      display: 'inline-block',
    },
    alertText: {
      ...defaultFont,
      fontSize: 13,
      paddingRight: 10,
    },
    alertNumber: {
      height: 14,
      paddingLeft: 4,
      paddingRight: 4,
      ...defaultFont,
      color: whiteColor,
      fontSize: 10,
      backgroundColor: sunColor,
      borderRadius: 4,
      textAlign: 'center',
      display: 'inline-block',
    },
    globeIcon: {
      width: 16,
      height: 16,
      marginRight: 5,
    },
    arrowIconArea: {
      marginLeft: 10,
      transform: 'translateY(-2px)',
    },
    avatarIconArea: {
      marginLeft: 12,
    },
    avatarIcon: {
      borderRadius: '50%',
      width: 30,
      height: 30,
    },
    regionText: {
      ...defaultFont,
      color: lightSlateGreyColor,
    },
    displayNameText: {
      ...defaultFont,
      color: lightSlateGreyColor,
    },
    verticalSeparate: {
      width: 1,
      height: 40,
      backgroundColor: whiteSmokeColor,
      marginRight: 12,
      marginLeft: 12,
    },
    profileMenuContainer: {
      width: 249.7,
      borderRadius: 4,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      border: `solid 1px ${pattensBlueColor}`,
      backgroundColor: whiteColor,
      marginTop: 16,
    },
    profileMenuInfo: {
      display: 'grid',
      position: 'relative',
      padding: '20px 25px',
      gridTemplateAreas: `
    'avatar name'
    'avatar mail'
    `,
      gridTemplateColumns: 'min-content auto',
      borderBottom: `1px solid ${pattensBlueColor}`,
      '& .avatar': {
        borderRadius: '50%',
        width: 36,
        height: 36,
        marginTop: 3,
        marginRight: 10,
        gridArea: 'avatar',
      },
      '& .name': {
        ...defaultFont,
        fontSize: 15,
        lineHeight: '1.2em',
        color: nightRiderColor,
        gridArea: 'name',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .mail': {
        ...defaultFont,
        fontSize: 12,
        lineHeight: '1.2em',
        color: denimColor,
        gridArea: 'mail',
        display: 'flex',
        alignItems: 'flex-end',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    profileMenuAccount: {
      paddingTop: 16.5,
      paddingBottom: 15.5,
      borderBottom: `1px solid ${pattensBlueColor}`,
    },
    checkedIcon: {
      position: 'absolute',
      left: 0,
      marginLeft: 10,
      marginRight: 6,
    },
    profilePopoverAccountName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    statusIcon: {
      marginLeft: 10,
    },
    profileMenuAccountSetting: {
      paddingTop: 5.5,
      paddingBottom: 5.5,
      borderBottom: `1px solid ${pattensBlueColor}`,
    },
    profileMenuButtonAddAccount: {
      width: '100%',
      height: 32,
      borderRadius: 4,
      border: `solid 1px ${pattensBlueColor}`,
      backgroundColor: whiteSmokeColor,
      ...defaultFont,
      fontSize: 13,
      lineHeight: 1.15,
      textAlign: 'center',
      color: denimColor,
      textTransform: 'none',
    },
    profileMenuListSettingItem: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
      ...defaultFont,
      fontSize: 13,
      color: nightRiderColor,
    },
    logout: {
      padding: 0,
      marginTop: 5.5,
      marginBottom: 5.5,
    },
    logoutButton: {
      ...defaultFont,
      fontSize: 13,
      color: nightRiderColor,
      paddingLeft: 30,
    },
    alertPopoverPaper: {
      width: 400,
      marginTop: 8,
    },
    alertItem: {
      display: 'block',
      borderBottom: `1px solid ${pattensBlueColor}`,
      paddingLeft: 21,
      paddingRight: 21,
      paddingTop: 13,
      paddingBottom: 13,
    },
    alertItemTitle: {
      ...oneLineText,
      ...defaultFontBold,
      cursor: 'pointer',
      fontSize: 15,
      lineHeight: '18px',
      color: nightRiderColor,
    },
    alertItemDescription: {
      ...defaultFont,
      ...oneLineText,
      color: lightSlateGreyColor,
      fontSize: 13,
      lineHeight: '18px',
    },
    seeAll: {
      ...defaultFont,
      fontSize: 14,
      textAlign: 'center',
      color: denimColor,
      justifyContent: 'center',
    },
    settingButton: {
      position: 'absolute',
      top: 12,
      right: 0,
      padding: 10,
      cursor: 'pointer',
      lineHeight: '13px',
    },
    settingIcon: {
      width: 13,
      height: 13,
    },
    linkItem: {},
    infoInner: {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      info: {
        alignItems: 'flex-end',
      },
      logo: {
        width: 220,
        marginRight: 20,
      },
      userinfo: {
        display: 'flex',
        flexDirection: 'column',
      },
      infoInner: {
        alignItems: 'flex-end',
      },
      accountInfo: {
        display: 'flex',
        alignItems: 'center',
      },
      accountName: {
        whiteSpace: 'normal',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
  networks: store.appState.networks,
  accountList: store.appState.listAccounts,
  accountSelected: store.appState.accountSeleted,
  unreadAlertList: store.appState.unreadAlertList,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateProfile: (args: AppActions.MutationUpdateProfileArgs) =>
    dispatch(AppActions.updateProfile(args)),
  selectNetwork: (args: AppActions.MutationSelectNetworkArgs) =>
    dispatch(AppActions.selectNetwork(args)),
  updateAccountSelected: (args: AppActions.UpdateAccountSelectedArgs) =>
    dispatch(AppActions.updateAccountSelected(args)),
  setAlertRead: (args: AlertActions.MutationSetAlertReadArgs) =>
    dispatch(AlertActions.setAlertRead(args)),
  listUnreadAlerts: (args: AlertActions.QueryListAlertsArgs) =>
    dispatch(AlertActions.listUnreadAlerts(args)),
  createAccount: (args) => dispatch(AppActions.createAccount(args)),
  listNetworks: (args) => dispatch(NetworkActions.listNetworks(args)),
  resetStore: () => dispatch({ type: 'RESET_STORE' }),
});

export default withRouter(
  withStyles(styles)(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header))),
);

import moment from 'moment-timezone';

export type SelectOption = {
  value: any;
  label: any;
};

export const momentDateConvert = (args: {
  date: string | Date | moment.Moment;
  formatInput?: string;
  formatOutput: string;
  utcOffset?: number | string;
}) => {
  if (moment(args.date, args.formatInput).isValid()) {
    if (args.utcOffset) {
      return moment(args.date, args.formatInput)
        .utcOffset(args.utcOffset)
        .format(args.formatOutput);
    } else {
      return moment(args.date, args.formatInput).format(args.formatOutput);
    }
  } else {
    return '';
  }
};

export const timezoneOptions: Array<SelectOption> = Array.from(
  new Set(
    moment.tz
      .countries()
      .map((country) => moment.tz.zonesForCountry(country))
      .flat(),
  ),
)
  .map((name) => ({
    value: name,
    offset: moment.tz(name).utcOffset(),
    label: `(UTC${moment.tz(name).format('Z')}) - ${name}`,
  }))
  .sort((a, b) => a.offset - b.offset);

export const toUTCStartDate = (dateString?: moment.MomentInput) =>
  moment(dateString).startOf('day').utc().toDate();

export const toUTCEndDate = (dateString?: moment.MomentInput) =>
  moment(dateString).endOf('day').utc().toDate();

// get years from (default 2020)
export const getYearOptions = (startYear = 2020): string[] => {
  return Array.from(
    {
      length: moment().diff(moment({ years: startYear }), 'years') + 1,
    },
    (v, k) => {
      return moment().subtract(k, 'years').toDate().getFullYear().toString();
    },
  );
};

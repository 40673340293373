import React from 'react';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';
import AppLayout from '~/components/common/Layout';
import { renderSubRoutes } from '~/utilities/react-router.util';
import { RouteConfigComponentProps } from 'react-router-config';
import { withProfile } from '~/hooks/with-profile';

interface Props extends WithStyles<typeof styles>, RouteConfigComponentProps {}

const Dashboard: React.FC<Props> = (props) => {
  return <AppLayout>{props?.route?.routes && renderSubRoutes(props.route.routes)}</AppLayout>;
};

const styles = createStyles({
  root: {},
});

export default compose(
  withFirebaseUser(),
  withProfile,
  withInitAppData,
  withStyles(styles),
)(Dashboard);

import React from 'react';
// Redux
// Component
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium } from '~/styles/themes/common-styles/font';
import { nepalColor, nightRiderColor, dodgerBlueColor } from '~/styles/themes/common-styles/color';
// Type

export interface ITabs {
  title: string;
  component: JSX.Element;
}

interface IStateProps {
  tabs: ITabs[];
}

interface IProps extends IStateProps, WithStyles<typeof styles> {
  id?: string;
}

interface IState {
  tabValue: number;
}

class CustomTabs extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabValue: 0,
    };
  }

  public render() {
    const { classes, tabs, id } = this.props;
    const { tabValue } = this.state;

    return (
      <>
        <Tabs
          value={tabValue}
          onChange={this.handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          id={id}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.title}
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          ))}
        </Tabs>
        <div className={classes.tabBarBottom} />
        {tabs.map((tab, index) => {
          if (tabValue !== index) {
            return null;
          }
          return (
            <div key={index} className={classes.content}>
              {tab.component}
            </div>
          );
        })}
      </>
    );
  }

  private handleChange = (e: React.ChangeEvent<{}>, value: number) => {
    this.setState({
      tabValue: value,
    });
  };
}

const styles = createStyles({
  root: {},
  tabRoot: {
    ...defaultFontMedium,
    textTransform: 'initial',
    fontSize: 15,
    color: nepalColor,
    '&$tabSelected': {
      color: nightRiderColor,
    },
    minWidth: 100,
  },
  tabsRoot: {
    // borderBottom: `1px solid ${nepalColor}`,
  },
  tabsIndicator: {
    backgroundColor: dodgerBlueColor,
    height: 3,
    zIndex: 3,
  },
  tabLabelContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabSelected: {},
  tabBarBottom: {
    width: '100%',
    height: 3,
    minHeight: 3,
    backgroundColor: 'rgba(153, 170, 185, 0.38)',
    transform: 'translateY(-3px)',
  },
  content: {},
});

export default withStyles(styles)(CustomTabs);

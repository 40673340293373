import React from 'react';
import { connect } from 'react-redux';
// Redux
import { IStore } from '~/stores/configure-store';
// Component
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';

import ImgIcon from '~/components/common/img-icon';
import NotifyIcon from '~/components/common/notify-icon';
import LGButton from '~/components/common/lg-button';
import ArrowIcon from '~/components/common/arrow-icon';
import Button from '@mui/material/Button';
// Route
import { Link, NavLink } from 'react-router-dom';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { container } from '~/styles/themes/common-styles/grid-member';
import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
  defaultFontBold,
} from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  denimColor,
  pattensBlueColor,
  lightSlateGreyColor,
  romanColor,
  whiteColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Translation
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
// Type
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { INetwork } from '~/types/network-types';
import CreateNetworkDialog from '~/components/common/create-network-dialog';
import { renderDocumentLink } from '~/utilities/render-utils';

interface IStateProps {
  accountSelected?: Account;
  networkSelected?: INetwork;
  networks?: INetwork[];
  amountUnreadAlert: number;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {
  anchorEl: any;
  openCreateNetworkDialog: boolean;
  searchNetworkText: string;
}

class SideBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorEl: undefined,
      openCreateNetworkDialog: false,
      searchNetworkText: '',
    };
  }

  public render() {
    const {
      classes,
      accountSelected,
      networkSelected,
      networks,
      amountUnreadAlert,
      t,
      i18n,
      selectedAccountRole,
      selectedNetworkRole,
    } = this.props;
    const { anchorEl, openCreateNetworkDialog, searchNetworkText } = this.state;
    const selectNetworkPopoverOpen = Boolean(anchorEl);
    const showInvitationsListItem = selectedAccountRole === 'owner';
    const showAccessListItem =
      networkSelected &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');
    const navigations: {
      route?: string;
      render: any;
      isExternal?: boolean;
      dataTestid?: string;
    }[] = [];
    navigations.push({
      dataTestid: 'side-bar-navigate-to-nodes',
      route: networkSelected && `/network/${networkSelected.networkUuid}/overview`,
      render: (
        <ListItem id="side-menu-nodes" className={classes.listItem}>
          <div className={classes.listIconArea}>
            <ImgIcon
              className={classNames(classes.listIcon, 'iconInactive')}
              imgUrl="/images/icons/nodes_ico.png"
            />
            <ImgIcon
              className={classNames(classes.listIcon, 'iconActive')}
              imgUrl="/images/icons/nodes_current_ico.png"
            />
          </div>
          <span>
            <Trans i18nKey={'nodes'}></Trans>
          </span>
        </ListItem>
      ),
    });
    if (showAccessListItem) {
      navigations.push({
        dataTestid: 'side-bar-navigate-to-access',
        route: '/access',
        render: (
          <ListItem id="side-menu-access" className={classes.listItem}>
            <div className={classes.listIconArea}>
              <ImgIcon
                className={classNames(classes.listIcon, 'iconInactive')}
                imgUrl="/images/icons/user_ico.png"
              />
              <ImgIcon
                className={classNames(classes.listIcon, 'iconActive')}
                imgUrl="/images/icons/user_current_ico.png"
              />
            </div>
            <span>
              <Trans i18nKey={'access'}></Trans>
            </span>
          </ListItem>
        ),
      });
    }
    navigations.push({
      route: 'separator',
      render: <div className={classes.separateLine} />,
    });
    navigations.push({
      dataTestid: 'side-bar-navigate-to-notifications',
      route: '/notifications',
      render: (
        <ListItem id="side-menu-notifications" className={classes.listItem}>
          <div className={classes.listIconArea}>
            <NotifyIcon className={'iconActive'} amountNotify={amountUnreadAlert} selected={true} />
            <NotifyIcon className={'iconInactive'} amountNotify={amountUnreadAlert} />
          </div>
          <span>
            <Trans i18nKey={'notifications'}></Trans>
          </span>
        </ListItem>
      ),
    });
    if (showInvitationsListItem) {
      navigations.push({
        dataTestid: 'side-bar-navigate-to-invitations',
        route: `/invitations`,
        render: (
          <ListItem id="side-menu-invitations" className={classes.listItem}>
            <div className={classes.listIconArea}>
              <ImgIcon
                className={classNames(classes.listIcon, 'iconInactive')}
                imgUrl="/images/icons/invite_ico.png"
              />
              <ImgIcon
                className={classNames(classes.listIcon, 'iconActive')}
                imgUrl="/images/icons/invite_on_ico.png"
              />
            </div>
            <span>
              <Trans i18nKey={'invitation'}></Trans>
            </span>
          </ListItem>
        ),
      });
    }
    navigations.push({
      dataTestid: 'side-bar-navigate-to-billing-license',
      route: `/billing/license`,
      render: (
        <ListItem id="side-menu-billing" className={classes.listItem}>
          <div className={classes.listIconArea}>
            <img
              className={classNames(classes.listIcon, 'iconInactive')}
              src="/images/icons/billing_ico.svg"
            />
            <img
              className={classNames(classes.listIcon, 'iconActive')}
              src="/images/icons/billing_active_ico.svg"
            />
          </div>
          <span>
            <Trans i18nKey={'billing'}></Trans>
          </span>
        </ListItem>
      ),
    });
    navigations.push({
      dataTestid: 'side-bar-navigate-to-docs',
      route: renderDocumentLink(i18n.language),
      isExternal: true,
      render: (
        <ListItem id="side-menu-docs" className={classes.listItem}>
          <div className={classes.listIconArea}>
            <ImgIcon
              className={classNames(classes.listIcon, 'iconInactive')}
              imgUrl="/images/icons/docs_ico.png"
            />
            <ImgIcon
              className={classNames(classes.listIcon, 'iconActive')}
              imgUrl="/images/icons/docs_current_ico.png"
            />
          </div>
          <span>
            <Trans i18nKey={'docs'}></Trans>
          </span>
        </ListItem>
      ),
    });
    navigations.push({
      dataTestid: 'side-bar-navigate-to-support',
      route: `https://support.bccloud.net`,
      isExternal: true,
      render: (
        <ListItem id="side-menu-support" className={classes.listItem}>
          <div className={classes.listIconArea}>
            <ImgIcon
              className={classNames(classes.listIcon, 'iconInactive')}
              imgUrl="/images/icons/support_ico.png"
            />
            <ImgIcon
              className={classNames(classes.listIcon, 'iconActive')}
              imgUrl="/images/icons/support_current_ico.png"
            />
          </div>
          <span>
            <Trans i18nKey={'support'}></Trans>
          </span>
        </ListItem>
      ),
    });
    navigations.push({
      route: 'separator',
      render: <div className={classes.separateLine} />,
    });

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Trans i18nKey={'blockchain_network'}></Trans>
        </div>
        <div className={classes.selectNetworkArea}>
          <LGButton
            data-testid="open-select-network-button"
            classes={{ root: classes.selectNetworkBtn }}
            onClick={this.openSelectNetworkPopover}
          >
            <span id="side-menu-select" className={classes.networkSelectedName}>
              {networkSelected ? networkSelected.networkName : <Trans i18nKey={'select'} />}
            </span>
            <ArrowIcon direction={'down'} size={3} />
          </LGButton>
          <Popover
            open={selectNetworkPopoverOpen}
            anchorEl={anchorEl}
            onClose={this.onCloseSelectNetworkPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            elevation={1}
            classes={{ paper: classes.selectNetworkPopoverPaper }}
          >
            <List className={classes.listNetwork}>
              <ListItem
                className={classNames(classes.listNetworkItem, classes.whiteSmokeBackgroundColor)}
              >
                <ImgIcon className={classes.searchIcon} imgUrl="/images/icons/search_ico.png" />
                <input
                  className={classes.findNetworkInput}
                  type="text"
                  name="network"
                  autoComplete="off"
                  placeholder={t('find_a_network')}
                  onChange={this.onFindNetworkInputChange}
                />
              </ListItem>
              <div className={classes.horizontalSeparate}></div>
              <div className={classes.listNetworkItemWrapper}>
                {(networks || [])
                  .filter((network) =>
                    network.networkName.toLocaleLowerCase().includes(searchNetworkText),
                  )
                  .map((network, index) => (
                    <Link to={`/network/${network.networkUuid}/overview`} key={index}>
                      <ListItem
                        button
                        className={classes.listNetworkItem}
                        onClick={this.onCloseSelectNetworkPopover}
                      >
                        {network.networkName}
                      </ListItem>
                    </Link>
                  ))}
              </div>
              <div className={classes.horizontalSeparate}></div>
              <ListItem
                button
                className={classNames(classes.listNetworkItem, classes.denimColorText)}
                onClick={this.onOpenCreateNetworkDialog}
              >
                <ImgIcon className={classes.addClusterIcon} imgUrl="/images/icons/add_ico.png" />
                <span className={classes.newNetworkText}>
                  <Trans i18nKey={'new_network'}></Trans>
                </span>
              </ListItem>
            </List>
          </Popover>
          <CreateNetworkDialog
            open={openCreateNetworkDialog}
            onClose={this.onCloseCreateNetworkDialog}
          />
        </div>
        <List className={classes.choiceList}>
          {navigations
            .filter((navigation) => navigation.route)
            .map((navigation, index) => {
              const { route, render, isExternal, dataTestid } = navigation;
              if (route === 'separator') {
                return <div key={index}>{render}</div>;
              }
              if (isExternal) {
                return (
                  <a
                    data-testid={dataTestid}
                    key={index}
                    href={route}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {render}
                  </a>
                );
              } else {
                return (
                  <NavLink
                    data-testid={dataTestid}
                    key={index}
                    activeClassName="active"
                    // exact={true}
                    strict={true}
                    to={route || ''}
                  >
                    {render}
                  </NavLink>
                );
              }
            })}
        </List>
        {accountSelected && accountSelected.status === 'payment_required' && (
          <div className={classes.paymentAlert}>
            <div
              className={classNames(
                classes.paymentAlertTitle,
                i18n.language === 'en' && classes.paymentAlertTitleEN,
              )}
            >
              <img src="/images/icons/attention_ico.svg" alt="" />
              <div
                className={classNames(
                  classes.paymentAlertTitleText,
                  i18n.language === 'en' && classes.paymentAlertTitleTextEN,
                )}
              >
                {t('side_bar_payment_alert_title')}
              </div>
            </div>
            <div
              className={classNames(
                classes.paymentAlertContent,
                i18n.language === 'en' && classes.paymentAlertContentEN,
              )}
            >
              {t('side_bar_payment_alert_content')}
            </div>
            <div className={classes.paymentAlertButtonWrapper}>
              <Link to="/billing/payment">
                <Button
                  data-testid="payment-alert-button"
                  className={classNames(
                    classes.paymentAlertButton,
                    i18n.language === 'en' && classes.paymentAlertButtonEN,
                  )}
                >
                  {t('side_bar_payment_alert_button_label')}
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }

  private onFindNetworkInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchNetworkText: event.target.value.toLocaleLowerCase(),
    });
  };

  private onCloseCreateNetworkDialog = () => {
    this.setState({
      openCreateNetworkDialog: false,
    });
  };

  private onOpenCreateNetworkDialog = () => {
    this.setState({
      openCreateNetworkDialog: true,
      anchorEl: null,
    });
  };

  private onCloseSelectNetworkPopover = (e) => {
    this.setState({
      anchorEl: null,
    });
  };

  private openSelectNetworkPopover = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };
}

const styles = createStyles({
  root: {},
  container,
  title: {
    ...defaultFontRegular,
    fontSize: 13,
    color: lightSlateGreyColor,
  },
  selectNetworkArea: {
    marginTop: 7,
  },
  selectNetworkBtn: {
    width: '100%',
    height: 38,
  },
  choiceList: {
    marginTop: 16,
    paddingTop: 0,
    paddingBottom: 0,
    '& .iconActive': {
      display: 'none',
    },
    '& .active': {
      color: denimColor,
    },
    '& .active .iconActive': {
      display: 'inline-block',
    },
    '& .active .iconInactive': {
      display: 'none',
    },
  },
  listItem: {
    padding: '0 10px',
    margin: '10px 0',
    '& span': {
      ...defaultFontMedium,
      fontSize: 15,
    },
  },
  selectNetworkPopoverPaper: {
    width: 240,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
    marginTop: 2,
  },
  findNetworkInput: {
    ...defaultFont,
    fontSize: 15,
    width: '100%',
    height: 35,
    border: 'none',
    backgroundColor: 'inherit',
    marginLeft: 12,
  },
  whiteSmokeBackgroundColor: {
    backgroundColor: whiteSmokeColor,
  },
  listNetwork: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchIcon: {
    width: 16,
    height: 16,
  },
  listNetworkItemWrapper: {
    maxHeight: 300,
    overflow: 'auto',
  },
  listNetworkItem: {
    ...defaultFontRegular,
    fontSize: 15,
    height: 48,
  },
  newNetworkText: {
    ...defaultFontMedium,
    marginLeft: 12,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  denimColorText: {
    color: denimColor,
  },
  listIconArea: {
    marginRight: 14,
    display: 'flex',
  },
  listIcon: {
    width: 16,
    height: 16,
  },
  separateLine: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
    marginTop: 20,
    marginBottom: 20,
  },
  networkSelectedName: {
    ...defaultFontMedium,
    ...oneLineText,
    fontSize: 15,
    marginRight: 10,
    color: nightRiderColor,
  },
  addClusterIcon: {},
  paymentAlert: {
    backgroundColor: romanColor,
    borderRadius: 4,
    padding: '12px 10px 18px',
    color: whiteColor,
  },
  paymentAlertTitle: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 20,
      height: 20,
      marginRight: 7,
    },
  },
  paymentAlertTitleEN: {
    flexDirection: 'column',
    '& img': {
      marginRight: 0,
    },
  },
  paymentAlertTitleText: {
    ...defaultFontMedium,
    fontSize: 14,
  },
  paymentAlertTitleTextEN: {
    marginTop: 5,
    lineHeight: '16px',
    textAlign: 'center',
    width: 150,
  },
  paymentAlertContent: {
    marginTop: 15,
    ...defaultFontRegular,
    fontSize: 12,
    lineHeight: '19px',
  },
  paymentAlertContentEN: {
    marginTop: 5,
    lineHeight: '14px',
  },
  paymentAlertButtonWrapper: {
    marginTop: 15,
  },
  paymentAlertButton: {
    width: '100%',
    backgroundColor: whiteColor,
    textTransform: 'none',
    color: romanColor,
    fontSize: 12,
    paddingLeft: 20,
    paddingRight: 20,
    ...defaultFontBold,
    '&:hover': {
      backgroundColor: whiteColor,
    },
  },
  paymentAlertButtonEN: {
    lineHeight: '14px',
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  networkSelected: store.appState.networkSelected,
  networks: store.appState.networks,
  amountUnreadAlert: store.appState.unreadAlertList.pageInfo.totalItems || 0,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SideBar)),
);

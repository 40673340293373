export default {
  hello: 'Hello',
  brand: 'G.U. Blockchain Cloud',
  already_have_an_account_log_in_here: 'Already have an account? Log in here →',
  login_here: 'here →',
  get_started: 'Get Started',
  why_GU_Chain: 'Why G.U. Chain?',
  solutions: 'Solutions',
  plan: 'Plan',
  plans: 'Plans',
  members: 'Members',
  projects: 'Projects',
  users: 'Users',
  storage: 'Storage',
  more: 'More',
  month: 'month',
  let_is_try: "Let's Try",
  what_are_you_waiting_for: 'What are you waiting for? Try it today.',
  contact_sales_detail:
    'It’s radically simple to use and universally accessible with a seamless experience for all.',
  pricing: 'Pricing',
  contact: 'Contact',
  sign_in: 'Sign In',
  congraturation: 'Congratulation!',
  you_can_successfully_create_a_new_account: 'You can successfully create a new account!',
  have_a_nice_blockchain_world: 'Have a nice blockchain world!',
  start: 'Start',
  'loading...': 'Loading...',
  login_success: 'Success',
  please_complete_email_verification: 'Please complete email verification',
  please_select_login_provider: 'Please select login provider',
  singin_by_xxx: 'Signed in by {{ name }}. Trying login..',
  signin_take_a_log_time: 'Registering account information. Please wait..',
  copyright_2016_openid_foundation: 'Copyright 2016 OpenID Foundation',
  about_account: 'About Account',
  chooser_privacy_policy: 'ChooserPrivacy Policy',
  'G.U.Blockchain Cloud': 'G.U.Blockchain Cloud',
  'subscribe_to_g.u.chain_via_email': 'Subscribe to G.U.Chain via Email',
  please_register_email:
    'Please register your Email address if you want to know the latest new of G.U.Chain',
  subscribe: 'Subscribe',
  FIND_A_STORE: 'FIND A STORE',
  CONTACT_US: 'CONTACT US',
  'ORDERING_&_PAYMENT': 'ORDERING & PAYMENT',
  SHIPPING: 'SHIPPING',
  RETURNS: 'RETURNS',
  FAQ: 'FAQ',
  SIZING_GUIDE: 'SIZING GUIDE',
  'ABOUT_G.U.Chain': 'ABOUT G.U.Chain',
  WORK_WITH_US: 'WORK WITH US',
  PRIVACY_POLICY: 'PRIVACY POLICY',
  'TERMS_&_CONDITIONS': 'TERMS & CONDITIONS',
  PRESS_ENQUIRIES: 'PRESS ENQUIRIES',
  started_section_description:
    'It’s radically simple to use and universally accessible with a seamless experience for all.',
  why_GU_chain_description:
    'Create, customize and manage your private chain or consortium chain for your business with click button simplicity and save months and millions.',
  landing_page_subtitle:
    'The Enterprise Ethereum Blockchain Cloud platform for building global, consortium networks.',
  sub_total: 'Sub Total',
  basic_support: 'Basic Support',
  total_monthly_cost: 'Total Money Cost',
  '10GB_storage': '10GB Storage',
  nodes: 'Nodes',
  easy_to_use_ui_ux: 'Easy to use UI/UX',
  easy_to_use_ui_ux_detail:
    'Blockchain Cloud was created based on the theme of how to simplify the operation of complex blockchain networks. Even if you are not a very advanced engineer, you can easily create, operate, and maintain your own blockchain using Blockchain Cloud. Furthermore, it is easy to create and use consortium chains across organizations.',
  easy_to_create_blockchain: 'Easy to Create Blockchain',
  easy_to_create_blockchain_detail:
    'All you need to do to set up your blockchain network is to click a button and fill in the required information. After a few minutes of waiting, your network will be up and running, and the statistics screen will immediately show you that your network is up and running. You can also immediately start developing for a properly load-balanced and highly available blockchain network through the Endpoint URL for using the launched network.',
  smart_monitoring: 'Smart Monitoring',
  smart_monitoring_detail:
    'To operate a stable blockchain network, Operation tools are required. Blockchain Cloud’s per-node metrics screen allows you to easily check the status of each node, including CPU, memory, storage, and number of peers. You can graphically check various information such as CPU, memory, storage, number of peers, etc.',
  read_more: 'Read More',
  about_gu_chain_cloud: 'About G.U. Blockchian Cloud',
  get_free: 'Get free',
  contact_sales: 'Contact Sales',
  easy_to_make_block_chain: 'Easy to make blockchain',
  easy_to_make_block_chain_des:
    'You can set up your own private, consortium, and customized blockchain in minutes.',
  simple_and_easy_to_use: 'Simple and easy to use',
  simple_and_easy_to_use_des:
    'We have created a user interface that is easy for anyone to use without the need for training.',
  enterprise_grade: 'Enterprise grade',
  enterprise_grade_des:
    'We support the operation of your blockchain network with support that is ready for enterprise production operations.',
  cookie_settings: 'Cookie Settings',
  accept_use_of_cookies: 'Accept Use of Cookies',
  accept_call_cookies: 'Accept all cookies',
  blockchain_for_modern_business_network: 'Blockchain for modern business network',
  blockchain_for_modern_business_network_detail:
    'The Enterprise Ethereum Blockchain cloud platform for building private, consortium business network.',
  accept_use_of_cookies_detail:
    'We use cookies to provide to analyze our traffic. We also share information about your use of our site with analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
  from: 'From',
  total_per_month: 'Total ( per month )',
  pricing_detail:
    'The Enterprise Ethereum Blockchain cloud platform for building private, consortium business network.',
  view_more: 'View More',
  learn_more: 'Learn More',
  for_starter: 'For Starter',
  for_professional: 'For Professional',
  starting_at: 'Starting at',
  example: 'Example',
  developer: 'Developer',
  standard: 'Standard',
  enterprise: 'Enterprise',
  prices: 'Prices',
  hour: 'hour',
  tps_limitation: 'TPS Limitation',
  start_free: 'Get Started',
  free_trial: 'Free Trial',
  single_cluster: 'Single Cluster',
  single_node: 'Single Node',
  multiple_clusters: 'Multiple Clusters',
  multiple_node: 'Multiple Node',
  free: 'FREE',
  plan_price_from: 'From <1>${{price}}</1>',
  start_with_coupon: 'Start with ${{price}} coupon',
  about_gu_chain_cloud_detail:
    'Create, customize and manage your private chain or consortium chain for your business with click button simplicity and save months and millions.',
  privacy_policy: 'Privacy Policy',
  support: 'Support',
  terms: 'Terms',
  commerce: 'Commerce',
  single_cluster_detail:
    'For teams learning Blockchain or developing test application. Start with 14 days free trial.',
  multiple_node_detail:
    'For teams learning Blockchain or developing test application. Start with 14 days free trial.',
  '14days_free': '14 days free',
  multiple_nodes: 'Multiple Nodes',
  for_business: 'For Business',
  for_enterprise: 'For Enterprise',
  gu_blockchain_cloud_footer: '© G.U.Technologies, Inc.',
  not_found_page_title: 'Not Found',
  not_found_page_description: 'The page you are looking for doesn’t exist or has been moved.',
  not_found_page_top_page_button_label: 'Top page',
  not_found_page_contact_us_text: 'Click here to contact us.',
  error_page_title: 'Error',
  error_page_description: 'A system error has occurred.<br>Please try again in a few minutes.',
  error_page_top_page_button_label: 'Top page',
  error_page_contact_us_text: 'Click here to contact us.',
  for_developer_test: 'For Developer, Test',
  for_developer_test_detail:
    'For beginners in blockchain learning, and for developer who want to launch a test network during development which is inappropriate in open test nets',
  for_production: 'For Production',
  for_production_detail:
    'For small production-level networks with an average 10 tps and a maximum of 100tps, and read accesses that does not exceed 30 million requests per month',
  for_business_detail:
    'For business production-level networks with an average 200tps and read accesses that does not exceed 500 million requests per month',
  server_cost: 'Server Cost',
  ethereum_node_price: 'Ethereum Node Price',
  price_page_prices: 'Prices',
  price_page_ethereum_node_price: 'Ethereum Node Price',
  price_page_storage: 'Storage',
  price_page_cluster: 'Cluster',
  price_page_month: 'Price per Month',
  price_page_hour: 'Hour',
  micro: 'Micro',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  extra_large: 'Extra Large',
  basic_capacity_unit_1gb: 'basic capacity unit 1GB',
  default_capacity: 'Default Capacity',
  cluster: 'Cluster',
  assumed_average_tps: 'Assumed Average TPS',
  limit_req_month: 'Limit Req / Month',
  enterprise_cluster: 'Enterprise Cluster',
  contact_us: 'Contact Us',
  unlimited: 'unlimited',
  blockchain_exporer_license: 'Blockchain Exploer License',
  storage_price: 'Storage price',
  total: 'Total',
  reference_composition: 'Reference Composition',
  price_page_reference_description:
    'The prices below are the estimated prices for this configuration if you choose to use this configuration.',
  lisence_detail_link: 'Click here to show more detail about lisence',
  plan_page_title: 'Plan',
  plan_page_description:
    'The Enterprise Ethereum Blockchain cloud platform for building private, consortium business network.',
  normal: 'Normal',
  plan_page_normal_description:
    'If you want to use a cluster with more than small size and expect to use it for a long period of time, it is possible to pay the bills, so please contact us for more information.',
  plan_page_enterprise_description:
    'If you want a customizable blockchain, including human support, you can choose the Enterprise plan, or contact us for an Enterprise plan.',
  cluster_type: 'Cluster Type',
  ethereum_node: 'Ethereum Node',
  blockchain_explorer: 'Blockchain Explorer',
  multi_user: 'Multi User',
  private_network: 'Private Network',
  consotium_network: 'Consortium Network',
  unlimited_endpoint: 'Unlimited Endpoint',
  multi_cluster: 'Multi Cluster',
  multi_resion: 'Multi Resion',
  enterprise_support: 'Enterprise Support',
  on_premise: 'On-Premise',
  plan_page_learn_more_ethereum_node_title: 'Ethereum Node',
  plan_page_learn_more_ethereum_node_description:
    'A server for configuring a single blockchain node.',
  plan_page_learn_more_storage_title: 'Storage',
  plan_page_learn_more_storage_description:
    'It is a non-volatile storage where blockchain data is stored.',
  plan_page_learn_more_cluster_title: 'Cluster',
  plan_page_learn_more_cluster_description:
    'All clusters include a load balancer and WAF DoS attack prevention mechanism. Access is blocked if the monthly request is exceeded.',
  maximum_monthly_requests: 'Maximum monthly requests',
  plan_page_learn_more_license_title: 'Blockchain Exploer License',
  plan_page_learn_more_license_description:
    'This tool allows you to view the state of the blockchain graphically and in real time.',
  law_sale_page_title: 'Notation based on the Act on Specified Commercial Transactions',
  pricing_plan_page_pricing_detail_ethereum_node: 'Ethereum Node',
  pricing_plan_page_pricing_detail_ethereum_node_description:
    'A server for configuring a single blockchain node.',
  pricing_plan_page_pricing_detail_server_cost: 'Server Cost',
  pricing_plan_page_pricing_detail_assumed_tps: 'Assumed TPS (Write Speed)',
  pricing_plan_page_pricing_detail_storage_base_unit: 'Storage (Base Unit 1GB / $0.20 per month)',
  pricing_plan_page_pricing_detail_price_monthly: 'Price (monthly)',
  pricing_plan_page_pricing_detail_per_hour: 'Per hour',
  pricing_plan_page_pricing_detail_average: 'Average',
  pricing_plan_page_pricing_detail_maximum: 'Maximum',
  pricing_plan_page_pricing_detail_default_capacity: 'Default capacity',
  pricing_plan_page_pricing_detail_ethereum_node_bottom_note:
    '※Micro, Small, and Micro only, the capacity is reduced by up to 20% after some high load conditions.',
  pricing_plan_page_pricing_detail_cluster: 'Cluster',
  pricing_plan_page_pricing_detail_cluster_description:
    'All clusters include a load balancer and a WAF DoS attack prevention mechanism. If a monthly request is exceeded, access is blocked.',
  pricing_plan_page_pricing_detail_estimated_rps: 'Estimated RPS (average request speed)',
  pricing_plan_page_pricing_detail_monthyly_request_limit: 'Monthly request limit',
  pricing_plan_page_pricing_detail_blockchain_exporer_license: 'Blockchain Exploer License',
  pricing_plan_page_pricing_detail_blockchain_exporer_license_description:
    'A tool that allows you to view the state of the blockchain graphically and in real time.',
  pricing_plan_page_pricing_detail_server_price: 'Server Price',
  pricing_plan_page_pricing_detail_storage: 'Storage',
  pricing_plan_page_pricing_detail_storage_prices: 'Storage prices',
  pricing_plan_page_pricing_detail_monthly_total_price: 'Total price (monthly)',
  pricing_plan_page_pricing_detail_erc20_note:
    '※Notional TPS and notional RPS are based on figures that measure smart contract execution by remittance of ERC20 tokens.',
  pricing_plan_page_pricing_detail_minimum_invoice_note:
    '※The minimum invoice amount will be $0.5.',
  pricing_plan_page_cluster_type: 'Cluster Type',
  admin_sign_in: 'Please sign in to',
};

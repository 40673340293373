import moment from 'moment-timezone';
import { ILicenseSummary } from '~/types/payment-types';
import { CONVERT_PRICE_TO_CURRENCY } from '~/constants/consts';
import { numberToDecimalStr } from '~/utilities/string';

export const calculateSubtotal = (license: ILicenseSummary, paidDate: string): number => {
  // check input
  if (paidDate === '' || !moment(paidDate).isValid()) {
    return 0;
  }

  // calc rate
  const date = moment(paidDate).utc();
  const monthDays = date.daysInMonth();

  const { numDays, qty, unitPrice } = license;
  const rate = numDays === monthDays ? 1 : numDays / monthDays;

  // calc subtotal
  return Math.round(rate === 1 ? unitPrice * qty : unitPrice * qty * rate);
};

export const priceToCurrency = (pricePerCent: number): number => {
  return pricePerCent !== 0 ? pricePerCent / CONVERT_PRICE_TO_CURRENCY : 0;
};

export const displayCurrency = (value: number, needConv: boolean = true) => {
  const price = needConv ? priceToCurrency(value) : value;
  return numberToDecimalStr(price, 2);
};

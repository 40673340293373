import React from 'react';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
  isFullScreen?: boolean;
}

const LoadingComponent: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  isFullScreen = true,
}) => {
  return (
    <div className={isFullScreen ? classes.fullScreen : classes.layout} data-testid="loading">
      <CircularProgress />
    </div>
  );
};

const styles: StyleRules = {
  fullScreen: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default withStyles(styles)(LoadingComponent);

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import { nightRiderColor, whiteColor } from '~/styles/themes/common-styles/color';
import { defaultFontBold } from '~/styles/themes/common-styles/font-guest';
import { AppRoutePath } from '~/AppRouter';

interface IProps {
  brand: string;
  openHeaderBar?: boolean;
  color: string;
  absolute?: boolean;
  fixed?: boolean;
  rightLinks?: any;
  changeColorOnScroll: {
    height: number;
    color: string;
  };
}

interface IState {
  openMenu: boolean;
}

class Header extends React.Component<IProps & WithStyles<typeof styles>, IState> {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  public render() {
    const { classes, color, openHeaderBar, absolute, fixed, rightLinks, children } = this.props;
    const { openMenu } = this.state;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
      [classes.hiddenMenu]: !openMenu,
    });

    return (
      <AppBar className={appBarClasses} data-show={openHeaderBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.flex}>
            <Link to={AppRoutePath.Dashboard}>
              <img className={classes.logo} src="/images/icons/logo_guest.svg" alt="" />
            </Link>

            <button
              className={classNames({
                [classes.closeMenuIcon]: true,
                [classes.transformCloseIcon]: openMenu,
              })}
              onClick={this.collapseMenu}
            >
              <img src="/images/icons/arrow-down.svg" alt="" />
            </button>
          </div>
          {rightLinks}
        </Toolbar>
      </AppBar>
    );
  }

  private collapseMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };
}

const styles: StyleRules = {
  root: {},
  appBar: {
    border: '0',
    marginBottom: '20px',
    color: nightRiderColor,
    width: '100%',
    height: 74,
    backgroundColor: 'rgba(255,255,255,0.8)',
    boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    position: 'relative',
    zIndex: 'unset',
    transitionProperty: 'all',
    transitionDuration: '0.5s',
    transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
    '@media (max-width: 970px)': {
      height: 360,
      background: 'rgba(255, 255, 255, 0.95)',
      overflow: 'hidden',
    },
  },
  hiddenMenu: {
    height: 74,
  },
  toolBar: {
    maxWidth: 1380,
    margin: 'auto',
    width: '100%',
    padding: '0 70px',
    minHeight: '50px',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    '@media (max-width: 970px)': {
      display: 'block',
      padding: '20px 20px 0',
      maxWidth: 'unset',
    },
  },
  absolute: {
    position: 'absolute',
    zIndex: '1100' as any,
  },
  fixed: {
    position: 'fixed',
    zIndex: '1100' as any,
    top: 36,
    transition: '0.5s',
    '&[data-show=false]': {
      top: 3,
    },
  },
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    color: nightRiderColor,
    height: 100,
    top: 0,
  },
  white: {
    border: '0',
    padding: '0.625rem 0',
    marginBottom: '20px',
    color: nightRiderColor,
    backgroundColor: '#fff !important',
    boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
  },
  logo: {
    width: 240,
  },
  title: {
    ...defaultFontBold,
    fontSize: 30,
    textTransform: 'initial',
    color: 'inherit',
    padding: '8px 0px',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent',
    },
  },
  flex: {
    flex: 1,
    '@media (max-width: 970px)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  closeMenuIcon: {
    border: 'none',
    outline: 'none',
    display: 'none',
    cursor: 'pointer',
    background: 'transparent',
    padding: 10,
    '& img': {
      width: 20,
    },
    '@media (max-width: 970px)': {
      display: 'block',
    },
    transition: '0.4s',
  },
  transformCloseIcon: {
    transform: 'rotate(180deg)',
  },
  linkItem: {
    // opacity: 1
  },
};

export default withStyles(styles)(Header);

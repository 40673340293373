import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import * as Yup from 'yup';
import moment from 'moment-timezone';

import * as AppActions from '~/stores/actions/app-action';
import { Profile, Account } from '~/types/account-types';
import { INetwork } from '~/types/network-types';
import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  whiteColor,
  denimColor,
  dimGrayColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  romanColor,
  dodgerBlueColor,
} from '~/styles/themes/common-styles/color';
// Formik
import { Formik, Field, Form, FieldProps } from 'formik';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { LOCALE_SUPPORTED } from '~/constants/locale';

// Component
import SettingDialog from './setting-dialog';
import CustomSelect from './custom-select';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import { MAX_PROFILE_NAME_LENGTH } from '~/constants/validation';
import { env } from '~/env';

const langCodeList = LOCALE_SUPPORTED.map((l) => l.lang);
const timeZoneList = moment.tz.names();

interface IStateProps {
  open: boolean;
  onClose: () => void;
  profile: Profile;
  networks: INetwork[];
  accountList: Account[];
}

interface IDispProps {
  updateProfile: (
    args: AppActions.MutationUpdateProfileArgs,
  ) => Promise<AppActions.UPDATE_PROFILE_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {}

class EditProfileDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, profile, open, onClose } = this.props;

    const mode = profile.provider || '';

    return (
      <SettingDialog
        open={open}
        onClose={onClose}
        title={this.props.t('profile_settings')}
        leftBtnLabel={this.props.t('cancel')}
        righBtnLabel={this.props.t('update')}
        onClickLeftBtn={onClose}
        hideSubmitBtn
      >
        <div>{this.formUpdateProfile}</div>
        <div className={classes.separateArea}>
          <div className={classes.separateLine}></div>
        </div>

        <div>{this.dispBcAccount}</div>
        <div className={classes.separateArea}>
          <div className={classes.separateLine}></div>
        </div>

        <div>{this.dispNetwork}</div>
        <div className={classes.separateArea}>
          <div className={classes.separateLine}></div>
        </div>

        <div>{mode === 'google.com' ? this.dispGooglePassword : this.dispPassword}</div>
        <div className={classes.separateArea}>
          <div className={classes.separateLine}></div>
        </div>

        {this.disp2FA}
      </SettingDialog>
    );
  }

  get formUpdateProfile() {
    const { classes, onClose, profile, t } = this.props;
    const validateSchema = Yup.object().shape<EditProfileFormValues>({
      name: Yup.string()
        .trim()
        .required(t('required_field'))
        .max(
          MAX_PROFILE_NAME_LENGTH,
          t('too_many_character_of_profile_name_error_message', { value: MAX_PROFILE_NAME_LENGTH }),
        ),
      lang: Yup.string()
        .trim()
        .required(t('required_field'))
        .oneOf(langCodeList, t('invalid_language_type')),
      zone: Yup.string()
        .trim()
        .required(t('required_field'))
        .oneOf(timeZoneList, t('invalid_timezone_type')),
    });

    return (
      <Formik<EditProfileFormValues>
        initialValues={{
          name: profile.displayName,
          lang: profile.preference.language,
          zone: profile.preference.timezone,
        }}
        validationSchema={validateSchema}
        enableReinitialize={true}
        onSubmit={this.onUpdateProfileSubmit}
        render={({ isValid, isSubmitting }) => (
          <Form>
            <div>
              <div className={classes.formSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className={classes.formSection}>
                      <img
                        id="member-profile-setting-avatar"
                        src={this.props.profile.photoURL}
                        className={classes.avatarIcon}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <div
                      className={classes.formSection}
                      data-testid="edit-profile-dialog-name-field"
                    >
                      <Field name="name" render={this.displayNameField} />
                    </div>
                    <div
                      className={classes.formSection}
                      data-testid="edit-profile-dialog-language-field"
                    >
                      <Field name="lang" render={this.languageField} />
                    </div>
                    <div
                      className={classes.formSection}
                      data-testid="edit-profile-dialog-timezone-field"
                    >
                      <Field name="zone" render={this.timezoneField} />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.btnArea}>
                <Button
                  data-testid="cancel-button"
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {this.props.t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  isValid={isValid}
                  label={this.props.t('update')}
                  submittingLabel={this.props.t('updating')}
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      />
    );
  }

  private displayNameField = ({ field, form }: FieldProps<EditProfileFormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('display_name')}</div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.name}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="display-name-input"
            id="member-profile-setting-input-name"
            {...field}
            placeholder={this.props.t('input_display_name')}
          />
        </div>
      </>
    );
  };

  private languageField = ({ field, form }: FieldProps<EditProfileFormValues>) => {
    const { classes } = this.props;
    const langSelection = LOCALE_SUPPORTED.map((l) => ({ value: l.lang, label: l.name }));

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('language')}</div>
          {!!form.errors.lang && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.lang}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="language-select"
            id="member-profile-setting-input-language"
            {...field}
            valueSelected={field.value}
            placeholder={this.props.t('select_language')}
            items={langSelection}
          />
        </div>
      </>
    );
  };

  private timezoneField = ({ field, form }: FieldProps<EditProfileFormValues>) => {
    const { classes } = this.props;
    const zoneSelection = timeZoneList
      .filter((z) => z.substr(0, 4) !== 'Etc/')
      .map((z) => ({ value: z, label: z }));

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('timezone')}</div>
          {!!form.errors.zone && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.zone}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="timezone-select"
            id="member-profile-setting-input-timezone"
            {...field}
            valueSelected={field.value}
            placeholder={this.props.t('select_timezone')}
            items={zoneSelection}
          />
        </div>
      </>
    );
  };

  private onUpdateProfileSubmit = async (values: EditProfileFormValues, formikActions) => {
    const { onClose, updateProfile } = this.props;
    const { setSubmitting } = formikActions;
    try {
      await updateProfile({
        input: {
          displayName: values.name.trim(),
          preference: {
            language: values.lang.trim(),
            timezone: values.zone.trim(),
          },
        },
      });
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  get dispBcAccount() {
    const { classes, accountList } = this.props;

    return (
      <>
        <div className={classes.formLabelLine} id="member-profile-setting-info">
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.subTitle}>{this.props.t('organization')}</div>
              <div className={classes.subDesc}>{this.props.t('organizations_you_belong_to')}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {accountList.map((account, j) => (
                <div className={classes.detailValue} key={j}>
                  {account.name}
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  get dispNetwork() {
    const { classes, networks } = this.props;
    const mynet = networks || [];

    return (
      <>
        <div className={classes.formLabelLine}>
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.subTitle}>{this.props.t('network')}</div>
              <div className={classes.subDesc}>{this.props.t('networks_you_have_control')}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {mynet.map((network, j) => (
                <div className={classes.detailValue} key={j}>
                  {network.networkName}
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  get dispSecurity() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.subTitle}>{this.props.t('security')}</div>
              <div className={classes.subDesc}>
                {this.props.t('log_out_of_all_sessions_except_this_current_browser')}
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <div className={classes.subTitle}>&nbsp;</div>
              <div className={classes.actionLink} onClick={this.onClickLogoutOtherSessions}>
                {this.props.t('log_out_other_sessions')}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  get disp2FA() {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.subTitle}>{t('two_factor_auth')}</div>
        </div>
        <div className={classes.formLabelLine}>
          <div
            className={classes.sub2Fa}
            dangerouslySetInnerHTML={{
              __html: t('two_factor_auth_guide', {
                value: `<a href=${env.REACT_APP_ACCOUNT_PORTAL_URL} target="_blank">G.U. Account</a>`,
              }),
            }}
          ></div>
        </div>
      </>
    );
  }

  private onClickLogoutOtherSessions = async () => {
    const {} = this.props;
    console.log('Triggered onClickLogoutOtherSessions');

    // TODO implement Logout Other Session action
  };

  get dispGooglePassword() {
    const { classes, profile } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.subTitle}>{this.props.t('password')}</div>
        </div>
        <div className={classes.formLabelLine}>
          <div className={classes.subDesc}>
            {this.props.t('you_are_currently_logged_in_with_your_Google_account')}{' '}
            <span className={classes.noticeArea}>{profile.email}</span>.
          </div>
        </div>
      </>
    );
  }

  get dispPassword() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={7}>
              <div className={classes.subTitle}>{this.props.t('password')}</div>
              <div className={classes.subDesc}>{this.props.t('change_account_password')}</div>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <div className={classes.subTitle}>&nbsp;</div>
              <div className={classes.actionLink} onClick={this.onClickChangePassword}>
                {this.props.t('change_password')}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  private onClickChangePassword = async () => {
    const {} = this.props;
    console.log('Triggered onClickChangePassword');

    // TODO implement Change Password action
  };
}

const styles = createStyles({
  root: {},
  paper: {
    width: '90%',
    maxWidth: 700,
  },

  // disp part
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
  },
  subDesc: {
    ...defaultFont,
    fontSize: 13,
    color: lightSlateGreyColor,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 13,
    fontWeight: 500,
    marginTop: 1,
    marginBottom: 1,
    wordBreak: 'break-all',
  },
  actionLink: {
    textAlign: 'right',
    ...defaultFont,
    fontSize: 15,
    fontWeight: 500,
    color: denimColor,
    cursor: 'pointer',
    '&:hover': {
      color: dodgerBlueColor,
    },
  },
  planeLink: {
    color: denimColor,
    '&:hover': {
      color: dodgerBlueColor,
    },
  },
  noticeArea: {
    fontWeight: 500,
  },

  // form part
  formControlLabel: {
    margin: 0,
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },

  // misc
  avatarIcon: {
    width: 120,
    height: 120,
    borderRadius: 999,
  },
  separateLine: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  separateArea: {
    paddingTop: 30,
    paddingBottom: 30,
  },

  // submit button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rightBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    paddingRight: 50,
    paddingLeft: 50,
    height: 36,
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
    textTransform: 'none',
  },
  sub2Fa: {
    ...defaultFont,
    fontSize: 13,
    color: lightSlateGreyColor,
    '& a': {
      color: '#1D79C4',
      textDecoration: 'underline',
    },
  },
});

export default withStyles(styles)(withTranslation()(EditProfileDialog));

type EditProfileFormValues = {
  name: string;
  lang: string;
  zone: string;
};

import * as Gtypes from '~/gapi/gtypes';

const geolocationQuery = `{
  countryCode
  regionCode
  lat
  lon
  timezone
}`;

export const getGeolocationQuery = `query {
  getGeolocation ${geolocationQuery}
}`;

export type GET_GEOLOCATION_RESULT_TYPE = {
  getGeolocation: Gtypes.Geolocation;
};

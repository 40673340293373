export const whiteColor = '#FFFFFF';
export const orientColor = '#1B4E6E';
export const nightRiderColor = '#333333';
export const nepalColor = '#99AAB9';
export const dodgerBlueColor = '#2086D9';
export const dimGrayColor = '#707070';
export const whiteSmokeColor = '#F5F5F5';
export const sunColor = '#F78A3C';
export const denimColor = '#1D79C4';
export const pelorousColor = '#2BA4C1';
export const slateBlueColor = '#616AD5';
export const persianGreenColor = '#11A796';
export const romanColor = '#E35A5A';
export const veryLightGreyColor = '#CCCCCC';
export const pinkSwanColor = '#B4B4B4';
export const whisperColor = '#EDEDED';
export const greyChateauColor = '#959EA7';
export const pattensBlueColor = '#D8DFE5';
export const lightSlateGreyColor = '#7B90A3';
export const snowColor = '#FAFAFA';
export const summerSkyColor = '#40C2E6';
export const creamCanColor = '#F0C150';
export const gunPowderColor = '#4D4D62';
export const matterhornColor = '#555555';
export const brightGreyColor = '#4D4F5C';
export const hawkesBlueColor = '#D7DAE2';
export const summerSkyColor2 = '#2EC3E2';
export const pelorousColor2 = '#239BA7';
export const sunglow = '#FFCF2F';
export const silverColor = '#BABABA';
export const solitudeColor = '#E8E9EC';
export const gainsboroColor = '#DBDBDB';
export const gainsboro2Color = '#E0E0E0';
export const gunPowder2Color = '#43425d';
export const ghostWhiteColor = '#F1F1F3';
export const dodgerBlue2Color = '#3B86FF';
export const blackRussianColor = '#0000000A';
export const manateeColor = '#868693';
export const peranoColor = '#a3a1fb';
export const aliceBlueColor = '#eaf0f4';
export const aliceBlue2Color = '#fafbfc';
export const havelockBlue = '#458ADA';
export const dodgerBlue3Color = '#2196F3';
export const dodgerBlue4Color = '#1890ff';
export const astronautBlueColor = '#1B435D';
export const matisseColor = '#3E5B72';
export const midGreyColor = '#65686A';
export const darkCeruleanColor = '#154E86';
export const zirconColor = '#E9EDEE';
export const poloBlueColor = '#85ABCB';
export const curiousBlueColor = '#4183C5';
export const whisper2Color = '#EAEAEA';
export const whiteSmoke2Color = '#F8F8F8';
export const aliceBlue3Color = '#F4F7FA';
export const dimGray2Color = '#666666';
export const lavenderColor = '#E9E9F0';

import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { persianGreenColor } from '~/styles/themes/common-styles/color';

interface IProps {
  className?: string;
  onClick?: any;
}

const circleIcon: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
}) => {
  return <div className={classNames(classes.root, className)}></div>;
};

const styles: StyleRules = {
  root: {
    display: 'inline-block',
    borderRadius: '50%',
    width: 10,
    height: 10,
    backgroundColor: persianGreenColor,
  },
};

export default withStyles(styles)(circleIcon);

import React from 'react';
import { connect } from 'react-redux';
// Redux
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import ContentContainerView from '~/components/common/ContentContainer';
import OverviewTab from './overview-tab';
import NodesTab from './nodes-tab';
import ProposalsTab from './proposals';
import ConsortiumTab from './consortium-tab';
import DebugTab from './debug-tab';
import HardForkTab from './hard-fork-tab';
// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Style
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
// Type
import { INetwork } from '~/types/network-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

// defines
import { POLLING_NETWORK_INTERVAL, TAB_TITLE_CONCAT } from '~/constants/consts';

import { Account, NetworkRoleType, AccountRoleType } from '~/types/account-types';
import { isDevMode } from '~/utilities/utils';
import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';
import NodeStatusIcon from '~/components/common/node-status-icon';

interface IStateProps {
  accountSelected?: Account;
  networkSelected?: INetwork;
  networks?: INetwork[];
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  checkNetwork: (
    args: NetworkActions.QueryGetNetworkArgs,
  ) => Promise<NetworkActions.GET_NETWORK_RESULT_TYPE>;
  selectNetwork: (network: INetwork) => void;
  getNetwork: (
    args: NetworkActions.QueryGetNetworkArgs,
  ) => Promise<NetworkActions.GET_NETWORK_RESULT_TYPE>;
  updateSelectNetwork: (
    args: AppActions.MutationSelectNetworkArgs,
  ) => Promise<AppActions.UPDATE_SELECT_NETWORK_RESULT_TYPE>;
  sendInvitation: (
    args: NetworkActions.MutationSendInvitationArgs,
  ) => Promise<NetworkActions.SEND_INVITATION_RESULT_TYPE>;
}

interface IProps
  extends IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    RouteComponentProps<{ networkId: string; tab?: string }>,
    WithTranslation {}

interface IState {}

class NetworkDetail extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount(): void {
    this.findAndSelectNetwork();
  }

  componentDidUpdate(prevProps: IProps, prevState) {
    if (prevProps.match.params.networkId !== this.props.match.params.networkId) {
      this.findAndSelectNetwork();
    }
  }

  public render() {
    const {
      networkSelected,
      accountSelected,
      sendInvitation,
      t,
      selectedNetworkRole,
      selectedAccountRole,
      classes,
    } = this.props;
    const showProposalsTab =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'member' ||
      selectedNetworkRole === 'operator';
    const showDebugTab =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin';
    const showHardForkProposalsTab =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin';
    const showConsortiumTab = selectedAccountRole === 'owner';

    // Loading
    if (networkSelected === undefined || accountSelected === undefined) {
      return null;
    }

    const tabs = [
      {
        ['data-testid']: 'navigate-to-overview',
        title: t('overview'),
        tabName: 'overview',
        url: `/network/${networkSelected.networkUuid}/overview`,
        component: (
          <OverviewTab
            network={networkSelected}
            account={accountSelected}
            sendInvitation={sendInvitation}
          />
        ),
      },
      {
        ['data-testid']: 'navigate-to-nodes',
        title: t('nodes'),
        tabName: 'nodes',
        url: `/network/${networkSelected.networkUuid}/nodes`,
        component: <NodesTab network={networkSelected} account={accountSelected} />,
      },
    ];

    if (showProposalsTab) {
      tabs.push({
        ['data-testid']: 'navigate-to-proposals',
        title: t('proposals'),
        tabName: 'proposals',
        url: `/network/${networkSelected.networkUuid}/proposals`,
        component: <ProposalsTab network={networkSelected} account={accountSelected} />,
      });
    }

    if (showConsortiumTab) {
      tabs.push({
        ['data-testid']: 'navigate-to-consortium',
        title: t('consortium'),
        tabName: 'consortium',
        url: `/network/${networkSelected.networkUuid}/consortium`,
        component: <ConsortiumTab network={networkSelected} account={accountSelected} />,
      });
    }

    if (showDebugTab) {
      tabs.push({
        ['data-testid']: 'navigate-to-debug',
        title: t('debug'),
        tabName: 'debug',
        url: `/network/${networkSelected.networkUuid}/debug`,
        component: <DebugTab network={networkSelected} />,
      });
    }

    if (showHardForkProposalsTab) {
      tabs.push({
        ['data-testid']: 'navigate-to-hard-fork',
        title: t('hard_fork'),
        tabName: 'hardfork',
        url: `/network/${networkSelected.networkUuid}/hardfork`,
        component: <HardForkTab network={networkSelected} account={accountSelected} />,
      });
    }

    document.title = TAB_TITLE_CONCAT + this.props.t('blockchain_network_overview');

    return (
      <ContentContainerView
        title={
          <div id="network-name" className={classes.title}>
            <span className={classes.titleText}>{networkSelected.networkName}</span>
            {networkSelected.planningHardFork?.startAt && (
              <span>
                <span className={classes.nodeStatusText}>{t('scheduling_hard_fork')}</span>
                <NodeStatusIcon circleClassName={classes.nodeStatusIcon} status={'pending'} />
              </span>
            )}
          </div>
        }
        titleId="network-name"
        tabs={tabs}
        tabId="network-tab"
        routes={[
          {
            name: `Network: ${networkSelected.networkName}`,
            url: `/network/${networkSelected.networkUuid}/overview`,
          },
        ]}
      />
    );
  }

  private pollingNetworkTimer: number | null = null;

  private stopPollingNetworkStatus = () => {
    if (this.pollingNetworkTimer) {
      window.clearInterval(this.pollingNetworkTimer);
    }
    this.pollingNetworkTimer = null;
  };

  private findAndSelectNetwork = () => {
    const {
      match,
      networkSelected,
      networks,
      selectNetwork,
      history,
      accountSelected,
      getNetwork,
    } = this.props;
    const id = match.params.networkId.toString();
    const network = (networks || []).find((network) => network.networkUuid === id);
    if (!network) {
      history.push(
        `/network/${networkSelected ? networkSelected.networkUuid + '/overview' : 'create'}`,
      );
    } else if (!match.params.tab) {
      history.push(`/network/${id}/overview`);
    } else if (!networkSelected || networkSelected.networkUuid !== network.networkUuid) {
      selectNetwork(network);
      if (accountSelected) {
        getNetwork({
          accountUuid: accountSelected.accountUuid,
          networkUuid: network.networkUuid,
        });
        this.props.updateSelectNetwork({
          input: {
            preference: {
              lastSelectedAccountUuid: accountSelected.accountUuid,
              lastSelectedNetworkUuid: network.networkUuid,
            },
          },
        });
      }
    }
  };
}

const styles: StyleRules = {
  container,
  nodeStatusIcon: {
    transform: 'translateY(2px)',
    fontSize: '1rem',
    marginLeft: 5,
  },
  nodeStatusText: {
    marginLeft: 15,
    fontSize: 15,
    color: 'red',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  networkSelected: store.appState.networkSelected,
  networks: store.appState.networks,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  checkNetwork: (args: NetworkActions.QueryGetNetworkArgs) =>
    dispatch(NetworkActions.getNetwork(args)),
  selectNetwork: (network: INetwork) => dispatch(NetworkActions.selectNetwork(network)),
  getNetwork: (args: NetworkActions.QueryGetNetworkArgs) =>
    dispatch(NetworkActions.getNetwork(args)),
  updateSelectNetwork: (args: AppActions.MutationSelectNetworkArgs) =>
    dispatch(AppActions.selectNetwork(args)),
  sendInvitation: (args: NetworkActions.MutationSendInvitationArgs) =>
    dispatch(NetworkActions.sendInvitation(args)),
});

export default compose<React.ClassicComponentClass>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NetworkDetail);

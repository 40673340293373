import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { defaultFontMedium } from '~/styles/themes/common-styles/font';
import { nightRiderColor, pattensBlueColor } from '~/styles/themes/common-styles/color';

interface IProps extends TableCellProps {
  checkbox?: boolean;
}

const tableCellHead: React.StatelessComponent<IProps & WithStyles<typeof styles>> = (props) => {
  const { classes, children, checkbox, className, ...tableCellProps } = props;
  return (
    <TableCell {...tableCellProps} className={classNames(classes.root, className)}>
      <div className={checkbox ? classes.checkbox : classes.content}>{children}</div>
    </TableCell>
  );
};

tableCellHead.defaultProps = {
  checkbox: false,
};

const styles = createStyles({
  root: {
    ...defaultFontMedium,
    fontSize: 13,
    lineHeight: '15px',
    color: nightRiderColor,
    padding: 0,
  },
  content: {
    height: 30,
    paddingLeft: 10,
    paddingRight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderLeft: `1px solid ${pattensBlueColor}`,
  },
  checkbox: {
    paddingLeft: 10,
    paddingRight: 30,
  },
});

export default withStyles(styles)(tableCellHead);

import React, { useMemo } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
// Actions
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { IStore } from '~/stores/configure-store';
// Translation
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { INetwork } from '~/types/network-types';
import { Account } from '~/types/account-types';
// Components
import Table from '@mui/material/Table';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import HardForkDetailNodeRow from './node-row';
import HardForkDetailExplorerRow from './explorer-row';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const HardForkDetail = (props: IProps) => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { classes } = props;

  const network = useSelector((store: IStore) => store.appState.networkSelected);
  const hardForkProposals = useSelector((store: IStore) => store.appState.hardForkProposals);

  const proposal = useMemo(() => {
    return hardForkProposals?.proposals.find((val) => val.uuid === params.id);
  }, [hardForkProposals?.proposals, params.id]);

  const items = useMemo(() => {
    return (
      network?.clusters
        .map((cluster) => cluster.nodes.map((node) => ({ cluster, node })))
        .reduce((pre, cur) => pre.concat(cur), [])
        .filter((item) => proposal?.nodeUuids.includes(item.node.nodeUuid)) ?? []
    );
  }, [network, proposal]);

  const explorerItems = useMemo(() => {
    return (
      network?.clusters.filter(
        (cluster) =>
          cluster.explorer && proposal?.explorerUuids.includes(cluster.explorer?.explorerUuid),
      ) ?? []
    );
  }, [network, proposal]);

  return (
    <div className={classes.root}>
      <IconButton
        data-testid={`navigate-to-hardfork-${network?.networkUuid}`}
        className={classes.backBtn}
        onClick={() => {
          history.push(`/network/${network?.networkUuid}/hardfork`);
        }}
      >
        <KeyboardBackspaceIcon />
      </IconButton>
      <div className={classes.tableArea}>
        <Table id="nodes-list">
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <TableHeadCustom>
            <TableRowHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadContent}>
                <span>{t('name')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadContent}>
                <span>{t('node_type')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadContent}>
                <span>{t('cluster')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadContent}>
                <span>{t('hard_fork')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.tableCellHeadContent}>
                <span>{t('hard_fork_status')}</span>
              </TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBodyCustom>
            {network &&
              items.map((item) => (
                <HardForkDetailNodeRow
                  key={item.node.nodeUuid}
                  item={item}
                  network={network}
                  proposal={proposal}
                />
              ))}
            {network &&
              explorerItems.map((item) => (
                <HardForkDetailExplorerRow
                  key={item.clusterUuid}
                  cluster={item}
                  network={network}
                  proposal={proposal}
                />
              ))}
          </TableBodyCustom>
        </Table>
      </div>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    backBtn: {
      marginTop: 10,
    },
    tableArea: {
      marginTop: 10,
    },
    tableCellHeadContent: {
      paddingRight: 20,
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
  });

export type Item = {
  name: string;
  periodTargetBlock: number;
  uuid: string;
  accountUuid: string;
  createAt: string;
  blockNumber?: number;
  appliedNodes?: number;
  status: string;
  approvedNodeUuids: string[];
  rejectedNodeUuids: string[];
  signerNodeUuids: string[];
  appliedNodeUuids: string[];
  appliedExplorerUuids: string[];
  nodeUuids: string[];
  explorerUuids: string[];
};

export default withStyles(styles)(withTranslation()(HardForkDetail));

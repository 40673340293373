import React, { useCallback, useMemo, useState } from 'react';
import ms from 'ms';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import { useDispatch, useSelector } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import Button from '@mui/material/Button';
import NodeStatusIcon from '~/components/common/node-status-icon';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  lightSlateGreyColor,
  snowColor,
  pattensBlueColor,
  denimColor,
  persianGreenColor,
  matterhornColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { displayNodeStatus, sortNodesByName } from '~/utilities/render-utils';
import { HARD_FORK_CONFIGURATION } from '~/constants/consts';
import { Node } from '~/gapi/gtypes';
import { Account } from '~/types/account-types';
import { INetwork } from '~/types/network-types';

interface IProps extends WithStyles<typeof styles> {
  hardForkName: string;
  account?: Account;
  network?: INetwork;
}

const CompatibleNodeTable = (props: IProps) => {
  const { classes, network, account, hardForkName } = props;
  const { t } = useTranslation();

  const renderTable = useCallback(
    (nodes: Node[]) => {
      const hardFork = HARD_FORK_CONFIGURATION.find((val) => val.value === hardForkName);
      return (
        <>
          {nodes.map((node) => {
            const isIncompatibale = hardFork?.incompatibleGeths.includes(node.nodeInfo.version);
            let renderWarningText;
            if (isIncompatibale) {
              renderWarningText = t('incompatibility');
            } else if (node.nodeInfo.syncing) {
              renderWarningText = t('syncing');
            }
            return (
              <div id="information" className={classes.nodeItem}>
                <div className={classes.nodeName}>
                  <NodeStatusIcon
                    className={classes.nodeStatusIcon}
                    status={displayNodeStatus(node)}
                  />
                  <div className={classes.nodeNameText}>{node.nodeName}</div>
                  {node.nodeInfo.signer ? (
                    <div className={classNames(classes.nodeType, classes.nodeSignerType)}>
                      <span>{t('validator_node')}</span>
                    </div>
                  ) : (
                    <div className={classNames(classes.nodeType)}>
                      <span>{t('relay_node')}</span>
                    </div>
                  )}
                </div>
                <div className={classNames(classes.nodeIncompatible)}>
                  <div className={classes.nodeVersion}>{`v${node.nodeInfo.version}`}</div>
                  <div>{renderWarningText || ''}</div>
                </div>
              </div>
            );
          })}
        </>
      );
    },
    [classes, hardForkName, t],
  );

  return (
    <div className={classes.compatibleNodes}>
      <div className={classes.formLabelLine}>
        <div className={classes.formLabel}>{t('compatible_nodes')}</div>
      </div>
      {network?.clusters
        .filter((cluster) => cluster.nodes.some((node) => node.nodeInfo.signer))
        .map((cluster) => {
          return (
            <div>
              <div className={classes.content}>
                <div className={classes.clusterTitle}>
                  <span className={classes.clusterTitleText}>{cluster.clusterName}</span>
                  <Button className={classes.otherAccountBtn} variant="contained">
                    {cluster.accountUuid === account?.accountUuid
                      ? t('my_cluster')
                      : cluster.accountName}
                  </Button>
                </div>
                <div className={classes.nodesText}>{t('nodes')}</div>
                {renderTable(
                  cluster.nodes.filter((node) => node.nodeInfo.signer).sort(sortNodesByName),
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {},
    otherAccountBtn: {
      ...defaultFontMedium,
      wordBreak: 'break-all',
      fontSize: 12,
      color: lightSlateGreyColor,
      textTransform: 'none',
      height: 'inherit',
      padding: '4px 8px',
      backgroundColor: pattensBlueColor,
      '&:hover': {
        backgroundColor: pattensBlueColor,
        boxShadow: 'none',
      },
      boxShadow: 'none',
      marginLeft: 10,
    },
    compatibleNodes: {
      marginTop: 20,
    },
    clusterTitle: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '80%',
    },
    clusterTitleText: {
      ...defaultFontMedium,
      fontSize: 18,
      color: denimColor,
    },
    nodesText: {
      ...defaultFontRegular,
      marginTop: 10,
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    nodeItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 34,
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: whiteSmokeColor,
      border: `1px solid ${pattensBlueColor}`,
      borderRadius: 4,
      paddingLeft: 10,
      paddingRight: 10,
    },
    nodeStatusIcon: {
      marginRight: 10,
    },
    nodeName: {
      ...defaultFont,
      fontSize: 14,
      color: denimColor,
      width: '70%',
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
    },
    nodeNameText: {
      width: '50%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    nodeVersion: {
      color: 'black',
      paddingRight: 8,
      whiteSpace: 'nowrap',
      width: '50%',
    },
    nodeIncompatible: {
      ...defaultFont,
      fontSize: 13,
      color: romanColor,
      display: 'flex',
      justifyContent: 'space-between',
      width: '30%',
    },
    content: {
      marginTop: 10,
      padding: 10,
      paddingBottom: 0,
      backgroundColor: snowColor,
      borderRadius: 4,
      border: `1px solid ${pattensBlueColor}`,
      boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
      wordBreak: 'break-word',
    },
    formLabel: {
      ...defaultFontMedium,
      fontSize: 12,
      marginBottom: 5,
    },
    formSection: {},
    formLabelLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    formError: {
      color: romanColor,
    },
    nodeType: {
      ...defaultFontMedium,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',
      height: 24,
      borderRadius: 14,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 12,
      color: matterhornColor,
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    nodeSignerType: {
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
  });

export default withStyles(styles)(CompatibleNodeTable);

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Redux
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
// Component
import NodeStatusIcon from '~/components/common/node-status-icon';
import ContentContainerView from '~/components/common/ContentContainer';
import OverviewTab from './tabs/overview-tab';
import SecurityTab from './tabs/security-tab';
import MetricsTab from './tabs/metrics-tab';
import LogsTab from './tabs/logs-tab';
import ProposalTab from './tabs/proposal';

// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// defines
import { isAccountOwnCluster, isDevMode } from '~/utilities/utils';

// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Style
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
// Type
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { INetwork, INode, ICluster } from '~/types/network-types';
import { displayNodeStatus, displayNodeStatusDetail } from '~/utilities/render-utils';
// Hook
import { POLLING_NETWORK_INTERVAL } from '~/constants/consts';

interface IStateProps {
  account?: Account;
  network?: INetwork;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  checkNetwork: (
    args: NetworkActions.QueryGetNetworkArgs,
  ) => Promise<NetworkActions.GET_NETWORK_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{ networkId: string; clusterId: string; nodeId: string; tab?: string }>,
    WithStyles<typeof styles>,
    WithTranslation {}

interface IState {
  cluster?: ICluster;
  node?: INode;
}

class Node extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.findNode();
  }

  componentDidUpdate(prevProps: IProps, prevState) {
    if (prevProps.network !== this.props.network) {
      this.findNode();
    }
    if (prevProps.match.params.nodeId !== this.props.match.params.nodeId) {
      this.findNode();
    }
  }

  public render() {
    const { classes, account, network, openSnackBar, t, selectedAccountRole, selectedNetworkRole } =
      this.props;
    const { cluster, node } = this.state;

    const showSecurityTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');
    const showMetricsTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin' ||
        selectedNetworkRole === 'operator' ||
        selectedNetworkRole === 'member');
    const showLogsTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');
    const showClefTab =
      !!node?.signerInfo?.internalClef &&
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');

    const showProposalTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin' ||
        selectedNetworkRole === 'member' ||
        selectedNetworkRole === 'operator');

    // Loading
    if (
      account === undefined ||
      network === undefined ||
      cluster === undefined ||
      node === undefined
    ) {
      return null;
    }
    const detail = displayNodeStatusDetail(node, t);

    const tabs = [
      {
        title: t('overview'),
        tabName: 'overview',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/overview`,
        component: (
          <OverviewTab
            node={node}
            cluster={cluster}
            network={network}
            account={account}
            openSnackBar={openSnackBar}
          />
        ),
      },
    ];

    if (showSecurityTab) {
      tabs.push({
        title: t('security'),
        tabName: 'security',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/security`,
        component: <SecurityTab node={node} cluster={cluster} network={network} />,
      });
    }

    if (showMetricsTab) {
      tabs.push({
        title: t('metrics'),
        tabName: 'metrics',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/metrics`,
        component: <MetricsTab account={account} node={node} network={network} />,
      });
    }

    if (showProposalTab) {
      tabs.push({
        title: t('proposals'),
        tabName: 'proposals',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/proposals`,
        component: (
          <ProposalTab account={account} cluster={cluster} node={node} network={network} />
        ),
      });
    }

    if (showLogsTab) {
      tabs.push({
        title: t('logs'),
        tabName: 'logs',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/logs`,
        component: <LogsTab account={account} node={node} network={network} />,
      });
    }

    return (
      <ContentContainerView
        title={
          <div id="node-name" className={classes.title}>
            <NodeStatusIcon
              circleClassName={classes.nodeStatusIcon}
              status={displayNodeStatus(node)}
            />
            <span className={classes.titleText}>{node.nodeName}</span>
            {detail ? <span className={classes.nodeStatusText}>{detail}</span> : null}
          </div>
        }
        tabs={tabs}
        routes={[
          {
            name: `Network: ${network.networkName}`,
            url: `/network/${network.networkUuid}/overview`,
          },
          {
            name: `Cluster: ${cluster.clusterName}`,
            url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/overview`,
          },
          { name: `Node: ${node.nodeName}` },
        ]}
        tabId="node-tab"
      />
    );
  }

  private pollingNetworkTimer: number | null = null;

  private stopPollingNetworkStatus = () => {
    if (this.pollingNetworkTimer) {
      window.clearInterval(this.pollingNetworkTimer);
    }
    this.pollingNetworkTimer = null;
  };

  private findNode = () => {
    const { clusterId, nodeId, networkId, tab } = this.props.match.params;
    const { network, history } = this.props;

    if (network) {
      if (network.networkUuid !== networkId) {
        history.push(`/network`);
      }

      const updater = {};
      const cluster = network.clusters.find((c) => c.clusterUuid === clusterId.toString());
      const node = (cluster || { nodes: [] }).nodes.find((n) => n.nodeUuid === nodeId.toString());

      if (cluster) {
        const oldCluster = this.state.cluster;

        if (!oldCluster || oldCluster !== cluster) {
          updater['cluster'] = cluster;
        }
      }
      if (node) {
        if (!tab) {
          history.push(`/network/${networkId}/cluster/${clusterId}/node/${nodeId}/overview`);
        }

        const oldNode = this.state.node;

        if (!oldNode || oldNode !== node) {
          updater['node'] = node;
        }
      }

      if (Object.keys(updater).length > 0) {
        this.setState(updater);
      }
    }
  };
}

const styles: StyleRules = {
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '70%',
    whiteSpace: 'nowrap',
    marginLeft: 10,
  },
  nodeStatusIcon: {
    transform: 'translateY(2px)',
    fontSize: '1rem',
    marginLeft: 0,
  },
  nodeStatusText: {
    marginLeft: 15,
    fontSize: 15,
    color: 'red',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  network: store.appState.networkSelected,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  checkNetwork: (args: NetworkActions.QueryGetNetworkArgs) =>
    dispatch(NetworkActions.getNetwork(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

export default compose<React.ClassicComponentClass>(
  withStyles(styles),
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Node);

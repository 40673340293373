import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import * as Yup from 'yup';

// Component
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomInputNumCommaFormat from '~/components/common/custom-input-num-comma-format';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import SubmitButton from '~/components/common/submit-button';

import { INetwork } from '~/types/network-types';
import { IFilterProposalHistory } from '..';

import { Formik, Field, FieldProps, FormikActions, Form } from 'formik';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { ListProposalHistoryFilter } from '~/gapi/gtypes';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  filter?: ListProposalHistoryFilter;
  onClose: () => void;
  onFilterPage: (args: IFilterProposalHistory) => void;
}

type FormValues = {
  startBlock?: number;
  endBlock?: number;
  address?: string;
};

const FilterForm = (props: IProps) => {
  const { classes, network, filter, onClose, onFilterPage } = props;
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  const { t } = useTranslation();

  const initialValues: FormValues = {
    startBlock: filter?.startBlock || undefined,
    endBlock: filter?.endBlock || undefined,
    address: filter?.address || '',
  };

  const validateSchema = Yup.object().shape<FormValues>({
    startBlock: Yup.number()
      .notRequired()
      .min(0, t('validate_minimum', { val: 0 })),
    endBlock: Yup.number()
      .notRequired()
      .min(0, t('validate_minimum', { val: 0 }))
      .test(
        'check-with-block-range',
        t('filter_end_block_more_than_start_block'),
        function (val: number) {
          const startBlock = this.parent.startBlock;
          return startBlock && val ? val >= startBlock : true;
        },
      ),
    address: Yup.string()
      .notRequired()
      .test({
        message: t('error_alloc_address') as string,
        name: 'address',
        test: (val) => (val ? /^0x[a-fA-F0-9]{40}$/g.test(val) : true),
      }),
  });

  const handleArrowClick = useCallback(() => {
    setOpenAutocomplete(!openAutocomplete);
  }, [openAutocomplete]);

  const handleClickOutside = useCallback(() => {
    setOpenAutocomplete(false);
  }, []);

  const allNodes = useMemo(() => {
    return network.clusters
      .map((cluster) =>
        cluster.nodes.map((node) => ({ node, label: node.nodeInfo.coinbaseAddress })),
      )
      .reduce((pre, cur) => pre.concat(cur), []);
  }, [network]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => `${option.node.nodeName}${option.label}`,
  });

  const addressField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div className={classes.formLabelLine}>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={2}>
              <div className={classes.formLabel}>{t('address')}:</div>
            </Grid>
            <Grid item xs={10}>
              <ClickAwayListener onClickAway={handleClickOutside}>
                <Autocomplete
                  {...field}
                  data-testid="address-input"
                  open={openAutocomplete}
                  disableClearable={true}
                  freeSolo
                  fullWidth
                  inputValue={field.value}
                  onInputChange={(event, newInputValue) => {
                    form.setFieldValue('address', newInputValue);
                  }}
                  options={allNodes}
                  filterOptions={filterOptions}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.node.nodeName}
                    </Box>
                  )}
                  renderInput={(params) => {
                    let nodeName;
                    if (/^0x[a-fA-F0-9]{40}$/g.test(field.value)) {
                      const selected = allNodes.find((val) => val.label === field.value);
                      nodeName = selected?.node.nodeName ? (
                        <div className={classes.inputNodeName}>{selected?.node.nodeName}</div>
                      ) : (
                        <div style={{ color: 'gray' }}>External Node</div>
                      );
                    }
                    return (
                      <TextField
                        {...params}
                        {...field}
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Input target address"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: nodeName,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              {field.value ? (
                                <IconButton
                                  data-testid="clear-address-button"
                                  onClick={() => {
                                    form.setFieldValue('address', '');
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              ) : (
                                <IconButton onClick={handleArrowClick}>
                                  {params.inputProps?.['aria-expanded'] ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </IconButton>
                              )}
                            </>
                          ),
                        }}
                        onClick={handleArrowClick}
                      />
                    );
                  }}
                />
              </ClickAwayListener>
            </Grid>
            {!!form.errors.address && form.touched.address && (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} className={classes.targetDetail}>
                  <div className={classes.formError}>{form.errors.address}</div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      );
    },
    [allNodes, classes, openAutocomplete, t, handleArrowClick, filterOptions, handleClickOutside],
  );

  const startBlockField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={2}>
                <div className={classes.formLabel}>{t('from_block')}:</div>
              </Grid>
              <Grid item xs={10} style={{ position: 'relative' }}>
                <CustomInputNumCommaFormat
                  data-testid="from-block-input"
                  {...field}
                  placeholder={t('from_block')}
                  id="filter-start-block"
                  isAllowed={(value) => value && value >= 0}
                  onChange={(values) => {
                    form.setFieldValue(field.name, values.floatValue);
                  }}
                />
                {field.value && (
                  <IconButton
                    className={classes.formBlockBtn}
                    style={{ position: 'absolute' }}
                    onClick={() => {
                      form.setFieldValue('startBlock', '');
                    }}
                    data-testid="clear-start-block-button"
                  >
                    {' '}
                    <ClearIcon />
                  </IconButton>
                )}
              </Grid>
              {!!form.errors.startBlock && form.touched.startBlock && (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10} className={classes.targetDetail}>
                    <div className={classes.formError}>{form.errors.startBlock}</div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      );
    },
    [classes, t],
  );

  const endBlockField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={2}>
                <div className={classes.formLabel}>{t('to_block')}:</div>
              </Grid>
              <Grid item xs={10} style={{ position: 'relative' }}>
                <CustomInputNumCommaFormat
                  data-testid="end-block-input"
                  {...field}
                  id="filter-end-block"
                  placeholder={t('to_block')}
                  isAllowed={(value) => value && value >= 0}
                  onChange={(values) => {
                    form.setFieldValue(field.name, values.floatValue);
                  }}
                />
                {field.value && (
                  <IconButton
                    className={classes.toBlockBtn}
                    style={{ position: 'absolute' }}
                    onClick={() => {
                      form.setFieldValue('endBlock', '');
                    }}
                    data-testid="clear-end-block-button"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Grid>
              {!!form.errors.endBlock && form.touched.endBlock && (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10} className={classes.targetDetail}>
                    <div className={classes.formError}>{form.errors.endBlock}</div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      );
    },
    [classes, t],
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      setSubmitting(true);
      onFilterPage({
        startBlock: values.startBlock || undefined,
        endBlock: values.endBlock || undefined,
        address: values.address,
      });
      setSubmitting(false);
    },
    [onFilterPage],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateSchema}
      onSubmit={onSubmit}
      render={({ isSubmitting, isValid, dirty }) => (
        <Form className={classes.content}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Field name="startBlock" render={startBlockField} />
            </Grid>
            <Grid item md={12}>
              <Field name="endBlock" render={endBlockField} />
            </Grid>
            <Grid item md={12}>
              <Field name="address" render={addressField} />
            </Grid>
          </Grid>
          <div className={classes.actions}>
            <Button
              data-testid="cancel-button"
              id="filter-proposal-history-cancel"
              disabled={isSubmitting}
              className={classes.leftBtn}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {t('cancel')}
            </Button>
            <SubmitButton
              data-testid="update-button"
              classes={{ root: classes.submitBtn }}
              id="filter-proposal-history-submit"
              isValid={isValid && dirty}
              isSubmitting={isSubmitting}
              label={t('update')}
              submittingLabel={t('updating')}
            />
          </div>
        </Form>
      )}
    />
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 20,
    width: 500,
  },
  targetDetail: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  actions: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'end',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 13,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 40,
      fontSize: '13px',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      backgroundColor: whiteSmokeColor,
      color: '#4D4F5C',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiIconButton-root': {
        padding: 4,
      },
      '& .MuiOutlinedInput-input::placeholder': {
        color: lightSlateGreyColor,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  submitBtn: {
    width: 80,
  },
  formBlockBtn: {
    position: 'absolute',
    right: 9,
    padding: 4,
    bottom: 4,
  },
  toBlockBtn: {
    position: 'absolute',
    right: 9,
    padding: 4,
    bottom: 4,
  },
  nodeName: {
    fontWeight: 400,
    color: nightRiderColor,
    fontSize: 13,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  inputNodeName: {
    fontWeight: 450,
    color: nightRiderColor,
    fontSize: 13,
    maxWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(FilterForm);

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Formik, Field, Form, FieldProps, FormikActions, FormikErrors, FieldArray } from 'formik';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import * as Yup from 'yup';
import IPCIDR from 'ip-cidr';
import publicIp from 'public-ip';

import {
  defaultFontBold,
  defaultFont,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  denimColor,
  dimGrayColor,
  whiteSmokeColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
import {
  INode,
  ICluster,
  INetwork,
  IRestrictionList,
  IRestrictionViewRules,
  RestrictionProtocolType,
} from '~/types/network-types';
import { displayPortRange } from '~/utilities/render-utils';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInputControlled from './custom-input-controlled';
import CustomSelect from './custom-select';
import SubmitButton from './submit-button';
import LGButton from '~/components/common/lg-button';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from './table-head';
import TableBodyCustom from './table-body';
import TableCellHeadCustom from './table-cell-head';
import TableCellBodyCustom from './table-cell-body';
import TableRowHeadCustom from './table-row-head';
import TableRowBodyCustom from './table-row-body';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  protocolTypeSelection,
  protocolTypeSelectionEnterprise,
  switchCidrInputType,
} from '~/types/network-selection';
// Type
import { Account } from '~/types/account-types';
import {
  VALIDATE_SECURITY_DESC_PATTERN,
  VALIDATE_SECURITY_DESC_LENGTH,
} from '~/constants/validation';

interface IProps {
  open: boolean;
  onClose: () => void;
  network: INetwork;
  cluster: ICluster;
  node: INode;
  rules: IRestrictionViewRules[];
}

interface IDispProps {
  setRules: (
    args: NetworkActions.MutationSetNodeRestrictionArgs,
  ) => Promise<NetworkActions.SET_NODE_RESTRICTION_RESULT_TYPE>;
}

interface IStateProps {
  account?: Account;
}

interface IState {}

class EditNodeRulesDialog extends React.Component<
  IProps & IDispProps & IStateProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, open, onClose, rules, account, t } = this.props;
    const initialValues: FormValues = {
      rules: rules.length > 0 ? rules.map((r) => ({ ...r, cidrType: 'custom' })) : [],
    };

    const selector =
      account && account.plan === 'enterprise'
        ? protocolTypeSelectionEnterprise
        : protocolTypeSelection;
    const validateSchema = Yup.object().shape({
      rules: Yup.array().of(
        Yup.object().shape({
          protocol: Yup.string()
            .required(t('required_field'))
            .oneOf(
              selector.map((sel) => sel.value),
              t('invalid_protocol_type'),
            ),
          cidr: Yup.string()
            .required(t('required_field'))
            .test('cidr-v4-check', t('invalid_ipv4_cidr_format'), function (cidrV4) {
              const addr = new IPCIDR(cidrV4);
              return addr.isValid();
            }),
          desc: Yup.string()
            .max(
              VALIDATE_SECURITY_DESC_LENGTH,
              t('validate_maximum_length', { val: VALIDATE_SECURITY_DESC_LENGTH }),
            )
            .matches(
              VALIDATE_SECURITY_DESC_PATTERN,
              t('desc_of_secvurity_allows_alpha_numeric_symbol'),
            ),
        }),
      ),
    });

    return (
      <CustomDialog
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ values, errors, isValid, isSubmitting, handleReset, setValues }) => (
            <Form className={classes.memberNodeSecurityForm}>
              <CustomDialogTitle className={classes.customDialogTitle}>
                <div id="member-node-security-title">{t('edit_inbound_rules')}</div>
              </CustomDialogTitle>
              <CustomDialogContent
                classes={{ root: classNames(classes.customDialogContent, classes.editRules) }}
              >
                <Table className={classes.memberInboudFormTable}>
                  <colgroup>
                    <col width="140px" />
                    <col width="110px" />
                    <col width="110px" />
                    <col width="300px" />
                    <col width="auto" />
                  </colgroup>
                  <TableHeadCustom>
                    <TableRowHeadCustom>
                      <TableCellHeadCustom>
                        <span>{t('type')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{t('protocol')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{t('port_range')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom>
                        <span>{t('source')}</span>
                      </TableCellHeadCustom>
                      <TableCellHeadCustom className={classes.tableCellHeadDescription}>
                        <span>{t('description')}</span>
                      </TableCellHeadCustom>
                    </TableRowHeadCustom>
                  </TableHeadCustom>
                  <FieldArray
                    name="rules"
                    render={(arrayHelpers) => {
                      return (
                        <TableBodyCustom className={classes.addRules}>
                          {values.rules.length > 0 &&
                            values.rules.map((rule, i) => {
                              const error = errors.rules && errors.rules[i];
                              const { protocol, range } = displayPortRange(rule.protocol);
                              return (
                                <TableRowBodyCustom key={i}>
                                  <TableCellBodyCustom className={classes.tableProtocolColumn}>
                                    {this.renderErrorArea(error, 'protocol')}
                                    <Field
                                      name={`rules.${i}.protocol`}
                                      render={this.ruleProtocolTypeField}
                                    />
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom className={classes.protocolCol}>
                                    <div id="member-node-security-rule-protocol">{protocol}</div>
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom>
                                    <div id="member-node-security-rule-range">{range}</div>
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom className={classes.tableCidrColumn}>
                                    {this.renderErrorArea(error, 'cidr')}
                                    <div>
                                      <Grid container className={classes.sourceItem}>
                                        <Grid
                                          item
                                          md={6}
                                          className={classNames(
                                            classes.gridLeftItem,
                                            classes.gridRuleItem,
                                          )}
                                        >
                                          {this.renderSwitchCidrInput(arrayHelpers, values, i)}
                                        </Grid>
                                        <Grid
                                          item
                                          md={6}
                                          className={classNames(
                                            classes.gridRightItem,
                                            classes.gridRuleItem,
                                          )}
                                        >
                                          <Field
                                            name={`rules.${i}.cidr`}
                                            render={this.ruleCidrField}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </TableCellBodyCustom>
                                  <TableCellBodyCustom className={classes.disColumn}>
                                    {this.renderErrorArea(error, 'desc')}
                                    <div>
                                      <Grid container>
                                        <Grid
                                          item
                                          md={10}
                                          className={classNames(
                                            classes.gridLeftItem,
                                            classes.gridRuleDescriptionItem,
                                          )}
                                        >
                                          <Field
                                            name={`rules.${i}.desc`}
                                            render={this.ruleDescriptionField}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          md={2}
                                          className={classNames(
                                            classes.gridRightItem,
                                            classes.deleteDes,
                                          )}
                                        >
                                          <div>
                                            <IconButton
                                              id="member-node-security-rule-remove"
                                              className={classes.closeBtn}
                                              onClick={() => arrayHelpers.remove(i)}
                                            >
                                              <ImgIcon
                                                className={classes.closeIcon}
                                                imgUrl="/images/icons/alert_error_ico.png"
                                              />
                                            </IconButton>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </TableCellBodyCustom>
                                </TableRowBodyCustom>
                              );
                            })}
                        </TableBodyCustom>
                      );
                    }}
                  />
                </Table>
              </CustomDialogContent>
              <CustomDialogActions
                classes={{
                  root: classNames(classes.customDialogActions, classes.editRulesActions),
                }}
              >
                <LGButton
                  data-testid="add-rule-button"
                  classes={{ root: classes.addRuleBtn }}
                  onClick={() => this.onAddItem(setValues, values)}
                >
                  <div>
                    <ImgIcon className={classes.addRuleIcon} imgUrl={`/images/icons/add_ico.png`} />
                    <span className={classes.addRuleItem}>{t('add_rule')}</span>
                  </div>
                </LGButton>
                <div className={classes.rightBtns}>
                  <Button
                    data-testid="cancel-button"
                    className={classes.leftBtn}
                    disabled={isSubmitting}
                    onClick={() => this.onCancel(handleReset, onClose)}
                    variant="contained"
                  >
                    {t('cancel')}
                  </Button>
                  <SubmitButton
                    data-testid="update-button"
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    label={t('update')}
                    submittingLabel={t('updating')}
                  />
                </div>
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { setRules, onClose, node, cluster, network, account } = this.props;

    if (!account) {
      return;
    }

    try {
      console.log(values);
      const ruleMap: { [key: string]: IRestrictionList } = {};

      for (const rule of values.rules) {
        if (ruleMap[rule.protocol]) {
          ruleMap[rule.protocol].inbound.push({
            cidr: rule.cidr,
            desc: rule.desc || '',
          });
        } else {
          ruleMap[rule.protocol] = {
            protocol: rule.protocol,
            inbound: [
              {
                cidr: rule.cidr,
                desc: rule.desc || '',
              },
            ],
          };
        }
      }

      await setRules({
        input: {
          accountUuid: account.accountUuid,
          networkUuid: network.networkUuid,
          clusterUuid: cluster.clusterUuid,
          nodeUuid: node.nodeUuid,
          rules: Object.entries(ruleMap).map(([_, v]) => v),
        },
      });
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };

  private onCancel = (handleReset: () => void, onClose: () => void) => {
    handleReset();
    onClose();
  };

  private onAddItem = (setValues: (values: FormValues) => void, values: FormValues) => {
    values.rules.push({
      protocol: 'HTTP',
      cidrType: 'custom',
      cidr: '',
      desc: '',
    });
    setValues(values);
  };

  private ruleProtocolTypeField = ({ field, form }: FieldProps<FormValues>) => {
    const { account } = this.props;
    const selector =
      account && account.plan === 'enterprise'
        ? protocolTypeSelectionEnterprise
        : protocolTypeSelection;

    return (
      <div>
        <CustomSelect
          data-testid="protocol-select"
          {...field}
          id="member-node-security-rule-type"
          placeholder="Select protocol"
          valueSelected={field.value}
          items={selector}
        />
      </div>
    );
  };

  private ruleCidrField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomInputControlled
          {...field}
          data-testid="address-input"
          id="member-node-security-rule-address"
          placeholder=""
        />
      </div>
    );
  };

  private ruleDescriptionField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <div>
        <CustomInputControlled
          {...field}
          data-testid="description-input"
          id="member-node-security-rule-description"
          placeholder=""
        />
      </div>
    );
  };

  private renderErrorArea = (err: FormikErrors<FormValuesRule> | undefined, column: string) => {
    const { classes } = this.props;
    return (
      <div className={classes.formLabelLine}>
        {err && err[column] && (
          <div className={classNames(classes.formLabel, classes.formError)}>{err[column]}</div>
        )}
      </div>
    );
  };

  private renderSwitchCidrInput = (helper: any, values: FormValues, index: number) => {
    const { classes } = this.props;
    return (
      <div>
        <CustomSelect
          data-testid="cidr-type-select"
          id="member-node-security-rule-addr-type"
          placeholder="Select CIDR input type"
          items={switchCidrInputType}
          valueSelected={values.rules[index].cidrType || switchCidrInputType[0].value}
          onChange={async (e: any) => {
            if (values.rules[index]) {
              const newValue = await this.onSwitchCidrValue(e);
              const result = {
                ...values.rules[index],
                cidr: newValue || values.rules[index].cidr,
                cidrType: e.target.value,
              };
              helper.replace(index, result);
            }
          }}
        />
      </div>
    );
  };

  private onSwitchCidrValue = async (e: any) => {
    const { target } = e;
    let result = '';

    switch (target.value) {
      case 'myip':
        result = `${await publicIp.v4()}/32`;
        break;
      case 'anywhere':
        result = '0.0.0.0/0';
        break;
      case 'custom':
      default:
        break;
    }
    return result;
  };
}

const styles = (theme) =>
  createStyles({
    root: {},
    dialogPaper: {
      maxWidth: 1240,
    },
    memberNodeSecurityForm: {
      width: 'calc(100% - 10%)',
      margin: 'auto',
    },
    customDialogTitle: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    customDialogContent: {
      padding: 0,
      overflowX: 'auto',
    },
    customDialogActions: {
      paddingRight: 0,
      paddingLeft: 0,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    memberInboudFormTable: {
      tableLayout: 'fixed',
    },
    formSection: {
      marginTop: 15,
    },
    formLabelLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    formLabel: {
      ...defaultFontMedium,
      fontSize: 12,
      marginBottom: 5,
    },
    formError: {
      color: romanColor,
    },
    // submit button
    btnArea: {
      marginTop: 30,
      textAlign: 'right',
    },
    leftBtn: {
      ...defaultFont,
      color: dimGrayColor,
      fontSize: 14,
      height: 36,
      backgroundColor: whiteSmokeColor,
      '&:hover': {
        backgroundColor: whiteSmokeColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      textTransform: 'none',
      marginRight: 10,
    },
    rightBtn: {
      ...defaultFontBold,
      fontSize: 16,
      color: whiteColor,
      paddingRight: 50,
      paddingLeft: 50,
      height: 36,
      backgroundColor: denimColor,
      '&:hover': {
        backgroundColor: denimColor,
      },
      textTransform: 'none',
    },
    addRuleBtn: {
      width: 150,
      height: 34,
      position: 'absolute',
      left: 0,
    },
    addRuleIcon: {
      verticalAlign: 'middle',
    },
    addRuleItem: {
      ...defaultFontMedium,
      ...oneLineText,
      fontSize: 15,
      marginRight: 10,
      marginLeft: 8,
      verticalAlign: 'middle',
    },
    tableCellHeadDescription: {
      width: 300,
    },
    closeIcon: {
      width: 16,
      height: 16,
    },
    closeBtn: {},
    gridLeftItem: {
      paddingRight: 6,
    },
    gridRightItem: {
      paddingLeft: 6,
    },
    gridRuleItem: {
      flexGrow: 0,
      maxWidth: '50%',
      flexBasis: '50%',
    },
    gridRuleDescriptionItem: {
      width: '80%',
    },
    tableProtocolColumn: {
      minWidth: 70,
    },
    tableCidrColumn: {
      minWidth: 160,
    },
    editRules: {},
    editRulesActions: {},
    rightBtns: {},
    tableCellHead: {},
    addRules: {},
    disColumn: {},
    deleteDes: {},
    protocolCol: {},
    sourceItem: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      memberInboudFormTable: {
        tableLayout: 'auto',
      },
      editRules: {
        padding: '0 20px 30px',
        '& th div': {
          paddingRight: 0,
        },
      },
      editRulesActions: {
        padding: '0 20px 50px',
        justifyContent: 'space-between',
      },
      addRuleBtn: {
        position: 'static',
        width: 'auto',
        padding: '0 8px',
      },
      addRuleItem: {
        margin: '0 32px',
      },
      rightBtns: {
        margin: 0,
      },
      dialogPaper: {
        margin: 0,
        width: '96%',
      },
      tableCellHead: {
        paddingRight: 0,
      },
      tableProtocolColumn: {
        minWidth: 0,
        '& div:nth-child': {
          minWidth: 78,
        },
        '& customSelect': {
          width: ' 90px !important',
          height: '34 !important',
        },
      },
      addRules: {
        '& tr td': {
          fontSize: 15,
          padding: '7px 0 0',
          '& div': {
            fontSize: 15,
          },
        },
      },
      sourceItem: {
        display: 'flex',
        flexDirection: 'column',
      },
      gridLeftItem: {
        padding: 0,
        width: 120,
        marginBottom: 4,
      },
      gridRightItem: {
        paddingLeft: 0,
        width: 120,
        marginBottom: 8,
      },
      formError: {
        fontSize: '12px !important',
      },
      tableCidrColumn: {
        minWidth: 0,
        '& input': {
          width: 120,
          height: 34,
          padding: '0 10px',
        },
      },
      protocolCol: {
        padding: '7px 10px 0 !important',
      },
      disColumn: {
        width: 290,
        padding: '0 !important',
        '& input': {
          width: 160,
          height: 34,
          padding: '0 12px',
        },
        '& div': {
          flexWrap: 'nowrap',
        },
      },
      deleteDes: {
        width: 0,
        margin: '5px auto',
        paddingLeft: 17,
        '& button': {
          padding: 0,
        },
      },
      closeIcon: {
        width: 24,
        height: 24,
      },
    },
  });

const mapStateToProps = (store: IStore, ownProps) => ({
  account: store.appState.accountSeleted,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  setRules: (args: NetworkActions.MutationSetNodeRestrictionArgs) =>
    dispatch(NetworkActions.setNodeRestriction(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditNodeRulesDialog)),
);

type FormValuesRule = {
  protocol: RestrictionProtocolType;
  cidrType: string;
  cidr: string;
  desc: string;
};

type FormValues = {
  rules: FormValuesRule[];
};

import React from 'react';
import classNames from 'classnames';
import chartjs from 'chart.js';
import { Line, ChartData } from 'react-chartjs-2';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { nightRiderColor, pattensBlueColor, denimColor } from '~/styles/themes/common-styles/color';

export type IData = {
  key: string;
  value: number;
}[];

interface IProps {
  title: string;
  className?: string;
  data: IData;
  lineColor?: string;
  lineWidth?: number;
  axesColor?: string;
  tickColor?: string;
  yAxesStepSize?: number;
  yAxesTickmin?: number;
  yAxesTickmax?: number;
  xAxesTickLimit?: number;
  tickFontSize?: number;
  tickPadding?: number;
}

const lineChart: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  title,
  lineColor,
  lineWidth,
  axesColor,
  tickColor,
  yAxesStepSize,
  yAxesTickmin,
  yAxesTickmax,
  xAxesTickLimit,
  tickFontSize,
  tickPadding,
  data,
}) => {
  const labels: string[] = [];
  const values: number[] = [];
  data.forEach((item, index) => {
    labels.push(item.key);
    values.push(item.value);
  });

  const chartData: ChartData<chartjs.ChartData> = {
    labels: labels,
    datasets: [
      {
        borderWidth: lineWidth,
        fill: false,
        lineTension: 0.1,
        borderColor: lineColor, // line color
        borderCapStyle: 'butt', // straight line
        borderDash: [], // Length and spacing of dashes
        borderDashOffset: 0.0, // Offset for line dashes
        borderJoinStyle: 'miter',
        pointBorderColor: lineColor,
        pointBackgroundColor: lineColor,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: lineColor,
        pointHoverBorderColor: lineColor,
        pointHoverBorderWidth: 1,
        pointRadius: 0,
        data: values,
      },
    ],
  };

  const option: chartjs.ChartOptions = {
    // responsive: true,
    // aspectRatio: 1.5,
    // maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    scales: {
      display: false,
      xAxes: [
        {
          gridLines: {
            color: axesColor,
            tickMarkLength: 0,
          },
          ticks: {
            fontColor: tickColor,
            fontSize: tickFontSize,
            padding: tickPadding,
            autoSkip: true,
            maxTicksLimit: xAxesTickLimit,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: axesColor,
            tickMarkLength: 0,
          },
          ticks: {
            fontColor: tickColor,
            fontSize: tickFontSize,
            stepSize: yAxesStepSize,
            padding: tickPadding,
            min: yAxesTickmin,
            max: yAxesTickmax,
          },
        },
      ],
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <Line data={chartData} height={200} options={option} />
    </div>
  );
};

lineChart.defaultProps = {
  lineColor: denimColor,
  lineWidth: 2,
  axesColor: pattensBlueColor,
  tickColor: nightRiderColor,
  tickFontSize: 10,
  tickPadding: 5,
  yAxesStepSize: 5,
  xAxesTickLimit: 6,
};

const styles: StyleRules = {
  root: {
    padding: 20,
    position: 'relative',
  },
  title: {
    ...defaultFontBold,
    fontSize: 14,
    textAlign: 'center',
    color: nightRiderColor,
    marginBottom: 10,
  },
};

export default withStyles(styles)(lineChart);

import * as Gtypes from '~/gapi/gtypes';

const alertQuery = `{
  alertUuid
  accountUuid
  networkUuid
  type
  title {
    en
    ja
  }
  description {
    en
    ja
  }
  linkUrl
  createAt
  updateAt
  read
}`;

export const listAlertsQuery = `query ($accountUuid: String!, $order: InputAlertOrder, $filter: InputAlertFilter, $page: InputPageInfo) {
  listAlerts(accountUuid: $accountUuid, order: $order, filter: $filter, page: $page) {
    alerts ${alertQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      unread
      types
      lastShown
      startDate
      endDate
      searchText
    }
  }
}`;

export type QueryListAlertsArgs = Gtypes.QueryListAlertsArgs;

export type LIST_ALERTS_RESULT_TYPE = {
  listAlerts: Gtypes.ListAlerts;
};

export const setAlertReadMutation = `mutation ($input: SetAlertReadInput!) {
  setAlertRead(input: $input) ${alertQuery}
}`;

export type MutationSetAlertReadArgs = Gtypes.MutationSetAlertReadArgs;

export type SET_ALERT_READ_RESULT_TYPE = {
  setAlertRead: Gtypes.Alert;
};

export type SET_ALL_ALERTS_READ_RESULT_TYPE = {
  setAllAlertsRead: Boolean;
};

export const setAllAlertsReadMutation = `mutation ($input: SetAllAlertsReadInput!) {
  setAllAlertsRead(input: $input)
}`;

export type MutationSetAllAlertsReadArgs = Gtypes.MutationSetAllAlertsReadArgs;

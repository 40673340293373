import { createTypeAsyncAction, createTypeAction } from '@gu-corp/redux-async-lib';
import { IStore } from '~/stores/configure-store';
import { ISnackBarType } from '~/types/app-types';
import { AccountRoleType } from '~/types/account-types';
import MemberGApiClient from './gapi/member-gapi-client';
import * as account from './gapi/query/account';
export * from './gapi/query/account';
import * as QueryGeolocation from './gapi/query/geolocation';
export * from './gapi/query/geolocation';
export type UpdateAccountSelectedArgs = { accountUuid: string };
export type OpenSnackBarArgs = {
  type: ISnackBarType;
  message: string;
};

export const openSnackBar = createTypeAction('OPEN_SNACK_BAR', (args: OpenSnackBarArgs) => args);

export const closeSnackBar = createTypeAction('CLOSE_SNACK_BAR', (_) => _);

export const getProfile = createTypeAsyncAction('GET_PROFILE', async () => {
  const client = new MemberGApiClient({});
  return client.getProfile();
});

export const selectNetwork = createTypeAsyncAction(
  'UPDATE_SELECT_NETWORK',
  async (args: account.MutationSelectNetworkArgs) => {
    const client = new MemberGApiClient({});
    return client.selectNetwork(args);
  },
);

export const updateProfile = createTypeAsyncAction(
  'UPDATE_ACCOUNT_PROFILE',
  async (args: account.MutationUpdateProfileArgs) => {
    const client = new MemberGApiClient({});
    return client.updateProfile(args);
  },
);

export const getMember = createTypeAsyncAction(
  'GET_MEMBER',
  async (args: account.QueryGetMemberArgs) => {
    const client = new MemberGApiClient({});
    return client.getMember(args);
  },
);

export const listMembers = createTypeAsyncAction(
  'LIST_MEMBERS',
  async (args: account.QueryToListMembersArgs) => {
    const client = new MemberGApiClient({});
    return client.listMembers(args);
  },
);

export const addMember = createTypeAsyncAction(
  'ADD_MEMBER',
  async (args: account.MutationAddMemberArgs) => {
    const client = new MemberGApiClient({});
    return client.addMember(args);
  },
);

export const updateMember = createTypeAsyncAction(
  'EDIT_MEMBER',
  async (args: account.MutationUpdateMemberArgs) => {
    const client = new MemberGApiClient({});
    return client.updateMember(args);
  },
);

export const removeMember = createTypeAsyncAction(
  'REMOVE_MEMBER',
  async (args: account.MutationRemoveMemberArgs) => {
    const client = new MemberGApiClient({});
    return client.removeMember(args);
  },
);

export const changePrimaryOwner = createTypeAsyncAction(
  'CHANGE_PRIMARY_OWNER',
  async (args: account.MutationChangePrimaryOwnerArgs) => {
    const client = new MemberGApiClient({});
    return client.changePrimaryOwner(args);
  },
);

export const updateAccountSelected = createTypeAsyncAction(
  'UPDATE_ACCOUNT_SELECTED',
  async (args: UpdateAccountSelectedArgs) => {
    const client = new MemberGApiClient({});
    return client.getAccount(args);
  },
);

export const getAccount = createTypeAsyncAction(
  'GET_ACCOUNT',
  async (args: account.QueryGetAccountArgs) => {
    const client = new MemberGApiClient({});
    return client.getAccount(args);
  },
);

export const updateAccount = createTypeAsyncAction(
  'UPDATE_ACCOUNT',
  async (args: account.MutationUpdateAccountArgs) => {
    const client = new MemberGApiClient({});
    return client.updateAccount(args);
  },
);

export const createAccount = createTypeAsyncAction(
  'CREATE_ACCOUNT',
  async (args: account.MutationCreateAccountArgs) => {
    const client = new MemberGApiClient({});
    return client.createAccount(args);
  },
);

export const removeAccount = createTypeAsyncAction(
  'REMOVE_ACCOUNT',
  async (args: account.MutationRemoveAccountArgs) => {
    const client = new MemberGApiClient({});
    return client.removeAccount(args);
  },
);

export const registerAddress = createTypeAsyncAction(
  'REGISTER_ADDRESS',
  async (args: account.MutationRegisterAddressArgs) => {
    const client = new MemberGApiClient({});
    return client.registerAddress(args);
  },
);

export const updateContact = createTypeAsyncAction(
  'UPDATE_CONTACT',
  async (args: account.MutationUpdateContactArgs) => {
    const client = new MemberGApiClient({});
    return client.updateContact(args);
  },
);

export const getGeolocation = createTypeAsyncAction('GET_GEOLOCATION', async () => {
  const client = new MemberGApiClient({});
  return client.getGeolocation();
});

export const acceptTermsOfService = createTypeAsyncAction(
  'ACCEPT_TERMS_OF_SERVICE',
  async (args: account.MutationAcceptTermsOfServiceArgs) => {
    const client = new MemberGApiClient({});
    return client.acceptTermsOfService(args);
  },
);

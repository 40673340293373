import React from 'react';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
// Redux
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// component
import Header from './header';
import SideBar from './side-bar';
import Footer from './footer';
import CustomSnackBar from '~/components/common/custom-snackbar';
import { checkBrowserSupport } from '~/utilities/app-utils';
import { ISnackBarState } from '~/types/app-types';
// style
import { container } from '~/styles/themes/common-styles/grid-member';

interface StateProps {
  snackBarState?: ISnackBarState;
}

interface DispProps {
  closeSnackBar: () => void;
}

interface Props extends StateProps, DispProps, WithStyles<typeof styles>, WithTranslation {}

interface State {}

class AppLayout extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.checkBrowserSupport();
  }

  private checkBrowserSupport = () => {
    if (!checkBrowserSupport(navigator.userAgent)) {
      window.alert(this.props.t('not_supported_browser_alert'));
    }
  };

  render() {
    const { classes, children, snackBarState, t, closeSnackBar } = this.props;

    return (
      <>
        <Header />
        <div className={classes.content}>
          <div className={classes.sideBar}>
            <SideBar />
          </div>
          <div className={classes.mainContent}>{children}</div>
          {snackBarState && (
            <CustomSnackBar snackBarState={snackBarState} onClose={closeSnackBar} />
          )}
        </div>
        <Footer />
      </>
    );
  }
}

const styles = createStyles({
  container,
  content: {
    ...container,
    paddingTop: 20,
    paddingBottom: 50,
    display: 'flex',
  },
  sideBar: {
    width: 180,
  },
  mainContent: {
    flex: 1,
    minWidth: 0,
    paddingLeft: 30,
  },
});

const mapStateToProps = (store: IStore): StateProps => ({
  snackBarState: store.appState.snackBarState,
});

const mapDispatchToProps = (dispatch): DispProps => ({
  closeSnackBar: () => dispatch(AppActions.closeSnackBar()),
});

export default withStyles(styles)(
  withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AppLayout)),
);

import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import { IStore } from '~/stores/configure-store';
import MemberGApiClient from './gapi/member-gapi-client';
import * as alert from './gapi/query/alert';
export * from './gapi/query/alert';

export const listAlerts = createTypeAsyncAction(
  'LIST_ALERTS',
  async (args: alert.QueryListAlertsArgs, store: IStore) => {
    const { alertList } = store.appState;
    const client = new MemberGApiClient({});
    return client.listAlerts({
      ...args,
      page: {
        pageIndex: alertList.pageInfo.pageIndex,
        pageSize: alertList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...alertList.filter,
        ...args.filter,
      },
    });
  },
);

export const listUnreadAlerts = createTypeAsyncAction(
  'LIST_UNREAD_ALERTS',
  async (args: alert.QueryListAlertsArgs) => {
    const client = new MemberGApiClient({});
    return client.listAlerts({
      ...args,
      filter: {
        ...args.filter,
        unread: true,
      },
    });
  },
);

export const setAlertRead = createTypeAsyncAction(
  'SET_ALERT_READ',
  async (args: alert.MutationSetAlertReadArgs) => {
    const client = new MemberGApiClient({});
    return client.setAlertRead(args);
  },
);

export const setAllAlertsRead = createTypeAsyncAction(
  'SET_ALL_ALERTS_READ',
  async (args: alert.MutationSetAllAlertsReadArgs) => {
    const client = new MemberGApiClient({});
    return client.setAllAlertsRead(args);
  },
);

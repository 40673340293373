import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  denimColor,
  lightSlateGreyColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  value: number;
  bgColor?: string;
  label: {
    text?: string;
    subLabel?: string;
    subLabel2?: string;
  };
}

class CircleProgress extends React.Component<IProps> {
  private pathRef: React.RefObject<any>;

  constructor(props) {
    super(props);

    this.pathRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps, prevState) {
    if (this.props.value !== prevProps.value) {
      const path = this.pathRef.current;
      if (path) {
        const length = path.getTotalLength();
        const value = parseInt(path.parentNode.getAttribute('data-value'));
        const to = length * ((100 - value) / 100);
        path.getBoundingClientRect();
        path.style.strokeDashoffset = `${Math.max(0, to)}`;
      }
    }
  }

  render() {
    const { classes, value, bgColor, label } = this.props;
    return (
      <div className={classes.root}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="160"
          width="160"
          viewBox="0 0 200 200"
          data-value={value}
        >
          <path
            className={classes.path}
            stroke="#ededed"
            d="M41 149.5a77 77 0 1 1 117.93 0"
            fill="none"
          />
          <path
            ref={this.pathRef}
            className={classes.path}
            stroke={bgColor ? bgColor : denimColor}
            d="M41 149.5a77 77 0 1 1 117.93 0"
            fill="none"
            strokeDasharray="350"
            strokeDashoffset="350"
          />
        </svg>

        <div className={classes.textArea}>
          <div className={classes.textValue}>{label.text}</div>
          <div className={classNames(classes.subLabel, !label.subLabel && classes.hidden)}>
            {label.subLabel || '-'}
          </div>
          <div className={classes.subLabel}>{label.subLabel2}</div>
        </div>
      </div>
    );
  }
}

const styles = (theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
      textAlign: 'center',
    },
    textArea: {
      position: 'absolute',
      top: 45,
      left: 0,
      right: 0,
    },
    path: {
      willChange: 'auto',
      strokeWidth: 17,
      strokeMiterlimit: 'inherit',
      transition: 'stroke-dashoffset 850ms ease-in-out',
    },
    textValue: {
      ...defaultFontMedium,
      fontSize: 14,
      color: nightRiderColor,
    },
    subLabel: {
      ...defaultFont,
      fontSize: 14,
      color: lightSlateGreyColor,
    },
    hidden: {
      visibility: 'hidden',
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      textArea: {
        top: 27,
      },
      textValue: {
        fontSize: 9,
      },
      subLabel: {
        fontSize: 9,
      },
    },
  });

export default withStyles(styles)(withTranslation()(CircleProgress));

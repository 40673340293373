import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

// Component
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowBodyCustom from '~/components/common/table-row-body';
import OptionButton from '~/components/common/option-button';
import DiscardProposalDialog from './discard-proposal-dialog';
import { Link } from 'react-router-dom';
// Style
import { matterhornColor, denimColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { Network, ProposalAddressInfo, Account } from '~/gapi/gtypes';
import Tooltip from '@mui/material/Tooltip';
import { ellipsifyText } from '~/utilities/utils';
import { INode } from '~/types/network-types';

interface Item {
  signer: boolean;
  target: ProposalAddressInfo;
  statusInNetwork?: boolean | undefined;
  statusInGethLocal?: boolean | undefined;
}

interface IProps extends WithStyles<typeof styles> {
  item: Item;
  network: Network;
  isDiscardLoading?: boolean;
  account: Account;
  node: INode;
  onDiscard: (address) => void;
}

const ProposalNodeRow = (props: IProps) => {
  const { classes, item, network, isDiscardLoading, account, node, onDiscard } = props;
  const { t } = useTranslation();
  const [optionPopoverAnchorEl, setOptionPopoverAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [openDiscardProposalDialog, setOpenDiscardProposalDialog] = useState(false);

  const nodeTypestatus = useCallback(
    (authorize?: boolean) => {
      if (authorize === undefined) return '';
      return authorize === true
        ? `${t('relay_node')} >> ${t('validator_node')}`
        : `${t('validator_node')} >> ${t('relay_node')}`;
    },
    [t],
  );

  const onOpenDiscardProposalDialog = useCallback(() => {
    setOpenDiscardProposalDialog(true);
    setOptionPopoverAnchorEl(null);
  }, []);

  const onCloseDiscardProposalDialog = useCallback(() => {
    setOpenDiscardProposalDialog(false);
  }, []);

  const handleDiscardProposal = useCallback(async () => {
    onDiscard && (await onDiscard(item.target.address));
    onCloseDiscardProposalDialog();
  }, [item.target.address, onCloseDiscardProposalDialog, onDiscard]);

  const onOpenPopover = useCallback((e) => {
    setOptionPopoverAnchorEl(e.currentTarget);
  }, []);

  const onClosePopover = useCallback((e) => {
    setOptionPopoverAnchorEl(null);
  }, []);

  const enableDiscardProposal = useMemo(() => {
    return (
      node.nodeInfo.status === 'alive' &&
      node.serverInfo.status === 'alive' &&
      (account?.role === 'owner' ||
        account?.role === 'admin' ||
        network?.role === 'owner' ||
        network?.role === 'admin')
    );
  }, [account?.role, network?.role, node]);

  return (
    <>
      <Popover
        open={Boolean(optionPopoverAnchorEl)}
        anchorEl={optionPopoverAnchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem
            data-testid={`discard-${item.target.address}-item`}
            button
            className={classes.listOptionItem}
            onClick={onOpenDiscardProposalDialog}
            disabled={!enableDiscardProposal}
          >
            {t('discard')}
          </ListItem>
        </List>
      </Popover>
      <DiscardProposalDialog
        open={openDiscardProposalDialog}
        isLoading={isDiscardLoading}
        onClose={onCloseDiscardProposalDialog}
        onSubmit={handleDiscardProposal}
      />
      <TableRowBodyCustom className={classes.tableRowBodyCustom}>
        <TableCellBodyCustom
          classes={{ root: classNames(classes.tableBodyCellCommon, classes.tableBodyTd) }}
        >
          {item.target.node ? (
            <Link
              className={classes.nodeLinkText}
              to={`/network/${network?.networkUuid}/cluster/${item.target.node.clusterUuid}/node/${item.target.node.nodeUuid}/overview`}
            >
              {`${item.target.node.nodeName} (${item.target.node.clusterName})`}
            </Link>
          ) : (
            <div className={classes.externalNode}>Node External</div>
          )}
        </TableCellBodyCustom>
        <TableCellBodyCustom
          classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
        >
          <Tooltip title={item.target.address} classes={{ tooltip: classes.tooltip }}>
            <div>{ellipsifyText(item.target.address, 10, 10)}</div>
          </Tooltip>
        </TableCellBodyCustom>
        <TableCellBodyCustom
          classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
        >
          <div style={{ display: 'flex' }}>
            <div
              className={classNames({
                [classes.nodeType]: true,
                [classes.backgroundRomanColor]: item.signer,
                [classes.backgroundSummerSkyColor]: !item.signer,
              })}
            >
              {item.signer ? t('validator_node') : t('relay_node')}
            </div>
          </div>
        </TableCellBodyCustom>
        <TableCellBodyCustom
          classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
        >
          {nodeTypestatus(item.statusInNetwork)}
        </TableCellBodyCustom>
        <TableCellBodyCustom
          classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
        >
          {nodeTypestatus(item.statusInGethLocal)}
        </TableCellBodyCustom>
        <TableCellBodyCustom
          classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
        >
          {item.statusInGethLocal !== undefined && (
            <OptionButton
              data-testid={`option-${item.target.address}-button`}
              onClick={onOpenPopover}
            />
          )}
        </TableCellBodyCustom>
      </TableRowBodyCustom>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: 20,
      paddingBottom: 50,
    },
    endPoint: {
      display: 'flex',
      minWidth: 200,
    },
    tableRowBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 10px 4px 10px',
      },
    },
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    tableBodyTd: {},
    font13: {},
    listOptionItem: {
      ...defaultFont,
      height: 46,
      width: 210,
      fontSize: 16,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    externalNode: {
      color: 'gray',
    },
    nodeLinkText: {
      color: denimColor,
    },
    nodeType: {
      ...defaultFontMedium,
      display: 'flex',
      alignItems: 'center',
      minHeight: 28,
      borderRadius: 14,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 12,
      color: matterhornColor,
    },
    backgroundRomanColor: {
      backgroundColor: 'rgb(227, 90, 90, 0.2)',
    },
    backgroundSummerSkyColor: {
      backgroundColor: 'rgb(64, 194, 230, 0.2)',
    },
    tooltip: {
      backgroundColor: 'rgb(20, 26, 31)',
    },
  });

export default withStyles(styles)(ProposalNodeRow);

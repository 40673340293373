import React from 'react';
// Redux
// Route
import { Link } from 'react-router-dom';
// Component
import ArrowIcon from '~/components/common/arrow-icon';
import CustomTabsLink from '~/components/common/custom-tabs-link';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { denimColor } from '~/styles/themes/common-styles/color';
// Type

interface ITabs {
  title: string;
  component: JSX.Element;
  url?: string;
  tabName: string;
}

interface IRoute {
  name: string;
  url?: string;
}

interface IStateProps {
  title: string | JSX.Element;
  tabs?: ITabs[];
  routes?: IRoute[];
}

interface IProps extends IStateProps, WithStyles<typeof styles> {
  titleId?: string;
  tabId?: string;
  children?: React.ReactNode;
}

interface IState {}

class ContentContainerView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { classes, title, tabs, children, routes, titleId, tabId } = this.props;

    return (
      <div className={classes.root}>
        {/* Render Route */}
        <div className={classes.routeArea}>
          {routes &&
            routes.map((route, index) => (
              <div key={index} className={classes.route}>
                {routes.length - 1 === index ? (
                  <span className={classes.route}>{route.name}</span>
                ) : (
                  <Link
                    className={classes.linkItem}
                    to={route.url || '#'}
                    data-testid={`navigate-to-${route.name}`}
                  >
                    <span className={classes.routeText}>{route.name}</span>
                  </Link>
                )}
                {index < routes.length - 1 && (
                  <div className={classes.arrowIcon}>
                    <ArrowIcon direction="right" />
                  </div>
                )}
              </div>
            ))}
        </div>
        {/* Render Title */}
        <div className={classes.title}>
          <span id={titleId} className={classes.titleText}>
            {title}
          </span>
        </div>
        {/* Render Tabs */}
        <div className={classNames(classes.tabsArea, !tabs && classes.marginWithOutTabs)}>
          {tabs ? (
            <CustomTabsLink id={tabId} tabs={tabs} variant={'fullWidth'} />
          ) : (
            <div className={classes.tabBarBottom} />
          )}
        </div>
        {/* Render Children */}
        {children}
      </div>
    );
  }
}

const styles = createStyles({
  root: {},
  container,
  // Route Style
  routeArea: {
    display: 'flex',
  },
  route: {
    display: 'flex',
    fontSize: 12,
    whiteSpace: 'nowrap',
    maxWidth: '30%',
  },
  linkItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  routeText: {
    ...defaultFont,
    fontSize: 12,
    lineHeight: '14px',
    color: denimColor,
  },
  arrowIcon: {
    marginLeft: 5,
    marginRight: 5,
    transform: 'translateY(-2px)',
  },
  // Title Style
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 15,
  },
  titleText: {
    ...defaultFontMedium,
    fontSize: 28,
    lineHeight: '33px',
  },
  // Tabs Style
  tabsArea: {
    marginTop: 10,
  },
  marginWithOutTabs: {
    marginTop: 25,
  },
  tabBarBottom: {
    width: '100%',
    height: 3,
    backgroundColor: 'rgba(153, 170, 185, 0.38)',
    transform: 'translateY(-3px)',
  },
});

export default withStyles(styles)(ContentContainerView);

export type Order = 'ASC' | 'DESC';

export type HeadCell<SortableHeadCellId extends string, UnsortableHeadCellId extends string> =
  | {
      id: SortableHeadCellId;
      sortable: true;
      label: string;
    }
  | {
      id: UnsortableHeadCellId;
      sortable: false;
      label: string;
    };

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) =>
  a[orderBy] !== undefined
    ? b[orderBy] !== undefined
      ? b[orderBy] < a[orderBy]
        ? -1
        : b[orderBy] > a[orderBy]
        ? 1
        : 0
      : 1
    : b[orderBy] !== undefined
    ? -1
    : 0;

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
): ((
  a: { [key in Key]?: number | string | Date },
  b: { [key in Key]?: number | string | Date },
) => number) =>
  order === 'DESC'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(array: T[], comparator: (a: T, b: T) => number): Array<T> => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) =>
    comparator(a[0], b[0]) !== 0 ? comparator(a[0], b[0]) : a[1] - b[1],
  );
  return stabilizedThis.map((el) => el[0]);
};

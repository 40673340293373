import React from 'react';
// Redux
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as AppActions from '~/stores/actions/app-action';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Localization
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { romanColor } from '~/styles/themes/common-styles/color';
// Components
import SubmitButton from '../../submit-button';
import CustomInput from '../../custom-input';
import CustomSelect from '../../custom-select';
// Types
import { Account, AccountRoleType } from '~/types/account-types';
// Mixs
import { validateHexAddress } from '~/utilities/validator';
import { LOCALE_SUPPORTED } from '~/constants/locale';
import { MAX_ORGANIZATION_NAME_LENGTH } from '~/constants/validation';
import { ALLOC_ADDRESS_LENGTH } from '~/constants/consts';
const langCodeList = LOCALE_SUPPORTED.map((l) => l.lang);

interface IStateProps {
  account?: Account;
  selectedAccountRole?: AccountRoleType;
}

interface IDispProps {
  updateAccount: (
    args: AppActions.MutationUpdateAccountArgs,
  ) => Promise<AppActions.UPDATE_ACCOUNT_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

class GeneralTab extends React.Component<IProps> {
  private validateSchema = Yup.object().shape<AccountFormValues>({
    name: Yup.string()
      .trim()
      .required(this.props.t('required_field'))
      .max(
        MAX_ORGANIZATION_NAME_LENGTH,
        this.props.t('too_many_character_of_organization_name_error_message', {
          value: MAX_ORGANIZATION_NAME_LENGTH,
        }),
      ),
    lang: Yup.string()
      .trim()
      .required(this.props.t('required_field'))
      .oneOf(langCodeList, this.props.t('invalid_language_type')),
    addr: Yup.string().test(
      'check-hex-addr-format',
      this.props.t('invalid_hex_address_format'),
      function (addr: string) {
        return addr && addr.length > 0
          ? validateHexAddress(addr) && addr.substring(2).length === ALLOC_ADDRESS_LENGTH
          : true;
      },
    ),
  });

  constructor(props) {
    super(props);
  }

  render() {
    const { classes, t, account, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    if (!account) {
      return null;
    }

    const initialValues: AccountFormValues = {
      name: account.name,
      lang: account.lang,
      addr: account.addr || '',
    };

    return (
      <div className={classes.generalTab}>
        <div className={classes.updateAccountForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={this.validateSchema}
            onSubmit={this.onUpdateAccountSubmit}
            enableReinitialize
            render={({ isValid, isSubmitting }) => (
              <Form>
                <div>
                  {/* Render Form Field */}
                  <div className={classes.formSection} title="name-field">
                    <Field name="name" render={this.nameField} />
                  </div>
                  <div className={classes.formSection} title="addr-field">
                    <Field name="lang" render={this.languageField} />
                  </div>
                  <div className={classes.formSection} title="addr-field">
                    <Field name="addr" render={this.transferAddressField} />
                  </div>
                  {/* Render Submit Button */}
                  <div className={classes.updateAccountBtnArea}>
                    <SubmitButton
                      isValid={isValid && updatable}
                      isSubmitting={isSubmitting}
                      label={t('save_change')}
                      submittingLabel={t('updating')}
                      id="member-account-general-submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    );
  }

  private nameField = ({ field, form }: FieldProps<AccountFormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'name'} />
          </div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.name}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="organization-name-input"
            id="member-account-general-input"
            {...field}
            placeholder={t('input_organization_name')}
            disabled={!updatable}
          />
        </div>
      </>
    );
  };

  private languageField = ({ field, form }: FieldProps<AccountFormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';
    const langSelection = LOCALE_SUPPORTED.map((l) => ({ value: l.lang, label: l.name }));

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'language_of_notification_emails'} />
          </div>
          {!!form.errors.lang && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.lang}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            data-testid="language-select"
            id="member-account-general-language"
            {...field}
            valueSelected={field.value}
            placeholder={t('select_language')}
            items={langSelection}
            disabled={!updatable}
          />
        </div>
      </>
    );
  };

  private transferAddressField = ({ field, form }: FieldProps<AccountFormValues>) => {
    const { classes, t, selectedAccountRole } = this.props;
    const updatable = selectedAccountRole === 'owner';

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'default_transfer_addr'} />
          </div>
          {!!form.errors.addr && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.addr}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            data-testid="default-transfer-address-input"
            id="member-account-general-addr"
            {...field}
            placeholder={t('input_default_transfer_addr')}
            disabled={!updatable}
          />
        </div>
      </>
    );
  };

  private onUpdateAccountSubmit = async (
    values: AccountFormValues,
    formikActions: FormikActions<AccountFormValues>,
  ) => {
    const { updateAccount, account } = this.props;
    if (!account) {
      return;
    }
    const { setSubmitting } = formikActions;
    await updateAccount({
      input: {
        accountUuid: account.accountUuid,
        name: values.name.trim(),
        lang: values.lang && values.lang.trim(),
        addr: values.addr && values.addr.trim(),
      },
    });
    setSubmitting(false);
  };
}

const styles = createStyles({
  generalTab: {
    overflow: 'auto',
  },
  updateAccountForm: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  formSection: {
    marginTop: 15,
    marginBottom: 8,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontBold,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  updateAccountBtnArea: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateAccount: (args: AppActions.MutationUpdateAccountArgs) =>
    dispatch(AppActions.updateAccount(args)),
});

export default withStyles(styles)(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GeneralTab)),
);

type AccountFormValues = {
  name: string;
  lang?: string;
  addr: string;
};

import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
// Redux
import * as AlertActions from '~/stores/actions/alert-action';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IStore } from '~/stores/configure-store';
// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Component
import ContentContainerView from '../../common/ContentContainer';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHeadCustom from '~/components/common/table-head';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import Pagination from '~/components/common/pagination';
import LoadingIcon from '~/components/common/loading-icon';
import NotificationItem from './notification-item';
import SubmitButton from '~/components/common/submit-button';
import NotificationTypeSelector from './type-selector';
import DatePicker from '~/components/common/date-picker';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  lightSlateGreyColor,
  nightRiderColor,
  nepalColor,
  whiteColor,
  whisperColor,
} from '~/styles/themes/common-styles/color';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
// Type
import { IAlert, IAlertList, AlertType } from '~/types/alert-types';
import { Account } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Defines
import { TAB_TITLE_CONCAT } from '~/constants/consts';
// Hook
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';

const SEARCH_INPUT_DELAY = 300;

interface IStateProps {
  accountSelected?: Account;
  alertList: IAlertList;
  unreadAlertList: IAlertList;
  listAlertsLoading: boolean;
  setAlertReadPending: boolean;
  setAllAlertsReadPending: boolean;
}

interface IDispProps {
  setAlertRead: (
    args: AlertActions.MutationSetAlertReadArgs,
  ) => Promise<AlertActions.SET_ALERT_READ_RESULT_TYPE>;
  setAllAlertsRead: (
    args: AlertActions.MutationSetAllAlertsReadArgs,
  ) => Promise<AlertActions.SET_ALL_ALERTS_READ_RESULT_TYPE>;
  listAlerts: (
    args: AlertActions.QueryListAlertsArgs,
  ) => Promise<AlertActions.LIST_ALERTS_RESULT_TYPE> | undefined;
}

interface IProps
  extends IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    RouteComponentProps<{}>,
    WithTranslation {}

interface IState {
  alertUuidIsReading?: string;
  listAlertsRenewing: boolean;
}

class Notifications extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      listAlertsRenewing: false,
    };
  }

  componentDidMount() {
    const { accountSelected, listAlerts, alertList } = this.props;
    accountSelected &&
      !alertList.alerts &&
      listAlerts({ accountUuid: accountSelected.accountUuid });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.unreadAlertList !== this.props.unreadAlertList) {
      this.renewListAlerts();
    }
  }

  renewListAlerts = async () => {
    const { accountSelected, listAlerts } = this.props;
    this.setState({ listAlertsRenewing: true });
    accountSelected && (await listAlerts({ accountUuid: accountSelected.accountUuid }));
    this.setState({ listAlertsRenewing: false });
  };

  public render() {
    const { classes } = this.props;

    document.title = TAB_TITLE_CONCAT + this.props.t('notifications');

    return (
      <ContentContainerView
        title={this.props.t('notifications')}
        titleId="member-notification-title"
      >
        <div className={classes.root}>{this.renderAlerts}</div>
      </ContentContainerView>
    );
  }

  private get renderAlerts() {
    const { classes, unreadAlertList, setAllAlertsReadPending, t, alertList, listAlertsLoading } =
      this.props;
    const isAllAlertsReaded = Boolean(unreadAlertList.pageInfo.totalItems);

    return (
      <div className={classes.root}>
        <div className={classes.topLine}>
          <SubmitButton
            data-testid="mark-all-read-button"
            id="member-notification-button"
            label={t('mark_all_read')}
            submittingLabel={t('mark_all_read')}
            onClick={this.onClickMarkAllRead}
            isValid={isAllAlertsReaded}
            isSubmitting={setAllAlertsReadPending}
            classes={{ root: classes.btnSubmit }}
          />
          <div className={classes.topLineRight}>
            {/* <SearchInput placeholder="Search" onChange={this.onSearchInputChange} classes={{ root: classes.searchInput }} /> */}
            <NotificationTypeSelector
              values={alertList.filter && alertList.filter.types}
              onChange={this.onNotificationTypeChange}
              classes={{ root: classes.notificationTypeSelector }}
            />
            <div className={classes.datePickerArea}>
              <div className={classes.startDatePicker}>
                <DatePicker
                  data-testid="start-date-input"
                  datePickerInlineProps={{
                    value: alertList.filter?.startDate
                      ? new Date(alertList.filter.startDate)
                      : null,
                    onChange: this.onStartDateChange,
                    disabled: listAlertsLoading,
                    maxDate: alertList.filter?.endDate
                      ? new Date(alertList.filter.endDate)
                      : undefined,
                    inputFormat: t('date_format'),
                  }}
                />
              </div>
              <div className={classes.datePickerSeparator}>~</div>
              <div className={classes.endDatePicker}>
                <DatePicker
                  data-testid="end-date-input"
                  datePickerInlineProps={{
                    value: alertList.filter?.endDate ? new Date(alertList.filter.endDate) : null,
                    onChange: this.onEndDateChange,
                    disabled: listAlertsLoading,
                    minDate: alertList.filter?.startDate
                      ? new Date(alertList.filter.startDate)
                      : undefined,
                    inputFormat: t('date_format'),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.notificationTable}
      </div>
    );
  }

  private onStartDateChange = (date) => {
    const { accountSelected, listAlerts, alertList } = this.props;
    accountSelected &&
      !moment(date).isSame(moment(alertList.filter && alertList.filter.startDate), 'day') &&
      listAlerts({
        accountUuid: accountSelected.accountUuid,
        filter: { startDate: moment(date).startOf('days').toISOString() },
      });
  };

  private onEndDateChange = (date) => {
    const { accountSelected, listAlerts, alertList } = this.props;
    accountSelected &&
      !moment(date).isSame(moment(alertList.filter && alertList.filter.endDate), 'day') &&
      listAlerts({
        accountUuid: accountSelected.accountUuid,
        filter: { endDate: moment(date).endOf('days').toISOString() },
      });
  };

  private onClickMarkAllRead = () => {
    const { accountSelected, setAllAlertsRead } = this.props;
    accountSelected && setAllAlertsRead({ input: { accountUuid: accountSelected.accountUuid } });
  };

  private onNotificationTypeChange = (values?: Array<AlertType>) => {
    const { accountSelected, listAlerts } = this.props;
    accountSelected &&
      listAlerts({
        accountUuid: accountSelected.accountUuid,
        filter: { types: values },
      });
  };

  private onSearchInputChange = (event) => {
    this.debouncedOnSearchInputChange(event.target.value);
  };

  private debouncedOnSearchInputChange = _.debounce((searchText: string) => {
    const { accountSelected, listAlerts } = this.props;
    accountSelected &&
      listAlerts({
        accountUuid: accountSelected.accountUuid,
        filter: { searchText },
      });
  }, SEARCH_INPUT_DELAY);

  get notificationTable() {
    const { classes, alertList, listAlertsLoading, t } = this.props;
    const { listAlertsRenewing } = this.state;
    const { alertUuidIsReading } = this.state;
    const { totalPages } = alertList.pageInfo;

    if (listAlertsLoading && !listAlertsRenewing) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    return (
      <div className={classes.tableArea}>
        <Table id="member-notification-detail" className={classes.tblList}>
          <TableHeadCustom>
            <TableRowHeadCustom>
              <TableCellHeadCustom className={classes.typeCell}>
                <span>{t('type')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.contentCell}>
                <span>{t('title')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom className={classes.dateCell}>
                <span>{t('date')}</span>
              </TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
          <TableBody>
            {(alertList.alerts || []).map((alert, index) => (
              <NotificationItem
                key={index}
                alert={alert}
                showInfo={alertUuidIsReading === alert.alertUuid}
                showNotificationInfo={() => this.showNotificationInfo(alert)}
              />
            ))}
          </TableBody>
        </Table>
        {totalPages !== undefined && totalPages >= 1 && (
          <Pagination
            classes={{ root: classes.pagination }}
            currentPage={alertList.pageInfo.pageIndex + 1}
            totalPage={totalPages}
            selectPage={this.onSelectPage}
          />
        )}
      </div>
    );
  }

  private showNotificationInfo = (alert: IAlert) => {
    const { alertUuid, read } = alert;
    const { setAlertRead, setAlertReadPending } = this.props;
    const { alertUuidIsReading } = this.state;
    this.setState({
      alertUuidIsReading: alertUuidIsReading
        ? alertUuidIsReading === alert.alertUuid
          ? undefined
          : alert.alertUuid
        : alert.alertUuid,
    });
    if (!setAlertReadPending && !read) {
      setAlertRead({
        input: { alertUuid },
      });
    }
  };

  private onSelectPage = (pageValue: number) => {
    const { accountSelected } = this.props;
    const pageIndex = pageValue - 1;
    accountSelected &&
      this.props.listAlerts({
        accountUuid: accountSelected.accountUuid,
        page: {
          pageIndex,
        },
      });
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 12,
      paddingBottom: 50,
    },
    // Top line
    topLine: {
      marginBottom: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    readAllBtn: {
      ...defaultFont,
      backgroundImage: `linear-gradient(to bottom, ${whiteColor} , ${whisperColor})`,
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: nepalColor,
      '&:disabled': {
        cursor: 'not-allowed',
      },
      padding: '6px 12px',
      '&:hover': {
        backgroundImage: `linear-gradient(to bottom, ${whiteColor} , ${whisperColor})`,
      },
      color: nightRiderColor,
    },
    topLineRight: {
      display: 'flex',
      alignItems: 'center',
    },
    searchInput: {
      marginRight: 20,
      width: 300,
    },
    notificationTypeSelector: {
      marginRight: 20,
    },
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
    },
    startDatePicker: {
      display: 'flex',
      alignItems: 'center',
    },
    endDatePicker: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickerSeparator: {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 13,
      color: lightSlateGreyColor,
    },
    // Table
    loadingArea: {
      marginTop: 100,
      textAlign: 'center',
    },
    typeCell: {
      ...defaultFontMedium,
      width: '15%',
      minWidth: 150,
    },
    contentCell: {
      ...defaultFontMedium,
      width: '60%',
    },
    dateCell: {
      ...defaultFontMedium,
      width: '25%',
    },
    description: {
      marginTop: 10,
    },
    descriptionText: {
      ...defaultFont,
      fontSize: 13,
    },
    dateText: {
      ...defaultFont,
      fontSize: 13,
      lineHeight: '18px',
      color: lightSlateGreyColor,
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
    btnSubmit: {},
    dtPicker: {},
    tableArea: {},
    tblList: {},
    tableBodyTd: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 1140,
      },
      tableBodyTd: {
        padding: 0,
      },
      notificationTypeSelector: {
        width: 120,
        marginRight: 10,
      },
      startDatePicker: {
        width: 110,
      },
      endDatePicker: {
        width: 110,
      },
      btnSubmit: {
        width: 130,
        fontSize: 14,
      },
      datePickerSeparator: {
        marginLeft: 5,
        marginRight: 5,
      },
      dtPicker: {
        padding: '0 8px',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  accountSelected: store.appState.accountSeleted,
  alertList: store.appState.alertList,
  listAlertsLoading: AlertActions.listAlerts.isPending(store),
  setAlertReadPending: AlertActions.setAlertRead.isPending(store),
  unreadAlertList: store.appState.unreadAlertList,
  setAllAlertsReadPending: AlertActions.setAllAlertsRead.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listAlerts: (args: AlertActions.QueryListAlertsArgs) => dispatch(AlertActions.listAlerts(args)),
  setAlertRead: (args: AlertActions.MutationSetAlertReadArgs) =>
    dispatch(AlertActions.setAlertRead(args)),
  setAllAlertsRead: (args: AlertActions.MutationSetAllAlertsReadArgs) =>
    dispatch(AlertActions.setAllAlertsRead(args)),
});

export default compose<React.ClassicComponentClass>(
  withStyles(styles),
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Notifications);

import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';
import { withProfile } from '~/hooks/with-profile';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import { calculateSubtotal, displayCurrency } from '~/utilities/payment-utils';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { BUSINESS_PHONE, REGISTRATION_NO, TAX_PERCENTAGE } from '~/constants/consts';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import { useSelector } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { LicenseSummary } from '~/gapi/gtypes';
import countryCodes from 'i18n-iso-countries/entry-node';

export interface IInvoiceParams {
  id: string;
  amount: number;
  tax: number;
  coupon: number;
  invoicedDate: string;
  paidDate: string;
  licenses: Array<LicenseSummary>;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const InvoicePage = (props: IProps) => {
  const { classes, t } = props;
  const { search } = useLocation();
  const account = useSelector((store: IStore) => store.appState.accountSeleted);
  const profile = useSelector((store: IStore) => store.appState.profile);

  const invoice: IInvoiceParams | null = useMemo(() => {
    const query = new URLSearchParams(search).get('details');
    try {
      return query ? JSON.parse(query) : null;
    } catch (error) {
      return null;
    }
  }, [search]);

  const renderLicenses = useMemo(() => {
    return invoice?.licenses?.map((val) => {
      return (
        <tr className={classes.licenseRow}>
          <td>{t('date_of_issue_at', { date: new Date(invoice.invoicedDate) })}</td>
          <td>{val.licenseName}</td>
          <td>{val.qty}</td>
          <td style={{ textAlign: 'end' }}>${displayCurrency(val.unitPrice)}</td>
          <td style={{ textAlign: 'end' }}>
            ${displayCurrency(calculateSubtotal(val, invoice.paidDate || invoice.invoicedDate))}
          </td>
        </tr>
      );
    });
  }, [classes.licenseRow, invoice, t]);

  const dispCountry = useMemo(
    () =>
      account?.paymentAddr?.country
        ? countryCodes.getName(
            account?.paymentAddr.country,
            profile?.preference.language || 'en',
          ) || account?.paymentAddr.country
        : undefined,
    [account?.paymentAddr?.country, profile?.preference.language],
  );

  const lang = useMemo(
    () => (profile?.preference.language ? profile?.preference.language.toLocaleLowerCase() : 'en'),
    [profile?.preference.language],
  );

  return (
    <div className={classes.root}>
      {invoice && account ? (
        <div className={classes.invoice}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
            <Typography variant="h4">{t('invoice')}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img className={classes.logo} src="/images/icons/logo_guest.svg" alt="" />
            </div>
          </div>
          <div>
            <table className={classes.table}>
              <tr style={{ fontWeight: 450 }}>
                <td>{t('billing_id')}</td>
                <td style={{ paddingLeft: '8px' }}>{invoice.id}</td>
              </tr>
              <tr>
                <td>{t('date_of_issue')}</td>
                <td style={{ paddingLeft: '8px' }}>
                  {t('date_of_issue_at', { date: new Date(invoice.invoicedDate) })}
                </td>
              </tr>
            </table>
          </div>
          <Grid container marginTop={4} marginBottom={4}>
            <Grid className={classes.addressCompany} xs={6}>
              <Typography fontWeight={450}>{t('business_name')}</Typography>
              {lang === 'ja' ? (
                <>
                  <Typography variant="body2">{t('business_postal_code')}</Typography>
                  <Typography variant="body2">{t('business_city')}</Typography>
                  <Typography variant="body2">{t('business_addr')}</Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2">{t('business_addr')}</Typography>
                  <Typography variant="body2">{t('business_city')}</Typography>
                  <Typography variant="body2">{t('business_postal_code')}</Typography>
                </>
              )}
              <Typography variant="body2">{BUSINESS_PHONE}</Typography>
              <Typography variant="body2">
                {t('qualified_invoice_issuer_registration_number')}&nbsp;&nbsp;{REGISTRATION_NO}
              </Typography>
            </Grid>
            <Grid className={classes.billTo} xs={6}>
              <Typography fontWeight={450}>{t('bill_to')}</Typography>
              <Typography variant="body2">{account.name}</Typography>
              {lang == 'ja' ? (
                <>
                  {dispCountry && <Typography variant="body2">{dispCountry}</Typography>}
                  <Typography variant="body2">{account.paymentAddr?.postalCode}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.state}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.city}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.address1}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.address2}</Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2">{account.paymentAddr?.address2}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.address1}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.city}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.state}</Typography>
                  <Typography variant="body2">{account.paymentAddr?.postalCode}</Typography>
                  {dispCountry && <Typography variant="body2">{dispCountry}</Typography>}
                </>
              )}
              <Typography variant="body2">{account.contactEmail}</Typography>
            </Grid>
          </Grid>
          {renderLicenses && (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tr className={classes.descriptionRow}>
                <th style={{ width: 'auto' }}>{t('dealing_date')}</th>
                <th style={{ width: '40%' }}>{t('description')}</th>
                <th>{t('qty')}</th>
                <th style={{ textAlign: 'end' }}>{t('unit_price')}</th>
                <th style={{ textAlign: 'end' }}>{t('invoice_amount')}</th>
              </tr>
              {renderLicenses}
            </table>
          )}
          <Grid container marginTop={3} marginBottom={3}>
            <Grid xs={6}></Grid>
            <Grid xs={6}>
              <Divider style={{ margin: '8px 0 8px 0' }} />
              <Stack className={classes.summary} divider={<Divider flexItem />} spacing={1}>
                <ListItem disablePadding>
                  <ListItemText secondary={t('subtotal')} />
                  <ListItemText
                    secondary={`$${displayCurrency(invoice.amount)}`}
                    style={{ textAlign: 'end' }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText secondary={t('amount_of_coupon_used')} />
                  <ListItemText
                    secondary={`-$${displayCurrency(invoice.coupon)}`}
                    style={{ textAlign: 'end' }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText secondary={`${t('tax')} (${TAX_PERCENTAGE})`} />
                  <ListItemText
                    secondary={`$${displayCurrency(invoice.tax)}`}
                    style={{ textAlign: 'end' }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText secondary={t('total')} />
                  <ListItemText
                    secondary={`$${displayCurrency(invoice.amount + invoice.tax - invoice.coupon)}`}
                    style={{ textAlign: 'end' }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary={t('amount_due')} />
                  <ListItemText
                    primary={`$${displayCurrency(
                      invoice.amount + invoice.tax - invoice.coupon,
                    )} USD`}
                    style={{ textAlign: 'end' }}
                  />
                </ListItem>
              </Stack>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>Invoice not found</div>
      )}
    </div>
  );
};

const styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgb(246, 249, 252)',
    color: nightRiderColor,
  },
  invoice: {
    margin: '84px 0 84px 0',
    minWidth: 700,
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '48px',
    borderRadius: '10px',
  },
  logo: {
    width: '240px',
    height: '32px',
  },
  table: {
    '& th,td': {
      padding: 0,
    },
  },
  descriptionRow: {
    borderBottom: `1pt solid ${nightRiderColor}`,
    '&>th': {
      paddingBottom: 10,
    },
  },
  summary: {
    '& .MuiTypography-body2': {
      color: nightRiderColor,
    },
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
    },
  },
  addressCompany: {
    '& .MuiTypography-root:not(:first-child)': {
      fontWeight: 300,
    },
  },
  billTo: {
    '& .MuiTypography-root:not(:first-child)': {
      fontWeight: 300,
    },
  },
  licenseRow: {
    '&>td': {
      fontSize: '0.875rem',
    },
  },
});

export default compose(
  withFirebaseUser(),
  withProfile,
  withInitAppData,
  withStyles(styles),
  withTranslation(),
)(InvoicePage);

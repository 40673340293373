import React from 'react';
import { connect } from 'react-redux';
// Redux
import { compose } from 'redux';
import * as NetworkActions from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
// Route
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFontMedium, defaultFontRegular } from '~/styles/themes/common-styles/font';
import { nightRiderColor, pattensBlueColor } from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';
// Component
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import ContentContainerView from '../../common/ContentContainer';
import LGButton from '~/components/common/lg-button';
import ArrowIcon from '~/components/common/arrow-icon';
import ImgIcon from '~/components/common/img-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableRowHeadCustom from '~/components/common/table-row-head';
import Pagination from '~/components/common/pagination';
import LoadingIcon from '~/components/common/loading-icon';
import InvitationTableRow from './invitation-table-row';
// Type
import { InvitationList } from '~/types/network-types';
import { Account, Profile } from '~/types/account-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { isDevMode } from '~/utilities/utils';
import classNames from 'classnames';
// Defines
import { POLLING_NETWORK_INTERVAL, TAB_TITLE_CONCAT } from '~/constants/consts';
// Hook
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withInitAppData } from '~/hooks/with-init-app-data';

interface IStateProps {
  invitationList: InvitationList;
  listInvitationsLoading: boolean;
  accountSeleted?: Account;
  profile?: Profile;
}

interface IDispProps {
  listInvitations: (
    args: NetworkActions.QueryListInvitationsArgs,
  ) => Promise<NetworkActions.LIST_INVITATIONS_RESULT_TYPE>;
  responseInvitation: (
    args: NetworkActions.MutationResponseInvitationArgs,
  ) => Promise<NetworkActions.RESPONSE_INVITATION_RESULT_TYPE>;
  cancelInvitation: (
    args: NetworkActions.MutationCancelInvitationArgs,
  ) => Promise<NetworkActions.CANCEL_INVITATION_RESULT_TYPE>;
  listNetworks: (
    args: NetworkActions.QueryListNetworksArgs,
  ) => Promise<NetworkActions.LIST_NETWORKS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {}

interface IState {
  anchorEl: HTMLButtonElement | null;
  inPolling: boolean;
}

class Invitations extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: null,
      inPolling: false,
    };
  }

  async componentDidMount() {
    // get received invitations
    const { invitationList } = this.props;
    if (!invitationList.invitations) {
      this.listInvitations();
    }

    // start polling

    this.pollingInvitationTimer = window.setInterval(async () => {
      const isOnline = window ? window.navigator.onLine : false;
      const account = this.props.accountSeleted;

      if (!isOnline) {
        if (isDevMode()) {
          console.log(`Polling skipped due to offline`);
        }
      } else if (account) {
        if (isDevMode()) {
          console.log(
            `Polling run each ${POLLING_NETWORK_INTERVAL} msec: invitation (${new Date().toString()})`,
          );
        }
        this.setState({ inPolling: true });

        await this.listInvitations();

        this.setState({ inPolling: false });

        if (isDevMode()) {
          console.log(`Polling done: invitation (${new Date().toString()})`);
        }
      }
    }, POLLING_NETWORK_INTERVAL);
  }

  componentWillUnmount() {
    this.stopPollingInvitationStatus();
  }

  listInvitations = () => {
    const { accountSeleted, listInvitations, invitationList } = this.props;
    return (
      accountSeleted &&
      listInvitations({
        accountUuid: accountSeleted.accountUuid,
        isSent: invitationList.isSent,
      })
    );
  };

  public render() {
    const { classes, invitationList } = this.props;
    const { anchorEl } = this.state;
    const isSent = invitationList.isSent;

    document.title = TAB_TITLE_CONCAT + this.props.t('invitation');

    return (
      <ContentContainerView title={this.props.t('invitation')} titleId="member-invitations-title">
        <div className={classes.root}>
          <LGButton
            data-testid="invitaion-button"
            id="member-invitations-action"
            classes={{ root: classes.selectListBtn }}
            onClick={this.onOpenMenu}
          >
            <div>
              <ImgIcon
                className={classes.listIcon}
                imgUrl={`/images/icons/${isSent ? 'invite_on_ico' : 'receive_ico'}.png`}
              />
              <span className={classes.listSelectedName}>
                {isSent ? this.props.t('sent') : this.props.t('received')}
              </span>
            </div>
            <ArrowIcon direction={'down'} size={3} />
          </LGButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.onCloseMenu}
            classes={{ paper: classes.menu }}
            elevation={1}
            disableAutoFocusItem
          >
            <MenuItem
              data-testid="received-item"
              className={classes.menuItem}
              onClick={this.onSelectMenu}
              value="received"
            >
              <ImgIcon className={!isSent ? '' : 'icon'} imgUrl="/images/icons/check_ico.png" />
              <Typography className="text">{this.props.t('received')}</Typography>
            </MenuItem>
            <MenuItem
              data-testid="sent-item"
              className={classes.menuItem}
              onClick={this.onSelectMenu}
              value="sent"
            >
              <ImgIcon className={isSent ? '' : 'icon'} imgUrl="/images/icons/check_ico.png" />
              <Typography className="text">{this.props.t('sent')}</Typography>
            </MenuItem>
          </Menu>
          {this.renderTable}
        </div>
      </ContentContainerView>
    );
  }

  get renderTable() {
    const {
      classes,
      invitationList,
      listInvitationsLoading,
      listInvitations,
      responseInvitation,
      cancelInvitation,
      listNetworks,
      accountSeleted,
    } = this.props;
    const { inPolling } = this.state;
    const { totalPages } = invitationList.pageInfo;
    const isSent = invitationList.isSent;

    if (listInvitationsLoading && !inPolling) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }

    if (
      invitationList.invitations === undefined ||
      accountSeleted === undefined ||
      totalPages === undefined
    ) {
      return null;
    }

    return (
      <>
        <div className={classNames(classes.root, classes.tableArea)}>
          <Table id="member-invitations-detail" className={classes.tblList}>
            <colgroup>
              <col width="auto" />
              <col width="auto" />
              <col width="160px" />
              <col width="180px" />
            </colgroup>
            <TableHeadCustom className={classes.tableHeadCustom}>
              <TableRowHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('network')}</span>
                  {/* <ArrowIcon direction="down" /> */}
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>
                    {isSent
                      ? this.props.t('invitation_table_account_sent_to')
                      : this.props.t('invitation_table_account_received_from')}
                  </span>
                  {/* <ArrowIcon direction="down" /> */}
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('action')}</span>
                  {/* <ArrowIcon direction="down" /> */}
                </TableCellHeadCustom>
                <TableCellHeadCustom>
                  <span>{this.props.t('date')}</span>
                </TableCellHeadCustom>
              </TableRowHeadCustom>
            </TableHeadCustom>
            <TableBodyCustom>
              {invitationList.invitations.map((invitation, index) => (
                <InvitationTableRow
                  key={index}
                  invitation={invitation}
                  isSent={isSent}
                  accountSeleted={accountSeleted}
                  listInvitations={listInvitations}
                  responseInvitation={responseInvitation}
                  cancelInvitation={cancelInvitation}
                  listNetworks={listNetworks}
                />
              ))}
            </TableBodyCustom>
          </Table>
        </div>
        {totalPages > 0 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={invitationList.pageInfo.pageIndex + 1}
              totalPage={totalPages}
              selectPage={this.onSelectPage}
            />
          </div>
        )}
      </>
    );
  }

  private onOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  private onCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  private onSelectMenu = (event: React.MouseEvent<HTMLElement>) => {
    const { accountSeleted, listInvitations } = this.props;
    const value = event.currentTarget.getAttribute('value');
    this.setState({ anchorEl: null });

    if (accountSeleted) {
      listInvitations({
        accountUuid: accountSeleted.accountUuid,
        isSent: Boolean(value === 'sent'),
        page: { pageIndex: 0 },
      });
    }
  };

  private onSelectPage = (pageValue: number) => {
    const { accountSeleted, listInvitations, invitationList } = this.props;
    const pageIndex = pageValue - 1;

    if (accountSeleted) {
      listInvitations({
        accountUuid: accountSeleted.accountUuid,
        isSent: invitationList.isSent,
        page: { pageIndex },
      });
    }
  };

  private pollingInvitationTimer: number | null = null;

  private stopPollingInvitationStatus = () => {
    if (this.pollingInvitationTimer) {
      window.clearInterval(this.pollingInvitationTimer);
    }
    this.pollingInvitationTimer = null;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 50,
    },
    tableHeadCustom: {
      '& span': {
        letterSpacing: 'normal',
      },
    },
    loadingArea: {
      marginTop: 200,
      textAlign: 'center',
    },
    selectListBtn: {
      width: 150,
      height: 34,
      marginTop: 20,
      marginBottom: 18,
      color: nightRiderColor,
    },
    listIcon: {
      verticalAlign: 'middle',
    },
    listSelectedName: {
      ...defaultFontMedium,
      ...oneLineText,
      fontSize: 15,
      marginRight: 10,
      marginLeft: 8,
      verticalAlign: 'middle',
    },
    menu: {
      width: 150,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      marginTop: 2,
    },
    menuItem: {
      ...defaultFontRegular,
      fontSize: 15,
      height: 48,
      '& .text': {
        ...defaultFontMedium,
        fontSize: 15,
        marginLeft: 8,
      },
      '& .icon': {
        visibility: 'hidden',
      },
      padding: '11px 16px',
    },
    pagination: {
      marginTop: 30,
      textAlign: 'center',
    },
    tableArea: {},
    tblList: {},
    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  invitationList: store.appState.invitationList,
  accountSeleted: store.appState.accountSeleted,
  listInvitationsLoading: NetworkActions.listInvitations.isPending(store),
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listInvitations: (args: NetworkActions.QueryListInvitationsArgs) =>
    dispatch(NetworkActions.listInvitations(args)),
  responseInvitation: (args: NetworkActions.MutationResponseInvitationArgs) =>
    dispatch(NetworkActions.responseInvitation(args)),
  cancelInvitation: (args: NetworkActions.MutationCancelInvitationArgs) =>
    dispatch(NetworkActions.cancelInvitation(args)),
  listNetworks: (args: NetworkActions.QueryListNetworksArgs) =>
    dispatch(NetworkActions.listNetworks(args)),
});

export default compose<React.ClassicComponentClass>(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Invitations);

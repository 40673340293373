import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as semver from 'semver';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';
import CustomSelect from '~/components/common/custom-select';

import { IExplorer } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { blockscoutBackendSelection } from '~/types/network-selection';
import {
  DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
  DEFAULT_VERSION_BLOCKSCOUT_FRONTEND,
} from '~/constants/consts';

interface IProps extends WithStyles<typeof styles> {
  networkUuid: string;
  clusterUuid: string;
  explorer: IExplorer;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  backendVersion: string;
};

const UpgradeExplorerVersionDialog = (props: IProps) => {
  const { classes, open, explorer, networkUuid, clusterUuid, onClose } = props;
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const isExplorerUpdating = useSelector((store: IStore) =>
    NetworkActions.updateBlockExplorerVersion.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    backendVersion: explorer.blockscoutInfo.backend.version,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    backendVersion: Yup.string().required(t('required_field')),
  });

  const blockscoutVersionOpts = useMemo(
    () =>
      blockscoutBackendSelection.filter(({ value }) =>
        semver.lte(explorer.blockscoutInfo.backend.version, value),
      ),
    [explorer.blockscoutInfo.backend.version],
  );

  const backendVersionField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('block_explorer_version')}</div>
            {!!form.errors.backendVersion && form.touched.backendVersion && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.backendVersion}
              </div>
            )}
          </div>
          <div>
            <CustomSelect
              data-testid="version-select"
              {...field}
              valueSelected={field.value}
              placeholder={t('select_block_exp_backend_version')}
              items={blockscoutVersionOpts}
            />
          </div>
        </>
      );
    },
    [classes, blockscoutVersionOpts, t],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { backendVersion } = values;
      try {
        if (accountSeleted?.accountUuid) {
          await dispatch(
            NetworkActions.updateBlockExplorerVersion({
              input: {
                accountUuid: accountSeleted.accountUuid,
                networkUuid,
                clusterUuid,
                backendVersion,
                frontendVersion: semver.gte(
                  values.backendVersion,
                  DEFAULT_MINIMUM_BLOCKSCOUT_VERSION_SUPPORT_UI_V2,
                )
                  ? DEFAULT_VERSION_BLOCKSCOUT_FRONTEND
                  : undefined,
              },
            }),
          );
          onClose();
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [accountSeleted, clusterUuid, dispatch, networkUuid, onClose],
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <CustomDialog open={open} onClose={handleCloseDialog}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-upgrade-explorer-title">{t('upgrade_block_explorer_version')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Field name="backendVersion" render={backendVersionField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-upgrade-explorer-cancel"
                  disabled={isSubmitting || isExplorerUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-upgrade-explorer-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting || isExplorerUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(UpgradeExplorerVersionDialog);

import UAParser from 'ua-parser-js';
import { supportedBrowsers } from '../constants/support';
import { Profile } from '~/types/account-types';

export const checkBrowserSupport = (userAgent: string) =>
  supportedBrowsers.includes(new UAParser(userAgent).getBrowser().name || '');

export const getProfileLocale = (prof?: Profile) => {
  const locale = {
    lang: 'en',
    zone: 'UTC',
  };

  if (prof) {
    locale.lang = prof.preference.language || 'en';
    locale.zone = prof.preference.timezone || 'UTC';
  }

  return locale;
};

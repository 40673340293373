import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';

// Component
import NodeStatusIcon from '~/components/common/node-status-icon';
import ContentContainerView from '~/components/common/ContentContainer';
import OverviewTab from './explorer/tabs/overview-tab';
import SecurityTab from './explorer/tabs/security-tab';
import MetricsTab from './explorer/tabs/metrics-tab';

// defines
import { POLLING_NETWORK_INTERVAL, TAB_TITLE_CONCAT } from '~/constants/consts';
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { INetwork, ICluster, IRestrictionNetwork } from '~/types/network-types';
import { isAccountOwnCluster, isDevMode } from '~/utilities/utils';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  displayBlockExplorerStatus,
  displayBlockExplorerStatusDetail,
} from '~/utilities/render-utils';
import { compose } from 'redux';

interface IStateProps {
  account?: Account;
  network?: INetwork;
  rules: IRestrictionNetwork[];
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {
  checkNetwork: (
    args: NetworkActions.QueryGetNetworkArgs,
  ) => Promise<NetworkActions.GET_NETWORK_RESULT_TYPE>;
  listExplorerRestrictions: (
    args: NetworkActions.QueryListExplorerRestrictionsArgs,
  ) => Promise<NetworkActions.LIST_EXPLORER_RESTRICTIONS_RESULT_TYPE>;
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
}

interface IProps
  extends IStateProps,
    IDispProps,
    WithStyles<typeof styles>,
    RouteComponentProps<{ networkId: string; clusterId: string; tab?: string }>,
    WithTranslation {}

interface IState {
  cluster?: ICluster;
}

class Explorer extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.findCluster(true);
  }

  componentDidUpdate(prevProps: IProps, prevState) {
    if (prevProps.network !== this.props.network) {
      this.findCluster();
    }
  }

  public render() {
    const { classes, network, account, t, selectedAccountRole, selectedNetworkRole } = this.props;
    const { cluster } = this.state;

    const showSecurityTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');
    const showMetricsTab =
      isAccountOwnCluster(account, cluster) &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin' ||
        selectedNetworkRole === 'operator' ||
        selectedNetworkRole === 'member');

    // Loading
    if (network === void 0 || cluster === void 0 || !cluster.explorer || account === undefined) {
      return null;
    }
    const detail = displayBlockExplorerStatusDetail(cluster, t);

    const tabs = [
      {
        title: t('overview'),
        tabName: 'overview',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/overview`,
        component: <OverviewTab cluster={cluster} network={network} account={account} />,
      },
    ];

    if (showSecurityTab) {
      tabs.push({
        title: t('security'),
        tabName: 'security',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/security`,
        component: <SecurityTab account={account} cluster={cluster} network={network} />,
      });
    }

    if (showMetricsTab) {
      tabs.push({
        title: t('metrics'),
        tabName: 'metrics',
        url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/metrics`,
        component: <MetricsTab account={account} cluster={cluster} network={network} />,
      });
    }

    document.title = TAB_TITLE_CONCAT + this.props.t('block_explorer_overview_title');

    return (
      <ContentContainerView
        title={
          <div id="explorer-name" className={classes.title}>
            <NodeStatusIcon
              circleClassName={classes.nodeStatusIcon}
              status={displayBlockExplorerStatus(cluster)}
            />
            <span className={classes.titleText}>{cluster.clusterName} block explorer</span>
            {detail ? <span className={classes.nodeStatusText}>{detail}</span> : null}
          </div>
        }
        tabs={tabs}
        routes={[
          {
            name: `Network: ${network.networkName}`,
            url: `/network/${network.networkUuid}/overview`,
          },
          {
            name: `Cluster: ${cluster.clusterName}`,
            url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/overview`,
          },
          {
            name: `Block Explorer`,
            url: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/overview`,
          },
        ]}
        tabId="explorer-tab"
      />
    );
  }

  private pollingNetworkTimer: number | null = null;

  private stopPollingNetworkStatus = () => {
    if (this.pollingNetworkTimer) {
      window.clearInterval(this.pollingNetworkTimer);
    }
    this.pollingNetworkTimer = null;
  };

  private findCluster = (loadAclList: boolean = false) => {
    const { clusterId, networkId, tab } = this.props.match.params;
    const {
      network,
      listExplorerRestrictions,
      openSnackBar,
      t,
      selectedAccountRole,
      selectedNetworkRole,
      account,
      history,
    } = this.props;

    let cluster: ICluster | undefined = undefined;

    if (network) {
      if (network.networkUuid !== networkId) {
        history.push(`/network`);
      }
      cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterId.toString());

      if (cluster) {
        if (!tab) {
          history.push(`/network/${networkId}/cluster/${cluster.clusterUuid}/explorer/overview`);
        }
        const oldCluster = this.state.cluster;

        if (!oldCluster || oldCluster !== cluster) {
          this.setState({
            cluster,
          });

          if (loadAclList && account?.accountUuid === cluster?.accountUuid) {
            if (cluster.explorer?.serverInfo.instanceId) {
              const callableListExplorerRestrictions =
                selectedAccountRole === 'owner' ||
                selectedAccountRole === 'admin' ||
                selectedNetworkRole === 'owner' ||
                selectedNetworkRole === 'admin' ||
                selectedNetworkRole === 'operator';
              if (callableListExplorerRestrictions) {
                account &&
                  listExplorerRestrictions({
                    accountUuid: account.accountUuid,
                    networkUuid: network.networkUuid,
                    clusterUuid: cluster.clusterUuid,
                  }).catch(console.log);
              }
            } else {
              openSnackBar({
                type: 'error',
                message: t('can_not_get_inbound_rules'),
              });
            }
          }
        }
      }
    }
    return {
      network,
      cluster,
    };
  };
}

const styles: StyleRules = {
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    marginLeft: 10,
  },
  nodeStatusIcon: {
    transform: 'translateY(2px)',
    fontSize: '1rem',
    marginLeft: 0,
  },
  nodeStatusText: {
    marginLeft: 15,
    fontSize: 15,
    color: 'red',
  },
};

const mapStateToProps = (store: IStore): IStateProps => ({
  account: store.appState.accountSeleted,
  network: store.appState.networkSelected,
  rules: store.appState.restrictions,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  checkNetwork: (args: NetworkActions.QueryGetNetworkArgs) =>
    dispatch(NetworkActions.getNetwork(args)),
  listExplorerRestrictions: (args: NetworkActions.QueryListExplorerRestrictionsArgs) =>
    dispatch(NetworkActions.listExplorerRestrictions(args)),
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
});

export default compose<React.ClassicComponentClass>(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Explorer);

import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import { Redirect, useLocation } from 'react-router-dom';
import { AppRoutePath } from '~/AppRouter';

let needEmailVerifiedValue: boolean;
let whenAuthedValue: string;
let whenUnauthedValue: string;
let whenLogoutValue: string;
let LoadingComponentValue: React.FunctionComponent;

export type InitFirebaseAuthOptions = {
  firebaseConfig: Record<string, any>;
  needEmailVerified?: boolean;
  whenAuthed?: string;
  whenUnauthed?: string;
  whenLogout?: string;
  LoadingComponent?: any;
};

export const initFirebaseAuth = ({
  firebaseConfig,
  needEmailVerified = false,
  whenAuthed = '/auth',
  whenUnauthed = '/',
  whenLogout = '/logout',
  LoadingComponent = 'Loading',
}: InitFirebaseAuthOptions) => {
  needEmailVerifiedValue = needEmailVerified;
  whenAuthedValue = whenAuthed;
  whenUnauthedValue = whenUnauthed;
  whenLogoutValue = whenLogout;
  LoadingComponentValue = LoadingComponent;
  if (firebase.apps.length < 1) {
    firebase.initializeApp(firebaseConfig);
  }
};

const FirebaseUserContext = createContext<firebase.User | null>(null);

export const useFirebaseUser = () => useContext(FirebaseUserContext);

export type WithFirebaseAuthOptions = {
  whenAuthed?: string;
  whenUnauthed?: string;
  whenLogout?: string;
  LoadingComponent?: any;
  needEmailVerified?: boolean;
};

export const withFirebaseUser =
  (args: WithFirebaseAuthOptions = {}) =>
  (ChildComponent) => {
    const WithFirebaseUserHOC: React.FunctionComponent = (props) => {
      const location = useLocation();
      const {
        whenAuthed = whenAuthedValue,
        whenUnauthed = whenUnauthedValue,
        whenLogout = whenLogoutValue,
        LoadingComponent = LoadingComponentValue,
        needEmailVerified = needEmailVerifiedValue,
      } = args;
      const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(
        firebase.auth().currentUser,
      );
      const [loading, setLoading] = useState(!Boolean(firebaseUser));

      useEffect(() => {
        const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
          setFirebaseUser(user);
          setLoading(false);
        });

        return () => {
          unsubscribe();
        };
      }, []);

      if (loading) {
        return <LoadingComponent />;
      }

      const isAuthed = firebaseUser && (needEmailVerified ? firebaseUser.emailVerified : true);

      if (!isAuthed && location.pathname !== whenUnauthed && location.pathname !== whenLogout) {
        return <Redirect to={whenUnauthed} exact={true} />;
      }

      if (isAuthed && location.pathname === whenUnauthed) {
        return <Redirect to={whenAuthed} exact={true} />;
      }
      return (
        <FirebaseUserContext.Provider value={firebaseUser}>
          <ChildComponent {...(props as any)} />
        </FirebaseUserContext.Provider>
      );
    };

    WithFirebaseUserHOC.displayName = 'WithFirebaseUserHOC';

    return WithFirebaseUserHOC;
  };

import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Styles
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { denimColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
// Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import NodeStatusIcon from '~/components/common/node-status-icon';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowBodyCustom from '~/components/common/table-row-body';
import OptionButton from '~/components/common/option-button';
import ProposeDialog from '~/components/common/ProposalDialog';
// Route
import { Link } from 'react-router-dom';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { INetwork } from '~/types/network-types';
import { Item } from '..';
import classNames from 'classnames';
import { listNodesBelongToAccount } from '~/utilities/utils';

interface IStateProps {
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  item: Item;
  network: INetwork;
  account: Account;
}

interface IState {
  optionPopoverAnchorEl: HTMLButtonElement | null;
  openSendProposalDialog: boolean;
}

class NodeRow extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      optionPopoverAnchorEl: null,
      openSendProposalDialog: false,
    };
  }

  public render() {
    const { classes, t, item, network, account, selectedAccountRole, selectedNetworkRole } =
      this.props;
    const consortium = network.consortiums.find(
      (consortium) => consortium.accountUuid === account.accountUuid,
    );
    const aliveSignerNodeBelongAccount = listNodesBelongToAccount(account, network, true).filter(
      (node) => node.nodeInfo.status === 'alive' && node.serverInfo.status === 'alive',
    );
    const showNodeProposal =
      aliveSignerNodeBelongAccount.length > 0 &&
      (selectedAccountRole === 'owner' ||
        selectedAccountRole === 'admin' ||
        selectedNetworkRole === 'owner' ||
        selectedNetworkRole === 'admin');

    return (
      <>
        {this.consortiumOptionsPopover}
        <TableRowBodyCustom className={classes.tableRowBodyCustom}>
          <TableCellBodyCustom
            classes={{ root: classNames(classes.tableBodyCellCommon, classes.tableBodyTd) }}
          >
            <Link to={item.link}>
              <div className={classNames(classes.nodeLinkText, classes.tableBodyTdState)}>
                <NodeStatusIcon className={classes.nodeStatusIcon} status={item.nodeStatus} />
                {item.nodeName}
              </div>
            </Link>
          </TableCellBodyCustom>
          <TableCellBodyCustom
            classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
          >
            <span className={classes.tableCellStatusText}>{item.nodeState}</span>
          </TableCellBodyCustom>
          <TableCellBodyCustom
            classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
          >
            {item.nodeType}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
          >
            {item.clusterName}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
          >
            {item.accountName}
          </TableCellBodyCustom>
          <TableCellBodyCustom
            classes={{ root: classNames(classes.tableBodyCellCommon, classes.font13) }}
          >
            {showNodeProposal && <OptionButton onClick={this.onOpenPopover} />}
          </TableCellBodyCustom>
        </TableRowBodyCustom>
      </>
    );
  }

  get consortiumOptionsPopover() {
    const { classes, t, item, network, account } = this.props;
    const { optionPopoverAnchorEl, openSendProposalDialog } = this.state;

    return (
      <>
        {openSendProposalDialog && (
          <ProposeDialog
            open={openSendProposalDialog}
            network={network}
            account={account}
            address={item.node.nodeInfo.coinbaseAddress}
            scroll="body"
            onClose={this.onCloseSendProposalDialog}
          />
        )}
        <Popover
          open={Boolean(optionPopoverAnchorEl)}
          anchorEl={optionPopoverAnchorEl}
          onClose={this.onClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
        >
          <List>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.onOpenSendProposalDialog}
            >
              {this.props.t('send_proposal')}
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }

  onOpenPopover = (e) => {
    this.setState({ optionPopoverAnchorEl: e.currentTarget });
  };
  onClosePopover = () => {
    this.setState({ optionPopoverAnchorEl: null });
  };

  onOpenSendProposalDialog = () => {
    this.setState({ openSendProposalDialog: true });
    this.onClosePopover();
  };
  onCloseSendProposalDialog = () => {
    this.setState({ openSendProposalDialog: false });
  };
}

const styles = (theme) =>
  createStyles({
    root: {},
    tableBodyCellCommon: {
      wordBreak: 'break-all',
    },
    nodeStatusIcon: {
      marginRight: 10,
    },
    nodeLinkText: {
      color: denimColor,
    },
    tableCellStatusText: {},
    listOptionItem: {
      ...defaultFont,
      height: 46,
      width: 210,
      fontSize: 16,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    tblList: {},
    tableBodyTd: {},
    tableBodyTdState: {},
    font13: {},

    [theme.breakpoints.between('sm', 'sm')]: {
      tableArea: {
        overflow: 'auto',
      },
      tblList: {
        width: 920,
      },
      tableBodyTd: {
        padding: 0,
      },
      tableBodyTdState: {
        fontSize: 13,
        color: '#7b90a3',
      },
      font13: {
        fontSize: 13,
      },
    },
    tableRowBodyCustom: {
      height: '48px',
      '& .MuiTableCell-root': {
        letterSpacing: 'normal',
        padding: '4px 30px 4px 10px',
      },
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NodeRow)),
);

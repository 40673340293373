import React from 'react';
// Redux
// Component
import DialogActions from '@mui/material/DialogActions';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';

// Type

interface IProps extends WithStyles<typeof styles> {}

const customDialogActions: React.FunctionComponent<IProps> = ({ classes, children }) => (
  <DialogActions classes={{ root: classes.root, spacing: classes.spacing }}>
    {children}
  </DialogActions>
);

const styles = createStyles({
  root: {
    position: 'relative',
    paddingLeft: 90,
    paddingRight: 90,
    paddingTop: 30,
    paddingBottom: 55,
    textAlign: 'right',
    margin: 0,
  },
  spacing: {},
});

export default withStyles(styles)(customDialogActions);
